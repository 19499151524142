<template>

    <div class="wrapContentIndex wrapContentPackage">
        <div class="Package">
            <div class="contentOverlay contentOverlaySidebar" :class="{'popupProvider': isHideProvider}">
                <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
                <div class="contentListProviderLeft menuStyle">
                    <div class="contentListProviderLeftInfo">
                        <h3>เมนู</h3>
                        <MenuLeft :is_slidebar="true"></MenuLeft>
                    </div>
                </div>
            </div>
            <div class="wrapContentIndexTitle">
                <button class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
                <div class="bx-mobile" v-if="false">
                    <div class="hamburger-lines" @click="OpenListProvider()">
                        <span class="line line1"></span>
                        <span class="line line2"></span>
                        <span class="line line3"></span>
                    </div>
                </div>
                <h3>โปรโมชั่น</h3>
            </div>
            <div class="navTopDesktop">
                <MenuNavTop @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop>
            </div>
            <MenuBar  :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"  :goHome="is_go_home" ></MenuBar>

            <div class="box-img hide">
                <!--                    <div class="box-user-img"><img src="@/assets/images/icon/set-icon/icon-shop.png"/></div>-->
                <!--            <div>-->
                <strong>แพ็คเกจ</strong>
                <!--                <small>Package</small>-->
                <!--            </div>-->
            </div>
            <!-- <MenuBar v-if="!embedMode" @openGame="openGame" @toggleMenu="openLeftMenu"/> -->
            <!-- <transition name="slide">
                <MenuLeft @close="closeLeftMenu" v-show="showMenuLeft"></MenuLeft>
            </transition> -->
            <div class="wrapContentIndexInfo">
                <div class="contentFlexIndex">
                    <div class="contentFlexIndexLeft active">
                        <MenuLeft></MenuLeft>
                    </div>
                    <div class="contentFlexIndexRight">
                        <div class="container">
                            <div class="TitleContainer">
                                <h3>โปรโมชั่น</h3>
                            </div>
                            <div class="content-page">

                                <div class="content-page-package">

                                    <div class="container-fluid-game container-fluid-game-spin">

                                        <div class="wrap-content-games">

                                            <div class="wrapper-box-games wrapper-box-shop">
                                                <div class="wrapper-shop-content">
                                                    <h2 style="margin-top: 0;">แพ็คเก็จสล็อต</h2>
                                                    <h3 class="title">เกมส์ ขายสปินโบนัส</h3>
                                                    <div class="shop-content shop-content-play">
                                                        <carousel
                                                                :per-page="2.1"
                                                                :per-page-custom="[[768, 4], [1400, 4.5]]"
                                                                :mouseDrag="true"
                                                                :navigationEnabled="true"
                                                                :paginationEnabled="false"
                                                                navigationNextLabel="❯"
                                                                navigationPrevLabel="❮"
                                                        >
                                                            <slide v-for="(game_name,key) of prgmatci_game" :key="key" >
                                                                <div class="shop-card card-red">
                                                                    <div class="card-hole"><span></span></div>
                                                                    <div class="box-detail-shop">
                                                                        <div class="topic-pack-name">
                                                                            <span>แพ็คซื้อสปินโบนัส</span>
                                                                        </div>
                                                                        <h3>{{game_name}}</h3>
                                                                        <div class="box-detail-shop-thumb">
                                                                            <div class="shop-ribbon ribbon-pay-bonus">
                                                                                <strong>ซื้อ</strong>
                                                                                <span>Spin Bonus</span>
                                                                                <div class="shop-ribbon-info"></div>
                                                                            </div>
                                                                            <img :src="'https://asia.pragmaticplay.net/game_pic/rec/325/'+key+'.png'">
                                                                        </div>
                                                                    </div>
                                                                    <a @click="goToGame(key)">

                                                                        <div class="shop-card-btn btnPlay">
                                                                            <span>เล่นเกม</span>
                                                                        </div>
                                                                        <div class="shop-card-bg">
                                                                            <div></div>
                                                                        </div>
                                                                        <div class="shop-card-bg-hover">
                                                                            <div></div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </slide>
                                                        </carousel>
                                                    </div>
                                                </div>
                                                <div class="wrapper-shop-content">
                                                    <h3 class="title">แพ็คเก็จสล็อตสุดคุ้ม</h3>

                                                    <div class="shop-content">
                                                        <carousel
                                                                :per-page="2.1"
                                                                :per-page-custom="[[768, 4], [1400, 4.5]]"
                                                                :mouseDrag="true"
                                                                :navigationEnabled="true"
                                                                :paginationEnabled="false"
                                                                navigationNextLabel="❯"
                                                                navigationPrevLabel="❮"
                                                        >
                                                            <slide v-for="(gp,key) of GAME_PACKET_LIST[0]" :key="key">
                                                                <div  class="shop-card shop-card-money card-gold" :class="{'shop-card-sold-out':(gp.packet_limit>0 &&  gp.balance_user_count<=0)}">
                                                                    <div class="time-event" v-if="gp.packet_end_datetime">
                                                                        <span :id="'packet-'+gp.packet_id">End in ?</span>
                                                                    </div>
                                                                    <div v-if="gp.packet_item_addition_qty>0" class="shop-ribbon free-ribbon-top" style="right: -8px;left: auto; top: -10px;">
                                                                        <strong>รับเพิ่ม</strong>
                                                                        <h4>
                                                                            {{gp.packet_item_addition_qty}}
                                                                        </h4>
                                                                        <div class="shop-ribbon-info"></div>
                                                                    </div>
                                                                    <div v-if="gp.packet_is_best_seller" class="tag-best-sale"><span>ขายดี</span></div>
                                                                    <div class="card-hole"><span></span></div>
                                                                    <div class="box-detail-shop">
                                                                        <div class="topic-pack-name">
                                                                            <span>แพ็คเกจสล็อต</span>
                                                                        </div>
                                                                        <h3>{{gp.packet_name}}</h3>
                                                                        <p>+50 Credit</p>
                                                                        <div class="box-detail-shop-thumb">
                                                                            <div v-if="gp.packet_discount" class="shop-ribbon ribbon-red" style="right: 6px;left: auto; top: 6px;">
                                                                                <strong>ลด</strong>
                                                                                <h4>฿{{gp.packet_discount}}</h4>
                                                                                <div class="shop-ribbon-info"></div>
                                                                            </div>
                                                                            <img src="@/assets/images/coin-big-1.png">
                                                                        </div>
                                                                        <div class="value-pack">
                                                                            <strong>฿{{gp.packet_price}}</strong>
                                                                            <span class="value-discount">฿{{gp.packet_full_price}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="shop-card-btn btnPay purchase_packet_btn" :packet_id="gp.packet_id" @click="purchasePacket(gp.packet_id,0)">
                                                                        <span>ซื้อแพ็กเกจ</span>
                                                                    </div>
                                                                    <div class="shop-card-bg">
                                                                        <div></div>
                                                                    </div>
                                                                    <div class="shop-card-bg-hover">
                                                                        <div></div>
                                                                    </div>
                                                                    <span v-if="gp.packet_limit>0" class="limit-shop">{{gp.balance_user_count>0?'ซื้อได้ '+gp.balance_user_count+' ครั้ง':'หมดจำนวนที่ซื้อได้'}}</span>

                                                                </div>
                                                            </slide>
                                                        </carousel>

                                                        <!-- <div class="shop-card card-gold">

                                                            <div class="card-hole"><span></span></div>
                                                            <div class="topic-pack-name">
                                                                <span>แพ็คเกจสล็อต</span>
                                                            </div>
                                                            <div class="box-detail-shop">
                                                                <h3>เครดิต 500</h3>
                                                                <p>+500 Credit</p>
                                                                <div class="box-detail-shop-thumb">
                                                                    <div class="shop-ribbon ribbon-red" style="right: 6px;left: auto; top: 6px;">
                                                                        <strong>ลด</strong>
                                                                        <h4>฿150</h4>
                                                                        <div class="shop-ribbon-info"></div>
                                                                    </div>
                                                                    <img src="@/assets/images/coin-big-1.png">
                                                                </div>
                                                                <div class="value-pack">
                                                                    <strong>฿350</strong>
                                                                    <span class="value-discount">฿500</span>
                                                                </div>
                                                            </div>
                                                            <div class="shop-card-btn btnPay purchase_packet_btn" packet_id="5">
                                                                <span>ซื้อแพ็กเกจ</span>
                                                            </div>
                                                            <div class="shop-card-bg">
                                                                <div></div>
                                                            </div>
                                                            <div class="shop-card-bg-hover">
                                                                <div></div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="wrapper-shop-content">
                                                    <h2>แพ็คเก็จสปินคุ้มค่า</h2>
                                                    <div class="shop-content">
                                                        <carousel
                                                                :per-page="2.1"
                                                                :per-page-custom="[[768, 4], [1400, 4.5]]"
                                                                :mouseDrag="true"
                                                                :navigationEnabled="true"
                                                                :paginationEnabled="false"
                                                                navigationNextLabel="❯"
                                                                navigationPrevLabel="❮"
                                                        >
                                                            <slide v-for="(gp,key) of GAME_PACKET_LIST[1]" :key="key" >
                                                                <div  class="shop-card shop-card-spin" :class="[card_spin_level_list[key],{'shop-card-sold-out':(gp.packet_limit>0 &&  gp.balance_user_count<=0)}]">
                                                                    <div class="shop-ribbon free-ribbon-top" style="right: -8px;left: auto; top: 0;" >
                                                                        <strong>ฟรีสปิน</strong>
                                                                        <h4>x{{gp.packet_item_qty}}</h4>
                                                                        <div class="shop-ribbon-info"></div>
                                                                    </div>
                                                                    <div class="time-event" v-if="gp.packet_end_datetime">

                                                                        <span :id="'packet-'+gp.packet_id">End in ?</span>
                                                                    </div>
                                                                    <div class="card-hole"><span></span></div>

                                                                    <div class="box-detail-shop">
                                                                        <div class="topic-pack-name">
                                                                            <span>แพ็คเกจสุดคุ้ม</span>
                                                                        </div>
                                                                        <h3>{{gp.packet_name}}</h3>
                                                                        <p>{{gp.name_case}}</p>
                                                                        <div class="box-detail-shop-thumb">
                                                                            <div v-if="gp.packet_item_addition_qty"  class="shop-ribbon ribbon-red" style="right: 6px;left: auto; top: 6px;">
                                                                                <strong>เพิ่ม</strong>
                                                                                <h4>{{gp.packet_item_full_qty}}+{{gp.packet_item_addition_qty}}</h4>
                                                                                <div class="shop-ribbon-info"></div>
                                                                            </div>
                                                                            <img :src="gp.item_img">
                                                                        </div>
                                                                        <div class="value-pack">
                                                                            <strong>฿{{gp.packet_price}}</strong>
                                                                            <span class="value-discount">฿{{gp.packet_full_price}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="shop-card-btn btnPay purchase_packet_btn" :packet_id="gp.packet_id" @click="purchasePacket(gp.packet_id,1)">
                                                                        <span>ซื้อแพ็กเกจ</span>
                                                                    </div>
                                                                    <div class="shop-card-bg"><div></div></div>
                                                                    <div class="shop-card-bg-hover"><div></div></div>
                                                                    <span v-if="gp.packet_limit>0" class="limit-shop">{{gp.balance_user_count>0?'ซื้อได้ '+gp.balance_user_count+' x ครั้ง':'หมดจำนวนที่ซื้อได้'}}</span>
                                                                </div>
                                                            </slide>
                                                        </carousel>
                                                    </div>
                                                    <div class="shop-content">
                                                        <carousel
                                                                :per-page="2.1"
                                                                :per-page-custom="[[768, 4], [1400, 4.5]]"
                                                                :mouseDrag="true"
                                                                :navigationEnabled="true"
                                                                :paginationEnabled="false"
                                                                navigationNextLabel="❯"
                                                                navigationPrevLabel="❮"
                                                        >
                                                            <slide v-for="(gp,key) of GAME_PACKET_LIST[2]" :key="key" >
                                                                <div  class="shop-card shop-card-slot" :class="[card_bonus_slot_level_list[key],{'shop-card-sold-out':(gp.packet_limit>0 &&  gp.balance_user_count<=0)}]">
                                                                    <div class="shop-ribbon free-ribbon-top" style="right: -8px;left: auto; top: 0;">
                                                                        <strong>ฟรีสปิน</strong>
                                                                        <h4>x{{gp.packet_item_qty}}</h4>
                                                                        <div class="shop-ribbon-info"></div>
                                                                    </div>
                                                                    <div class="time-event" v-if="gp.packet_end_datetime">

                                                                        <span :id="'packet-'+gp.packet_id">End in ?</span>
                                                                    </div>
                                                                    <div class="card-hole"><span></span></div>

                                                                    <div class="box-detail-shop">
                                                                        <div class="topic-pack-name">
                                                                            <span>แพ็คเกจสุดคุ้ม</span>
                                                                        </div>
                                                                        <h3>{{gp.packet_name}}</h3>
                                                                        <p>{{gp.name_case}}</p>
                                                                        <div class="box-detail-shop-thumb">
                                                                            <div v-if="gp.packet_item_addition_qty"  class="shop-ribbon ribbon-red" style="right: 6px;left: auto; top: 6px;">
                                                                                <strong>เพิ่ม</strong>
                                                                                <h4>{{gp.packet_item_full_qty}}+{{gp.packet_item_addition_qty}}</h4>
                                                                                <div class="shop-ribbon-info"></div>
                                                                            </div>
                                                                            <img :src="'https://asia.pragmaticplay.net/game_pic/rec/325/'+gp.ref_id+'.png'">
                                                                        </div>
                                                                        <div class="value-pack">
                                                                            <strong>฿{{gp.packet_price}}</strong>
                                                                            <span class="value-discount">฿{{gp.packet_full_price}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="shop-card-btn btnPay purchase_packet_btn" :packet_id="gp.packet_id" @click="purchasePacket(gp.packet_id,2)">
                                                                        <span>ซื้อแพ็กเกจ</span>
                                                                    </div><div class="shop-card-bg">
                                                                    <div></div>
                                                                </div>
                                                                    <div class="shop-card-bg-hover">
                                                                        <div></div>
                                                                    </div>
                                                                    <span v-if="gp.packet_limit>0" class="limit-shop">{{gp.balance_user_count>0?'ซื้อได้ '+gp.balance_user_count+' ครั้ง':'หมดจำนวนที่ซื้อได้'}}</span>
                                                                </div>
                                                            </slide>
                                                        </carousel>

                                                    </div>
                                                </div>
                                            </div>
                                            <PromotionTime></PromotionTime>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navButtonMobile">
                    <MenuNav  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
                </div>
            </div>

            <!--        Modal แจ้งเตือน-->
            <div class="box-award box-alert" id="modal_alert" :class="alert_show" v-if="alert_obj">
                <div class="box-alert-content">

                    <div class="content-all">
                        <div class="content">
                            <div class="title" style="color: white">
                                <h1>{{alert_obj.title}}</h1>
                            </div>
                            <p>{{alert_obj.content}}</p>
                            <div class="footer footer-center">
                                <button v-if="alert_obj.close_btn" type="button" class="btn btn-default-outline" @click="closeAlert()" >Close
                                </button>
                                <button v-if="alert_obj.ok_btn" type="button" class="btn" @click="confirmAlert()" >OK</button>
                            </div>
                        </div>
                        <div class="content-bg"></div>
                    </div>



                </div>
                <div class="overlay-box"></div>
            </div>
            <!--        ได้รับของรางวัล-->
            <div class="box-award" v-if="claim_bonus_obj">
                <div class="box-alert-content">
                    <div class="title title-center">
                        <h1>{{claim_bonus_obj.title}}</h1>
                    </div>
                    <div class="content-all">
                        <div class="content">
                            <div class="row-box-award">
                                <ul>
                                    <li>
                                        <div class="item-award">
                                            <div class="set-coin"><img :src="claim_bonus_obj.img" alt="" /></div>
                                            <span class="Value-item">{{claim_bonus_obj.packet_name}}</span>
                                        </div>
                                        <h5>{{claim_bonus_obj.packet_name2}}</h5>
                                    </li>
                                    <li v-if="false">
                                        <div class="item-award" >
                                            <div class="set-coin"><img src="@/assets/images/coin-small-1.png" alt="" /></div>
                                            <span class="Value-item">฿10</span>
                                        </div>
                                        <h5>Credit</h5>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer footer-center">
                                <button type="button" class="btn bg-gradient-btn cliam_bonus_btn" @click="confirmPurchasePakcet(claim_bonus_obj.packet_id,claim_bonus_obj.packet_type)"  >{{claim_bonus_obj.btn_title}}</button>
                                <button type="button" class="btn" @click="cancelPurchase()">ยกเลิก</button>
                            </div>
                        </div>
                        <div class="content-bg"></div>
                    </div>



                </div>
                <div class="overlay-box"></div>
            </div>
        </div>
    </div>

    
</template>
<script>
    var is_ios = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i));

    import MenuBar from '@/components/MenuBar'
    import { Carousel, Slide } from 'vue-carousel'
    // import MenuLeft from '@/components/MenuLeft'
    import store from '@/store'
    import $ from 'jquery'
    import moment from 'moment';
    window.jQuery = $;
    window.$ = $;
    // import axiosWrapper from '@/axiosWrapper'
    import PromotionTime from '@/components/user/PromotionTime'

    import MenuLeft from '@/components/MenuLeft'
    import MenuNav from '@/components/MenuNav'
    import MenuNavTop from '@/components/MenuNavTop'
export default {
    name: 'Package',
    components: {
        MenuBar,
        Carousel,
        Slide,
        // MenuLeft,
        PromotionTime,
        MenuLeft,
        MenuNav,
        MenuNavTop,
    },

    data() {
    
        return {
            is_go_home: '',
            showChatWithAdminNumber:0,
            showDepositWithdrawNumber:0,
            menuNumber :0,
            isHideProvider: false,
            isHideListGame: true,
            isHideListGameContent: false,

            PES_BASE_URL: '',
            embedMode:null,
            prgmatci_game: this.getPragmaticGame(),
            GAME_PACKET_LIST:[
                [],
                [],
                []
            ],
            card_spin_level_list:[
                'shop-card-event card-premium-event-v1',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
            ],
            card_bonus_slot_level_list:[
                'shop-card-event card-premium-event-v2',
                'card-premium-event-v2',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-premium-event-v2',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
                'card-premium-event-v2',
                'card-gold-d1',
                'card-silver',
                'card-greed',
                'card-brown',
            ],

            alert_obj:null,
            claim_bonus_obj:null,  
            alert_show:'', 
            after_ok:null,
            
        }
    },
    computed: {
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            user_profile() {
                return store.state.user_profile
            },
            appImageProfile(){
                return store.state.appInfo.app_image_profile
            },
        },
    methods:{
        goHome() {
                this.is_go_home='Index';
                let self = this;
                setTimeout(()=>{
                    self.is_go_home='';
                },1000)
                
        },
        showChatWithAdmin(){
                this.showChatWithAdminNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showChatWithAdminNumber=0;
                },100);
                
            },
            showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

        showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
        },

        OpenListProvider() {
            this.isHideProvider = true;
        },
        ClosePopupProvider() {
            this.isHideProvider = false;
        },
        OpenListAllGame(){
            this.isHideListGame = false;
            this.isHideListGameContent = true;
        },
        CloseListAllGame() {
            this.isHideListGame = true;
            this.isHideListGameContent = false;
        },

        toIndexPopularPage() {
            this.$router.push({name: 'IndexPopular'})
        },
        toIndexCasinoPage() {
            this.$router.push({name: 'IndexCasino'})
        },
        toIndexSlotPage() {
            this.$router.push({name: 'IndexSlot'})
        },
        toIndexFishingPage() {
            this.$router.push({name: 'IndexFishing'})
        },
        toIndexSportPage() {
            this.$router.push({name: 'IndexSport'})
        },
        toIndexLottoPage() {
            this.$router.push({name: 'IndexLotto'})
        },
        toIndexUnboxPage() {
            this.$router.push({name: 'IndexUnbox'})
        },

        checkPackage(packet_id,packet_type,gp){
            gp = gp?gp:this.getGP(packet_id,packet_type);
            let pass=true;
            let pes_profile = this.pes_profile;
            // let user_profile = this.user_profile;
            if(gp){
                if(gp.packet_bank_acocunt_date){
                    let account_date =pes_profile.create_datetime
                    if(moment(account_date).format('x')<moment(gp.packet_bank_acocunt_date).format('x')){
                        
                        pass=false;
                    }
                }
                
            }
            return pass;
        },
        update_pes_data(){
            store.commit('updatePesProfile', this.pes_profile);
        },
        openGame(){

        },
        openLeftMenu(){

        },
        showMenuLeft(){

        },
        closeLeftMenu(){

        },
        getGamePacket(){
            var self = this;
            if(this.pes_profile){
            $.get(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/game/packet/get.php?token=`+this.pes_profile.token,function(response){
                
                
                var game_packet_list=[
                    [],
                    [],
                    []
                ];
                for(var i=0;i<response.data.length;i++){
                    let pass=self.checkPackage(response.data[i].packet_id,response.data[i].packet_type,response.data[i]);
                    if(pass){
                        game_packet_list[response.data[i].packet_type].push(response.data[i]);
                        if(response.data[i].packet_end_datetime){
                            var gp = (response.data[i]);
                            self.setTimer(gp);
                            
                        }
                    }else{
                        console.log(response.data[i].packet_id)
                    }
                    

                }
                self.GAME_PACKET_LIST = game_packet_list;
                // console.log(
                //     self.GAME_PACKET_LIST[0]
                // )

            })
        
            }else{
            $.get(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/game/packet/get.php`,function(response){
                
                
                var game_packet_list=[
                    [],
                    [],
                    []
                ];
                for(var i=0;i<response.data.length;i++){
                    game_packet_list[response.data[i].packet_type].push(response.data[i]);
                    if(response.data[i].packet_end_datetime){
                        var gp = (response.data[i]);
                        self.setTimer(gp);
                        
                    }
                }
                self.GAME_PACKET_LIST = game_packet_list;
                // console.log(
                //     self.GAME_PACKET_LIST[0]
                // )

            })
        
            }

        },
        setTimer(gp){
            var toDate = is_ios? new Date(moment(gp.packet_end_datetime).format('YYYY/MM/DD HH:mm:ss')): new Date(gp.packet_end_datetime);
                        // console.log(gp.packet_id,toDate);
                        timeBetweenDatesV2(toDate, 'packet-'+gp.packet_id);
                        setInterval(function(){
                            timeBetweenDatesV2(toDate, 'packet-'+gp.packet_id);
                
                        },1000);
        },
        getPragmaticGame(){
            var list ={
                                    'vs40wildwest':'Wild West Gold',
                                    'vs20fruitsw':'Sweet Bonanza',
                                    'vs20sbxmas':'Sweet Bonanza Xmas',
                                    'vs20kraken':'Release the Kraken',
                                    'vswaysrhino':'Great Rhino Megaways',
                                    'vs20fruitparty':'Fruit Party',
                                    'vs1600drago':'Drago - Jewels of Fortune',
                                    'vs10bookoftut':'Book of Tut'
                                    };

                                    return list;
        },
        purchasePacket(packet_id,packet_type){
            var gp=this.getGP(packet_id,packet_type);
            
            var claim_bonus_obj={
                title:''+gp.packet_name,
                packet_name : gp.packet_name,
                packet_name2 : gp.packet_name,
                img:'/images/coin-big-1.png',
                btn_title:'ยืนยันการซื้อ',
                packet_id :gp.packet_id,
                packet_type :  packet_type,
            }
            var ref_id = gp.ref_id;
            if(packet_type==1){
                claim_bonus_obj.img = gp.item_img;
            }else if(packet_type==2){
                claim_bonus_obj.img = 'https://asia.pragmaticplay.net/game_pic/rec/325/'+ref_id+'.png';
            }
            this.claim_bonus_obj=claim_bonus_obj;
            

        },
        closeAlert(){
            var self = this;
            this.alert_show ='hide';
            setTimeout(() => {
                self.alert_obj=null;
            }, 1000*0.3);
            
        },
        confirmAlert(){
            var self = this;
            this.closeAlert(); 
            console.log(this.after_ok);  
            if(this.after_ok){
                if(this.after_ok.packet_type==1){
                    window.location.href='/unbox-play/'+this.after_ok.ref_id;
                }else if(this.after_ok.packet_type==2){
                    window.open(`${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=desktop&by=&service=pragmatic&code=`+this.after_ok.ref_id+'&token='+self.pes_profile.token+(self.appImageProfile?('&logo_img='+self.appImageProfile):''),'_blank');
                }
            }
            
            
            
            

        },
        confirmPurchasePakcet(packet_id,pakcet_type){
                var gp = this.getGP(packet_id,pakcet_type);
                var self = this;
                if(self.pes_profile){
                    $.post( `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/game/packet/purchase.php`,{packet_id:packet_id,token:this.token},function(response){
                        if(response.code==1){
                            
                            gp.balance_user_count--;
                            self.updateGP(gp);
                            self.claim_bonus_obj=null;
                            // var alert_obj_after_ok=null;
                            // var alert_obj={
                            //     title:'Message',
                            //     content:'Purchase Success.',
                            //     ok_btn:true,
                            //     close_btn:false,
                            //     after_ok: null,
                            // }
                            
                            var data = response.data;
                            var balance= data.balance; 
                            
                            self.pes_profile.THB_free = balance;
                            

                            if(gp.packet_type==1){
                                var spiner=self.pes_profile.sp_bonus_item.spiner;
                                var count =gp.packet_item_qty;
                                if(gp.ref_id in spiner){
                                    count+=spiner[gp.ref_id];
                                }
                                spiner[gp.ref_id]=count;
                                self.pes_profile.sp_bonus_item.spiner=spiner;
                                self.update_pes_data();
                                // console.log(count);
                                // var conf= confirm('Do you want spin now?');
                                // if(conf){
                                //     window.location.href='/unbox-play/'+gp.ref_id;
                                // }
                               var alert_obj_after_ok={
                                    title:'Confirm',
                                    content:'Do you want spin now?',
                                    ok_btn:true,
                                    close_btn:true,
                                    after_ok:"",
                                }
                                // self.after_ok =gp;
                                // // alert_obj.after_ok=alert_obj_after_ok;
                                // self.alert_obj =alert_obj_after_ok;
                                // self.alert_show ='';

                            }else if(gp.packet_type==2){
                                
                                // var r= confirm('Do you want go to slot game now?');
                                // if(r){
                                //      window.open(`${self.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=desktop&by=&service=pragmatic&code=`+gp.ref_id,'_blank')
                                // }
                                // var cmd =`window.open("${self.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=desktop&by=&service=pragmatic&code=`+gp.ref_id+'&token='+self.pes_profile.token+ "\",'_blank')";
                                alert_obj_after_ok={
                                    title:'Confirm',
                                    content:'Do you want go to slot game now?',
                                    ok_btn:true,
                                    close_btn:true,
                                }

                            }
                            self.after_ok =gp;
                            self.alert_obj =alert_obj_after_ok;
                            self.alert_show ='';

                        }else{
                            var title = response.msg;
                            // title ='Require bank account';
                            if(title=='Require bank account'){
                                sessionStorage.removeItem('new_member_register');
                                sessionStorage.removeItem('is_packet_show');
                                self.$router.push({name: 'Account', query: {to_create_account: 'yes',packet_id:packet_id}})
                                // self.$refs['go-to-add-bank-account'].show()
                            }else{
                                if(title=='Require bank account'){
                                    title='กดเพิ่มบัญชีเพื่อซื้อแพ็คเกจ';
                                }else if(title.match('Account must register after ')){
                                    title = title.replace("Account must register after ","บัญชีต้องสมัครหลัง ");
                                }else if(title=='Balance not enough.'){
                                    title='ยอดเงินคงเหลือไม่พอ';
                                }else if(title=='Packet has limit.'){
                                    title='ไม่สามารถซื้อแพ็คเกจนี้ได้อีก'
                                }   
                                self.claim_bonus_obj=null;
                                var alert_obj2={
                                    title:'Message',
                                    content:title,
                                    ok_btn:true,
                                    close_btn:false,
                                    // after_ok: alert_obj_after_ok,
                                }
                                self.alert_obj = alert_obj2;
                                self.alert_show ='';
                            }
                            
                            
                            // alert(response.msg);
                        }
                        

                    });
        
                }else{
                    this.$modal.show('AuthModal');
                }
     
        },
        getGP(packet_id,packet_type){
            var gp;
            for(var i=0;i<this.GAME_PACKET_LIST[packet_type].length;i++){
                if(packet_id == this.GAME_PACKET_LIST[packet_type][i].packet_id){
                    gp = this.GAME_PACKET_LIST[packet_type][i];
                    break;
                }
            }
            return gp;
        },
        updateGP(gp){
            let packet_id = gp.packet_id;
            let packet_type = gp.packet_type;
            for(let i=0;i<this.GAME_PACKET_LIST[packet_type].length;i++){
                if(packet_id == this.GAME_PACKET_LIST[packet_type][i].packet_id){
                    this.GAME_PACKET_LIST[packet_type][i] = gp;
                    break;
                }
            }

        },
        cancelPurchase(){
            this.claim_bonus_obj =null;
        },
        goToGame(key){
            if(this.pes_profile){
                var url=`${this.envDomains.VUE_APP_PES_BASE_URL}/game/casinoonline/sa_sexy_redirect.php?mode=mobile&by=&service=pragmatic&code=`+key+'&token='+this.pes_profile.token+(this.appImageProfile?('&logo_img='+this.appImageProfile):'');
                window.open(url,'_blank');
            }else{
                this.$modal.show('AuthModal');
            }
            
        }
    },
    created() {
        this.PES_BASE_URL = this.envDomains.VUE_APP_PES_BASE_URL
    },
    async mounted() {
        this.getGamePacket();
    }

}
function timeBetweenDatesV2(toDate, prefix = '') {
        // console.log(prefix);
        var dateEntered = toDate;
        var now = new Date();
        var difference = dateEntered.getTime() - now.getTime();
        if (difference <= 0) {
            // Timer done
            var text = $('#'+prefix).text();
            if(text=='หมดเวลา'){
                return 1;
            }else{
                $('#'+prefix).text('หมดเวลา');
                $('#'+prefix).parent('div').parent('div').addClass('shop-card-sold-out');
                
            }

        } else {
        
        var seconds = Math.floor(difference / 1000);
                var minutes = Math.floor(seconds / 60);
                var hours = Math.floor(minutes / 60);
                var days = Math.floor(hours / 24);
                
                minutes %= 60;
                seconds %= 60;
                hours %=24;
                if(days>0){
                        $("#" + prefix).text('เหลือเวลา '+days+' วัน');
                        
                }else{
                        if(hours>0){
                                $("#" + prefix).text('เหลือเวลา '+hours+':'+minutes+':'+seconds);

                        }else if(minutes>0){
                                $("#" + prefix ).text('เหลือเวลา '+minutes+':'+seconds);
                        }else{
                                $("#" + prefix).text('เหลือเวลา '+seconds+' วินาที');
                        }

                }
                // console.log($("#" + prefix).text());

                return 0;
        }
        }
</script>
<style src="@/assets/css/package.css" scoped></style>
<style src="@/assets/css/unbox.css" scoped></style>
<style>
    .Package .MenuBar.box-all-games{display: none!important}
    .Package span.box-txt-back:before{
        content: '\e88a';
        font: 25px "Material Icons";
        margin-right: 5px;
    }
    .Package .content-page {
        height: auto;
        overflow-y: initial;
        background-color: transparent;
    }
    .Package .container-fluid-game-spin{
        height: auto!important;
    }
    .Package .content-page{padding: 15px!important}
    .Package .activePromotionNav{
        background-color: rgba(0, 0, 0, 0.4);
    }
    @media (orientation: landscape) {
        .Package .MenuBar.box-all-games{display: block!important}
    }
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape){
        .Package .MenuBar.box-all-games{display: none!important}
        .Package .content-page {
            height: auto;
            overflow-y: initial;
            background-color: transparent;
        }
        .Package .content-page-package{
            position: relative;
            background-color: white;
            border-radius: 20px 20px 0 0;
        }
        .Package .container-fluid-game-spin {
            height: 100%!important;
        }
    }
</style>
<style scoped>

    .Package{
        background-image: linear-gradient(
                135deg
                , #F372D4 10%, #c765f0 100%);
    }
    .wrap-menu-top{
        padding: 10px 5px 10px 5px;

    }
    .container-fluid-game-spin{
        height: calc(100vh - 126px);
    }
    .content-page {
        background-color: #fff;
        border-radius: 20px 20px 0 0;
        padding: 5px 0;
    }

    .box-user-img, .box-user-img img {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .box-img, .box-menu-img {
        display: flex;
        color: #54117f;
        font-family: "Athiti", sans-serif;
    }

    .box-img {
        padding: 0 15px;
        flex: 3;
        height: unset;
        left: 25px;
    }

    .box-img small {
        display: block;
        margin-top: -5px;
    }

    .box-list-menu {
        padding: 0px 10px;
    }

    .box-list-menu p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #54117f;
        font-family: "Athiti", sans-serif;
    }

    .wrapper-box{
        margin-top: 15px;
    }

    .box-games-list{
        width: 145px;
        padding: 0px;
        border-radius: 10px;
    }
    .box-img-list{
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        height: 80px;
        background-image: url(../assets/images/casino/sexy-baccarat/sexy-bacarat.png);
    }

    .box-type{
        font-size: 13px;
        margin-bottom: 5px;
        clear: both;
        height: 20px;
    }

    .box-type > .txt-header{
        color: #54117f;
        font-weight: 600;
    }

    .box-type a{
        color: #888;
    }

    .box-type img{
        height: 20px;
        margin-top: -5px;
    }

    .wrapper-box-type{
        margin-bottom: 10px;
    }
    @media (orientation: landscape){
        .MenuBarUnbox .MenuBar.box-all-games{display: block}
        .content-page {
            /*position: relative;*/
            /*margin: 20px 10px 0 42%;*/
        }
        .MenuBarUnbox .wrapbox-list-games{
            height: calc(100vh - 50px);
        }

    }
</style>