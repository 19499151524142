<template>

    <div class="wrapContentIndex LottoNumber LottoNumberResult LottoNumberResultAll">
        <div class="wrapContentIndexTitle ">
            <div class="IndexTitleInto">
                <button style="padding: 0 10px;" class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
                <h3>ผลการออกรางวัล</h3>
            </div>
        </div>
        <div class="wrapContentIndexInfo wrapContentIndexInfoResultGovernment">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="container">
                        <div class="boxHitecCasino boxGovernmentLottery">
                            <div class="LottoNumberContent">
                                <div class="LottoNumberContentBack ResultNumLotto">
                                    <div class="LottoNumberListGroup">
                                        <div class="LottoNumberList">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/VN.png">
                                                    <div class="group">
                                                        <h4>หวยฮานอย</h4>
                                                        <span>งวดวันที่ 12/12/2565</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>789</strong>
                                                            <span>เลขท้าย 3 ตัว</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>64</strong>
                                                            <span>เลขท้าย 2 ตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 style="text-align: center">ผลรางวัลย้อนหลัง</h4>
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <td>วันที่</td>
                                                <td>3 ตัวบน</td>
                                                <td> 2 ตัวล่าง</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>31 ตุลาคม 2022 </td>
                                                <td>182</td>
                                                <td>18</td>
                                            </tr>
                                            <tr>
                                                <td>31 ตุลาคม 2022 </td>
                                                <td>182</td>
                                                <td>18</td>
                                            </tr>
                                            <tr>
                                                <td>31 ตุลาคม 2022 </td>
                                                <td>182</td>
                                                <td>18</td>
                                            </tr>
                                            <tr>
                                                <td>31 ตุลาคม 2022 </td>
                                                <td>182</td>
                                                <td>18</td>
                                            </tr>
                                            <tr>
                                                <td>31 ตุลาคม 2022 </td>
                                                <td>182</td>
                                                <td>18</td>
                                            </tr>
                                            <tr>
                                                <td>31 ตุลาคม 2022 </td>
                                                <td>182</td>
                                                <td>18</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


    export default {
        name: 'LottoNumberResultNumLotto',
        components: {
        },
    }
</script>