export default ({
    namespaced: true,
    state: {
        cart_list : [],
    },
    mutations :{
        updateCartList(state, ctx) {
            state.cart_list = ctx
        },
    }
    
});