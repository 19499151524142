<template>

    <div class="wrapContentIndex wrapContentProfile">
        <div class="ListGame ListGame-profile">
            <div class="contentOverlay" :class="{'popupProvider': isHideProvider}">
                <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
                <div class="contentListProviderLeft menuStyle">
                    <div class="contentListProviderLeftInfo">
                        <h3>เมนู</h3>
                        <MenuLeft :is_slidebar="true"></MenuLeft>
                    </div>
                </div>
            </div>
            <div class="wrapContentIndexTitle">
                <button style="padding: 0 10px;" class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
                <h3>โปรไฟล์</h3>
            </div>

            <div class="navTopDesktop">
                <MenuNavTop @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop>
            </div>
            <!--        Text-->

            <div class="box-edit-profile" v-show="isShowEditDisplayName">
                <div class="box-edit-profile-info">
                    <div class="box-edit-profile-top">
                        <h4>แก้ไข Display Name</h4>
                        <button  @click="cancelEditDisplayName"
                                 class="btn btn-link btn-sm"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="box-edit-profile-content">
                    <span >
                                                        <input v-model="display_name" type="text" class="form-control">
                                                    </span>
                        <div class="box-edit-profile-group">
                            <button @click="confirmEditDisplayName"
                                    class="btn btn-link btn-sm">ตกลง</button>

                        </div>
                    </div>

                </div>

            </div>
            <div class="box-img-top-profile hide">
                <div class="box-user-detail">
                    <strong>โปรไฟล์</strong>
                </div>

            </div>
            <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/>
            <MenuBar :level_up_obj="level_up_obj" :daily_bonus_obj="daily_bonus_obj" :daily_mission_obj="daily_mission_obj"
                     :claim_bonus_obj="claim_bonus_obj" :alert_obj="alert_obj"
                     :daily_bonus_show="daily_bonus_show" :daily_mission_show="daily_mission_show"
                     :sp_daily_mission_bonus_date="sp_daily_mission_bonus_date"
                     :alert_obj_show="alert_obj_show"
                     :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"
                     :goHome="is_go_home"
            >
            </MenuBar>
            <div class="wrapContentIndexInfo">
                <div class="contentFlexIndex">
                    <div class="contentFlexIndexLeft active">
                        <MenuLeft></MenuLeft>
                    </div>
                    <div class="contentFlexIndexRight">
                        <div class="container">
                            <div v-if="is_ready" class="content-page">
                                <PromotionTime></PromotionTime>

                                <div class="wrapper-box">



                                    <div class="content-profile-all">
                                        <div class="content-profile-all-info">

                                            <div class="bxProfileColumn">
                                                <div class="ground-top-all">
                                                    <div class="ground-top-all-info">
                                                        <!--                        profile-->
                                                        <div class="boxProfile-row box-content-profile-info">
                                                            <div class="section-img-profile">
                                                                <div class="section-img-profile-left ">
                                                                    <!--                            <div class="frame-profile frame-premium-2"></div>-->
                                                                    <div class="box-img-profile"
                                                                         @click="openChangeImageProfile"
                                                                         :style="{'background-image' : `url(${user_profile.picture_url || '/images/avatar.png'})`}"></div>
                                                                    <div class="bg-profile" :class="{'sp-premium-5':avatar.bg}"
                                                                         :style="{'background-image':'url('+(avatar.bg? pes_profile.sp_bonus_item.avatar.bg.url:'')+')'}"></div>
                                                                </div>

                                                                <!-- <div class="box-name-profile">{{sp_info.profile.username}}</div> -->
                                                                <div class="section-img-profile-right">
                                                                    <div class="box-name-profile">
                                                                        <div style="display: flex;">
                                                                            <div class="box-name-profile-info">
                                                                                <h3>{{user_profile.pes_username}}</h3>
                                                                                <div class="box-name-profile-info-content">
                                                                                    <div class="box-level-incoming_msg"
                                                                                         :class="'bx-lv-'+(Math.floor(sp_info.level.sp_user_level/10))">
                                                                                        <span>LV {{sp_info.level.sp_user_level}}</span>
                                                                                    </div>
                                                                                    <span  v-show="!isShowEditDisplayName">
                                                        {{user_profile.display_name}}
                                                    </span>
                                                                                    <button v-show="!isShowEditDisplayName" @click="toEditDisplayName"
                                                                                            class="btn btn-link btn-sm btn-edit-name">
                                                                                        <i class="fas fa-pen"></i> แก้ไข
                                                                                    </button>
                                                                                </div>


                                                                            </div>



                                                                        </div>
                                                                    </div>
                                                                    <div class="box-flex-center">

                                                                        <div @click="goChangePassword" class="btn btn-password">เปลี่ยนรหัสผ่าน</div>
                                                                    </div>


                                                                </div>



                                                            </div>
                                                        </div>

                                                        <!--                        ยอดเงินทั้งหมด-->
                                                        <div class="boxProfile-row box-content box-content-profile">
                                                            <!--                            balancePromotion-->
                                                            <div class="box-content-profile-info-bg">
                                                                <div class="box-content-profile-info" :class="{'balancePromotion': current_pv_id}">
                                                                    <div class="topic-box">
                                                                        <h2>ยอดเงินทั้งหมด</h2>
                                                                        <!--                                    <router-link :to="{name:'Statement'}" class="btn-stm">-->
                                                                        <!--                                        <i class="fas fa-flag"></i><span>รายงาน</span>-->
                                                                        <!--                                    </router-link>-->
                                                                    </div>
                                                                    <div class="box-content-balance">
                                                                        <strong>฿{{pes_profile.THB_free | numberFormat(2)}} </strong>
                                                                        <div class="box-content-balance-reload" @click="reloadBalance()"></div>
                                                                    </div>
                                                                    <div class="box-content-btn">
                                                                        <router-link :to="{name:'DepositWithdraw'}" class="btn-stm btn-stm-DepositWithdraw">
                                                                            <span><i class="fas fa-plus"></i> ฝาก - ถอน</span>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                                <div class="box-content-btn-group">
                                                                    <!--                                <div class="box-content-btn">-->
                                                                    <!--                                    <router-link :to="{name:'Account'}" class="btn-stm btn-stm-wallet">-->
                                                                    <!--                                        <span><i class="fas fa-wallet"></i> กระเป๋าเงิน</span>-->
                                                                    <!--                                    </router-link>-->
                                                                    <!--                                </div>-->

                                                                </div>
                                                            </div>

                                                            <div class="ground-top-all-bg"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="boxProfile-row box-get-free">
                                                    <div class="topic-box">
                                                        <h2><img src="@/assets/images/coin-small-1.png"/> รับเงินฟรี</h2>
                                                        <div class="nav-info"  @click="showAirdropNotice()"></div>
                                                    </div>
                                                    <div class="content-profile-all-info-flex">
                                                        <!--                        Air Drop-->
                                                        <div class="content-airDrop">
                                                            <div class="topic-circle">
                                                                <div class="topic-circle-info">
                                                                    <i class="fas fa-donate"></i>
                                                                </div>
                                                            </div>
                                                            <div class="topic-box">
                                                                <h2>แอร์ดรอป</h2>
                                                                <div class="nav-log" @click="showModalLog('air_drop_bonus')">
                                                                    <i class="fas fa-ellipsis-h"></i>
                                                                </div>
                                                            </div>
                                                            <div class="content-airDrop-1">
                                                                <p>เหลือเวลาอีก</p>
                                                                <div class="area-timer">
                                                                    <i class="far fa-clock"></i>
                                                                    <div class="content-airDrop-timer">
                                                                        <ul>
                                                                            <li>
                                                                                <span id="air_drop_hours">0</span>
                                                                                <small>h</small>
                                                                            </li>
                                                                            <li>
                                                                                <span>:</span>
                                                                                <small></small>
                                                                            </li>
                                                                            <li>
                                                                                <span id="air_drop_minutes">0</span>
                                                                                <small>m</small>
                                                                            </li>
                                                                            <li>
                                                                                <span>:</span>
                                                                                <small></small>
                                                                            </li>
                                                                            <li>
                                                                                <span id="air_drop_seconds">0</span>
                                                                                <small>s</small>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div class="ct-btn-get-freeCoin">
                                                                    <!--                                        รับดอกเบี้ยทุก 8 ชม.-->
                                                                    <span class="value-free">฿{{sp_info.air_drop.number}} </span>
                                                                    <small>{{sp_info.air_drop.text}}</small>
                                                                    <!-- <div class="btn btn-default" :class="{'acitve':sp_info.air_drop.sp_amount}">รับ</div> -->
                                                                    <!--                                        <div class="btn btn-default">รับ</div>-->
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--                     Free Bonus-->
                                                        <div class="box-free-coin">
                                                            <div class="topic-circle">
                                                                <div class="topic-circle-info">
                                                                    <i class="fas fa-piggy-bank"></i>
                                                                </div>
                                                            </div>
                                                            <div class="topic-box">
                                                                <h2>เล่นหมดรับเพิ่ม</h2>
                                                                <div v-if="false" class="nav-log">
                                                                    <i class="fas fa-ellipsis-h"></i>
                                                                </div>
                                                            </div>
                                                            <div class="box-free-coin-1">
                                                                <div class="box-free-coin-2">
                                                                    <p>เหลือเวลาอีก</p>
                                                                    <div class="area-timer">
                                                                        <i class="far fa-clock"></i>
                                                                        <ul class="bt-timer">
                                                                            <!--                                        <span id="days"></span>days-->
                                                                            <!--                                        <span id="hours"></span>hours-->
                                                                            <li>
                                                                                <span id="free_bonus_hours">0</span>
                                                                                <small>h</small>
                                                                            </li>
                                                                            <li>
                                                                                <span>:</span>
                                                                                <small></small>
                                                                            </li>
                                                                            <li>
                                                                                <span id="free_bonus_minutes">00</span>
                                                                                <small>m</small>
                                                                            </li>
                                                                            <li>
                                                                                <span>:</span>
                                                                                <small></small>
                                                                            </li>
                                                                            <li>
                                                                                <span id="free_bonus_seconds">00</span>
                                                                                <small>s</small>
                                                                            </li>


                                                                        </ul>
                                                                    </div>

                                                                    <div class="ct-btn-get-freeCoin">

                                                                        <span class="value-free">฿{{sp_info.free_bonus.amount}}</span>
                                                                        <small>เหลือ: {{sp_info.free_bonus.count}}</small>
                                                                        <div  class="btn btn-default" :class="{active:sp_info.free_bonus.diff>0 && sp_info.balance.THB_total<sp_info.free_bonus.min_amount && sp_info.free_bonus.count>0}"
                                                                              @click="claimFreeBonus(sp_info.free_bonus.diff>0 && sp_info.balance.THB_total<sp_info.free_bonus.min_amount && sp_info.free_bonus.count>0)">รับ
                                                                        </div>
                                                                        <!--                                            <div  class="btn btn-default active">รับ</div>-->
                                                                    </div>
                                                                </div>
                                                                <div class="ct-captcha" style="display: none;">
                                                                    <img src="/images/newCaptchaAnchor.gif">
                                                                </div>

                                                            </div>


                                                        </div>

                                                        <!--                        cashback-->
                                                        <div class="box-cashback">
                                                            <div class="topic-circle">
                                                                <div class="topic-circle-info">
                                                                    <i class="fas fa-hand-holding-usd"></i>
                                                                </div>
                                                            </div>
                                                            <div class="topic-box">
                                                                <h2>เสียได้คืน</h2>
                                                                <div class="nav-log" @click="showModalLog('cash_back_bonus')">
                                                                    <i class="fas fa-ellipsis-h"></i>
                                                                </div>
                                                            </div>
                                                            <div class="box-cashback-1">
                                                                <div class="left-cashback-timer-content">
                                                                    <p>เหลือเวลาอีก</p>
                                                                    <div class="bt-timer-day">
                                                                        <span id="cashback_days">0</span>
                                                                        <span>วัน</span>
                                                                    </div>
                                                                    <ul class="bt-timer">
                                                                        <li>
                                                                            <span id="cashback_hours">0</span>
                                                                            <small>h</small>
                                                                        </li>
                                                                        <li>
                                                                            <span>:</span>
                                                                            <small></small>
                                                                        </li>
                                                                        <li>
                                                                            <span id="cashback_minutes">00</span>
                                                                            <small>m</small>
                                                                        </li>
                                                                        <li>
                                                                            <span>:</span>
                                                                            <small></small>
                                                                        </li>
                                                                        <li>
                                                                            <span id="cashback_seconds">00</span>
                                                                            <small>s</small>
                                                                        </li>


                                                                    </ul>
                                                                </div>
                                                                <div class="cashback-btn-get-freeCoin">
                                                                    <span class="value-free">{{sp_info.cash_back.sp_amount>0?'฿'+sp_info.cash_back.sp_amount:'฿'+'0'}}</span>
                                                                    <!--                                        <small>cashback</small>-->
                                                                    <button type="button" class="btn btn-default" @click="claimCashBackBonus(sp_info.cash_back.sp_amount>0 && dayOfWeek==1)" :class="{active:sp_info.cash_back.sp_amount>0 && dayOfWeek==1}">รับ</button>
                                                                    <!--                                        <button  type="button" class="btn btn-default">รับ</button>-->
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div  v-if="false" class="boxProfile-row box-mission">
                                                    <div class="topic-box">
                                                        <h2>
                                                            <img src="@/assets/images/bg-level-up.png"/>
                                                            ภาระกิจ
                                                        </h2>
                                                        <div class="nav-info"></div></div>
                                                    <div class="box-mission-1">
                                                        <div class="box-mission-1-btn btn-checkinDay"  @click="showDailyBonus()">
                                                            <i class="far fa-calendar-alt"></i>
                                                            <span>เช็กอินรายวัน</span>
                                                        </div>
                                                        <div id="daily_mission_show_btn" class="box-mission-1-btn btn-missionDay" @click="showDailyMission()">
                                                            <i class="far fa-calendar-check"></i>
                                                            <span>ภาระกิจรายวัน</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--                    Referrals-->
                                                <div class="displayNewDesignReferral boxProfile-row box-referral box-referral-level">
                                                    <div class="topic-box topic-box-referral">
                                                        <div class="groupTopic-box-area">
                                                            <img src="@/assets/images/refer-icon.png"/>
                                                            <div class="groupTopic-box">
                                                                <h3>ชวนเพื่อนมาเล่น</h3>
                                                                <p>เหลือเวลา 3วัน 12:12:12</p>
                                                            </div>
                                                        </div>

                                                        <div class="nav-group">
                                                            <div class="nav-info nav-info-Referral"  @click="showReferralNotice()"></div>
                                                            <div class="nav-log" @click="showModalLog('referral_bonus')">
                                                                <i class="fas fa-ellipsis-h"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="tabTopReferrals">
                                                        <ul>
                                                            <li class="active">
                                                                <i class="fas fa-file-invoice"></i>
                                                                <span>ภาพรวม</span>
                                                            </li>
                                                            <li>
                                                                <i class="fas fa-users"></i>
                                                                <span>สมาชิก</span>
                                                            </li>
                                                            <li>
                                                                <i class="fas fa-coins"></i>
                                                                <span>รายได้</span>
                                                            </li>
                                                            <li>
                                                                <i class="fas fa-piggy-bank"></i>
                                                                <span>ถอนได้</span>
                                                            </li>
                                                        </ul>
                                                    </div>


                                                    <div class="box-referral-1">
                                                        <!--                                                    ภาพรวม-->
                                                        <div class="boxReferralOverview">
                                                            <div class="topic-box-mini boxIncomeReferral">
                                                                <h3><i class="fas fa-coins"></i> รายได้คงเหลือ</h3>
                                                                <strong>361.70</strong>
                                                            </div>
                                                            <div class="referralOverview">
                                                                <ul>
                                                                    <li>
                                                                        <span>รายได้ทั้งหมด</span>
                                                                        <strong>50,000.00</strong>
                                                                    </li>
                                                                    <li>
                                                                        <span>เล่นทั้งหมด</span>
                                                                        <strong>1,500,000.00</strong>
                                                                    </li>
                                                                    <li>
                                                                        <span>แนะนำสมาชิก</span>
                                                                        <strong>12</strong>
                                                                    </li>
                                                                    <li>
                                                                        <span>ถอนทั้งหมด</span>
                                                                        <strong>50,000.00</strong>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="topic-box-mini">
                                                                <h3><i class="fas fa-link"></i> ลิงค์รับทรัพย์</h3>
                                                            </div>
                                                            <div class="content-referral ">
                                                                <div class="col-group-referral">
                                                                    <div class="col-content-referral">
                                                                        <label>ลิงค์ของคุณ</label>
                                                                        <div class="input-group">
                                                                            <input class="form-control" id="referral_link"
                                                                                   :value="sp_info.referral.link" :readonly="true">
                                                                            <div class="input-group-btn">
                                                                                <button type="button" class="btn btn-default"
                                                                                        @click="coppyToClipboard('referral_link')"><i
                                                                                        class="far fa-copy"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-content-referral" style="margin-left: 5px;">
                                                                        <label>โค้ดรับทรัพย์</label>
                                                                        <div class="input-group">
                                                                            <input class="form-control" id="referral_code"
                                                                                   :value="sp_info.referral.referral_code" :readonly="true">
                                                                            <div class="input-group-btn">
                                                                                <button type="button" class="btn btn-default"
                                                                                        @click="coppyToClipboard('referral_code')"><i
                                                                                        class="far fa-copy"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="left-referral-level">
                                                                <div class="topic-box-mini">
                                                                    <h3>เพื่อนที่แนะนำ <span>{{sp_info.referral.referral_count_checked+'/'+sp_info.referral.referral_count}}</span> </h3>
                                                                    <small>รายได้ปัจจุบัน</small>
                                                                </div>
                                                                <!--                                            <p>Your percentage of referrals` bets: 0.10%-->
                                                                <!--                                                Information is updated once per 15 minutes</p>-->
                                                                <div class="left-referral-level-content">
                                                                    <div class="bt-timer-group">
                                                                        <p>เหลือเวลาอีก</p>
                                                                        <ul class="bt-timer">
                                                                            <li class="bt-timer-day-referral">
                                                                                <span id="referral_days">6</span>
                                                                                <span>วัน</span>
                                                                            </li>
                                                                            <li>
                                                                                <span id="referral_hours">15</span>
                                                                                <small>h</small>
                                                                            </li>
                                                                            <li>
                                                                                <span>:</span>
                                                                                <small></small>
                                                                            </li>
                                                                            <li>
                                                                                <span id="referral_minutes">20</span>
                                                                                <small>m</small>
                                                                            </li>
                                                                            <li>
                                                                                <span>:</span>
                                                                                <small></small>
                                                                            </li>
                                                                            <li>
                                                                                <span id="referral_seconds">10</span>
                                                                                <small>s</small>
                                                                            </li>


                                                                        </ul>
                                                                    </div>
                                                                    <div class="referral-btn-get-freeCoin">
                                                                        <span>฿200</span>
                                                                        <button type="button" class="btn">รับ</button>
                                                                    </div>
                                                                    <div class="referral-btn-get-freeCoin" v-if="false">
                                                                        <span>฿{{parseFloat(sp_info.referral.referral_bonus.amount)}}</span>
                                                                        <button v-if="sp_info.referral.referral_bonus.amount && sp_info.referral.dayOfWeek==1" type="button" class="btn"
                                                                                @click="claimReferralBonus(sp_info.referral.referral_bonus.amount)">รับ
                                                                        </button>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div style="clear: both"></div>
                                                            <div class="full-width">
                                                                <div class="slide-nav">
                                                                    <div class="slide-left-outsize">
                                                                        <span class="glyphicon glyphicon-backward"></span>
                                                                    </div>
                                                                    <div class="slide-left">
                                                                        <span class="glyphicon glyphicon-chevron-left"></span>
                                                                    </div>
                                                                    <div class="slide-center">
                                                                        <div class="list-cat">
                                                                            <carousel
                                                                                    :per-page="3.5"
                                                                                    :mouseDrag="true"
                                                                                    :spacePadding="1"
                                                                                    :navigationEnabled="true"
                                                                                    :paginationEnabled="false"
                                                                                    navigationNextLabel="❯"
                                                                                    navigationPrevLabel="❮"
                                                                                    paginationPosition="bottom"
                                                                            >
                                                                                <slide v-for="(ref,key) of sp_info.referral.referral_list"
                                                                                       :key="key">
                                                                                    <div class="box-lv-referral"
                                                                                         :class="ref.count<=sp_info.referral.referral_count_claim?'active-claimed-refer':  (sp_info.referral.referral_count_checked>=ref.count?'active':'')">
                                                                                        <div class="box-lv-referral-info">
                                                                                            <p>รีเฟอร์รัล</p>
                                                                                            <div class="circle-box">{{ref.count}}</div>
                                                                                            <div class="box-award-referral">
                                                                                                <img src="@/assets/images/coin-small-1.png"/>
                                                                                                <div class="bg-award-referra"></div>
                                                                                            </div>
                                                                                            <ul :class="ref.count<=sp_info.referral.referral_count_claim?'active-claimed-refer':  (sp_info.referral.referral_count_checked>=ref.count?'active':'')">
                                                                                                <!--                                                                    <li>{{ref.count}}</li>-->
                                                                                                <li>{{ref.bonus_item_name}}</li>
                                                                                            </ul>
                                                                                            <button v-if="ref.count<=sp_info.referral.referral_count_claim" type="button" class="btn btn-default btn-xs"
                                                                                                    @click="claimReferral(ref.count,ref.bonus_item_value)">

                                                                                            </button>
                                                                                            <button v-else type="button" class="btn btn-default btn-xs"
                                                                                                    @click="claimReferral(ref.count,ref.bonus_item_value)">
                                                                                                รับ
                                                                                            </button>
                                                                                        </div>

                                                                                    </div>
                                                                                </slide>
                                                                            </carousel>
                                                                        </div>
                                                                    </div>
                                                                    <div class="slide-right">
                                                                        <span class="glyphicon glyphicon-chevron-right"></span>
                                                                    </div>
                                                                    <div class="slide-right-outsize">
                                                                        <span class="glyphicon glyphicon-forward"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--                                                    สมาชิก-->
                                                        <div class="boxReferralUser">
                                                            <div class="topic-box-mini">
                                                                <h3> <i class="fas fa-users"></i> สมาชิกที่แนะนำ</h3>
                                                            </div>
                                                            <div class="ReferralUserTable">
                                                                <table>
                                                                    <tr>
                                                                        <th>วันเวลาที่สมัคร</th>
                                                                        <th class="text-center">ยูสเซอร์เนม</th>
                                                                        <th class="text-center">แทงทั้งหมด</th>
                                                                    </tr>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td class="ReferralUserDate">
                                                                            <span>2023-03-20</span>
                                                                            <span>13:13:41</span>
                                                                        </td>
                                                                        <td class="text-center">099999xxxx</td>
                                                                        <td class="text-center">100.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="ReferralUserDate">
                                                                            <span>2023-03-20</span>
                                                                            <span>13:13:41</span>
                                                                        </td>
                                                                        <td class="text-center">099999xxxx</td>
                                                                        <td class="text-center">100.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="ReferralUserDate">
                                                                            <span>2023-03-20</span>
                                                                            <span>13:13:41</span>
                                                                        </td>
                                                                        <td class="text-center">099999xxxx</td>
                                                                        <td class="text-center">100.00</td>
                                                                    </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>

                                                        <!--                                                    รายได้-->
                                                        <div class="boxReferralIncome">
                                                            <div class="topic-box-mini boxIncomeReferral">
                                                                <h3><i class="fas fa-coins"></i> รายได้</h3>
                                                                <div class="boxSelectMonth">
                                                                    <label></label>
                                                                    <span></span>
                                                                    <input type="text" placeholder="มีนาคม">
                                                                </div>
                                                            </div>
                                                            <p class="notifyIncome text-red">การคำนวณรายได้ ระบบจะตัดยอดทุกเที่ยงคืน หากสมาชิกต้องการสอบถาม
                                                                ข้อมูลเพิ่มเติม กรุณาติดต่อเจ้าหน้าที่</p>
                                                            <div class="boxReferralIncomeTab">
                                                                <ul>
                                                                    <li class="active">รายได้จากหวย</li>
                                                                    <li>รายได้จากเกม</li>
                                                                </ul>
                                                            </div>
                                                            <div class="boxReferralIncomeAll">
                                                                <span>รายได้รวม</span>
                                                                <strong>400.00</strong>
                                                            </div>
                                                            <div class="ReferralUserTable">
                                                                <table>
                                                                    <tr>
                                                                        <th>วันที่เล่น</th>
                                                                        <th class="text-center">รายได้</th>
                                                                    </tr>
                                                                    <tbody>
                                                                        <tr>
                                                                        <td class="ReferralUserDate">
                                                                            <span>2023-03-20</span>
                                                                            <span>13:13:41</span>
                                                                        </td>
                                                                        <td class="text-center">100.00</td>
                                                                    </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>

                                                        <!--                                                    ถอนได้-->
                                                        <div class="boxReferralWithdraw">
                                                            <div class="topic-box-mini boxIncomeReferral">
                                                                <h3><i class="fas fa-piggy-bank"></i> แจ้งรายการถอนได้</h3>
                                                            </div>
                                                            <span class="alert-success">
                                                                ถอนรายได้สำเร็จ
                                                            </span>
                                                            <p class="notifyIncome text-red">รายได้ ระบบแนะนำจะถอนเข้าเป็นเงินเครดิต หากสมาชิกต้องการสอบถามข้อมูลเพิ่มเติม
                                                                กรุณาติดต่อเจ้าหน้าที่</p>
                                                            <div class="boxCurrentIncome">
                                                                <p>รายได้ปัจจุบัน</p>
                                                                <h3>0.44</h3>
                                                            </div>
                                                            <div class="topic-box-mini">
                                                                <h3>ต้องการถอน  <small>ระบุจำนวนเงิน</small></h3>
                                                            </div>
                                                            <div class="withdrawInput">
                                                                <input type="text" placeholder="0.00">
                                                                <button type="submit" class="btn">แจ้งถอนได้</button>
                                                            </div>

                                                            <div class="topic-box-mini boxIncomeReferral">
                                                                <h3><i class="fas fa-clock"></i> แนะนำเพื่อนประวัติถอนล่าสุด</h3>
                                                            </div>
                                                            <div class="ReferralUserTable">
                                                                <table>
                                                                    <tr>
                                                                        <th>วันเวลาที่สมัคร</th>
                                                                        <th class="text-center">จำนวนที่ถอน</th>
                                                                        <th class="text-center">คงเหลือ</th>
                                                                    </tr>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td class="ReferralUserDate">
                                                                            <span>2023-03-20</span>
                                                                            <span>13:13:41</span>
                                                                        </td>
                                                                        <td class="text-center">100.00</td>
                                                                        <td class="text-center">100.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="ReferralUserDate">
                                                                            <span>2023-03-20</span>
                                                                            <span>13:13:41</span>
                                                                        </td>
                                                                        <td class="text-center">100.00</td>
                                                                        <td class="text-center">100.00</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="ReferralUserDate">
                                                                            <span>2023-03-20</span>
                                                                            <span>13:13:41</span>
                                                                        </td>
                                                                        <td class="text-center">100.00</td>
                                                                        <td class="text-center">100.00</td>
                                                                    </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="bxProfileColumn">



                                                <!--                        level-->
                                                <div class="boxProfile-row box-level" style="display: block">
                                                    <div class="topic-box">
                                                        <h2>
                                                            <img src="@/assets/images/bg-level-up.png"/>
                                                            เลเวล

                                                        </h2>
                                                        <div class="nav-info"  @click="showLevelNotice()"></div></div>
                                                    <div class="box-level-slide">
                                                        <carousel
                                                                :per-page="4.5"
                                                                :mouseDrag="true"
                                                                :navigationEnabled="true"
                                                                :paginationEnabled="false"
                                                                :value="5"
                                                                navigationNextLabel="❯"
                                                                navigationPrevLabel="❮"
                                                                paginationPosition="bottom"
                                                        >
                                                            <slide v-for="(reward_item_id,level) of sp_info.level.sp_level_up_reward_index"
                                                                   :key="level">
                                                                <div class="box-level-list"
                                                                     :class="{'active-after':level<=sp_info.level.sp_user_level_claim,'box-level-list-big':level%10==0,'active':(level>sp_info.level.sp_user_level_claim && level<=sp_info.level.sp_user_level),'active-current-level':level==sp_info.level.sp_user_level}">
                                                                    <div class="item-level">
                                                                        <h5><small>Lv</small> {{level}}</h5>
                                                                        <div class="item-level-award">
                                                                            <img v-if="sp_info.level.sp_level_up_reward[level].case_name"
                                                                                 :src="sp_info.level.sp_level_up_reward[level].item_img"/>
                                                                            <img v-else src="@/assets/images/coin-small-1.png"/>
                                                                            <div class="name-itembox" v-if="level%10==0">
                                                                                <span>{{sp_info.level.sp_level_up_reward[level].case_name}}</span>
                                                                            </div>
                                                                            <p>
                                                                                {{sp_info.level.sp_level_up_reward[reward_item_id].bonus_item_name}}</p>
                                                                        </div>
                                                                        <button type="button" class="btn btn-default btn-xs"
                                                                                @click="claimLevel(level)">
                                                                        </button>
                                                                        <div v-if="level%10==0" class="bg-level-num">{{level}}</div>
                                                                    </div>
                                                                </div>
                                                            </slide>
                                                        </carousel>
                                                    </div>
                                                </div>


                                                <!--                    history-->
                                                <div class="boxProfile-row box-history">
                                                    <div class="topic-box"><h2>ประวัติการเล่น</h2>
                                                        <div class="nav-info"></div>
                                                    </div>
                                                    <div class="box-history-nav">
                                                        <carousel
                                                                :per-page="4.5"
                                                                :mouseDrag="true"
                                                                :navigationEnabled="true"
                                                                :paginationEnabled="false"
                                                                :value="5"
                                                                navigationNextLabel="❯"
                                                                navigationPrevLabel="❮"
                                                                paginationPosition="bottom"
                                                        >
                                                            <slide>
                                                                <div class="historyList" :class="{'active':tab_current=='all'}"
                                                                     @click="tab_current='all'"><a href="#all-tab" aria-controls="all" role="tab"
                                                                                                   data-toggle="tab">ทั้งหมด</a>
                                                                </div>
                                                            </slide>
                                                            <slide>
                                                                <div class="historyList" :class="{'active':tab_current=='unbox'}"
                                                                     @click="tab_current='unbox'"><a href="#unbox-tab" aria-controls="unbox"
                                                                                                     role="tab" data-toggle="tab">เปิดกล่อง</a></div>
                                                            </slide>
                                                            <slide>
                                                                <div class="historyList" :class="{'active':tab_current=='hilo'}"
                                                                     @click="tab_current='hilo'"
                                                                ><a href="#hilo-tab" aria-controls="messages" role="tab"
                                                                    data-toggle="tab">เกมส์ไฮโล</a></div>
                                                            </slide>
                                                            <slide>
                                                                <div class="historyList"  :class="{'active':tab_current=='roulette'}"
                                                                     @click="tab_current='roulette'"><a href="#roulette-tab" aria-controls="roulette"
                                                                                                        role="tab" data-toggle="tab">เกมส์รูเล็ต</a>
                                                                </div>
                                                            </slide>
                                                            <slide>
                                                                <div class="historyList"  :class="{'active':tab_current=='baccarat'}"
                                                                     @click="tab_current='baccarat'"><a href="#baccarat-tab" aria-controls="baccarat"
                                                                                                        role="tab" data-toggle="tab">เกมส์บาคาร่า</a>
                                                                </div>
                                                            </slide>
                                                        </carousel>
                                                    </div>

                                                    <div class="box-history-1">
                                                        <!-- Nav tabs -->
                                                        <ul v-if="false" class="nav nav-pills" role="tablist">
                                                            <li role="presentation" :class="{'active':tab_current=='all'}"
                                                                @click="tab_current='all'"><a href="#all-tab" aria-controls="all" role="tab"
                                                                                              data-toggle="tab">ทั้งหมด</a>
                                                            </li>
                                                            <li role="presentation" :class="{'active':tab_current=='unbox'}"
                                                                @click="tab_current='unbox'"><a href="#unbox-tab" aria-controls="unbox"
                                                                                                role="tab" data-toggle="tab">เปิดกล่อง</a></li>
                                                            <li role="presentation" :class="{'active':tab_current=='hilo'}"
                                                                @click="tab_current='hilo'"
                                                            ><a href="#hilo-tab" aria-controls="messages" role="tab"
                                                                data-toggle="tab">เกมส์ไฮโล</a></li>
                                                            <li role="presentation" :class="{'active':tab_current=='roulette'}"
                                                                @click="tab_current='roulette'"><a href="#roulette-tab" aria-controls="roulette"
                                                                                                   role="tab" data-toggle="tab">เกมส์รูเล็ต</a>
                                                            </li>
                                                            <li role="presentation" :class="{'active':tab_current=='baccarat'}"
                                                                @click="tab_current='baccarat'"><a href="#baccarat-tab" aria-controls="baccarat"
                                                                                                   role="tab" data-toggle="tab">เกมส์บาคาร่า</a>
                                                            </li>
                                                        </ul>


                                                        <div class="tab-content">

                                                            <div role="tabpanel" class="tab-pane" :class="{active:tab_current=='all'}"
                                                                 id="all-tab">
                                                                <table class="table">
                                                                    <tbody>
                                                                    <tr v-for="(row,key) of newHistoryList" :key="key" class="unbox-his">
                                                                        <div>
                                                                            <td>
                                                                                <h5>{{'side_win' in row
                                                                                    ?'Baccarat':(row.item_name?row.name_case:(row.game==0?'Roulette':'Hilo'))}}</h5>
                                                                                <strong>{{row.item_name}}</strong>
                                                                                <small>{{convertToDate(row.create_datetime)}} |
                                                                                    {{convertToTime((row.create_datetime))}}
                                                                                </small>
                                                                            </td>
                                                                            <td>Cost
                                                                                <span>฿{{numberWithCommasNormal(parseFloat(row.cost))}}</span>
                                                                            </td>
                                                                            <td>Profit/Lose<span :class="row.game_result">฿{{numberWithCommasNormal(parseFloat(row.game_profit_loss))}}</span>
                                                                            </td>
                                                                        </div>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div role="tabpanel" class="tab-pane" :class="{active:tab_current=='unbox'}"
                                                                 id="unbox-tab">
                                                                <table class="table">
                                                                    <tbody>
                                                                    <tr v-for="(row,key) of historyAndInventory[1]" :key="key"
                                                                        class="unbox-his">
                                                                        <div v-if="row.case_id">
                                                                            <td>
                                                                                <h5>{{row.name_case}}</h5>
                                                                                <strong>{{row.item_name}}</strong>
                                                                                <small>{{convertToDate(row.create_datetime)}} |
                                                                                    {{convertToTime((row.create_datetime))}}
                                                                                </small>
                                                                            </td>
                                                                            <td>Cost
                                                                                <span>฿{{numberWithCommasNormal(parseFloat(row.cost))}}</span>
                                                                            </td>
                                                                            <td>Profit/Lose<span :class="row.game_result">฿{{numberWithCommasNormal(parseFloat(row.game_profit_loss))}}</span>
                                                                            </td>
                                                                        </div>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div role="tabpanel" class="tab-pane" :class="{active:tab_current=='hilo'}"
                                                                 id="hilo-tab">
                                                                <table class="table">
                                                                    <tbody>
                                                                    <tr v-for="(row,key) of historyAndInventory[1]" :key="key"
                                                                        class="unbox-his">
                                                                        <div v-if="row.name_case=='hilo'">
                                                                            <td>
                                                                                <h5>Hilo</h5>
                                                                                <strong>{{row.item_name}}</strong>
                                                                                <small>{{convertToDate(row.create_datetime)}} |
                                                                                    {{convertToTime((row.create_datetime))}}
                                                                                </small>
                                                                            </td>
                                                                            <td>Cost
                                                                                <span>฿{{numberWithCommasNormal(parseFloat(row.cost))}}</span>
                                                                            </td>
                                                                            <td>Profit/Lose<span :class="row.game_result">฿{{numberWithCommasNormal(parseFloat(row.game_profit_loss))}}</span>
                                                                            </td>
                                                                        </div>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div role="tabpanel" class="tab-pane" :class="{active:tab_current=='roulette'}"
                                                                 id="roulette-tab">
                                                                <table class="table">
                                                                    <tbody>
                                                                    <tr v-for="(row,key) of historyAndInventory[1]" :key="key"
                                                                        class="unbox-his">
                                                                        <div v-if="row.name_case=='roulette'">
                                                                            <td>
                                                                                <h5>Roulette</h5>
                                                                                <strong>{{row.item_name}}</strong>
                                                                                <small>{{convertToDate(row.create_datetime)}} |
                                                                                    {{convertToTime((row.create_datetime))}}
                                                                                </small>
                                                                            </td>
                                                                            <td>Cost
                                                                                <span>฿{{numberWithCommasNormal(parseFloat(row.cost))}}</span>
                                                                            </td>
                                                                            <td>Profit/Lose<span :class="row.game_result">฿{{numberWithCommasNormal(parseFloat(row.game_profit_loss))}}</span>
                                                                            </td>
                                                                        </div>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div role="tabpanel" class="tab-pane" :class="{active:tab_current=='baccarat'}"
                                                                 id="baccarat-tab">
                                                                <table class="table">
                                                                    <tbody>
                                                                    <tr v-for="(row,key) of historyAndInventory[1]" :key="key"
                                                                        class="unbox-his">
                                                                        <div v-if="'side_win' in row ">
                                                                            <td>
                                                                                <h5>Baccarat</h5>
                                                                                <strong>{{row.item_name}}</strong>
                                                                                <small>{{convertToDate(row.create_datetime)}} |
                                                                                    {{convertToTime((row.create_datetime))}}
                                                                                </small>
                                                                            </td>
                                                                            <td>Cost
                                                                                <span>฿{{numberWithCommasNormal(parseFloat(row.cost))}}</span>
                                                                            </td>
                                                                            <td>Profit/Lose<span :class="row.game_result">฿{{numberWithCommasNormal(parseFloat(row.game_profit_loss))}}</span>
                                                                            </td>
                                                                        </div>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>








                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navButtonMobile">
                    <MenuNav   @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
                </div>
            </div>
            <div class="header-games bg-profile" style="display: none">
                <div class="fa-pull-right"><i class="fa fa-cog"></i></div>
                <router-link :to="{name: 'Home'}">
                    <span><i class="fa fa-chevron-left"></i> </span>
                </router-link>
                <div>โปรไฟล์</div>
            </div>
            <!--        <div class="box-logout" @click="goSignOut"><i class="fas fa-sign-in-alt"></i> ออกจากระบบ</div>-->

            <UserChangeImageProfile
                    v-show="isShowChangeImageProfile"
                    @close="closeChangeImageProfile"></UserChangeImageProfile>

            <div v-if="false" class="content-edit-profile hide">
                <div class="content-edit-profile-info">
                    <div class="header">
                        <div class="close-boxeditProfile"></div>
                        <h3>เปลี่ยนรูปโปรไฟล์</h3>
                        <div class="confirm-boxeditProfile"></div>
                    </div>
                    <div class="content">
                        <div class="content-default-profile">
                            <div class="profile-thumb">
                                <img src="/images/set-profile/set4/003.png">
                            </div>
                        </div>
                        <div class="content-all-set">
                            <div class="nav-set-all">
                                <div class="nav"><span class="pfa-set2-icon-1"></span> เซท 2</div>
                                <div class="nav"><span class="pfa-set3-icon-1"></span> เซท 3</div>
                                <div class="nav active"><span class="pfa-set4-icon-1"></span> เซท 4</div>
                                <div class="nav"><span class="pfa-set5-icon-1"></span> เซท 5</div>
                            </div>
                            <div class="container-set-all">
                                <div class="all-thumb-set1 hide">
                                    <ul>
                                        <li><span class="pfa-set1-icon-1"></span></li>
                                        <li><span class="pfa-set1-icon-2"></span></li>
                                        <li class="active"><span class="pfa-set1-icon-3"></span></li>
                                        <li><span class="pfa-set1-icon-5"></span></li>
                                        <li><span class="pfa-set1-icon-6"></span></li>
                                        <li><span class="pfa-set1-icon-7"></span></li>
                                        <li><span class="pfa-set1-icon-8"></span></li>
                                        <li><span class="pfa-set1-icon-9"></span></li>
                                        <li><span class="pfa-set1-icon-10"></span></li>
                                        <li><span class="pfa-set1-icon-11"></span></li>
                                        <li><span class="pfa-set1-icon-12"></span></li>
                                        <li><span class="pfa-set1-icon-13"></span></li>
                                        <li><span class="pfa-set1-icon-14"></span></li>
                                        <li><span class="pfa-set1-icon-15"></span></li>
                                        <li><span class="pfa-set1-icon-16"></span></li>
                                        <li><span class="pfa-set1-icon-17"></span></li>
                                        <li><span class="pfa-set1-icon-18"></span></li>
                                        <li><span class="pfa-set1-icon-19"></span></li>
                                        <li><span class="pfa-set1-icon-20"></span></li>
                                        <li><span class="pfa-set1-icon-21"></span></li>
                                        <li><span class="pfa-set1-icon-22"></span></li>
                                        <li><span class="pfa-set1-icon-23"></span></li>
                                        <li><span class="pfa-set1-icon-24"></span></li>
                                        <li><span class="pfa-set1-icon-25"></span></li>
                                        <li><span class="pfa-set1-icon-26"></span></li>
                                        <li><span class="pfa-set1-icon-27"></span></li>
                                        <li><span class="pfa-set1-icon-28"></span></li>
                                        <li><span class="pfa-set1-icon-29"></span></li>
                                        <li><span class="pfa-set1-icon-30"></span></li>
                                        <li><span class="pfa-set1-icon-31"></span></li>
                                        <li><span class="pfa-set1-icon-32"></span></li>
                                        <li><span class="pfa-set1-icon-33"></span></li>
                                        <li><span class="pfa-set1-icon-34"></span></li>
                                        <li><span class="pfa-set1-icon-35"></span></li>
                                        <li><span class="pfa-set1-icon-36"></span></li>
                                        <li><span class="pfa-set1-icon-37"></span></li>
                                        <li><span class="pfa-set1-icon-38"></span></li>
                                        <li><span class="pfa-set1-icon-39"></span></li>
                                        <li><span class="pfa-set1-icon-40"></span></li>
                                        <li><span class="pfa-set1-icon-41"></span></li>
                                        <li><span class="pfa-set1-icon-42"></span></li>
                                        <li><span class="pfa-set1-icon-43"></span></li>
                                        <li><span class="pfa-set1-icon-44"></span></li>
                                        <li><span class="pfa-set1-icon-45"></span></li>
                                        <li><span class="pfa-set1-icon-46"></span></li>
                                        <li><span class="pfa-set1-icon-47"></span></li>
                                        <li><span class="pfa-set1-icon-48"></span></li>
                                        <li><span class="pfa-set1-icon-49"></span></li>
                                        <li><span class="pfa-set1-icon-50"></span></li>
                                    </ul>
                                </div>
                                <div class="all-thumb-set2 hide">
                                    <ul>
                                        <li><span class="pfa-set2-icon-1"></span></li>
                                        <li><span class="pfa-set2-icon-2"></span></li>
                                        <li class="active"><span class="pfa-set2-icon-3"></span></li>
                                        <li><span class="pfa-set2-icon-4"></span></li>
                                        <li><span class="pfa-set2-icon-5"></span></li>
                                        <li><span class="pfa-set2-icon-6"></span></li>
                                        <li><span class="pfa-set2-icon-7"></span></li>
                                        <li><span class="pfa-set2-icon-8"></span></li>
                                        <li><span class="pfa-set2-icon-9"></span></li>
                                        <li><span class="pfa-set2-icon-10"></span></li>
                                        <li><span class="pfa-set2-icon-11"></span></li>
                                        <li><span class="pfa-set2-icon-12"></span></li>
                                        <li><span class="pfa-set2-icon-13"></span></li>
                                        <li><span class="pfa-set2-icon-14"></span></li>
                                        <li><span class="pfa-set2-icon-15"></span></li>
                                        <li><span class="pfa-set2-icon-16"></span></li>
                                        <li><span class="pfa-set2-icon-17"></span></li>
                                        <li><span class="pfa-set2-icon-18"></span></li>
                                        <li><span class="pfa-set2-icon-19"></span></li>
                                        <li><span class="pfa-set2-icon-20"></span></li>
                                        <li><span class="pfa-set2-icon-21"></span></li>
                                        <li><span class="pfa-set2-icon-22"></span></li>
                                        <li><span class="pfa-set2-icon-23"></span></li>
                                        <li><span class="pfa-set2-icon-24"></span></li>
                                        <li><span class="pfa-set2-icon-25"></span></li>
                                        <li><span class="pfa-set2-icon-26"></span></li>
                                        <li><span class="pfa-set2-icon-27"></span></li>
                                        <li><span class="pfa-set2-icon-28"></span></li>
                                        <li><span class="pfa-set2-icon-29"></span></li>
                                        <li><span class="pfa-set2-icon-30"></span></li>
                                        <li><span class="pfa-set2-icon-31"></span></li>
                                        <li><span class="pfa-set2-icon-32"></span></li>
                                        <li><span class="pfa-set2-icon-33"></span></li>
                                        <li><span class="pfa-set2-icon-34"></span></li>
                                        <li><span class="pfa-set2-icon-35"></span></li>
                                        <li><span class="pfa-set2-icon-36"></span></li>
                                        <li><span class="pfa-set2-icon-37"></span></li>
                                        <li><span class="pfa-set2-icon-38"></span></li>
                                        <li><span class="pfa-set2-icon-39"></span></li>
                                        <li><span class="pfa-set2-icon-40"></span></li>
                                    </ul>
                                </div>
                                <div class="all-thumb-set3 hide">
                                    <ul>
                                        <li><span class="pfa-set3-icon-1"></span></li>
                                        <li><span class="pfa-set3-icon-2"></span></li>
                                        <li class="active"><span class="pfa-set3-icon-3"></span></li>
                                        <li><span class="pfa-set3-icon-4"></span></li>
                                        <li><span class="pfa-set3-icon-5"></span></li>
                                        <li><span class="pfa-set3-icon-6"></span></li>
                                        <li><span class="pfa-set3-icon-7"></span></li>
                                        <li><span class="pfa-set3-icon-8"></span></li>
                                        <li><span class="pfa-set3-icon-9"></span></li>
                                        <li><span class="pfa-set3-icon-10"></span></li>
                                        <li><span class="pfa-set3-icon-11"></span></li>
                                        <li><span class="pfa-set3-icon-12"></span></li>
                                        <li><span class="pfa-set3-icon-13"></span></li>
                                        <li><span class="pfa-set3-icon-14"></span></li>
                                        <li><span class="pfa-set3-icon-15"></span></li>
                                        <li><span class="pfa-set3-icon-16"></span></li>
                                        <li><span class="pfa-set3-icon-17"></span></li>
                                        <li><span class="pfa-set3-icon-18"></span></li>
                                        <li><span class="pfa-set3-icon-19"></span></li>
                                        <li><span class="pfa-set3-icon-20"></span></li>
                                        <li><span class="pfa-set3-icon-21"></span></li>
                                        <li><span class="pfa-set3-icon-22"></span></li>
                                        <li><span class="pfa-set3-icon-23"></span></li>
                                        <li><span class="pfa-set3-icon-24"></span></li>
                                        <li><span class="pfa-set3-icon-25"></span></li>
                                        <li><span class="pfa-set3-icon-26"></span></li>
                                        <li><span class="pfa-set3-icon-27"></span></li>
                                        <li><span class="pfa-set3-icon-28"></span></li>
                                        <li><span class="pfa-set3-icon-29"></span></li>
                                        <li><span class="pfa-set3-icon-30"></span></li>
                                        <li><span class="pfa-set3-icon-31"></span></li>
                                        <li><span class="pfa-set3-icon-32"></span></li>
                                        <li><span class="pfa-set3-icon-33"></span></li>
                                        <li><span class="pfa-set3-icon-34"></span></li>
                                        <li><span class="pfa-set3-icon-35"></span></li>
                                        <li><span class="pfa-set3-icon-36"></span></li>
                                        <li><span class="pfa-set3-icon-37"></span></li>
                                        <li><span class="pfa-set3-icon-38"></span></li>
                                        <li><span class="pfa-set3-icon-39"></span></li>
                                        <li><span class="pfa-set3-icon-40"></span></li>
                                        <li><span class="pfa-set3-icon-41"></span></li>
                                        <li><span class="pfa-set3-icon-42"></span></li>
                                        <li><span class="pfa-set3-icon-43"></span></li>
                                        <li><span class="pfa-set3-icon-44"></span></li>
                                        <li><span class="pfa-set3-icon-45"></span></li>
                                        <li><span class="pfa-set3-icon-46"></span></li>
                                        <li><span class="pfa-set3-icon-47"></span></li>
                                        <li><span class="pfa-set3-icon-48"></span></li>
                                        <li><span class="pfa-set3-icon-49"></span></li>
                                        <li><span class="pfa-set3-icon-50"></span></li>

                                    </ul>
                                </div>
                                <div class="all-thumb-set4 hide">
                                    <ul>
                                        <li><span class="pfa-set4-icon-1"></span></li>
                                        <li><span class="pfa-set4-icon-2"></span></li>
                                        <li class="active"><span class="pfa-set4-icon-3"></span></li>
                                        <li><span class="pfa-set4-icon-4"></span></li>
                                        <li><span class="pfa-set4-icon-5"></span></li>
                                        <li><span class="pfa-set4-icon-6"></span></li>
                                        <li><span class="pfa-set4-icon-7"></span></li>
                                        <li><span class="pfa-set4-icon-8"></span></li>
                                        <li><span class="pfa-set4-icon-9"></span></li>
                                        <li><span class="pfa-set4-icon-10"></span></li>
                                        <li><span class="pfa-set4-icon-11"></span></li>
                                        <li><span class="pfa-set4-icon-12"></span></li>
                                        <li><span class="pfa-set4-icon-13"></span></li>
                                        <li><span class="pfa-set4-icon-14"></span></li>
                                        <li><span class="pfa-set4-icon-15"></span></li>
                                        <li><span class="pfa-set4-icon-16"></span></li>
                                        <li><span class="pfa-set4-icon-17"></span></li>
                                        <li><span class="pfa-set4-icon-18"></span></li>
                                        <li><span class="pfa-set4-icon-19"></span></li>
                                        <li><span class="pfa-set4-icon-20"></span></li>
                                        <li><span class="pfa-set4-icon-21"></span></li>
                                        <li><span class="pfa-set4-icon-22"></span></li>
                                        <li><span class="pfa-set4-icon-23"></span></li>
                                        <li><span class="pfa-set4-icon-24"></span></li>
                                        <li><span class="pfa-set4-icon-25"></span></li>
                                        <li><span class="pfa-set4-icon-26"></span></li>
                                        <li><span class="pfa-set4-icon-27"></span></li>
                                        <li><span class="pfa-set4-icon-28"></span></li>
                                        <li><span class="pfa-set4-icon-29"></span></li>
                                        <li><span class="pfa-set4-icon-30"></span></li>
                                        <li><span class="pfa-set4-icon-31"></span></li>
                                        <li><span class="pfa-set4-icon-32"></span></li>
                                        <li><span class="pfa-set4-icon-33"></span></li>
                                        <li><span class="pfa-set4-icon-34"></span></li>
                                        <li><span class="pfa-set4-icon-35"></span></li>
                                        <li><span class="pfa-set4-icon-36"></span></li>
                                        <li><span class="pfa-set4-icon-37"></span></li>
                                        <li><span class="pfa-set4-icon-38"></span></li>
                                        <li><span class="pfa-set4-icon-39"></span></li>
                                        <li><span class="pfa-set4-icon-40"></span></li>
                                    </ul>
                                </div>
                                <div class="all-thumb-set5">
                                    <ul>
                                        <li><span class="pfa-set5-icon-1"></span></li>
                                        <li><span class="pfa-set5-icon-2"></span></li>
                                        <li class="active"><span class="pfa-set5-icon-3"></span></li>
                                        <li><span class="pfa-set5-icon-4"></span></li>
                                        <li><span class="pfa-set5-icon-5"></span></li>
                                        <li><span class="pfa-set5-icon-6"></span></li>
                                        <li><span class="pfa-set5-icon-7"></span></li>
                                        <li><span class="pfa-set5-icon-8"></span></li>
                                        <li><span class="pfa-set5-icon-9"></span></li>
                                        <li><span class="pfa-set5-icon-10"></span></li>
                                        <li><span class="pfa-set5-icon-11"></span></li>
                                        <li><span class="pfa-set5-icon-12"></span></li>
                                        <li><span class="pfa-set5-icon-13"></span></li>
                                        <li><span class="pfa-set5-icon-14"></span></li>
                                        <li><span class="pfa-set5-icon-15"></span></li>
                                        <li><span class="pfa-set5-icon-16"></span></li>
                                        <li><span class="pfa-set5-icon-17"></span></li>
                                        <li><span class="pfa-set5-icon-18"></span></li>
                                        <li><span class="pfa-set5-icon-19"></span></li>
                                        <li><span class="pfa-set5-icon-20"></span></li>
                                        <li><span class="pfa-set5-icon-21"></span></li>
                                        <li><span class="pfa-set5-icon-22"></span></li>
                                        <li><span class="pfa-set5-icon-23"></span></li>
                                        <li><span class="pfa-set5-icon-24"></span></li>
                                        <li><span class="pfa-set5-icon-25"></span></li>
                                        <li><span class="pfa-set5-icon-26"></span></li>
                                        <li><span class="pfa-set5-icon-27"></span></li>
                                        <li><span class="pfa-set5-icon-28"></span></li>
                                        <li><span class="pfa-set5-icon-29"></span></li>
                                        <li><span class="pfa-set5-icon-30"></span></li>
                                        <li><span class="pfa-set5-icon-31"></span></li>
                                        <li><span class="pfa-set5-icon-32"></span></li>
                                        <li><span class="pfa-set5-icon-33"></span></li>
                                        <li><span class="pfa-set5-icon-34"></span></li>
                                        <li><span class="pfa-set5-icon-35"></span></li>
                                        <li><span class="pfa-set5-icon-36"></span></li>
                                        <li><span class="pfa-set5-icon-37"></span></li>
                                        <li><span class="pfa-set5-icon-38"></span></li>
                                        <li><span class="pfa-set5-icon-39"></span></li>
                                        <li><span class="pfa-set5-icon-40"></span></li>
                                        <li><span class="pfa-set5-icon-41"></span></li>
                                        <li><span class="pfa-set5-icon-42"></span></li>
                                        <li><span class="pfa-set5-icon-43"></span></li>
                                        <li><span class="pfa-set5-icon-44"></span></li>
                                        <li><span class="pfa-set5-icon-45"></span></li>
                                        <li><span class="pfa-set5-icon-46"></span></li>
                                        <li><span class="pfa-set5-icon-47"></span></li>
                                        <li><span class="pfa-set5-icon-48"></span></li>
                                        <li><span class="pfa-set5-icon-49"></span></li>
                                        <li><span class="pfa-set5-icon-50"></span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!--                    <div class="content-footer-edit">-->
                        <!--                        <button type="button" class="btn"> แก้ไข </button>-->
                        <!--                    </div>-->
                    </div>
                </div>
            </div>


            <div v-if="log_obj" class="modal modal-log" :class="modal_log_show" id="modalLog" tabindex="-1" role="dialog"
                 aria-labelledby="mySmallModalLabel" style="display:block">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="modalLogTitle">{{log_obj.title}} Log</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModalLog()"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <table class="table table-bordered" id="tbody-log">
                                <tr>
                                    <th>Date</th>
                                    <th>Title</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                    <th>Amount</th>
                                    <th>Percent</th>
                                </tr>
                                <tr v-for="(obj,key) of log_obj.list" :key="key">
                                    <td>{{obj.datetime}}</td>
                                    <td>{{obj.type}}</td>
                                    <td>{{obj.status}}</td>
                                    <td>{{obj.total}}</td>
                                    <td>{{obj.amount}}</td>
                                    <td>{{obj.percent}}</td>
                                </tr>
                            </table>
                        </div>
<!--                        <div class="modal-footer">-->
<!--                            <button type="button" class="btn btn-default" >Close</button>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>

            <div class="Condition Condition-modal" :class="{'Condition-modal-hide':airdrop_hide}" >

                <div class="referral-condition">
                    <div class="box-condition-all">
                        <button type="button" class="close" @click="hideAirdropNotice()"><i class="fas fa-times"></i></button>
                        <div class="box-section-condition">
                            <h6>Airdrop </h6>
                            <div class="box-condition">
                                <span>Airdrop (รับดอกเบี้ยสูง จากยอดเครดิตที่มีอยู่)  </span>
                                <span>- อัตราดอกเบี้ยสูงกว่าฝากเงินในธนาคารทั่วไป</span>
                                <span>- จะได้รับดอกเบี้ย 0.01% ของยอดเครดิตที่มีอยุ่</span>
                                <span>- เล่นเกมส์ต่างๆ เพื่อสะสมยอดเงินให้เพิ่มขึ้นเรื่อยๆ</span>
                                <span>- ดอกเบี้ยจะทบยอดเงินเครดิตในบัญชีของคุณ</span>
                                <span>- รับดอกเบี้ยทุก 8 ชั่วโมง</span>
                                <hr>
                                <span>เล่นหมดรับเพิ่ม (รับเพิ่มง่ายๆวันล่ะ 2 ครั้ง)</span>
                                <span>- โบนัสพิเศษแจกเครดิตฟรี ครั้งล่ะ 10 บาท</span>
                                <span>- โบนัสพิเศษ สูงสุดถึง 1,000 บาท</span>
                                <span>- เมื่อลูกค้ามียอดเงินในบัญชีเป็น 0 บาท</span>
                                <span>- แจกทุก 12 ชั่วโมง จำนวน 100 ครั้ง</span>
                                <span>- กดรับเพื่อนำไปเล่นเกมต่างๆได้เลย</span>
                                <hr>
                                <span>เสียได้คืน (คืนเงิน 0.2% ของยอดเสีย)</span>
                                <span>- ได้รับเงินคืน 0.2% ของยอดที่เล่นเสีย</span>
                               <span>- สรุปยอดคืนเงินสัปดาห์ละ 1 ครั้ง</span>
                                <span>- ยอดเสียที่นำามาคิดอัตราคืนเงิน ต้องมียอดเสียขั้นต่ำ 1,000  คือยอดที่เล่นเสียจากเงินที่เติมใน
                                สัปดาห์นั้น</span>
                                <span>-  ยอดเติมในแต่ละสัปดาห์ ไม่สามารถนำมาทบเพื่อคืนเงิน
                                ในสัปดาห์ถัดมาได้</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="Condition Condition-modal" :class="{'Condition-modal-hide':referral_hide}" >

                <div class="referral-condition">
                    <div class="box-condition-all">
                        <button type="button" class="close" @click="hideReferralNotice()"><i class="fas fa-times"></i></button>
                        <div class="box-section-condition">
                            <h6>ลิงค์รับทรัพย์ (แนะนำเพื่อน) </h6>
                            <div class="box-condition">
                                <h5>Copy link ส่งให้เพื่อน ในหน้าโปรไฟล์เว็บไซต์</h5>
                                <span>- สมัครผ่านลิ้งที่ได้รับการแนะนำ</span>
                                <span>- เพื่อนยังได้รับ Spin ฟรีอีกด้วยสิทธิประโยชน์ที่จะได้รับจาก Referrals</span>
                                <h4 >หมายเหตุ :  เพื่อนที่แนะนำต้องมีการฝากเงินมาและมียอดการเล่น ระบบถึงจะเอามาคำนวณรายได้</h4>
                                <hr>
                                    <span>1. ได้รับเงินเครดิต จำนวนเพื่อนที่สมัครผ่านลิงค์แนะนำของท่าน
                                                จำนวนเพื่อนเเนะนำ ค่าตอบเเทนที่จะได้รับ
                                     </span>
                                    <span style="margin-left: 20px;">
                                        1 และ 5 คน 10 บาท<br>
                                        10 และ 15 คน 20 บาท<br>
                                        20 และ 25 คน 30 บาท<br>
                                        40 และ 50 คน 50 บาท<br>
                                        75 และ 100 คน 300 บาท<br>
                                        150 คน 500 บาท<br>
                                        300 คน 1,000 บาท<br>
                                        500 คน 2,000 บาท<br>
                                    </span>
                                <h4 >หมายเหตุ :
                                    ระบบจะคิดเฉพาะเพื่อนที่ฝากเข้ามาเล่นและจะนับเฉพาะยุสที่ยังไม่เคยฝาก
                                    * กรณีเพื่อนที่เคยฝากและนับแล้ว จะไม่นำมาคำนวนให้อีกถ้ามีการฝากซ้ำเข้ามา แต่จะไปคิดเป็นรายได้ยอด Turn Over แทน *</h4>
                                <span>
                                    2.ได้รับโบนัสฟรี 0.10% ของยอด Turn Over (ยอดที่เล่นเกมของเพื่อนที่แนะนำ) เช่น เพื่อนเล่นได้  Turn Over  10,000  ท่านจะได้รับเงิน 10 บาทฟรีๆ
                                </span>
                                <hr>
                                <h6>เงือนไขการรับรายได้</h6>
                                <span>- ระยะเวลาคำณวนรายได้คือ วันจันทร์เวลา 00.00 น. - วันอาทิตย์ 23.59 น.</span>
                                <span>- ระยะเวลาในการกดรับรายได้คือ วันจันทร์ เวลา 00.00 น.- 23.59 น.</span>
                                <h4 >* การกดรับรายได้ 1อาทิตย์ สามารถกดรับได้1ครั้งเท่านั้น *</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="Condition Condition-modal" :class="{'Condition-modal-hide':level_hide}" >

                <div class="referral-condition">
                    <div class="box-condition-all" style="height: 250px;">
                        <button type="button" class="close" @click="hideLevelNotice()"><i class="fas fa-times"></i></button>
                        <div class="box-section-condition">
                            <h6>ระบบLevel   (อัพเลเวลรับเงินแสน)</h6>
                            <div class="box-condition" style="height: auto">
                                <span>- เมื่อเล่นเกมส์อย่างต่อเนื่อง จะสามารถอัพเลเวลได้ (คิดจากยอดการเล่น)</span>
                                <span>- ซึ่งจำนวนเครดิตฟรีจะเพิ่มขึ้นเรื่อยๆ ตามระดับเลเวล</span>
                                <span>- รับเครดิตเงินสด สูงสุด 500บาท</span>
                                <span>- รับสิทธิ์เปิดกล่องฟรีมูลค่าของรางวัลสูงสุด 150,000 บาท</span>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!--Modal Info   -->
            <!--        Modal แจ้งเตือน-->
            <div class="box-alert " id="modal_alert" v-if="alert_obj2" :class="alert_obj2_show">
                <div class="box-alert-content">
                    <div class="content-all">
                        <div class="title">
                            <h1>{{alert_obj2.title}}</h1>
                        </div>
                        <div class="content-allInfo">
                            <div class="content">
                                <span>{{alert_obj2.content}}</span>
                            </div>
                            <div class="footer">
                                <button v-if="alert_obj2.close_btn" type="button" class="btn btn-default-outline" @click="closeModalAlert()" >Close
                                </button>
                                <button v-if="alert_obj2.ok_btn" type="button" class="btn" @click="closeModalAlert()">ตกลง</button>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="overlay-box"></div>
            </div>

            <div class="box-bonus-day box-bonus-user" v-if="alert_obj3" :class="alert_obj3_show" >
                <div class="content-bonus-day content-mission-day">
                    <div class="title">
                        <div class="title-logo">
                            <img src="@/assets/images/bg-topUp/topic-bg-05.png" alt="" >
                        </div>
                        <div class="close-box-bonus"  @click="closeModalAlert()"></div>
                    </div>
                    <div class="content-all">
                        <div class="content">
                            <div class="contentInfo">
                                <p>ลูกค้าต้องฝากเงินรวม {{min_claim_bonus}} บาทขึ้นไปในวัน เพื่อรับรางวัลได้</p>
                                <!--                        <h2>โปรโมชั่นฝากเงินครั้งแรก</h2>-->
                                <div class="contentRowBonus">
                                    <div class="listRowBonus">
                                        <div class="listRowBonusInfo">
                                            <div class="topic">
                                                <img src="@/assets/images/icon/set-icon/icon-calenda.png">
                                            </div>
                                            <strong><span>{{mission_bonus_list[1]}}</span><small>฿</small></strong>
                                            <div class="TagTopic">ฝากรวม</div>
                                            <div class="buttonBonus" @click="toDepositWithdrawPage()">
                                                <span>ฝาก ฿{{min_claim_bonus}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listRowBonus">
                                        <div class="listRowBonusInfo">
                                            <div class="topic">
                                                <img src="@/assets/images/icon/set-icon/icon-spin3.png">
                                            </div>
                                            <strong><span>{{mission_bonus_list[2]}}</span><small>฿</small></strong>
                                            <div class="TagTopic">เปิดกล่องสุ่ม</div>
                                            <div class="buttonBonus buttonBonusFree">
                                                <span>ฟรี</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listRowBonus">
                                        <div class="listRowBonusInfo">
                                            <div class="topic">
                                                <img src="@/assets/images/icon/set-icon/icon-baccarat-21.png">
                                            </div>
                                            <strong><span>{{mission_bonus_list[3]}}</span><small>฿</small></strong>
                                            <div class="TagTopic">เล่นบาคาร่า</div>
                                            <div class="buttonBonus buttonBonusFree">
                                                <span>ฟรี</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listRowBonus">
                                        <div class="listRowBonusInfo">
                                            <div class="topic">
                                                <img src="@/assets/images/icon/set-icon/icon-hilo-21.png">
                                            </div>
                                            <strong><span>{{mission_bonus_list[4]}}</span><small>฿</small></strong>
                                            <div class="TagTopic">เล่นไฮโล</div>
                                            <div class="buttonBonus buttonBonusFree">
                                                <span>ฟรี</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listRowBonus">
                                        <div class="listRowBonusInfo">
                                            <div class="topic">
                                                <img src="@/assets/images/icon/set-icon/icon-fantan2.png">
                                            </div>
                                            <strong><span>{{mission_bonus_list[5]}}</span><small>฿</small></strong>
                                            <div class="TagTopic">เล่นถั่วหยิบ</div>
                                            <div class="buttonBonus buttonBonusFree">
                                                <span>ฟรี</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listRowBonus">
                                        <div class="listRowBonusInfo">
                                            <div class="topic">
                                                <img src="@/assets/images/icon/set-icon/icon-tiger-dagon2.png">
                                            </div>
                                            <strong><span>{{mission_bonus_list[6]}}</span><small>฿</small></strong>
                                            <div class="TagTopic">เล่นดราก้อนไทเกอร์</div>
                                            <div class="buttonBonus buttonBonusFree">
                                                <span>ฟรี</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="listRowBonus">
                                        <div class="listRowBonusInfo">
                                            <div class="topic">
                                                <img src="@/assets/images/coin-big-1.png">
                                            </div>
                                            <strong><span>{{mission_bonus_list['all']}}</span><small>฿</small></strong>
                                            <div class="TagTopic">เครียร์ทั้งหมด</div>
                                            <div class="buttonBonus buttonBonusFree">
                                                <span>ฟรี</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul style="display: none">
                                    <!--                            <li><img  src="@/assets/images/icon/set-icon/icon-calenda.png" alt="" />ภาระกิจรายวัน สูงสุด 60 บาท</li>-->
                                    <li>ภาระกิจรายวัน สูงสุด 60 บาท</li>
                                    <li>โบนัสรายวัน สูงสุด 720 บาท</li>
                                    <li>รับดอกเบี้ย ทุก8ชั่วโมง </li>
                                    <li>รับเครดิตคืน เมื่อเล่นเสีย</li>
                                    <li>รับเครดิตเพิ่มจากการ Lv.</li>
                                    <li>มีสร้างรายได้จาการแนะนำเพื่อน</li>
                                </ul>
                                <div class="footer footer-center">
                                    <button type="button" class="btn bg-gradient-btn" @click="toDepositWithdrawPage()">ฝากเงิน {{min_claim_bonus}}฿ ฟรี {{mission_bonus_list[1]}}฿</button>
                                </div>
                            </div>

                        </div>
                        <div class="content-bg"></div>
                    </div>
                </div>
                <div class="overlay-box"></div>
            </div>

        </div>
    </div>

</template>
<script>

    import MenuBar from '@/components/MenuBar'
    // import MenuLeft from '@/components/MenuLeft'
    // import MenuBarUnbox from '@/components/menu-bar/Unbox'
    import LayoutMixin from '@/mixins/Layout'
    import store from '@/store'
    import axiosWrapper from '@/axiosWrapper'
    import UserMixin from '@/mixins/UserMixin'
    import {Carousel, Slide} from 'vue-carousel'
    import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
    import $ from 'jquery'

    window.jQuery = $;
    window.$ = $;
    import moment from 'moment';
    import BigNumber from 'bignumber.js'

    var is_ios = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i));
    import AccountMixin from '@/components/user/AccountMixin'
    import UserChangeImageProfile from '@/components/user/ChangeImageProfile'
    import PromotionTime from '@/components/user/PromotionTime'

    import MenuLeft from '@/components/MenuLeft'
    import MenuNav from '@/components/MenuNav'
    import MenuNavTop from '@/components/MenuNavTop'

    export default {
        name: 'ListGame',
        components: {
            FloatButtomRightPanel,
            MenuBar,
            // MenuLeft,
            // MenuBarUnbox,
            UserChangeImageProfile,
            Carousel,
            Slide,
            PromotionTime,
            MenuLeft,
            MenuNav,
            MenuNavTop,
        },
        mixins: [
            LayoutMixin,
            UserMixin,
            AccountMixin,
        ],
        data() {
            return {
                is_go_home: '',
                mission_bonus_list :{
                    1:10,
                    2:10,
                    3:10,
                    4:10,
                    5:10,
                    6:10,
                    'all':10,
                },
                min_claim_bonus :50,
                showChatWithAdminNumber:0,
                showDepositWithdrawNumber:0,
                menuNumber :0,
                isHideProvider: false,
                isHideListGame: true,
                isHideListGameContent: false,

                sp_info: null,
                is_ready: false,
                historyAndInventory: [],
                historyAndInventoryAll: [],
                tab_current: 'all',
                // isShowLevelUp:true,
                level_up_obj: null,
                daily_bonus_obj: null,
                daily_mission_obj: null,
                claim_bonus_obj: null,
                alert_obj: null,
                alert_obj2 :null,
                alert_obj3:null,
                daily_bonus_show: true,
                daily_mission_show: 0,
                sp_daily_mission_bonus_date: null,
                log_obj: null,
                modal_log_show: '',
                log_type_list: {
                    'cash_back_bonus': 'Cash Back',
                    'referral_bonus': 'Referral Bonus',
                    'air_drop_bonus': 'AirDrop',
                },
                alert_obj_show: '',
                alert_obj2_show :'',
                alert_obj3_show:'',
                isShowChangeImageProfile: false,
                newHistoryList: null,
                avatar_bg_list: [],
                avatar: {},
                referral_hide:true,
                airdrop_hide:true,
                level_hide:true,
                dayOfWeek : moment().format('e'),

            };
        },
        created: function () {
            var self = this;

            $(document).on('click', '#modal_level_up_close_btn', function () {
                $('#modal_level_up').addClass('hide');

                setTimeout(() => {
                    // console.log(self.level_up_obj);
                    self.level_up_obj = undefined;
                }, 1000 * 0.3);

            });
            $(document).on('click', '#modal_level_up_confirm_btn', function () {
                        
                        setTimeout(() => {
                            self.level_up_obj = undefined;
                        }, 1000 * 0.3);
            });
            $(document).on('click', '#close_modal_alert_btn', function () {
                self.alert_obj_show = 'hide';
                setTimeout(() => {
                    self.alert_obj_show = '';
                    self.alert_obj2= undefined;

                }, 1000 * 0.3);
            });

        },
        computed: {
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            user_profile() {
                return store.state.user_profile
            },
            current_pv_id() {
                return (this.pes_profile) ? this.pes_profile.current_pv_id : null
            },
        },
        methods: {
            goHome() {
                this.is_go_home='Index';
                let self = this;
                setTimeout(()=>{
                    self.is_go_home='';
                },1000)
                
            },
            showChatWithAdmin(){
                this.showChatWithAdminNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showChatWithAdminNumber=0;
                },100);
                
            },
            showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

            showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
            },
            OpenListProvider() {
                this.isHideProvider = true;
            },
            ClosePopupProvider() {
                this.isHideProvider = false;
            },
            OpenListAllGame(){
                this.isHideListGame = false;
                this.isHideListGameContent = true;
            },
            CloseListAllGame() {
                this.isHideListGame = true;
                this.isHideListGameContent = false;
            },

            toIndexPopularPage() {
                this.$router.push({name: 'IndexPopular'})
            },
            toIndexCasinoPage() {
                this.$router.push({name: 'IndexCasino'})
            },
            toIndexSlotPage() {
                this.$router.push({name: 'IndexSlot'})
            },
            toIndexFishingPage() {
                this.$router.push({name: 'IndexFishing'})
            },
            toIndexSportPage() {
                this.$router.push({name: 'IndexSport'})
            },
            toIndexLottoPage() {
                this.$router.push({name: 'IndexLotto'})
            },
            toIndexUnboxPage() {
                this.$router.push({name: 'IndexUnbox'})
            },

            toDepositWithdrawPage() {
                this.$router.push({name: 'DepositWithdraw'})
            },
            closeModalAlert(){

                
                if(this.alert_obj2){
                    this.alert_obj2=null;
                }else if(this.alert_obj){
                    this.alert_obj=null;
                }else if(this.alert_obj3){
                   this.alert_obj3=null;
                   this.$cookies.set("dont_first_deposit_today",1,60*60*4);
                }
                
            
            },
            update_pes_profile(val){
                store.commit('updatePesProfile', val)
            },
            async getSPInfo() {
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/info.php?token=` + this.token,
                });
                let pes_profile=this.pes_profile;
                let balance= res.data.data.balance;
                pes_profile.THB_free = balance.THB_free;
                pes_profile.THB_used = balance.THB_used;
                pes_profile.THB_total = balance.THB_total;
                this.min_claim_bonus  = pes_profile.min_claim_bonus;
                this.update_pes_profile(pes_profile);
                return res.data.data;
            },
            async reloadBalance(){
                await this.getSPInfo();
            },
            async getAvatar() {
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/avatar/bg/list.php?token=` + this.token,
                });
                return res.data.data;
            },
            async getHistoryAndInventory() {
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/historyAndInventory.php?token=` + this.token,
                });
                return res.data.data;
            },
            convertToDate(dt) {
                return moment(dt).format('DD.MM.YYYY');
            },
            convertToTime(dt) {
                return moment(dt).format('HH:MM');
            },
            manageAirDrop() {
                var air_drop = this.sp_info.air_drop;
                var sp_next_calculate_time = air_drop.sp_next_calculate_time;
                var toDate = is_ios ? new Date(moment(sp_next_calculate_time).format('YYYY/MM/DD HH:mm:ss')) : new Date(sp_next_calculate_time);
                var timer = setInterval(function () {
                    timeBetweenDates(toDate, 'air_drop_');
                }, 1000);
                timer;
                if (air_drop.sp_amount > 0) {
                    var claim_text = 'Claim AirDrop';
                    this.sp_info.air_drop.text = claim_text;
                    this.sp_info.air_drop.number = (parseFloat(air_drop.sp_amount).toFixed(4));
                } else {
                    var interest = new BigNumber(this.pes_profile.THB_free).times(0.01).div(100);
                    var interest_text = 'รับแอร์ดรอป 0.01% ทุก 8 ชม.';
                    this.sp_info.air_drop.text = interest_text;
                    this.sp_info.air_drop.number = (interest.toFixed(4));
                }

            },
            manageFreeBonus() {
                var free_bonus = this.sp_info.free_bonus;
                var current_timstamp = parseInt(moment().format('x'));
                var timestamp = free_bonus.timestamp;
                var remaining = free_bonus.remaining;
                var time = parseInt(timestamp) + parseInt(remaining)
                // console.log(remaining);
                // console.log(moment(time,'x').format('YYYY-MM-DD HH:mm:ss'))
                // console.log(moment(current_timstamp,'x').format('YYYY-MM-DD HH:mm:ss'));

                var diff = current_timstamp - time;
                // console.log('diff', diff);
                if (diff > 0) {
                    this.sp_info.free_bonus.diff = diff;

                } else {
                    this.sp_info.free_bonus.diff = diff;
                    var toDate = new Date(time);
                    var timer = setInterval(function () {
                        var result_timer = timeBetweenDates(toDate, 'free_bonus_');
                        if (result_timer) {
                            clearInterval(timer);
                        }
                    }, 1000);
                }
                // let sp_info = this.sp_info;
                // console.log(sp_info.free_bonus.diff>0 && sp_info.balance.THB_total<10 && sp_info.free_bonus.count>0);
            },
            manageCashBack() {
                var cash_back = this.sp_info.cash_back;

                var sp_next_calculate_time = cash_back.sp_next_calulate_time;

                var toDate = is_ios ? new Date(moment(sp_next_calculate_time).format('YYYY/MM/DD HH:mm:ss')) : new Date(sp_next_calculate_time);
                var timer = setInterval(function () {
                    var result_timer = timeBetweenDates(toDate, 'cashback_');
                    if (result_timer) {
                        clearInterval(timer);
                    }
                }, 1000);
            },
            manageLevel() {
                var reward_list = [];
                for (var i = 0; i <= 10; i++) {
                    reward_list[i] = [];
                }
                for (var index in this.sp_info.level.sp_level_up_reward_index) {
                    var reward = this.sp_info.level.sp_level_up_reward_index[index];
                    var main_level = Math.floor((index) / 10);
                    if (index % 10 == 0) {
                        reward_list[main_level - 1].push(reward);
                    } else {
                        reward_list[main_level].push(reward);
                    }


                }
                this.level_reward_list = reward_list;

                var page = Math.ceil(this.sp_info.level.sp_user_level_claim / 3.3) - 1;
                var value = 375 * (page);
                value;
                setTimeout(function () {
                    // console.log(Carousel.prop.getNextPage );
                    for (let i = 0; i < page; i++) {
                        $('.VueCarousel-navigation-next').trigger('click');
                    }

                    // $('div.VueCarousel-inner').css('transform', 'translate(-'+value+'px, 0px)');
                }, 1000 * 1);
                // // Carousel.value=10;
                // // console.log(reward_list);

            },
            showReferralNotice(){
                this.referral_hide=false;
            },
            hideReferralNotice(){
                this.referral_hide=true;
            },
            showAirdropNotice(){
                this.airdrop_hide=false;
            },
            hideAirdropNotice(){
                this.airdrop_hide=true;
            },
            showLevelNotice(){
                this.level_hide=false;
            },
            hideLevelNotice(){
                this.level_hide=true;
            },
            manageReferral() {
                var referral = this.sp_info.referral;
                var url = `${this.envDomains.VUE_APP_API}?c=` + referral.channel + '&m=' + encodeURIComponent(referral.message_api_id) + '&r=' + referral.referral_code + '&id=' + referral.reward_id_list[0];
                this.sp_info.referral.link = url;
                this.sp_info.referral.max = this.sp_info.referral.referral_list[12].count;
                this.sp_info.referral.percent = parseInt((this.sp_info.referral.referral_count / this.sp_info.referral.max) * 100);
                this.sp_info.referral.percent = this.sp_info.referral.percent > 100 ? 100 : this.sp_info.referral.percent;

                let sp_next_calculate_time = referral.sp_next_calulate_time;
                let dayOfWeek = moment().format('e');
                this.sp_info.referral.dayOfWeek= dayOfWeek;
                // console.log(dayOfWeek,'yes');

                if(sp_next_calculate_time){
                    let toDate = is_ios ? new Date(moment(sp_next_calculate_time).format('YYYY/MM/DD HH:mm:ss')) : new Date(sp_next_calculate_time);
                    let timer = setInterval(function () {
                        timeBetweenDates(toDate, 'referral_');
                    }, 1000);
                    timer;
                }



            },
            manageHistoryAndInventory() {
                // var unboxList = this.historyAndInventory[1];
                var all_list = [];
                for (var index = 0; index < this.historyAndInventory.length; index++) {
                    var list = this.historyAndInventory[index];
                    for (var i = 0; i < list.length; i++) {
                        all_list.push(list[i]);
                    }

                }
                this.historyAndInventoryAll = all_list;

            },
            claimLevel(level) {

                if (level == this.sp_info.level.sp_user_level_claim + 1 && level <= this.sp_info.level.sp_user_level) {
                    console.log('claim');
                    $('#modal_level_up').addClass('show');
                    this.isShowLevelUp = true;
                    this.level_up_obj = {level: parseInt(this.sp_info.level.sp_user_level_claim) + 1};
                    // this.level_up_obj.img ='/assets/images/coin-small-1.png';
                    this.level_up_obj.bonus_item_name = this.sp_info.level.sp_level_up_reward[this.sp_info.level.sp_level_up_reward_index[level]].bonus_item_name;
                    this.level_up_obj.type = level % 10 != 0 ? 'Credit' : this.sp_info.level.sp_level_up_reward[this.sp_info.level.sp_level_up_reward_index[level]].case_name;
                    this.level_up_obj.img = level % 10 != 0 ? '' : this.sp_info.level.sp_level_up_reward[this.sp_info.level.sp_level_up_reward_index[level]].item_img;

                    $('#modal_level_up').removeClass('hide').show();

                } else if (level > this.sp_info.level.sp_user_level_claim + 1 && level <= this.sp_info.level.sp_user_level) {
                    this.isShowLevelUp = true;
                    level = this.sp_info.level.sp_user_level_claim + 1;
                    this.level_up_obj = {level: this.sp_info.level.sp_user_level_claim + 1};
                    // this.level_up_obj.img ='/assets/images/coin-small-1.png';
                    this.level_up_obj.bonus_item_name = this.sp_info.level.sp_level_up_reward[this.sp_info.level.sp_level_up_reward_index[level]].bonus_item_name;
                    this.level_up_obj.type = level % 10 != 0 ? 'Credit' : this.sp_info.level.sp_level_up_reward[this.sp_info.level.sp_level_up_reward_index[level]].case_name;
                    this.level_up_obj.img = level % 10 != 0 ? '' : this.sp_info.level.sp_level_up_reward[this.sp_info.level.sp_level_up_reward_index[level]].item_img;
                    $('#modal_level_up').removeClass('hide').show();
                    console.log('Please claim previous level [' + (this.sp_info.level.sp_user_level_claim) + ']')
                } else {
                    console.log('nothing');
                }
            },
            claimLevelConfirm(level) {
                var self = this;
                $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_user_level_bonus.php`, {
                    level: level,
                    token: this.token
                }, function (response) {
                    if (response.code == 1) {
                        setTimeout(() => {
                            self.level_up_obj = undefined;
                        }, 1000 * 0.3);
                        var data = response.data;
                        var bonus_type = data.bonus_type;
                        self.sp_info.level.sp_user_level_claim = parseInt(level);
                        if (bonus_type == 0) {
                            var balance = new BigNumber(data.bonus_item_value).plus(self.pes_profile.THB_free).toNumber();
                            self.pes_profile.THB_free = balance;


                        } else if (bonus_type == 1) {
                            bonus_type
                        } else {
                            bonus_type
                        }
                        $('#modal_level_up').addClass('hide')
                        console.log(this.sp_info.level.sp_user_level_claim,level)
                        this.sp_info.level.sp_user_level_claim = level;
                    } else {
                        let msg = response.msg;
                        if(msg=='Need deposit first'){
                            msg='*ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                            self.alert_obj3=1;
                            return ;
                        }
                        self.alert_obj2= {
                            title: 'แจ้งเตือน',
                            content: msg,
                            ok_btn: true,
                            close_bnt: false,
                        };
                    }
                });
            },
            claimFreeBonus(_checked) {
                if(_checked){
                    var self = this;
                    $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_free_bonus.php`, {token: this.token}, function (response) {
                        if (response.code == 1) {
                            var balance = new BigNumber(20).plus(self.pes_profile.THB_free).toNumber();
                            self.pes_profile.THB_free = balance;
                            self.sp_info.free_bonus.diff = -1;
                            self.sp_info.free_bonus.count--;
                            self.sp_info.free_bonus.timestamp = moment().format('x');
                            self.manageFreeBonus()

                            // alert(response.msg);
                            self.alert_obj2= {
                                title: 'แจ้งเตือน',
                                content: 'รับโบนัสสำเร็จ',
                                ok_btn: true,
                                close_bnt: false,
                            };

                        } else {
                            let msg = response.msg;
                            if(msg=="Can't claim free bonus,balance less than minimum."){
                                msg ='ไม่สามารถรับโบนัสได้ , เครดิตของท่านต้องมีน้อยกว่า 10';
                            }else if(msg=='Bonus not found.'){
                                msg ='ไม่พบโบนัส';
                            }else if(msg=='Need deposit first'){
                                msg='ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                                self.alert_obj3=1;
                                return ;
                            }
                            self.alert_obj2= {
                                title: 'แจ้งเตือน',
                                content: msg,
                                ok_btn: true,
                                close_bnt: false,
                            };
                            // alert(response.msg);
                        }
                    });
                
                    }

            },
            claimCashBackBonus(_checked){
                if(_checked){
                    var self = this;
                    $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_cash_back_bonus.php`, {token: this.token}, function (response) {
                        if (response.code == 1) {
                            var balance = new BigNumber(self.sp_info.cash_back.sp_amount).plus(self.pes_profile.THB_free).toNumber();
                            self.pes_profile.THB_free = balance;
                            self.sp_info.cash_back.sp_amount=0;
                            self.sp_info.cash_back.sp_datetime=null;

                            // alert(response.msg);
                            self.alert_obj2= {
                                title: 'แจ้งเตือน',
                                content: 'รับโบนัสสำเร็จ',
                                ok_btn: true,
                                close_bnt: false,
                            };

                        } else {
                            let msg = response.msg;
                            if(msg=="Can't claim free bonus,balance less than minimum."){
                                msg ='ไม่สามารถรับโบนัสได้ , เครดิตของท่านต้องมีน้อยกว่า 10';
                            }else if(msg=='Bonus not found.'){
                                msg ='ไม่พบโบนัส';
                            }else if(msg=='Need deposit first'){
                                msg='ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                                self.alert_obj3=1;
                                return ;
                            }
                            self.alert_obj2= {
                                title: 'แจ้งเตือน',
                                content: msg,
                                ok_btn: true,
                                close_bnt: false,
                            };
                            // alert(response.msg);
                        }
                    });
                
                    }
            },
            claimReferralBonus(amount) {
                var self = this;
                $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_referral_bonus2.php`, {token: this.token}, function (response) {
                    if (response.code == 1) {
                        var balance = new BigNumber(amount).plus(self.pes_profile.THB_free).toNumber();
                        self.pes_profile.THB_free = balance;
                        // alert(response.msg);
                        self.alert_obj2= {
                            title: 'แจ้งเตือน',
                            content: 'รับสำเร็จ',
                            ok_btn: true,
                            close_bnt: false,
                        };
                        self.sp_info.referral.referral_amount=0;
                        self.sp_info.referral.referral_bonus={
                            amount:0,
                            datetime:null,
                        }

                    } else {
                        let msg = response.msg;
                        if(msg=='Need deposit first'){
                            msg='*ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                            self.alert_obj3=1;
                            return ;
                        }
                        self.alert_obj2= {
                            title: 'แจ้งเตือน',
                            content: msg,
                            ok_btn: true,
                            close_bnt: false,
                        };
                        // alert(response.msg);
                    }
                });
            },
            claimReferral(level, amount) {
                var self = this;
                // console.log(level + '>' + this.sp_info.referral.referral_count_claim + '&&' + level + '<=' + this.sp_info.referral.referral_count);
                if (level > this.sp_info.referral.referral_count_claim && level <= this.sp_info.referral.referral_count) {
                    $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_referral_bonus.php`, {
                        level: level,
                        token: this.token
                    }, function (response) {
                        if (response.code == 1) {
                            var balance = new BigNumber(amount).plus(self.pes_profile.THB_free).toNumber();
                            self.pes_profile.THB_free = balance;
                            self.sp_info.referral.referral_count_claim=level;
                            // alert(response.msg);
                            self.alert_obj2= {
                                title: 'แจ้งเตือน',
                                content: 'รับสำเร็จ',
                                ok_btn: true,
                                close_bnt: false,
                            };

                        } else {
                            let msg = response.msg;
                            if(msg=='Need deposit first'){
                                msg='*ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                                self.alert_obj3=1;
                                return ;
                            }
                            self.alert_obj2= {
                                title: 'แจ้งเตือน',
                                content: msg,
                                ok_btn: true,
                                close_bnt: false,
                            };
                            // alert(response.msg);
                        }
                    });

                } else {

                    console.log("else referral ");
                }
            },
            coppyToClipboard(id) {
                /* Get the text field */
                var self = this;
                var copyText = document.getElementById(id);
                    copyText.select();
                    copyText.setSelectionRange(0, 99999); /* For mobile devices */
                    // console.log('xx');
                    /* Copy the text inside the text field */
                    document.execCommand("copy");

                    /* Alert the copied text */
                    // alert("Copied successful.");
                    self.alert_obj2= {
                        title: 'แจ้งเตือน',
                        content: 'คัดลอกสำเร็จ',
                        ok_btn: true,
                        close_bnt: false,
                    };
                // console.log(copyText);

                /* Select the text field */
                

            },
            showClaimBonus(_class, bonus_id, bonus_item_name, bonus_type, bonus_item_type_name, bonus_item_value, bonus_item_img) {
                if (_class == 'get-bonus-active' || _class == 'claim_daily_mission') {
                    var bonus_claim_type = '';
                    if (_class == 'get-bonus-active') {
                        bonus_claim_type = 'daily_bonus';
                    } else if (_class == 'claim_daily_mission') {
                        bonus_claim_type = 'daily_mission';
                    }
                    if (bonus_claim_type) {
                        this.claim_bonus_obj = {
                            bonus_claim_type: bonus_claim_type,
                            bonus_id: bonus_id,
                            bonus_item_name: bonus_item_name,
                            bonus_type: bonus_type,
                            bonus_item_type_name: bonus_item_type_name,
                            bonus_item_value: bonus_item_value,
                            bonus_item_img: bonus_item_img,
                        }
                    }


                }
            },
            goSignOut() {
                this.signOut()
                setTimeout(() => {
                    location.reload()
                }, 100)
            },
            showModalLog(type) {
                this.modal_log_show = '';
                var self = this;
                $.get(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/sp_log.php?type=` + type + '&token=' + this.token, function (response) {
                    if (response.code == 1) {
                        var data = response.data;
                        var list = [];
                        for (var i = 0; i < data.length; i++) {
                            var _data = {
                                datetime: moment(data[i].create_datetime).format('DD/MM/YYYY HH:mm'),
                                total: self.numberWithCommasNormal((parseFloat(data[i].total)).toFixed(2)),
                                amount: self.numberWithCommasNormal((parseFloat(data[i].amount)).toFixed(2)),
                                percent: self.numberWithCommasNormal((parseFloat(data[i].percent)).toFixed(2)),
                                title: self.log_type_list[type],
                                status: data[i].status != 1 ? 'ยังไม่เครม' : 'เครมแล้ว',
                            };
                            list.push(_data);
                        }
                        var obj = {
                            list: list,
                            type: type,
                            title: self.log_type_list[type],

                        };
                        self.log_obj = obj;
                        console.log("log obj");

                    } else {
                        self.alert_obj2= {
                            title: 'แจ้งเตือน',
                            content: response.msg,
                            ok_btn: true,
                            close_bnt: false,
                        };
                    }
                });
            },
            closeModalLog() {
                var self = this;
                this.modal_log_show = 'hide';
                setTimeout(() => {
                    self.modal_log_show = '';
                    self.log_obj = null;
                }, 1000 * 0.3);

            },
            openChangeImageProfile() {
                this.isShowChangeImageProfile = true
            },
            closeChangeImageProfile() {
                this.isShowChangeImageProfile = false
            },

            numberWithCommasNormal(x) {

                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //console.log(x);
                //console.log(parts.join("."));
                return parts.join(".");


            },
            showDailyMission(){
                sessionStorage.setItem('showDailyMission',1);
                this.$router.push({name:'Home',query: {}});
            },
            showDailyBonus(){
                sessionStorage.setItem('showDailyBonus',1);
                this.$router.push({name:'Home',query: {}});
            },
            goChangePassword() {
                this.$router.push({name: 'Account', query: {'change-password': 'yes'}})
            },
        },
        async mounted() {
            this.sp_info = await this.getSPInfo();
            let av = this.sp_info.sp_bonus_item.avatar;
            if (!av) {
                this.avatar = {
                    bg: null,
                    bg_list: {},
                }
            } else {
                this.avatar = av;
            }
            this.historyAndInventory = await this.getHistoryAndInventory();
            var newHistoryList = [];
            for (let i = 0; i < this.historyAndInventory[1].length; i++) {
                if (this.historyAndInventory[1][i].side_win || this.historyAndInventory[1][i].item_name || this.historyAndInventory[1][i].game == 0 || this.historyAndInventory[1][i].game == 1) {
                    newHistoryList.push(this.historyAndInventory[1][i]);
                }
            }
            this.newHistoryList = newHistoryList;
            this.manageAirDrop();
            this.manageFreeBonus();
            this.manageCashBack();
            this.manageLevel();
            this.manageReferral();
            this.manageHistoryAndInventory();
            this.mission_bonus_list[1]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[1]].bonus_item_value)
            this.mission_bonus_list[2]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[2]].bonus_item_value)
            this.mission_bonus_list[3]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[3]].bonus_item_value)
            this.mission_bonus_list[4]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[4]].bonus_item_value)
            this.mission_bonus_list[5]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[5]].bonus_item_value)
            this.mission_bonus_list[6]=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config[6]].bonus_item_value)
            this.mission_bonus_list['all']=parseFloat(this.sp_info.daily_mission.sp_daily_mission_bonus_item[this.sp_info.daily_mission.sp_daily_mission_bonus_config['all']].bonus_item_value)

            this.is_ready = true;
            var self = this;
            $(document).on('click', 'div.claim_daily_bonus', function () {
                var _add_class = $(this).attr('_add_class');
                var bonus_id = $(this).attr('bonus_id');
                var bonus_item_name = $(this).attr('bonus_item_name');
                var bonus_item_type_name = $(this).attr('bonus_item_type_name');
                var bonus_type = $(this).attr('bonus_type');
                var bonus_item_value = $(this).attr('bonus_item_value');
                var bonus_item_img = $(this).attr('bonus_item_img');
                self.showClaimBonus(_add_class, bonus_id, bonus_item_name, bonus_type, bonus_item_type_name, bonus_item_value, bonus_item_img);
                self.daily_bonus_obj = null;
            });
            $(document).on('click', 'button.claim_daily_mission', function () {
                var _add_class = $(this).attr('_add_class');
                var bonus_id = $(this).attr('bonus_id');
                var bonus_item_name = $(this).attr('bonus_item_name');
                var bonus_item_type_name = $(this).attr('bonus_item_type_name');
                var bonus_type = $(this).attr('bonus_type');
                var bonus_item_value = $(this).attr('bonus_item_value');
                var bonus_item_img = $(this).attr('bonus_item_img');
                self.showClaimBonus(_add_class, bonus_id, bonus_item_name, bonus_type, bonus_item_type_name, bonus_item_value, bonus_item_img);
            });
            $(document).on('click', 'button.cliam_bonus_btn', function () {
                var bonus_claim_type = $(this).attr('bonus_claim_type');
                var bonus_type = $(this).attr('bonus_type');
                var bonus_item_value = $(this).attr('bonus_item_value');
                var bonus_id = $(this).attr('bonus_id');
                var is_claim = $(this).attr('is_claim');
                var _self = $(this);
                if (!is_claim) {
                    $(this).attr('is_claim', 1);
                    if (bonus_claim_type == 'daily_bonus') {
                        $.post(`${self.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_daily_login_bonus.php`, {token: self.token}, function (response1) {
                            if (response1.code == 1) {
                                // bonus_type;
                                // $('#modal_daily_bonus').hide();
                                self.sp_info.daily_bonus.sp_daily_login_bonus_date = moment().format('YYYY-MM-DD');
                                self.daily_bonus_obj = null;
                                self.claim_bonus_obj = null;
                                if (bonus_type == 0) {
                                    var balance = new BigNumber(self.pes_profile.THB_free).plus(bonus_item_value).toNumber();
                                    self.pes_profile.THB_free = balance;
                                }

                            } else {
                                let msg = response1.msg;
                                    if(msg=='Need deposit first'){
                                        msg='*ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                                        self.alert_obj3=1;
                                        return ;
                                    }
                                    self.alert_obj2 ={
                                        title:'แจ้งเตือน',
                                        content: msg,
                                        ok_btn:true,
                                        close_bnt:false,
                                    };						

                                }
                            setTimeout(function () {
                                _self.removeAttr('is_claim');
                            }, 1000)
                        });
                    } else if (bonus_claim_type == 'daily_mission') {
                        $.post(`${self.envDomains.VUE_APP_PES_BASE_URL}/api/v3/sp/claim_daily_mission_bonus.php`, {
                            mission_id: bonus_id,
                            token: self.token
                        }, function (response2) {
                            if (response2.code == 1) {
                                self.claim_bonus_obj = null;
                                self.sp_info.daily_mission.sp_daily_mission_bonus[bonus_id].status = 2;
                                self.daily_mission_obj.sp_daily_mission_bonus[bonus_id].status = 2;
                                if (self.daily_mission_show > 0) {
                                    self.daily_mission_show = self.daily_mission_show - 1;
                                }


                                if (bonus_type == 0) {
                                    var balance = new BigNumber(self.pes_profile.THB_free).plus(bonus_item_value).toNumber();
                                    self.pes_profile.THB_free = balance;
                                }

                            } else {
                                let msg =response2.msg;
                                if(msg=='Need deposit first'){
                                    msg='ต้องมียอดเติมครั้งแรกก่อนกดรับ';
                                    self.alert_obj3=1;
                                    return ;
                                }
                                self.alert_obj2= {
                                    title: 'แจ้งเตือน',
                                    content: msg,
                                    ok_btn: true,
                                    close_bnt: false,
                                };

                            }
                            setTimeout(function () {
                                _self.removeAttr('is_claim');
                            }, 1000)
                        });

                    }
                }


            });

            // $(document).on('click','.close-box-bonus',function(){
            //     var bonus_claim_type = $(this).attr('bonus_claim_type');
            //     if(bonus_claim_type){
            //         self[bonus_claim_type]=null;
            //     }
            // });
            // $(document).on('click','#daily_mission_show_btn',function(){
            //     self.daily_mission_obj = self.sp_info.daily_mission;
            // });
            // $(document).on('click','#daily_bonus_show_btn',function(){
            //     self.manageDailyBonus();
            // });


        },

    }

    function timeBetweenDates(toDate, prefix = '') {
        var dateEntered = toDate;
        var now = new Date();
        var difference = dateEntered.getTime() - now.getTime();
        if (difference <= 0) {
// Timer done
            return 1;
        } else {

            var seconds = Math.floor(difference / 1000);
            var minutes = Math.floor(seconds / 60);
            var hours = Math.floor(minutes / 60);
            var days = Math.floor(hours / 24);

            minutes %= 60;
            seconds %= 60;
            hours %= 24;
            $("#" + prefix + "days").text(days);
            $("#" + prefix + "hours").text(hours);
            $("#" + prefix + "minutes").text(minutes);
            $("#" + prefix + "seconds").text(seconds);
            return 0;
        }
    }


    // $(window).scroll(function(){
    //     if ($(this).scrollTop() > 100) {
    //         $('#headerProfile').addClass('header-profile');
    //     } else {
    //         $('#headerProfile').removeClass('header-profile');
    //     }
    // });
    // $("div").click(function(){
    //     $(".box-interface").toggleClass("active");
    // });











</script>

<style src="@/assets/css/profile.css" scoped></style>
<style>

    /*.ListGame .MenuBar.box-all-games ,*/
    /*.ListGame-profile .box-balance-total,*/
    /*.ListGame-profile .box-balance-total{*/
    /*    display: none!important;*/
    /*}*/
    .ListGame-profile span.box-txt-back:before{
        content: '\e88a';
        font: 25px "Material Icons";
        margin-right: 5px;
    }
    .ListGame-profile .wrap-menu-top {
        background-image: none;
    }

    /*.ListGame-profile .box-advertise-games {*/
    /*    display: none*/
    /*}*/
    .ListGame-profile .content-page{margin-top: 0; padding: 5px 0 0;}
    .ListGame-profile .rowListDropdownDailyBonus,
    .ListGame-profile .rowListDropdownDailyMission{
        display: none!important;
    }
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape) {
        .ListGame .MenuBar.box-all-games {
            /*display: none !important*/
        }
    }
</style>
<style scoped>
    .VueCarousel-slide {
        flex-basis: initial;
    }

    .header-games {
        padding: 10px;
        height: 95px;
        text-align: center;
        color: #fff;
        font-family: "Athiti", sans-serif;
    }


    .header-games span {
        display: inline-block;
        float: left;
    }

    .header-games img {
        width: 80px;
    }

    .box-game {
        position: relative;
        background-position: center;
        background-size: cover;
        height: 160px;
        border-radius: 20px;
        margin-bottom: 15px;
    }

    .box-game span {
        display: inline-block;
        background-color: #fff;
        padding: 5px 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 10px 0px 0px 0px;
        font-family: "Athiti", sans-serif;
        font-weight: 600;
    }


    .box-name-profile-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
        position: relative;
        z-index: 3;
    }
    .box-name-profile-info h3{
        margin-bottom: 0;
        font: 800 18px/1 Athiti;
    }
    .box-name-profile-info span{
        font: 400 16px Athiti;
    }
    /*.box-img-profile,*/
    .box-name-profile {
        /*width: 225px;*/
        /*margin-top: -42px;*/
        margin-left: 3px;
        /*color: white;*/
    }



    .box-name-profile {
        font-weight: 600;
        position: relative;
    }
    .box-name-profile-info-content{
        display: flex;
        align-items: center;
    }
    .box-name-profile-info-content button{
        position: relative;

    }
    .box-name-profile-info-content button i{
        background-color: transparent!important;
        width: auto;
        height: auto;
    }
    .box-content {
        font-family: "Athiti", sans-serif;
    }

    .box-content small {
        font-size: 11px;
    }


    .nav-tabs-tl > li {
        width: 20%;
    }

    .nav-tabs-tl > li > a.nav-link {
        padding: 2px 5px;
        font-size: 13px;
        text-align: center;
        font-family: "Athiti", sans-serif;
        font-weight: 600;
        color: #54117f;
    }

    .nav-tabs-tl > li > a.active {
        border: 0px;
        border-bottom: solid 2px;
    }

    .box-cols {
        background-color: #eee;
        padding: 10px;
        font-family: "Athiti", sans-serif;
    }

    .box-cols strong, .box-cols i {
        font-size: 13px;
    }

    .box-list {
        display: flex;
        padding: 15px 15px 10px 15px;
    }

    .box-list i {
        font-size: 16px;
        color: #888;
    }

    .box-list > .box-icon-list {
        padding-right: 15px;
    }

    .box-list > .box-content-list {
        flex: 2;
    }

    .box-content-list ul {
        margin: 0px;
        padding: 0px;
    }

    .box-content-list li {
        display: inline-block;
        line-height: 14px;
        margin-right: 10px;
    }

    .box-content-list p {
        margin: 0px;
        font-size: 16px;
        font-weight: 600;
    }

    .box-content-list small {
        font-size: 11px;
        color: #888;
    }

    .btn-purple {
        background-color: rgba(243, 114, 212, 0.9);
        color: #fff;
        border-radius: 10px;
    }

    .wrapper-box-list {
        margin-bottom: 10px;
    }

</style>