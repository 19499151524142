<template>

    <div class="wrapContentIndex LottoNumber LottoNumberBet">
        <!--     ตัวเลขที่เลือก    //////////////////////////////////////////-->
        <div class="containerNumSelect" v-if="showModalConfirm" >
            <div class="wrapNumSelect">
                <div class="boxNumSelect">
                    <div class="title">
                        <h4>เลขที่เลือก</h4>
                        <button type="button" class="btn" @click="showModalConfirm=false"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="content contentBillBet">
                        <table>
                            <thead>
                                <tr>
                                    <th>ตัวเลข</th>
                                    <th>ราคา</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row,number) of game.game_info.list" :key="number"  v-show="row.status==null"  >
                                    <td ><span>{{number}}</span></td>
                                    <td ><p>{{ game.price }} บาท</p></td>
                                    <td >
                                        <button type="button" class="btn" @click="removeItem(number)"> ลบ</button>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td><span>00</span></td>
                                    <td><p>20 บาท</p></td>
                                    <td>
                                        <button type="button" class="btn"><i class="fas fa-minus-circle"></i></button>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>00</span></td>
                                    <td><p>20 บาท</p></td>
                                    <td>
                                        <button type="button" class="btn"><i class="fas fa-minus-circle"></i></button>
                                    </td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                    <div class="footer">
                        <table>
                            <tr>
                                <td>กระดานที่</td>
                                <td>{{game.round_main}}</td>
                            </tr>
                            <tr>
                                <td>เลขที่รายการ</td>
                                <td>#</td>
                            </tr>
                        </table>
                        <ul>
                            <li>รวม</li>
                            <li><strong>{{bet_sum}}</strong> บาท</li>
                        </ul>
                        <button type="button" class="btn" @click="confirmBet2()">ตกลง</button>
                    </div>
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>

        <!--  กดซื้อแล้ว    //////////////////////////////////////////-->
        <div class="containerNumSelect containerNumSelectBetDetail" v-if="showModalConfirm2">
            <div class="wrapNumSelect">
                <div class="boxNumSelect">
                    <div class="title">
                        <i class="fas fa-check"></i>
                        <h2>ดำเนินการสำเร็จ</h2>
                        <h4>ระบบได้ทำการส่งโพยของท่านเรียบร้อยแล้ว</h4>
                    </div>
                    <div class="content">
                        <table>
                            <tbody>
                            <tr v-for="(row,number) of game.game_info.list" :key="number"  v-show="row.status==null" >
                                <td><span>{{number}}</span></td>
                                <td><p>{{game.price}} บาท</p></td>
                            </tr>
                            <!-- <tr>
                                <td><span>00</span></td>
                                <td><p>20 บาท</p></td>
                            </tr>
                            <tr>
                                <td><span>00</span></td>
                                <td><p>20 บาท</p></td>
                            </tr> -->
                            </tbody>

                        </table>

                    </div>
                    <div class="footer">
                        <ul>
                            <li>เลขที่รายการ</li>
                            <li>#{{ last_purchase_id }}</li>
                        </ul>
                        <ul>
                            <li>รวม</li>
                            <li><strong>{{ bet_sum }}</strong> บาท</li>
                        </ul>
                        <div class="footerGroup">
                            <button type="button" class="btn btnPlay" @click="init()">แทงต่อ</button>
                            <button type="button" class="btn btnBill" @click=goToBill()>ดูโพย</button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>

        <!--        //////////////////////////////////////////-->
        <!-- Confirm  ลบตัวเลขที่เลือก    //////////////////////////////////////////-->
        <div class="containerNumSelect ConfirmContainerNumSelect"  v-if="showModalConfirmRemove" >
            <div class="wrapNumSelect wrapNumSelectDeleteConfirm">
                <div class="boxNumSelect">
                    <div class="title">
                        <h4>Confirm</h4>
                        <button type="button" class="btn" @click="closeModalRemove"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="content">
                        <table>
                            <tbody>
                            <tr>
                                <td><p>ตัวเลขที่ต้องการลบ</p></td>
                                <td><span>{{remove_number}}</span></td>

                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="footer">
                        <button type="button" class="btn" @click="confirmRemoveItem">ตกลง</button>
                    </div>
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>
        <!--        //////////////////////////////////////////-->
        <div class="wrapContentIndexTitle ">

            <button style="padding: 0 10px;" class="btn " @click="goToIndex()"><i class="fas fa-chevron-left"></i></button>
            <h3>กลับ</h3>
            <MenuBar ></MenuBar>
        </div>
        <div class="wrapContentIndexInfo" v-if="game">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="containerLottoNumberBet">
                        <div class="LottoNumberBetTopic">
                            <div class="LottoNumberBetTopicContent">
                                <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                <div class="groupLottoNumberBetTopicContent">
                                    <h3>{{title_game_type[game.game_type]}}</h3>
                                    <div class="groupLottoNumberBetTopicContent2">

                                        <div class="LottoNumberBetTime">
                                            <p>งวดวันที่ {{ showDate(game.date) }}</p>
                                            <ul class="LNDay">
                                                <li>
                                                    <span> {{ showEndDay(game.end_datetime) }}&nbsp;</span>
                                                    <span> {{ showEndTime(game.end_datetime) }}</span>
                                                </li>
                                                <li class="closeTime">
                                                    <label>ปิดรับ 17:42</label>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>

                                </div>
                                <span>กระดานที่ {{game.round_main}}</span>
                            </div>

                            <div class="LottoNumberList">
                                <div class="LottoNumberListContent">
                                    <div class="LNListContentLeft">
                                        <ul>
                                            <li>ถูก 2 ตัวล่างรับ 350</li>
                                            <li>ถูก 2 ตัวบนรับ 350</li>
                                        </ul>

                                    </div>
                                    <div class="LNListContentRight">
                                        <small>เบอร์ละ</small>
                                        <strong>{{ game.price }}</strong>
                                        <span>บาท</span>
                                    </div>
                                </div>
                            </div>
                            <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                        </div>
                        <div class="LottoNumberBetContent">
                            <div class="LottoNumberBetContentList" v-for="(rows,row_index) of game_table" :key="row_index" >

                                <div class="LTTNButton" v-for="(row,r_index) of rows" :key="r_index" :class="{'activeNone':row.status>0 && row.user_id != pes_profile.user_id,'active':row.status>0 && row.user_id == pes_profile.user_id, 'activeSelected':row.status==null}" @click="selectNumber(row.number)"><span>{{ row.number }}</span></div>
                                
                            </div>
                            <!-- <div class="LottoNumberBetContentList">
                                <div class="LTTNButton active"><span>00</span></div>
                                <div class="LTTNButton"><span>01</span></div>
                                <div class="LTTNButton"><span>02</span></div>
                                <div class="LTTNButton"><span>03</span></div>
                                <div class="LTTNButton"><span>04</span></div>
                                <div class="LTTNButton"><span>05</span></div>
                                <div class="LTTNButton"><span>06</span></div>
                                <div class="LTTNButton"><span>07</span></div>
                                <div class="LTTNButton activeNone"><span>08</span></div>
                                <div class="LTTNButton activeNone"><span>09</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton"><span>10</span></div>
                                <div class="LTTNButton"><span>11</span></div>
                                <div class="LTTNButton"><span>12</span></div>
                                <div class="LTTNButton"><span>13</span></div>
                                <div class="LTTNButton"><span>14</span></div>
                                <div class="LTTNButton"><span>15</span></div>
                                <div class="LTTNButton activeNone"><span>16</span></div>
                                <div class="LTTNButton"><span>17</span></div>
                                <div class="LTTNButton"><span>18</span></div>
                                <div class="LTTNButton"><span>19</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton activeSelected"><span>20</span></div>
                                <div class="LTTNButton activeNone"><span>21</span></div>
                                <div class="LTTNButton"><span>22</span></div>
                                <div class="LTTNButton"><span>23</span></div>
                                <div class="LTTNButton"><span>24</span></div>
                                <div class="LTTNButton activeNone"><span>25</span></div>
                                <div class="LTTNButton"><span>26</span></div>
                                <div class="LTTNButton"><span>27</span></div>
                                <div class="LTTNButton"><span>28</span></div>
                                <div class="LTTNButton"><span>29</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton"><span>30</span></div>
                                <div class="LTTNButton"><span>31</span></div>
                                <div class="LTTNButton"><span>32</span></div>
                                <div class="LTTNButton"><span>33</span></div>
                                <div class="LTTNButton"><span>34</span></div>
                                <div class="LTTNButton"><span>35</span></div>
                                <div class="LTTNButton"><span>36</span></div>
                                <div class="LTTNButton"><span>37</span></div>
                                <div class="LTTNButton"><span>38</span></div>
                                <div class="LTTNButton activeSelected"><span>39</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton"><span>40</span></div>
                                <div class="LTTNButton"><span>41</span></div>
                                <div class="LTTNButton"><span>42</span></div>
                                <div class="LTTNButton activeSelected"><span>43</span></div>
                                <div class="LTTNButton"><span>44</span></div>
                                <div class="LTTNButton"><span>45</span></div>
                                <div class="LTTNButton"><span>46</span></div>
                                <div class="LTTNButton"><span>47</span></div>
                                <div class="LTTNButton"><span>48</span></div>
                                <div class="LTTNButton"><span>49</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton"><span>50</span></div>
                                <div class="LTTNButton"><span>51</span></div>
                                <div class="LTTNButton"><span>52</span></div>
                                <div class="LTTNButton"><span>53</span></div>
                                <div class="LTTNButton"><span>54</span></div>
                                <div class="LTTNButton"><span>55</span></div>
                                <div class="LTTNButton"><span>56</span></div>
                                <div class="LTTNButton"><span>57</span></div>
                                <div class="LTTNButton"><span>58</span></div>
                                <div class="LTTNButton"><span>59</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton"><span>60</span></div>
                                <div class="LTTNButton"><span>61</span></div>
                                <div class="LTTNButton"><span>62</span></div>
                                <div class="LTTNButton"><span>63</span></div>
                                <div class="LTTNButton"><span>64</span></div>
                                <div class="LTTNButton"><span>65</span></div>
                                <div class="LTTNButton"><span>66</span></div>
                                <div class="LTTNButton"><span>67</span></div>
                                <div class="LTTNButton"><span>68</span></div>
                                <div class="LTTNButton"><span>69</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton"><span>70</span></div>
                                <div class="LTTNButton"><span>71</span></div>
                                <div class="LTTNButton"><span>72</span></div>
                                <div class="LTTNButton"><span>73</span></div>
                                <div class="LTTNButton"><span>74</span></div>
                                <div class="LTTNButton"><span>75</span></div>
                                <div class="LTTNButton"><span>76</span></div>
                                <div class="LTTNButton"><span>77</span></div>
                                <div class="LTTNButton"><span>78</span></div>
                                <div class="LTTNButton"><span>79</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton"><span>80</span></div>
                                <div class="LTTNButton"><span>81</span></div>
                                <div class="LTTNButton"><span>82</span></div>
                                <div class="LTTNButton"><span>83</span></div>
                                <div class="LTTNButton"><span>84</span></div>
                                <div class="LTTNButton"><span>85</span></div>
                                <div class="LTTNButton"><span>86</span></div>
                                <div class="LTTNButton"><span>87</span></div>
                                <div class="LTTNButton"><span>88</span></div>
                                <div class="LTTNButton"><span>89</span></div>
                            </div>
                            <div class="LottoNumberBetContentList">
                                <div class="LTTNButton"><span>90</span></div>
                                <div class="LTTNButton"><span>91</span></div>
                                <div class="LTTNButton"><span>92</span></div>
                                <div class="LTTNButton"><span>93</span></div>
                                <div class="LTTNButton active"><span>94</span></div>
                                <div class="LTTNButton"><span>95</span></div>
                                <div class="LTTNButton"><span>96</span></div>
                                <div class="LTTNButton"><span>97</span></div>
                                <div class="LTTNButton"><span>98</span></div>
                                <div class="LTTNButton"><span>99</span></div>
                            </div> -->

                        </div>
                        <div class="LottoNumberBetButton active">
                            <button class="btn" @click="confirmBet">ดำเนินการ</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import axiosWrapper from '@/axiosWrapper'
    import store from '@/store'
    import MenuBar from '@/components/MenuBar'
    const querystring = require('query-string')
    export default {
        name: 'LottoNumberBet',
        components: {
            MenuBar,
        },
        computed: {
           
           pes_profile() {
               return store.state.pes_profile
           },
           token() {
               return this.pes_profile.token
           },
       },
        data (){
            return {
                'title_game_type':[
                    'หวยเบอร์ทองรัฐบาล'
                ],
                showModalConfirm:false,
                showModalConfirm2:false,
                showModalConfirmRemove:false,
                gbs_id : null,
                game : null,
                game_table : [],
                bet_sum : 0,
                last_purchase_id :null,
                remove_number  : null,
                lotto_type : 'gold_number',
            }
        },
        methods: {
            goToIndex() {
                if(this.envDomains.VUE_APP_SITE_TYPE=='lotto'){
                    this.$router.push({'name':'Index'})
                }else{
                    this.$router.push({'name':'LottoNumber'})
                }
               
            },
            goToSearch() {
                this.$router.push({'name':'LottoNumberSearch'})
            },
            goToBillList() {
                this.$router.push({'name':'LottoNumberBill'})
            },
            goToBet(gbs_id){
                this.$router.push({'name':'LottoNumberBet',params:{gbs_id :gbs_id}})
            },
            goToBill() {
                
                this.$router.push({'path':`/LottoNumberBill?gid=${this.last_purchase_id}&lotto_type=${this.lotto_type}`})
            },
            async getGame(){
                
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gold_number/getGame?token=`+this.token+'&gbs_id='+this.gbs_id,
                    data: {
                        
                    }
                })
                let data = res.data.data;
                if(data){
                    this.game = data;
                    this.last_purchase_id =data.gid;
                    this.game.game_info = JSON.parse(data.game_info);
                    let list = this.game.game_info.list;
                    let game_table =[];
                    let count =0;
                    
                     
                    for(let number in list){
                        number
                        // let number_in = parseInt(number);
                        if(count==0){
                            game_table.push([]);
                            
                        }
                        // let index =game_table.length-1;
                        // list[number].number = number;
                        // game_table[index].push(list[number]);
                        count++;
                        if(count==10){
                            count=0;
                        }

                    }
                    for(let number in list){
                        let number_in = parseInt(number);
                        let index_in = parseInt(number_in/10);
                        list[number].number = number;
                        game_table[index_in].push(list[number]);
                        
                    }
                    
                    this.game_table =game_table;
                    // console.log(game_table);

                }
                
               
                
            },
            selectNumber(number){
                if(this.game.game_info.list[number]['status']==null){
                    this.game.game_info.list[number]['status'] =0;
                }else if(this.game.game_info.list[number]['status']==0){
                    this.game.game_info.list[number]['status'] =null;

                }else if(this.game.game_info.list[number]['status']==1){
// 
                }

            },
            async init(){
                this.showModalConfirm2=false;
                await this.getGame();
            },
            confirmBet(){
                this.showModalConfirm=true;
                this.calSumPurchase()

            },
            async confirmBet2(){
               
                this.showModalConfirm=false;
                if(this.bet_sum>0){
                   
                    this.showModalConfirm2=true;
                    this.purchase();
                }else{
                    alert('กรุณาเลือกเลขใหม่')
                    this.showModalConfirm2=true;
                }
                

            },
            async purchase(){
                let bet_list ={};
                for(let number in this.game.game_info.list){
                    if(this.game.game_info.list[number]['status']==null){

                        bet_list[number]={}
                    
                    }
                }

                
                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gold_number/purchase`,
                    data: querystring.stringify({
                        token: this.token,
                        gbs_id:this.gbs_id,
                        bet_list : JSON.stringify(bet_list),
                    })
                })
                if(res.status !== 200) {
                    console.log(res.data.msg);
                    alert(res.data.msg);
                }else{
                    let data  = res.data.data;
                    console.log(data);
                    this.last_purchase_id = data.gid;
                    // this.purchase_status=3;
                    // this.cart_list = [];
                    // this.setCartList(this.cart_list);
                    // 
                    // this.date_result = data.date_result;
                }
            


            },
            removeItem(number){
                this.showModalConfirmRemove=true;
                this.showModalConfirm=false;
                this.remove_number=number;
                
                
            },
            closeModalRemove(){
                this.showModalConfirmRemove=false;
                this.showModalConfirm=true;
                this.remove_number=null;
                
            },
            confirmRemoveItem(){
                // this.showModalConfirm=false;
                let number = this.remove_number;
                if(this.game.game_info.list[number]['status']==null){
                    this.game.game_info.list[number]['status']=0;
                    this.calSumPurchase();
                    this.remove_number = null;
                    this.showModalConfirmRemove=false;
                    this.showModalConfirm=true;
                }
            },
            calSumPurchase(){
                let sum = 0;
                for(let number in this.game.game_info.list){
                    
                    if(this.game.game_info.list[number]['status']==null){
                        // console.log('in in in');
                        sum+=this.game.price;
                    }
                }
                // console.log(sum);
                this.bet_sum = sum;
                
            },
            showEndDay(datetime){
                
                let day = moment(datetime).diff(moment(),'days');
                // a.diff(b, 'days') 
                return (day>0? day+' วัน':'');

            },
            showEndTime(datetime){
                let time =moment(datetime).format('HH:mm:ss');
                return time;
            },
            showDate(date){
                
                let moment_datetime=moment(date).format('DD/MM/YYYY');
                let date_array =moment_datetime.split("/");
                return date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)
            },
            
        },
        async mounted() {
            let gbs_id = this.$route.params.gbs_id;
            this.gbs_id =gbs_id;
            await this.getGame();
        },
    }
</script>