<template>
    <div>
        <b-modal modal-class="" ref="logoutService"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 :hide-footer="true"
                 hide-header
                 :hide-header-close="true"
                 title="">
            <div class="style-modal modal-notify-text-center">
                <i class="fas fa-hourglass-half"></i>
                <p>กำลังถอนเงินจาก msport </p>
                <h2>กรุณารอสักครู่...</h2>
            </div>
        </b-modal>
        <div class="navButtonMobileInfo">

            <div class="navButtonLeft">

                <ul v-if="menu_list_left.length>0" class="nav">
                    <li v-for="(menu, key) of menu_list_left" :key="key" :class="getClassMenu(menu.id)"  >
                        <div v-if="menu.id=='support'  && menu_left_setting_mapping[menu.id]!=false" class="nevNotification hide"><span>1</span></div>
                        <a v-if="menu_left_setting_mapping[menu.id]!=false" class="nav-link text-white" @click="beforeToPage(menu.id)">
                            <div class="navBarIcon iconMain" :class="[menu.class]"></div>
                            <span>{{menu.name}}</span>
                        </a>
                    </li>
                    
                </ul>
                <ul v-else class="nav">
                    <li @click="showDailyMission()">
                        <a class="nav-link text-white" >
                            <div class="navBarIcon iconMain iconMission"></div>
                            <p>ภาระกิจ</p>
                        </a>
                    </li>
                    <li class="navButtonShop">
                        <a  @click="toShopPage" class="nav-link text-white">
                            <div class="navBarIcon iconMain iconPromotion"></div>
                            <p>โปรโมชั่น</p>
                        </a>
                    </li>
                    <li class="NavShopDiamond">
                        <a class="nav-link text-white">
                            <div class="navBarIcon iconMain iconExchangeDiamond"></div>
                            <span>แลกเพชร</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div v-if="menu_list_center.length>0" class="navButtonMobileCenter"  @click="beforeToPage(menu_list_center[0].id)">
                <div v-if="menu_list_center[0].id=='support'  && menu_left_setting_mapping[menu_list_center[0].id]!=false" class="nevNotification hide"><span>1</span></div>
                <a v-if="menu_left_setting_mapping[menu_list_center[0].id]!=false" href="#" class="nav-link">
                    <div class="iconCenterNavBar" :class="[menu_list_center[0].class]"></div>
                </a>
                <div v-if="menu_left_setting_mapping[menu_list_center[0].id]" class="spin-win spin-win-bg"></div>
            </div>
            <div v-else class="navButtonMobileCenter"  @click="showPrivilege">
                <a href="#" class="nav-link">
                    <div class="iconCenterNavBar iconPrivilege"></div>
                </a>
                <div class="spin-win spin-win-bg"></div>
            </div>

            <div class="navButtonMobileCenterBG">
                <span></span>
            </div>

<!--            Diamond Mod-->
            <div class="navDiamond">
                <div class="navButtonMobileCenter" >
                    <a href="#" class="nav-link" @click="showSpinFortune()">
                        <div class=" iconCenterNavBar iconSpinFortune"></div>
                    </a>
                    <div class="spin-win spin-win-bg"></div>
                </div>
            </div>


            <div class="navButtonRight">
                <ul v-if="menu_list_right.length>0"  class="nav">
                    <li v-for="(menu, key) of menu_list_right" :key="key" :class="getClassMenu(menu.id)" @click="beforeToPage(menu.id)">
                        <div v-if="menu.id=='support' && menu_left_setting_mapping[menu.id]!=false" class="nevNotification hide"><span>1</span></div>
                        <a v-if="menu_left_setting_mapping[menu.id]!=false" href="#" class="nav-link text-white">
                            <div class="navBarIcon iconMain" :class="[menu.class]"></div>
                            <p>{{menu.name}}</p>
                        </a>
                    </li>
                    
                </ul>
                <ul v-else class="nav">
                    <li @click="toAccountPage" >
                        <a href="#" class="nav-link text-white">
                            <div class="navBarIcon iconMain iconDw"></div>
                            <p>ฝาก - ถอน</p>
                        </a>
                    </li>
                    <li>
                        <div class="nevNotification hide"><span>1</span></div>
                        <a href="#" class="nav-link text-white" @click="showChatWithAdmin">
                            <div class="navBarIcon iconMain iconSupport"></div>
                            <p>Support</p>
                        </a>

                    </li>
                </ul>
            </div>
            <div class="bgNavButtonMobileInfo"></div>
        </div>
    </div>
</template>
<script>
import UserMixin from '@/mixins/UserMixin'
import store from '@/store'
export default {
    name: 'MenuNav',
    mixins: [UserMixin],
    components: {
    },
    computed: {
        menu_setting(){
            return localStorage.getItem('menu_setting')? JSON.parse(localStorage.getItem('menu_setting')) :null;
        },
        menu_left_setting(){
            return localStorage.getItem('menu_left_setting')? JSON.parse(localStorage.getItem('menu_left_setting')) :null;
        },
        currentRouteName() {
            return this.$route.name;
        },
        pes_profile() {
            return store.state.pes_profile
        },
        token() {
            return this.pes_profile.token
        },
    },
    methods: {
        async beforeToPage(id){
            
            if(this.currentRouteName=='IndexSport'){
                this.$refs['logoutService'].show();
                await this.loadUserData(this.token,'yes')
                this.$refs['logoutService'].hide();
                this.toPage(id)
            }else{
                this.toPage(id)
            }
        },
        showSpinFortune(){
            this.$emit('showSpinFortune');
        },
        toAccountPage() {
            this.$router.push({name: 'DepositWithdraw'})
        },
        toShopPage() {
            this.$router.push({name: 'Package'})
        },
        showDailyMission(){
            this.$emit('showMenuNav',1);
        },
        showPrivilege(){
            this.$emit('showMenuNav',2);
        },
        showChatWithAdmin(){
           
           if(this.pes_profile){
               this.$emit('showChatWithAdmin');
           }else{
               this.goSignUp()
               //show signin
           }
       },
       showDepositWithdrawModal(){
           if(this.pes_profile){
               this.$emit('showDepositWithdrawModal');
           }else{
               this.goSignUp()
               //show signin
           }
           
       },
        getClassMenu(id){
            if(id=='exchange_diamond'){
                return ['NavShopDiamond']
            }else if(id=='promotion'){
                return ['navButtonShop']
            }else if(id=='privilege'){
                return ['NavTopPrivilege']
            }else if(id=='deposit_withdraw'){
                return ['activeDepositWithdrawNav']
            }else{
                return [];
            }

        },
        toPage(id){
            if(id=='exchange_diamond'){
                null;
            }else if(id=='promotion'){
                this.toShopPage()
            }else if(id=='privilege'){
                this.showPrivilege()
            }else if(id=='deposit_withdraw'){
                this.showDepositWithdrawModal()
            }else if(id=='mission'){
                this.showDailyMission()
            }else if(id=='support'){
                this.showChatWithAdmin()
            }else if(id=='spin_fortune'){
                null
            }else if(id=='casino'){
                this.$router.push({'name':'IndexCasino'})

            }else if(id=='fishing'){
                this.$router.push({'name':'IndexFishing'})
            }else if(id=='lotto'){
                this.$router.push({name:'LottoHome'})
            }else if(id=='slot'){
                this.$router.push({'name':'IndexSlot'})
            }else if(id=='sport'){
                this.$router.push({'name':'IndexSport'})
            }else if(id=='unbox'){
                this.$router.push({'name':'IndexUnbox'})

            }else if(id=='popular'){
                this.$router.push({'name':'IndexPopular'})
            }else{
                null;
            }
        },
    },
    data() {
        return {
            'menu_list_left':[],
            'menu_list_center':[],
            'menu_list_right':[],
            'menu_left_setting_mapping':{}
        }
    },
    mounted() {
        if(this.menu_setting){
            this.menu_list_left.push(this.menu_setting['menu1'])
            this.menu_list_left.push(this.menu_setting['menu2'])
            this.menu_list_center.push(this.menu_setting['menu3'])
            this.menu_list_right.push(this.menu_setting['menu4'])
            this.menu_list_right.push(this.menu_setting['menu5'])
            
        }
        if(this.menu_left_setting){
            for(let menu in this.menu_left_setting){
                let m = this.menu_left_setting[menu];
                let id = m.id;
                let is_show  = m.is_show;
                this.menu_left_setting_mapping[id]=is_show;
            }
        }
    }

    
}
</script>
<style scoped>
</style>