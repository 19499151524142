<template>

    <div class="UserDepositWithdraw UserDepositWithdraw-edit Profile box-content-profile">

        <PromotionTime :bus="promotionTimeBus"></PromotionTime>

        <div class="content-tabTransfer-all">

            <div class="bxDepositWithdrawColumn">
                <div class="ground-top-all">
                    <div class="ground-top-all-info">
                        <div class="top-profile top-balance top-balance-money" >
                            <!--            <div class="left"><i class="fas fa-wallet"></i></div>-->

                            <small>ยอดเงินที่ถอนได้</small>
                            <h2>
                <span>
                    ฿{{balanceCanWithdraw | toFixed(2) | comma(2) }}
                </span>
                                <button @click="refresh" class="btn" :class="{'on-refresh': isOnRefresh}" :disabled="isOnRefresh">
                        <span class="icon">
                            <i class="fas fa-redo"></i>
                        </span>
                                </button>
                            </h2>
                        </div>
                        <div class="ground-top-balance-total">
                            <div class="top-profile top-balance top-balance-total top-balance-total-bonus" >
                                <!--            <div class="left"><i class="fas fa-wallet"></i></div>-->


                                <span>ยอดเงินโบนัส</span>
                                <strong>
                                    ฿ {{thbCredit | toFixed(2) | comma(2) }}
                                </strong>

                            </div>
                            <div class="top-profile top-balance top-balance-total" >
                                <!--            <div class="left"><i class="fas fa-wallet"></i></div>-->


                                <span>ยอดเงินทั้งหมด</span>
                                <strong>
                                    ฿ {{balance | toFixed(2) | comma(2) }}
                                </strong>

                            </div>
                        </div>
                    </div>
                    <div class="ground-top-all-bg"></div>
                </div>
                <!--            โปรโมชั่นโค้ด-->
                <div class="boxInputCoupon">
                    <div class="boxInputCouponInfoBox">
                        <h4><i class="fas fa-ticket-alt"></i> โปรโมชั่นโค้ด</h4>
                        <div class="boxInputCouponInfo">
                            <div class="btnCouponSubmit" @click="submitPromotionCode">ยืนยัน</div>
                            <input v-model="voucherCode" type="text" placeholder="กรอกโค้ดโปรโมชั่น xxxxxx">
                        </div>
                        <div class="boxReCaptcha">
                            <vue-recaptcha
                                    ref="recaptcha"
                                    @verify="submitPromotionCodeRecaptchaVerified"
                                    @expired="submitPromotionCodeRecaptchaExpired"
                                    :sitekey="googleCaptchaSiteKey">
                            </vue-recaptcha>
                        </div>
                    </div>

                </div>
                <div class="content-withdraw-all">
                    <div class="title">
                        <h5>ธนาคารที่เลือก</h5>
                        <span>({{account_selected.bank_name | bank_display}})</span>
                    </div>
                    <div class="scrolling-wrapper-flexbox-card">
                        <carousel
                                :per-page="1"
                                :mouseDrag="true"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮"
                                paginationPosition="bottom"
                        >
                            <slide v-for="account in account_list" :key="account.id">
                                <div :class="{'active': account_selected === account}"
                                     class="panel-block-card-1" @click="selectAccount(account)" >
                                <span :class="account.bank_name | bank_icon_class" class="bank-logo-s">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </span>
                                    <!--                                <div class="box-name-bank">-->
                                    <!--                                    <div>{{account.bank_name | bank_display('short')}}</div>-->
                                    <!--                                </div>-->
                                    <div class="box-number-account">
                                        <h6>หมายเลขบัญชี</h6>
                                        <div>{{account.account_no || '-'}}</div>
                                    </div>
                                    <div class="box-name-account">
                                        <h6>ชื่อบัญชี</h6>
                                        <div>{{account.account_name || '-'}}</div>
                                    </div>
                                    <span class="tag" v-if="account.is_verified">VERIFIED</span>
                                </div>
                            </slide>
                        </carousel>

                    </div>


                </div>
            </div>
            <div class="bxDepositWithdrawColumnInfo">
                <div class="bxDepositWithdrawColumn2">
                    <!--            <div class="title-tabTransfer-all"><p>เลือกบริการ</p></div>-->
                    <div class="TextRed" style="font-size: 12px; ">
                        *** ต้องมาจากบัญชีที่ลงทะเบียนไว้เท่านั้น ***
                    </div>
                    <div class="content-tabTransfer">
                        <ul>
                            <li @click="toDeposit" :class="{'active': page === 'deposit'}">
                                <span><i class="fas fa-check-circle"></i></span>
                                <strong>ฝาก</strong>
                                <p>Deposit</p>
                            </li>
                            <li @click="toWithdraw" :class="{'active': page === 'withdraw'}">
                                <span><i class="fas fa-check-circle"></i></span>
                                <strong>ถอน</strong>
                                <p>Withdraw</p>
                            </li>
                        </ul>
                    </div>
                    <div class="content-detail-repair-bank">
                        <h6><i class="fas fa-exclamation-triangle"></i> ธนาคารออนไลน์ ปรับปรุงระบบทุกวัน ดังนี้</h6>
                        <ul>
                            <li>
                                <div class="colB-left">
                                    <img src="@/assets/images/bank_icon/kbank.png"/>
                                    <span>กสิกรไทย:KBANK</span>
                                </div>
                                <div class="colB-right">
                                    <label>01.00-03.30 น.</label>
                                </div>
                            </li>
                            <li>
                                <div class="colB-left">
                                    <img src="@/assets/images/bank_icon/scb-logo.png"/>
                                    <span>ไทยพาณิชย์:SCB</span>
                                </div>
                                <div class="colB-right">
                                    <label>23.00-00.00 น.</label>
                                </div>
                            </li>
                            <li>
                                <div class="colB-left">
                                    <img src="@/assets/images/bank_icon/ktb.png"/>
                                    <span>กรุงไทย:KTB</span>
                                </div>
                                <div class="colB-right">
                                    <label>01.00-03.30 น.</label>
                                </div>
                            </li>
                            <li>
                                <div class="colB-left">
                                    <img src="@/assets/images/bank_icon/bangkok.png"/>
                                    <span>กรุงเทพ :BBL</span>
                                </div>
                                <div class="colB-right colB-right-more">
                                    <label>
                                        <span>23.00-23.30 น.</span>
                                        <span>และ 02.30 - 04.00 น.</span>
                                    </label>
                                </div>
                            </li>

                        </ul>
                        <p class="redTextAlert">*** กรุณาหลีกเลี่ยงการทำธุรกรรมการเงินในช่วงเวลาดังกล่าว</p>
                    </div>

                    <!--            <carousel>-->

                    <!--            </carousel>-->



                    <div class="box-add-money">
                        <div class="title"><h5>ใส่จำนวนเงิน </h5></div>
                        <div class="ground-add-money">
                            <ul>
                                <li @click="amount--"><button type="button" class="down-amount"></button></li>
                                <li>
                                    <span></span>
                                    <input v-model="amount"
                                           @focus="$event.target.select()"
                                           class="form-control" type="number">
                                </li>
                                <li @click="amount++"><button type="button" class="up-amount"></button></li>
                            </ul>
                        </div>
                        <div class="groups-list-amount">
                            <div @click="amount = amountHelper"
                                 v-for="(amountHelper, key) of amountHelpers" :key="key"
                                 :class="`list-amount ${(parseInt(amount) === parseInt(amountHelper)) ? 'active' : ''}`">
                                <span>{{amountHelper}}</span>
                            </div>
                        </div>


                    </div>
                    <p v-if="amountLimit" class="redTextAlert">
                        <!--                **ยอดฝาก-ถอน ขึ้นต่ำ 50 บาท   ยอดฝากสูงสุด 200,000 บาท-->
                        **ยอดฝาก ขึ้นต่ำ {{depositMin | numberFormat}} บาท ยอดฝากสูงสุด {{depositMax | numberFormat}} บาท

                        **ยอดถอน ขึ้นต่ำ {{withdrawMin | numberFormat}} บาท ยอดถอนสูงสุด {{withdrawMax | numberFormat}} บาท
                    </p>
                    <div class="form-row form-row-ok">
                        <button @click="submit" class="btn btn-block btn-success">
                            <!--                    <i class="fas fa-check"></i> -->
                            ดำเนินการ</button>
                    </div>

                </div>
                <div class="bxDepositWithdrawColumn2">
                    <div class="his-transection">
                        <h5>รายการล่าสุด</h5>
                        <!--            <h6>21/06/15</h6>-->
                        <div v-if="transaction_list.length === 0" class="v-transaction-row v-transaction-row-none">
                            <div class="v-transaction-col">ยังไม่มีรายการ</div>
                        </div>
                        <div v-for="transaction in transaction_list" :key="transaction.id">
                            <!--                v-transaction-row-deposit -->
                            <!--                v-transaction-row-withdraw-->
                            <!--//noti-fail/-->
                            <!--//noti-pass/-->
                            <!--//noti-wait/-->
                            <div @click="reOpenTransferPage(transaction)" :class="`v-transaction-row ${calTransactionRowClass(transaction.transaction_type)} ${calTransactionRowStatusClass(transaction.transaction_status)}`">
                                <div class="v-transaction-col-group">
                                    <div class="v-transaction-col">
                                        <div class="iconHis-transection"></div>
                                    </div>
                                    <div class="v-transaction-col" style="flex: 1; flex-direction: column">

                                        <div class="v-transaction-col-noti">
                                            <strong>{{transaction.transaction_type | transactionTypeDisplay}}</strong>
                                            <span :class="{'color-green': transaction.transaction_status === 'PASS'}">
                                    <span v-if="transaction.transaction_status === 'REJECT'" @click="toChatWithAdmin" style="cursor: pointer;">
                                        {{calTransactionStatusText(transaction.transaction_status)}}
                                    </span>
                                    <span v-else>
                                        {{calTransactionStatusText(transaction.transaction_status)}}
                                    </span>
                                </span>
                                        </div>
                                        <p> {{transaction.created_at | dateTimeFormat2}}</p>

                                    </div>
                                    <div class="v-transaction-col" style="flex-direction: column; align-items: flex-end;">
                                        <div class="v-transaction-col-amount">
                                            <span>{{transaction.amount | floatFormat}}</span>
                                            <small v-if="transaction.amount_balance">({{transaction.amount_balance | floatFormat}})</small>
                                        </div>
                                        <div v-if="canOpenTag(transaction)" @click="toOpenTag(transaction)" class="text-primary">แจ้งปัญหา</div>
                                        <div v-if="canShowNote(transaction.transaction_status, transaction.note)" @click="openNoteModal(transaction.note)" class="text-primary">หมายเหตุ</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>




        </div>





<!--        ยืนยันการทำรายการ-->
        <b-modal @ok="confirm" ref="confirm-modal" hide-header centered
        ok-title="ยืนยัน" cancel-title="ยกเลิก" modal-class="editModal" button-size="lg">

            <div class="style-modal" style="padding: 20px 10px; text-align: center;">
                <i class="fas fa-check-circle" style="color: #0b6b2d; margin-bottom: 20px;"></i>
                <h3>ยืนยันการทำรายการ</h3>
            </div>
        </b-modal>

<!--        แจ้งเติมเงิน-->
        <b-modal ref="open-tag-modal"
                 modal-class="editModal" button-size="lg"
        hide-footer
        centered
        title="แจ้งเติมเงิน"
        cancel-title="ยกเลิก">
            <div>
                <div class="form-row" style="justify-content: center; align-items: center; flex-direction: column">
                    <p>แนบรูปสลิป</p>
                    <Uploader :bus="uploader_bus" @image_selected="imageSelected"/>
                </div>
                <div class="form-row">
                    <h4>ข้อความ</h4>
                    <input v-model="note" class="form-control" type="text" placeholder="ข้อความ">
                </div>
                <div class="form-row">

                </div>
                <div class="form-row form-row-flex">
                    <h4>รายการ</h4>
                    <div v-if="selected_transaction" class="form-row-content">
                        {{selected_transaction.cartid}}:
                        [{{selected_transaction.created_at | dateTimeFormat2}}]
                        {{selected_transaction.amount | toFixed(2)}}({{selected_transaction.amount_balance | toFixed(2)}})
                    </div>
                </div>

                <div class="form-row form-row-flex">
                    <h4>เวลาโอน</h4>
                    <date-picker 
                        v-model="transfer_time" 
                        format="YYYY-MM-DD H:mm:ss"
                        value-type="format"
                        type="datetime" 
                        placeholder="เวลาโอน"></date-picker>
                </div>
                <div class="form-row custom-footer" style="justify-content: center; padding-top: 20px;">
                    <button @click="$refs['open-tag-modal'].hide()" class="btn btn-outline-primary btn-lg">ยกเลิก</button>
                    <button @click="openTag" class="btn btn-primary btn-lg">ตกลง</button>
                </div>
            </div>
        </b-modal>

<!--        แจ้งปัญหาสำเร็จ-->
        <b-modal ref="open-tag-success-modal"
                 modal-class="editModal" button-size="lg"
        hide-header
        centered
        ok-only 
        ok-title="ตกลง"
        @ok="refresh">
            <div class="style-modal">
                <i class="fas fa-check-circle" style="color: #0b6b2d"></i>
                <h3>แจ้งปัญหาสำเร็จ</h3>
                <span>กรุณารอเจ้าหน้าที่ตรวจสอบ</span>
            </div>
        </b-modal>

<!--        ส่งคำขอถอนเงินสำเร็จ-->
        <b-modal ref="withdraw-success-modal"
                 modal-class="editModal" button-size="lg"
        hide-header
        centered
        ok-only 
        ok-title="ตกลง"
        @ok="refresh">
            <div class="style-modal">
                <i class="fas fa-check-circle" style="color: #0b6b2d"></i>
                <h3>ส่งคำขอถอนเงินสำเร็จ</h3>
                <span>ระบบกำลังดำเนินการ โปรดรอ...</span>
            </div>
        </b-modal>

<!--        เพิ่มเลขบัญชีเพื่อให้สามารถฝากถอนเงินได้-->
        <b-modal ref="go-create-account-modal"
                 modal-class="editModal" button-size="lg"
        hide-header
        centered
        ok-only 
        no-close-on-backdrop
        no-close-on-esc
        ok-title="ตกลง"
        @ok="() => {$router.push({name: 'Account', query: {to_create_account: 'yes'}})}">
            <div class="style-modal modal-notify-text-center">
                <i class="fas fa-check-circle" style="color: #0b6b2d; margin-top: 15px;"></i>
                <h3>เพิ่มเลขบัญชี</h3>
                <span>เพื่อให้สามารถฝากถอนเงินได้</span>
            </div>
        </b-modal>

<!--        หมายเหตุ-->
        <b-modal ref="note-modal"
                 modal-class="editModal" button-size="lg"
        title="หมายเหตุ"
        centered
        ok-only 
        ok-title="ตกลง"
        @ok="closeNoteModal">
            <div class="style-modal">
                <h5>{{currentTransactionNote}}</h5>
            </div>
        </b-modal>

        <b-modal ref="pending-transactions-modal" hide-header centered
        ok-title="ปิด" ok-only modal-class="editModal" button-size="lg">
            <div class="style-modal">
                <h3>ไม่สามารถทำรายการได้</h3>
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <div class="message-highlight">คุณมีรายการฝากก่อนหน้า</div>
                    <div class="message-highlight">กรุณาโอนเงินหรือยกเลิกรายการ</div>
                </div>
            </div>
            <!-- <div class="his-transection">
                <div v-for="transaction in pendingTransactions" :key="transaction.id">
                    <div @click="reOpenTransferPage(transaction)" :class="`v-transaction-row ${calTransactionRowClass(transaction.transaction_type)} ${calTransactionRowStatusClass(transaction.transaction_status)}`" style="cursor: pointer;">
                        <div class="v-transaction-col-group">
                            <div class="v-transaction-col">
                                <div class="iconHis-transection"></div>
                            </div>
                            <div class="v-transaction-col" style="flex: 1; flex-direction: column">

                                <div class="v-transaction-col-noti">
                                    <strong>{{transaction.transaction_type | transactionTypeDisplay}}</strong>
                                    <span :class="{'color-green': transaction.transaction_status === 'PASS'}">
                                        <span v-if="transaction.transaction_status === 'REJECT'" @click="toChatWithAdmin" style="cursor: pointer;">
                                            {{calTransactionStatusText(transaction.transaction_status)}}
                                        </span>
                                        <span v-else>
                                            {{calTransactionStatusText(transaction.transaction_status)}}
                                        </span>
                                    </span>
                                </div>
                                <p> {{transaction.created_at | dateTimeFormat2}}</p>
                            </div>
                            <div class="v-transaction-col" style="flex-direction: column; align-items: flex-end;">
                                <div class="v-transaction-col-amount">
                                    <span>{{transaction.amount | floatFormat}}</span>
                                    <small v-if="transaction.amount_balance">({{transaction.amount_balance | floatFormat}})</small>
                                </div>
                                <div v-if="canShowNote(transaction.transaction_status, transaction.note)" @click="openNoteModal(transaction.note)" class="text-primary">หมายเหตุ</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </b-modal>

        <b-modal ref="voucher-message-modal" hide-header centered
        ok-title="ตกลง" modal-class="editModal" ok-only button-size="lg">
            <span>{{voucherMessage}}</span>
        </b-modal>
<!--        hide-header-->
        <b-modal ref="otp-before-generate-withdraw-url-verify-modal" centered hide-footer
        ok-title="ยกเลิก" title="ยืนยันตัวตนผ่าน OTP" modal-class="editModal editModal2" ok-only button-size="lg">
            <OTP @submit="toGenerateWithdrawUrlVerify" 
            submitText="ยืนยัน" 
            :showLabel="true"
            :dataMobilePhoneNumber="user_mobile_phone_number"
            :isFixMobilePhoneNumber="true"></OTP>
        </b-modal>

        <b-modal ref="general-message-modal" centered
        ok-title="ตกลง"  title="แจ้งเตือน" modal-class="editModal" ok-only button-size="lg">
            <span style="width: 100%; text-align: center">{{generalMessage}}</span>
        </b-modal>
        <b-modal ref="mo-message-modal" centered
        ok-title="ตกลง"  :title="mo_message_title" modal-class="editModal" ok-only button-size="lg">
            <span style="width: 100%; text-align: center">{{mo_message}}</span>
        </b-modal>
    </div>
</template>
<script>

import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import debounce from '@/debounce'
import Vue from 'vue'
import Uploader from './Uploader'
import { Carousel, Slide } from 'vue-carousel'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import PromotionTime from '@/components/user/PromotionTime'
import UserMixin from '@/mixins/UserMixin'
import {REWARD_TYPE_KEY} from './Constant'
import VueRecaptcha from 'vue-recaptcha';
import OTP from '@/components/OTP'

export default {
    name: 'UserDepositWithdraw',
    mixins: [
        UserMixin,
    ],
    components: {
        Uploader,
        DatePicker,
        Carousel,
        Slide,
        PromotionTime,
        VueRecaptcha,
        OTP,
    },
    data() {
        return {
            page: 'deposit',
            amount: 50,
            amountHelpers: [50, 100, 300, 500, 1000],
            account_list: [],
            account_selected: {},
            loading: null,
            transaction_list: [],
            cartid: '',
            selected_transaction: null,
            image: '',
            note: '',
            transfer_time: this.$moment().format('YYYY-MM-DD H:mm:ss'),
            uploader_bus: new Vue(),
            currentTransactionNote: '',
            pendingTransactions: [],
            amountLimit: null,
            isOnLoadData: false,
            promotionTimeBus: new Vue(),
            voucherCode: '',
            voucherMessage: '',
            isSubmitPromotionCodeRecaptchaVerified: false,
            submitPromotionCodeRecaptchaToken: '',
            generalMessage: '',
            mo_message_title:'',
            mo_message:'',
        }
    },
    computed: {
        user_profile() {
            return store.state.user_profile
        },
        pes_profile() {
            return store.state.pes_profile
        },
        token() {
            return (this.pes_profile) ? this.pes_profile.token : ''
        },
        balance() {
            return this.pes_profile['THB_free']
        },
        balances() {
            return this.pes_profile.balances
        },
        thbCredit() {
            if(!this.balances) {
                return 0
            }
            if(!this.balances[0]) {
                return 0
            }
            return this.balances[0]['THB_credit']
        },
        balanceCanWithdraw() {
            return parseFloat(this.balance) - parseFloat(this.thbCredit)
        },
        depositMin() {
            return (this.amountLimit) ? this.amountLimit.deposit_min : '-'
        },
        depositMax() {
            return (this.amountLimit) ? this.amountLimit.deposit_max : '-'
        },
        withdrawMin() {
            return (this.amountLimit) ? this.amountLimit.withdraw_min : '-'
        },
        withdrawMax() {
            return (this.amountLimit) ? this.amountLimit.withdraw_max : '-'
        },
        isOnLoadUserProfile() {
            return store.state.isOnLoadUserProfile
        },
        isOnRefresh() {
            return this.isOnLoadData || this.isOnLoadUserProfile
        },
        pv_info() {
            return (this.pes_profile) ? this.pes_profile.pv_info : null
        },
        pv() {
            if(!this.pv_info) {
                return []
            }
            if(!this.pv_info.pv) {
                return []
            }
            return this.pv_info.pv
        },
        user_mobile_phone_number() {
            return (this.user_profile) ? this.user_profile.mobile_phone_number : ''
        },
    },
    filters: {
        transactionTypeDisplay(raw) {
            if(!raw) {
                return ''
            }
            if(raw === 'deposit') {
                return 'ฝาก'
            } else if(raw === 'withdraw') {
                return 'ถอน'
            } else {
                return raw
            }
        },
    },
    methods: {
        submitPromotionCodeRecaptchaVerified(response) {
            this.isSubmitPromotionCodeRecaptchaVerified = true
            this.submitPromotionCodeRecaptchaToken = response
        },
        submitPromotionCodeRecaptchaExpired() {
            this.isSubmitPromotionCodeRecaptchaVerified = false
            this.submitPromotionCodeRecaptchaToken = ''
        },
        async submitPromotionCode() {

            const labelCal = (type, qty) => {
                if(parseInt(type) === REWARD_TYPE_KEY.CREDIT) {
                    return 'เครดิต'
                } else if(parseInt(type) === REWARD_TYPE_KEY.UNBOX) {
                    return `ได้รับ ฟรีสปิน X${qty}`
                }
            }

            const callbackCal = (type) => {
                if(parseInt(type) === REWARD_TYPE_KEY.CREDIT) {
                    return 'showStatusModal'
                } else if(parseInt(type) === REWARD_TYPE_KEY.UNBOX) {
                    return 'goUnboxPlay'
                }
            }

            const callRewardValue = (pvm) => {
                if(parseInt(pvm.reward_type) === REWARD_TYPE_KEY.CREDIT) {
                    return pvm.reward_total
                } else if(parseInt(pvm.reward_type) === REWARD_TYPE_KEY.UNBOX) {
                    return pvm.reward_value
                }
            }

            // const getRecaptchaV3Token = async () => {
            //     return new Promise((resolve) => {
            //         window.grecaptcha.ready(() => {
            //             window.grecaptcha.execute(this.envDomains.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY_V3, {action: 'submit'}).then((token) => {
            //                 resolve(token)
            //             })
            //         })
            //     })
            // }

            if(!this.voucherCode) {
                return
            }

            // this.submitPromotionCodeRecaptchaToken = await getRecaptchaV3Token()
            
            if(!this.isSubmitPromotionCodeRecaptchaVerified) {
                this.voucherMessage = 'กรุณายืนยันไม่ใช่หุ่นยนต์'
                this.$refs['voucher-message-modal'].show()
                return
            }

            const loading = this.$loading()

            const alreadyPurchaseItem = this.pv.find((item) => {
                return item.code === this.voucherCode
            })

            let res = null
            let reward = null
            if(alreadyPurchaseItem) {
                res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/voucher/activate`,
                    data: {
                        token: this.token,
                        id: alreadyPurchaseItem.pid,
                        recaptchaToken: this.submitPromotionCodeRecaptchaToken,
                        domain: window.location.hostname,
                    },
                })
                if(res.status === 200) {
                    reward = {
                        label: labelCal(res.data.data.pvm.reward_type, res.data.data.pvm.reward_qty),
                        reward_type: res.data.data.pvm.reward_type,
                        reward_value: callRewardValue(res.data.data.pvm),
                        target_balance: res.data.data.pvm.target_balance,
                        target_turnover: res.data.data.pvm.target_turnover,
                        target_return: res.data.data.pvm.target_return,
                        callback: callbackCal(res.data.data.pvm.reward_type),
                    }
                }
            } else {
                res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/voucher/purchase`,
                    data: {
                        token: this.token,
                        code: this.voucherCode,
                        recaptchaToken: this.submitPromotionCodeRecaptchaToken,
                        domain: window.location.hostname,
                    },
                })
                if(res.status === 200) {
                    reward = {
                        label: labelCal(res.data.purchase.reward_type, res.data.purchase.reward_qty),
                        reward_type: res.data.purchase.reward_type,
                        reward_value: callRewardValue(res.data.purchase),
                        target_balance: res.data.purchase.target_balance,
                        target_turnover: res.data.purchase.target_turnover,
                        target_return: res.data.purchase.target_return,
                        callback: callbackCal(res.data.purchase.reward_type),
                    }
                }
            }

            const loadUserProcess = await this.loadUserData(this.token, 'yes')

            loading.close()

            this.$refs.recaptcha.reset()
            this.isSubmitPromotionCodeRecaptchaVerified = false
            this.submitPromotionCodeRecaptchaToken = ''

            if(res.status !== 200) {
                this.voucherMessage = res.data.message
                this.$refs['voucher-message-modal'].show()
                return
            }

            if(!loadUserProcess.success) {
                this.voucherMessage = `ไม่สามารถโหลดโปรไฟล์ผู้ใช้ กรุณาลองใหม่อีกครั้ง CODE[${loadUserProcess.message}]`
                this.$refs['voucher-message-modal'].show()
                return
            }

            this.promotionTimeBus.$emit('toClaimReward', reward)
        },
        refresh: debounce(function() {
            if(this.isOnRefresh) {
                return
            }
            this.loadData()
            this.requestUserReload()
        }, 200),
        toDeposit() {
            this.page = 'deposit'
        },
        toWithdraw() {
            this.page = 'withdraw'
        },
        submit: debounce(async function() {
            
            if(!this.amount || !Number.isInteger(parseFloat(this.amount))){
                // this.$bvToast.toast('จำนวนเงินไม่ถูกต้อง', {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full'
                // })
                this.generalMessage = 'จำนวนเงินไม่ถูกต้อง'
                this.$refs['general-message-modal'].show()
                return
            }

            this.$refs['confirm-modal'].show()
        }, 200),
        confirm: debounce(async function() {
            
            if(this.page === 'deposit') {

                this.loading = this.$loading()

                const generate_transfer_url_res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_transfer/generate_transfer_url`,
                    data: {
                        amount: `${parseInt(this.amount)}.00`,
                        bank_account_id: this.account_selected.id,
                        token: this.pes_profile.token,
                        pay_deposit_return_url: `${this.envDomains.VUE_APP_API}/api/deposit-withdraw/landing?redirect-url=${encodeURIComponent(window.location.origin+'/deposit-withdraw')}`,
                    }
                });

                this.loading.close()

                if(generate_transfer_url_res.status === 200){
                    window.location = `${this.envDomains.VUE_APP_PAY_BASE_URL}${generate_transfer_url_res.data.url}`
                } else if(generate_transfer_url_res.status === 400 && generate_transfer_url_res.data.message_code === 'have_pending_transaction') {
                    this.pendingTransactions = generate_transfer_url_res.data.pendingTransactions
                    this.openPendingTransactionsModal()
                } else {
                    // this.$bvToast.toast(generate_transfer_url_res.data.message, {
                    //     title: 'แจ้งเตือน',
                    //     toaster: 'b-toaster-top-full',
                    //     noAutoHide: true,
                    // })
                    this.generalMessage = generate_transfer_url_res.data.message
                    this.$refs['general-message-modal'].show()
                }
            } else if(this.page === 'withdraw') {

                if(!this.account_selected.is_verified){
                    // this.$bvToast.toast('ต้องเป็นธนาคารที่ verified เท่านั้น', {
                    //     title: 'แจ้งเตือน',
                    //     toaster: 'b-toaster-top-full'
                    // })
                    // this.generalMessage = 'ต้องเป็นธนาคารที่ verified เท่านั้น'
                    this.mo_message_title='ยังไม่ยืนยันบัญชีธนาคาร',
                    this.mo_message =`กรุณาใช้บัญชีธนาคารที่สมัคร ทำการฝากเงินเพื่อยืนยันบัญชีธนาคาร`
                    this.$refs['mo-message-modal'].show()
                    return
                }

                if(!this.balanceCanWithdraw || !parseFloat(this.balanceCanWithdraw) || parseFloat(this.balanceCanWithdraw) < parseFloat(this.amount)){
                    // this.$bvToast.toast('เครดิตไม่เพียงพอ', {
                    //     title: 'แจ้งเตือน',
                    //     toaster: 'b-toaster-top-full'
                    // })
                    this.generalMessage = 'เครดิตไม่เพียงพอ'
                    this.$refs['general-message-modal'].show()
                    return
                }

                if(!this.user_profile.is_mobile_phone_number_verified) {
                    this.$refs['otp-before-generate-withdraw-url-verify-modal'].show()
                    return
                }
                this.generateWithdrawUrlVerify()
            }
        }, 200),
        async generateWithdrawUrl() {

            this.loading = this.$loading()

            const generate_transfer_url_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_transfer/generate_withdraw_url`,
                data: {
                    amount: `${parseInt(this.amount)}.00`,
                    bank_account_id: this.account_selected.id,
                    token: this.pes_profile.token,
                }
            });

            this.loading.close()

            if(generate_transfer_url_res.status === 200){
                this.$refs['withdraw-success-modal'].show()
            }else{
                // this.$bvToast.toast(generate_transfer_url_res.data.message, {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full',
                //     noAutoHide: true,
                // })
                this.generalMessage = generate_transfer_url_res.data.message
                this.$refs['general-message-modal'].show()
            }
        },
        toGenerateWithdrawUrlVerify(data) {
            const mobile_phone_number = data.mobile_phone_number
            const otp = data.otp
            this.generateWithdrawUrlVerify(mobile_phone_number, otp)
        },
        async generateWithdrawUrlVerify(mobile_phone_number = '', otp = '') {

            this.loading = this.$loading()

            const generate_transfer_url_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_transfer/generate-withdraw-url-verify`,
                data: {
                    amount: `${parseInt(this.amount)}.00`,
                    bank_account_id: this.account_selected.id,
                    token: this.pes_profile.token,
                    mobile_phone_number: mobile_phone_number,
                    otp: otp,
                }
            });

            this.loading.close()

            if(generate_transfer_url_res.status === 200){
                this.$refs['otp-before-generate-withdraw-url-verify-modal'].hide()
                this.$refs['withdraw-success-modal'].show()
            }else{
                // this.$bvToast.toast(generate_transfer_url_res.data.message, {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full',
                //     noAutoHide: true,
                // })
                this.generalMessage = generate_transfer_url_res.data.message
                this.$refs['general-message-modal'].show()
            }
        },
        getAccountList: async function(){

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_account/get_by_id_user`,
                data: {
                    token: this.pes_profile.token
                }
            });
            if(res.status === 200) {
                this.account_list = res.data;
                if(this.account_list.length > 0){
                    this.account_selected = this.account_list[0];
                }else{
                    this.$refs['go-create-account-modal'].show()
                }
            } else if (res.status === 400) {
                // this.$bvToast.toast(res.data.message, {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full'
                // })
                this.generalMessage = res.data.message
                this.$refs['general-message-modal'].show()
            } else {
                // this.$bvToast.toast('เกิดปัญหาบางอย่าง กรุณาลองอีกครั้ง', {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full'
                // })
                this.generalMessage = 'เกิดปัญหาบางอย่าง ไม่สามารถโหลดข้อมูลบัญชีได้ กรุณาลองอีกครั้ง'
                this.$refs['general-message-modal'].show()
            }
        },
        selectAccount(account) {
            this.account_selected = account
        },
        async getTransactionList() {

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_transfer/transaction/get`,
                data: {
                    token: this.pes_profile.token
                }
            });
            if(res.status === 200){
                this.transaction_list = res.data;
            }else if(res.status === 400){
                // this.$bvToast.toast(res.data.message, {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full'
                // })
                this.generalMessage = res.data.message
                this.$refs['general-message-modal'].show()
            }else{
                // this.$bvToast.toast('เกิดปัญหาบางอย่าง กรุณาลองอีกครั้ง', {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full'
                // })
                this.generalMessage = 'เกิดปัญหาบางอย่าง ไม่สามารถโหลดข้อมูลรายการล่าสุดได้ กรุณาลองอีกครั้ง'
                this.$refs['general-message-modal'].show()
            }
        },
        canOpenTag: function(transaction){
            return transaction.transaction_type === 'deposit' && transaction.transaction_status === 'FAIL'
        },
        canReOpenTransferPage(transaction) {
            return transaction.transaction_type === 'deposit' && (transaction.transaction_status === 'WAIT' || transaction.transaction_status === 'PENDING')
        },
        toOpenTag: function(transaction){
            this.clearOpenTagData();
            this.selected_transaction = transaction;
            this.cartid = transaction.cartid;
            this.$refs['open-tag-modal'].show()
        },
        openTag: debounce(async function() {

            if(!this.cartid || !this.image || !this.note) {
                // this.$bvToast.toast('กรุณากรอกข้อมูลให้ครบถ้วน', {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full'
                // })
                this.generalMessage = 'กรุณากรอกข้อมูลให้ครบถ้วน'
                this.$refs['general-message-modal'].show()
                return
            }

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_transfer/open_tag`,
                data: {
                    cartid: this.cartid,
                    image: this.image,
                    note: this.note,
                    token: this.pes_profile.token,
                    date: this.transfer_time,
                }
            });
            if(res.status === 200 && res.data.code === 200){
                this.$refs['open-tag-modal'].hide()
                this.$refs['open-tag-success-modal'].show()
                this.clearOpenTagData();
            }else{
                // this.$bvToast.toast(res.data.message, {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full'
                // })
                this.generalMessage = res.data.message
                this.$refs['general-message-modal'].show()
            }

            this.loading.close()
        }, 200),
        imageSelected: function(ctx){
            this.image = ctx;
        },
        clearOpenTagData: function(){
            this.cartid = '';
            this.image = '';
            this.note = '';
            this.selected_transaction = null;
            this.uploader_bus.$emit('clear_data');
        },
        async loadData() {

            this.isOnLoadData = true

            // this.loading = this.$loading()

            await Promise.all([
                this.getAccountList(),
                this.getTransactionList(),
            ])
            // this.loading.close()

            this.isOnLoadData = false
        },
        requestUserReload() {
            store.commit('updateNeedUserReloadWithForceRefreshBalance', true)
        },
        calTransactionRowClass(type) {
            if(type === 'deposit') {
                return 'v-transaction-row-deposit'
            } else if(type === 'withdraw') {
                return 'v-transaction-row-withdraw'
            } else {
                return ''
            }
        },
        calTransactionRowStatusClass(status) {
            if(status === 'FAIL' || status === 'REQUEST' || status === 'REJECT') {
                return 'noti-fail'
            } else if(status === 'PASS') {
                return 'noti-pass'
            } else if(status === 'WAIT') {
                return 'noti-wait'
            } else {
                return ''
            }
        },
        calTransactionStatusText(status) {
            if(status === 'PASS' || status === 'APPROVE') {
                return 'สำเร็จ'
            } else if(status === 'FAIL') {
                return 'ไม่สำเร็จ'
            } else if(status === 'REJECT') {
                return 'ติดต่อแอดมิน'
            } else {
                return 'กำลังดำเนินการ'
            }
        },
        canShowNote(status, note) {
            if(status === 'PASS' || status === 'APPROVE') {
                return false
            } 
            return !!note
        },
        openNoteModal(note) {
            this.currentTransactionNote = note
            this.$refs['note-modal'].show()
        },
        closeNoteModal() {
            this.currentTransactionNote = ''
            this.$refs['note-modal'].hide()
        },
        toChatWithAdmin() {
            store.commit('updateNeedChatWithAdmin', true)
        },
        async reOpenTransferPage(transaction) {

            if(!this.canReOpenTransferPage(transaction)) {
                return
            }

            const cartid = transaction.cartid

            this.loading = this.$loading()

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_transfer/get-transaction-token`,
                data: {
                    token: this.pes_profile.token,
                    cartid: cartid,
                }
            });

            this.loading.close()

            if(res.status === 200){
                window.location = `${this.envDomains.VUE_APP_PAY_BASE_URL}${res.data.url}`
            }else{
                // this.$bvToast.toast(res.data.message, {
                //     title: 'แจ้งเตือน',
                //     toaster: 'b-toaster-top-full'
                // })
                this.generalMessage = res.data.message
                this.$refs['general-message-modal'].show()
            }
        },
        async openPendingTransactionsModal() {
            this.$refs['pending-transactions-modal'].show()
        },
        async loadAmountLimit() {

            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LIFF_API}/api/bank_transfer/get-amount-limit`,
                data: {
                    token: this.pes_profile.token
                }
            });

            if(res.status === 200){
                this.amountLimit = res.data
            }else{
                console.log('loadAmountLimit err', res.data.message)
            }
        },
    },
    mounted() {
        // this.$refs['note-modal'].show()
        this.loadData()
        this.loadAmountLimit()

        // let recaptchaScript = document.createElement('script')
        // recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${this.envDomains.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY_V3}`)
        // document.head.appendChild(recaptchaScript)
    }
}
</script>
<style src="@/assets/css/obs-liff.css" scoped></style>
<style scoped>

.message-highlight {
    margin: 4px 0;
    border-radius: 15px;
    background-color: #cf0c84;
    color: white;
    padding: 0 10px;
    width: fit-content;
    font-size: 20px;
}
</style>
<style>
    .UserDepositWithdraw .contentFloatCoupon{
        top: 0;
    }
    .editModal h5.modal-title{font: 800 23px Athiti;}
    .editModal i{margin: 20px 0;}
    .editModal .modal-footer,
    .editModal .modal-body{ z-index: 2}
    .editModal .modal-body h4{ font: 800 16px Athiti;}
    .editModal .form-row-flex h4{ margin: 0 10px 0 0;}
    .editModal .form-row-flex{
        align-items: center;
    }
    .editModal p{margin-bottom: 10px;}
    .editModal .Uploader{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .editModal .Uploader .btn .file-icon{margin-right: 10px;}
    .editModal .Uploader .btn{
        padding: 0 10px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .editModal .Uploader label{margin-top: 10px;}
    .editModal .form-row-content{
        background-color: #eee;
        border-radius: 10px;
        padding: 10px;
    }
    .editModal .modal-content{border-radius: 20px; z-index: 2}
    .editModal .modal-content:after{
        width: 80%;
        content: '';
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 20px;
        position: absolute;
        bottom: -10px;
        top: 0;
        left: 10px;
        right: 10px;
        z-index: 1;
        margin: auto;
    }
    .editModal .modal-content .btn-secondary{
        border-color: #d20a84;color: #d20a84;
        background-color: transparent;
    }
    .editModal .modal-content .btn-primary{
        color: white!important;
        border: 0;
        background-color: #d20a84;
        background-image:  linear-gradient(to bottom, #753189 0%, #d20a84 100%)!important;
    }
    .UserDepositWithdraw-edit .VueCarousel-navigation-button{
        display: none!important;
    }
    .wrapContentDepositWithdraw .activeDepositWithdrawNav{
        /*background-color: rgba(0, 0, 0, 0.4);*/
    }

    .themeBlueLight .wrapContentDepositWithdraw .activeDepositWithdrawNav{
        /*background-color: rgba(97, 150, 183, 0.44) !important;*/
    }

    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape){
        .editModal .modal-dialog{
            width: 350px!important;
        }
    }
</style>
<style scoped>
    /*.VueCarousel-slide{flex-basis: initial;}*/
    .content-tabTransfer-all{
        background-color: #fff;
        border-radius: 20px;
        padding: 10px 0;

    }
    .ground-top-all{
        position: relative;
        margin: 5px 15px 5px;
        border-radius: 10px;
        padding: 0 0 10px 0;
    }
    .ground-top-all-info{
        position: relative;
        z-index: 3;
        border-radius: 10px;
        padding: 10px;
        background-image: linear-gradient(to left, #753189 0%, #d20a84 100%);;
    }
    .ground-top-all-bg{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 10px;
        right: 10px;
        bottom: 3px;
        border-radius: 10px;
        background-color: rgba(210, 10, 132, 0.37);
    }
    .ground-top-all-bg:after{
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: -6px;
        border-radius: 10px;
        background-color: rgba(210, 10, 132, 0.37);
    }
    .UserDepositWithdraw .top-balance-money{
        /*background-color: rgba(210, 10, 132, 0.1);*/
        /*padding: 1px 0;*/
        border-radius: 10px;
    }
    .UserDepositWithdraw .top-balance-money button.btn{
        background-color: rgba(255, 255, 255, 0.25);
        color: white;
    }
    .UserDepositWithdraw .top-balance-money span{
        color: rgb(255, 255, 255);
    }

    /*.UserDepositWithdraw .ground-top-balance-total span,*/
    /*.UserDepositWithdraw .top-balance-money small{*/
    /*    color: rgba(39, 11, 62, 0.7)*/
    /*}*/

    .ground-top-balance-total{
        display: flex;flex-direction: row;
        margin: 10px 0;}
    .ground-top-balance-total .top-profile{
        flex: 1;
        border-radius: 0;
        padding-bottom: 0!important;
    }
    .ground-top-balance-total .top-profile:first-child{
        border-right: solid 1px rgba(255, 255, 255, 0.5);
    }
    .ground-top-balance-total strong{font-size: 21px;color: rgba(39, 11, 62, 1);}
   .top-balance-total-bonus strong{color: #0cb041}
    .form-row {
        display: flex;
        justify-content: flex-start;
        margin: 8px 4px;
    }
    .form-row.custom-footer {
        justify-content: flex-end;
    }
    .form-row.custom-footer button {
        margin: 0 4px;
    }
    .modal-notify-text-center {
        display: flex;
        justify-content: center;
        min-height: 100px;
        align-items: center;
    }
    .v-transaction-row {
        display: flex;
        border-bottom: solid 1px darkslategray;
        padding: 4px;
        justify-content: flex-start;
    }
    .v-transaction-col {
        display: flex;
        padding: 0 2px;
        font-size: 12px;
    }
    .content-tabTransfer{
        background-color: transparent;
        border: solid 1px rgba(210, 10, 132, 0.11);

    }
    .content-tabTransfer ul li{padding: 5px 0}
    .content-tabTransfer ul li.active{
        background-image: linear-gradient(to left, #753189 0%, #d20a84 100%);;
        background-color: transparent;
        color: white;
    }
    .content-tabTransfer ul li.active strong{color: white}
    .content-tabTransfer ul li span{display: none}
/*.content-tabTransfer ul li {background-color: rgba(243, 114, 212, 0);}*/
.top-profile{
    background-color: transparent

}
.top-balance .left{
    top: 0;
    bottom: 0;
    height: 30px;
    margin: auto;
    line-height: 0;
    color: rgba(84, 17, 127, 0.5);
}
.color-green {
    color: green;
}


</style>