<template>

    <div class="wrapContentIndex LottoNumber LottoNumberResult LottoNumberInfo" v-if="lotto_result">
        <div class="wrapContentIndexTitle ">
            <div class="IndexTitleInto">
                <button style="padding: 0 10px;" class="btn " @click="goBack()"><i class="fas fa-chevron-left"></i></button>
                <h3>กลับ</h3>
            </div>
        </div>
        <div class="wrapContentIndexInfo wrapContentIndexInfoResultGovernment">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="container">
                        <div class="nav-menu-topic">
                            <h3>ผลสลากกินแบ่งรัฐบาล</h3>
                            <h4>ผลรางวัลงวดวันที่ {{ showDateResult(lotto_result.date) }}</h4>
                        </div>
                        <div class="boxHitecCasino boxGovernmentLottery">
                            <div class="LottoNumberContent">
                                <div class="LottoNumberList">
                                    <div class="boxResultSimple">
                                        <div class="content">
                                            <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                <div class="resultLottoCell OneBigLotto">
                                                    <strong class="text-red">{{  lotto_result.lotto_reward_type_1[0] }}</strong>
                                                    <p>รางวัลที่1</p>
                                                </div>
                                                <div class="resultLottoCell MiniBigLotto">
                                                    <strong>
                                                        <span>{{ lotto_result.lotto_reward_type_f3[0] }}</span>
                                                        <span>{{ lotto_result.lotto_reward_type_f3[1] }}</span>
                                                    </strong>
                                                    <p>รางวัลเลขหน้า 3 ตัว</p>
                                                </div>
                                                <div class="resultLottoCell MiniBigLotto">
                                                    <strong>
                                                        <span>{{ lotto_result.lotto_reward_type_p3[0] }}</span>
                                                       <span>{{ lotto_result.lotto_reward_type_p3[1] }}</span>
                                                    </strong>
                                                    <p>รางวัลเลขท้าย 3 ตัว</p>
                                                </div>
                                                <div class="resultLottoCell TwoResult">
                                                    <strong>{{  lotto_result.lotto_reward_type_p2[0] }}</strong>
                                                    <p>รางวัลเลขท้าย 2 ตัว</p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="LottoNumberList LottoNumberListBack">
                                    <div class="boxResultSimple boxResultSimpleSecond">
                                        <div class="content">
                                            <div class="resultLottoCellGroup">
                                                <div class="resultLottoCell title">
                                                    <span>รางวัลข้างเคียงรางวัลที่ 1</span>
                                                    <small>รางวัลละ 100,000 บาท</small>
                                                </div>
                                                <div class="resultLottoCell">
                                                    
                                                    <strong v-for="(r,key) of lotto_result.lotto_reward_type_n1" :key="key">{{ r }}</strong>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="boxResultSimple boxResultSimpleTwo">
                                        <div class="content">
                                            <div class="resultLottoCellGroup">
                                                <div class="resultLottoCell title">
                                                    <span>รางวัลที่ 2</span>
                                                    <small>รางวัลละ 200,000 บาท</small>
                                                </div>
                                                <div class="resultLottoCell">
                                                    <strong v-for="(r,key) of lotto_result.lotto_reward_type_2" :key="key">{{ r }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="boxResultSimple">
                                        <div class="content">
                                            <div class="resultLottoCellGroup">
                                                <div class="resultLottoCell title">
                                                    <span>รางวัลที่ 3</span>
                                                    <small>รางวัลละ 80,000 บาท</small>
                                                </div>
                                                <div class="resultLottoCell">
                                                    <strong v-for="(r,key) of lotto_result.lotto_reward_type_3" :key="key">{{ r }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="boxResultSimple boxResultSimpleSmall">
                                        <div class="content">
                                            <div class="resultLottoCellGroup">
                                                <div class="resultLottoCell title">
                                                    <span>รางวัลที่ 4</span>
                                                    <small>รางวัลละ 40,000 บาท</small>
                                                </div>
                                                <div class="resultLottoCell">
                                                    <strong v-for="(r,key) of lotto_result.lotto_reward_type_4" :key="key">{{ r }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="boxResultSimple  boxResultSimpleSmall">
                                        <div class="content">
                                            <div class="resultLottoCellGroup">
                                                <div class="resultLottoCell title">
                                                    <span>รางวัลที่ 5</span>
                                                    <small>รางวัลละ 20,000 บาท</small>
                                                </div>
                                                <div class="resultLottoCell">
                                                    <strong v-for="(r,key) of lotto_result.lotto_reward_type_5" :key="key">{{ r }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import axiosWrapper from '@/axiosWrapper'
    import store from '@/store'
    import moment from 'moment';
    export default {
        name: 'LottoNumberResultGovernmentInfo',
        components: {
        },
        computed: {
           
           pes_profile() {
               return store.state.pes_profile
           },
           token() {
                return  this.pes_profile ? this.pes_profile.token :'';
           },
       },
        data (){
            return {
                lotto_result  : null,

            }
        },
        methods : {
            goBack() {
                this.$router.push({'name':'LottoNumberResultGovernmentAll'})
            },
            
            showDateResult(date){
                
                let moment_datetime=moment(date).format('DD/MM/YYYY');
                let date_array =moment_datetime.split("/");
                return date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)
            },
            async getLottoResult(date){
                 
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gov_lotto/get-result-list?token=${this.token}&date=${date}`,
                    data: {
                        
                    }
                })
                this.lotto_result =res.data.data;
                this.lotto_result.lotto_reward_type_1 = JSON.parse(this.lotto_result.lotto_reward_type_1);
                this.lotto_result.lotto_reward_type_2 = JSON.parse(this.lotto_result.lotto_reward_type_2);
                this.lotto_result.lotto_reward_type_3 = JSON.parse(this.lotto_result.lotto_reward_type_3);
                this.lotto_result.lotto_reward_type_4 = JSON.parse(this.lotto_result.lotto_reward_type_4);
                this.lotto_result.lotto_reward_type_5 = JSON.parse(this.lotto_result.lotto_reward_type_5);
                this.lotto_result.lotto_reward_type_f3 = JSON.parse(this.lotto_result.lotto_reward_type_f3);
                this.lotto_result.lotto_reward_type_p3 = JSON.parse(this.lotto_result.lotto_reward_type_p3);
                this.lotto_result.lotto_reward_type_n1 = JSON.parse(this.lotto_result.lotto_reward_type_n1);
                this.lotto_result.lotto_reward_type_p2 = JSON.parse(this.lotto_result.lotto_reward_type_p2);
            },
        },
        async mounted() {
            let date =this.$route.params.id
            await this.getLottoResult(date);
            
        }
    }
</script>