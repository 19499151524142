<template>

    <div class="wrapContentIndex LottoNumber LottoNumberBill">
        <!--     รายละเอียดโพย    //////////////////////////////////////////-->
        <div class="containerNumSelect" v-if="showModalBill && lotto_type=='gold_number'">
            <div class="wrapNumSelect">
                <div class="boxNumSelect">
                    <div class="title">
                        <h4>รายละเอียดโพย</h4>
                        <button type="button" class="btn" @click="showModalBill=false"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="content">
                        <div class="LottoNumberBillContent">
                            <div class="BillBox">
                                <div class="BillBoxTop"><h3>{{ lotto_title_list[lotto_type] }}</h3><span>งวดวันที่ {{ showDate(show_result.date_result) }}</span>
                                </div>
                                <div class="BillBoxCol">
                                    <div class="BillBoxRow"><strong>฿{{ numberWithCommas(parseFloat(show_result.result_cost)) }}</strong>
                                        <p>เงินเดิมพัน</p></div>
                                    <div class="BillBoxRow"><strong>{{ numberWithCommas(parseFloat(show_result.result_return)) }} ฿</strong>
                                        <p>ผลแพ้ชนะ</p></div>
                                </div>
                            </div>
                            <table>
                                <tr>
                                    <td>กระดานที่</td>
                                    <td>{{ show_result.round_main }}</td>
                                </tr>
                                <tr>
                                    <td>เลขที่รายการ</td>
                                    <td>#{{ show_result.gid }}</td>
                                </tr>
                            </table>
                        </div>
                        <ul class="detailBillList">
                            <li v-for="(bet,bet_index) of JSON.parse(show_result.bet_list)"  :class="{'detailBillListWin':bet.lotto_reward_status==1}" :key="bet_index">
                                <div class="detailBillListGroup">
                                    <span>{{bet_index}}</span>
                                    <p>{{numberWithCommas(bet.price) }} บาท</p>
                                </div>
                                <label class="boxStatusBet" :class="{'StatusBetWait':bet.lotto_reward_status==0,'StatusBetWin': bet.lotto_reward_status==1,'StatusBetLose': bet.lotto_reward_status==2}">{{bet_status_list[bet.lotto_reward_status]}}</label>
                            </li>
                            <!-- <li>
                                <div class="detailBillListGroup">
                                    <span>00</span>
                                    <p>20 บาท</p>
                                </div>
                                <label class="boxStatusBet StatusBetWait">รอผลออก</label>
                            </li>
                            <li>
                                <div class="detailBillListGroup">
                                    <span>44</span>
                                    <p>20 บาท</p>
                                </div>
                                <label class="boxStatusBet StatusBetLose">ไม่ถูกรางวัล</label>
                            </li>
                            <li class="detailBillListWin">
                                <div class="detailBillListGroup">
                                    <span>99</span>
                                    <p>20 บาท</p>
                                </div>
                                <label class="boxStatusBet StatusBetWin">ถูกรางวัล</label>
                            </li> -->
                        </ul>
<!--                        <p class="bottomDetailBill">*** เงินที่ถูกรางวัลจะเข้า User โดยอัตโนมัติ ***</p>-->
                    </div>
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>

        <!--     รายละเอียดโพย สลากฯ   //////////////////////////////////////////-->
<!--        WinGovernment-->
<!--        WaitGovernment-->
<!--        LoseGovernment-->
        <div class="containerNumSelect boxDetailBillGovernment WinGovernment" v-if="showModalBill  && lotto_type=='gov_lotto'">
<!--        <div class="containerNumSelect boxDetailBillGovernment WinGovernment" v-if="true">-->
            <div class="wrapNumSelect">
                <div class="boxNumSelect">
                    <div class="title">
                        <h4>รายละเอียด</h4>
                        <button type="button" class="btn" @click="showModalBill=false"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="footer" v-if="show_bet.game_result==99"> 
                        <div class="footerContent">
<!--                            <img src="@/assets/images/trophy-icon.png"/>-->
                            <p>คุณถูกรางวัลเลขท้าย 3 ตัว</p>
                            <span>จำนวนเงินรางวัล</span>
                            <strong>4,000 บาท</strong>
                        </div>
                    </div>
                    <div class="content" v-if="false">
                        <table class="tableTotalBill">
                            <tr>
                                <td>จำนวนจำนวนสลากฯ</td>
                                <td>4 ใบ</td>
                            </tr>
                            <tr>
                                <td>งวดวันที่</td>
                                <td>16/12/2566</td>
                            </tr>
                            <tr>
                                <td>ชุดที่</td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>โพยเลขที่</td>
                                <td>#1673855517063324</td>
                            </tr>
                            <tr>
                                <td>จำนวนเงินที่ใช้</td>
                                <td>200 บาท</td>
                            </tr>
                            <tr class="StatusBetWinRow">
                                <td>สถานะ</td>
                                <td><div class="boxStatusBet StatusBetWin">ถุกรางวัล</div></td>
                            </tr>
                            <tr class="StatusBetWaitRow">
                                <td>สถานะ</td>
                                <td><div class="boxStatusBet StatusBetWait">รอผล</div></td>
                            </tr>
                            <tr class="StatusBetLoseRow">
                                <td>สถานะ</td>
                                <td><div class="boxStatusBet StatusBetLose">ไม่ถูกรางวัล</div></td>
                            </tr>
                            <tr class="defaultRowText">
                                <td>ยอดเงินที่ได้รับ</td>
                                <td>4,000 บาท</td>
                            </tr>
                            <tr class="StatusBetLoseRowText">
                                <td>ยอดเงินที่เสียไป</td>
                                <td class="redText">- 4,000 บาท</td>
                            </tr>
                        </table>
                    </div>
                    <div class="content boxNumSelectContent">
                        <div class="ListLottoNum">
                            <div class="ListLottoNumInfo">
                                <div class="topic">
                                    <div class="tagTopicSet">
                                        <p>{{ show_bet.lotto_set_count }} ใบ</p>
                                        <span>{{ show_bet.lotto_set_count*6 }} ล.</span>
                                    </div>
                                    <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                    <div class="boxStatusBet" :class="{'StatusBetWait':show_bet.lotto_user_pl==0,'StatusBetWin': show_bet.lotto_user_pl>0,'StatusBetLose': show_bet.lotto_user_pl<0}">{{bet_status_list[show_bet.lotto_user_pl==0 ? 0:  show_bet.lotto_user_pl>0?1:2 ]}}</div>
                                </div>
                                <div class="content">
                                    <ul>
                                        <li>{{ show_bet.lotto_number[0] }}</li>
                                        <li>{{ show_bet.lotto_number[1] }}</li>
                                        <li>{{ show_bet.lotto_number[2] }}</li>
                                        <li>{{ show_bet.lotto_number[3] }}</li>
                                        <li>{{ show_bet.lotto_number[4] }}</li>
                                        <li>{{ show_bet.lotto_number[5] }}</li>
                                    </ul>

                                    <div class="ListDetail">
                                        <small>งวดที่</small>
                                        <strong>{{ show_bet.lotto_count }}</strong>
                                    </div>
                                    <div class="priceLottoLabel">
                                        <span>{{ numberWithCommas(show_bet.lotto_price)}}</span>บาท
                                    </div>
                                </div>
                                <div class="contentBg"></div>
                                <div class="contentBgUnder"></div>
                            </div>
                            <div class="footer" v-if="show_bet.lotto_user_pl>0">
                                <div class="footerContent" >
                                    <p>{{ showRewardTitle(show_bet) }}</p>
                                    <span>จำนวนเงินรางวัล<strong>{{ numberWithCommas(show_bet.lotto_reward_total) }}</strong>บาท</span>
                                </div>
                            </div>
                        </div>
                        <table class="tableTotalBill">
                            <tr>
                                <td>จำนวนจำนวนสลากฯ</td>
                                <td><span>{{ show_bet.lotto_set_count }}</span> ใบ</td>
                            </tr>
                            <tr>
                                <td>งวดวันที่</td>
                                <td><span>{{  showDate(show_bet.date) }}</span></td>
                            </tr>
                            <tr>
                                <td>วัน/เวลาที่ซื้อ</td>
                                <td><span>{{ showDateTime(show_bet.create_datetime)  }}</span></td>
                            </tr>
                            <!-- <tr>
                                <td>ชุดที่</td>
                                <td><span>16</span></td>
                            </tr> -->
                            <tr>
                                <td>โพยเลขที่</td>
                                <td><span>#{{show_result.gid}}</span></td>
                            </tr>
                            <tr>
                                <td>ทั้งหมด</td>
                                <td><span>{{ numberWithCommas(show_bet.lotto_price) }}</span> บาท</td>
                            </tr>
                        </table>

                    </div>
<!--                    <p class="bottomDetailBill">*** เงินที่ถูกรางวัลจะเข้า User โดยอัตโนมัติ ***</p>-->
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>

        <div class="wrapContentIndexTitle ">
            <button style="padding: 0 10px;" class="btn " @click="goToIndex()"><i class="fas fa-chevron-left"></i></button>
            <div class="IndexTitleIntoGroup">
                <h3>สลากของฉัน</h3>
<!--                <span>งวดวันที่ 12/12/2565</span>-->
            </div>
        </div>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="containerLottoNumberBill">
                        <div class="LottoNumberBillTopic">
                            <div class="contentSubBill">
                                <div class="navSubBill" :class="{'active':lotto_type=='gold_number'}" @click="showLottoByType('gold_number')">
                                    <span>หวยเบอร์</span>
                                </div>
                                <div class="navSubBill"  :class="{'active':lotto_type=='gov_lotto'}" @click="showLottoByType('gov_lotto')">
                                    <span>สลากดิจิทัล</span>
                                </div>
                            </div>
                            <div class="contentStatBill" v-if="summary">
                                <div class="colStatBill">
                                    <strong>{{ summary[lotto_type+'_total'] }}</strong>
                                    <p>ยอดรวม(บาท)</p>
                                </div>
                                <div class="colStatBill colStatBillHaft">
                                    <strong>{{ summary[lotto_type+'_result'] }}</strong>
                                    <p>ออกผลแล้ว</p>
                                </div>
                                <div class="colStatBill">
                                    <strong>{{ summary[lotto_type+'_bet'] }}</strong>
                                    <p>ยังไม่ออกผล</p>
                                </div>
                            </div>
                            <ul>
                                <li @click="lottoChooseTab('all')" :class="{'active':lotto_tab=='all'}"><span>โพยทั้งหมด</span></li>
                                <li @click="lottoChooseTab('1')" :class="{'active':lotto_tab=='1'}"><span>ยังไม่ออกผล</span></li>
                                <li @click="lottoChooseTab('2')" :class="{'active':lotto_tab=='2'}"><span>ออกผลแล้ว</span></li>
                                <li @click="lottoChooseTab('prev')" :class="{'active':lotto_tab=='prev'}"><span>โพยก่อนหน้า</span></li>
                            </ul>
                        </div>

<!--                        โพยหวยเบอร์-->
                        <div class="LottoNumberBillContent NumBill" v-if="lotto_type=='gold_number'">
                                <div  v-for="(result,index) of result_list" :key="index" class="BillBox" :class="{'BillBoxWait':result.game_status<3,'BillBoxWin': result.result_return>0,'BillBoxLose': result.result_return<0}" @click="showBill(result)">
                                    <div class="BillBoxTop" >
                                        <h3>{{ lotto_title_list[lotto_type] }}</h3>
                                        <span class="BillBoxTopDate">งวดวันที่ {{ showDate(result.date_result) }}</span>
                                        <span>กระดานที่ {{ result.round_main }}</span>
                                        <div class="boxStatusBet" :class="{'StatusBetWait':result.game_status<3,'StatusBetWin': result.result_return>0,'StatusBetLose':  result.result_return<0}">{{showResultTitle(result)}}</div>
                                    </div>
                                    <div class="BillBoxCol">
                                        <div class="BillBoxRow">
                                            <strong>฿{{ numberWithCommas(parseInt(result.result_cost))}}</strong>
                                            <p>เงินเดิมพัน</p>
                                        </div>
                                        <div class="BillBoxRow">
                                            <strong>{{ result ? numberWithCommas(parseInt(result.profit_loss)) :'0' }} ฿</strong>
                                            <p>ผลแพ้ชนะ</p>
                                        </div>
                                    </div>
                                </div>

<!--                            Demo-->
                            
                            <div class="BillBox BillBoxWait" v-if="false">
                                <div class="BillBoxTop">
                                    <h3>หวยเบอร์ทอง</h3>
                                    <span>งวดวันที่ 12/12/2565</span>
                                    <div class="boxStatusBet StatusBetWait">รอผล</div>
                                </div>
                                <div class="BillBoxCol">
                                    <div class="BillBoxRow">
                                        <strong>฿40.00</strong>
                                        <p>เงินเดิมพัน</p>
                                    </div>
                                    <div class="BillBoxRow">
                                        <strong>0.00 ฿</strong>
                                        <p>ผลแพ้ชนะ</p>
                                    </div>
                                </div>
                            </div>
                            <div class="BillBox BillBoxWin"  v-if="false">
                                <div class="BillBoxTop">
                                    <h3>หวยเบอร์ทองเวียดนาม</h3>
                                    <span>งวดวันที่ 12/12/2565</span>
                                    <div class="boxStatusBet StatusBetWin">ถูกรางวัล</div>
                                </div>
                                <div class="BillBoxCol">
                                    <div class="BillBoxRow">
                                        <strong>฿40.00</strong>
                                        <p>เงินเดิมพัน</p>
                                    </div>
                                    <div class="BillBoxRow">
                                        <strong>0.00 ฿</strong>
                                        <p>ผลแพ้ชนะ</p>
                                    </div>
                                </div>
                            </div>
                        </div>

<!--                        โพยสลากฯ-->
                        <div class="LottoNumberBillContent BillContentGovernment" v-if="lotto_type=='gov_lotto'" >
                            <div class="LottoNumberContent">
                                <!--                                    Demo-->
                                <div class="ListLottoNum ListLottoNumSet" v-if="false">
                                    <div class="ListLottoNumInfo">
                                        <div class="topic">
                                            <div class="tagTopicSet">
                                                <p>1 ใบ</p>
                                                <span>6 ล.</span>
                                            </div>
                                            <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            <div class="boxStatusBet StatusBetWait"></div>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li>5</li>
                                                <li>6</li>
                                            </ul>
                                            <div class="ListDetail">
                                                <small>ชุดที่</small>
                                                <strong>20</strong>
                                            </div>
                                            <div class="ListDetail">
                                                <small>งวดที่</small>
                                                <strong>20</strong>
                                            </div>
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                        </div>
                                        <div class="contentBg"></div>
                                        <div class="contentBgUnder"></div>
                                    </div>
                                </div>
                                <!--                                    //////-->
                                <div  class="ListLottoNum" v-for="(result,index) of result_list" :key="index">
                                    <div class="LottoNumberContentTitle">
                                        <h5>งวดวันที่ <strong>{{ showDate(result.date_result) }}</strong></h5>
                                        <h5>เลขที่รายการ <strong>#{{result.gid}}</strong></h5>
                                    </div>

<!--                                    ListLottoNumSet-->
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNum ListLottoNumBox" v-for="(bet,bet_index) of JSON.parse(result.bet_list)" :key="bet_index" :class="{'ListLottoNumSet':bet.lotto_set_count>1}">
                                            <div class="ListLottoNumInfo" @click="showBill(result,bet)">
                                                <div class="topic">
                                                    <div class="tagTopicSet">
                                                        <p>{{ bet.lotto_set_count }} ใบ</p>
                                                        <span>{{ bet.lotto_set_count*6 }} ล.</span>
                                                    </div>
                                                    <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                                    <div class="boxStatusBet" :class="{'StatusBetWait':bet.lotto_user_pl==0,'StatusBetWin': bet.lotto_user_pl>0,'StatusBetLose': bet.lotto_user_pl<0}">{{bet_status_list[bet.lotto_user_pl==0 ? 0:  bet.lotto_user_pl>0?1:2 ]}}</div>
                                                </div>
                                                <div class="content">
                                                    <ul v-if="bet.lotto_number">

                                                        <li>{{bet.lotto_number[0]}}</li>
                                                        <li>{{bet.lotto_number[1]}}</li>
                                                        <li>{{bet.lotto_number[2]}}</li>
                                                        <li>{{bet.lotto_number[3]}}</li>
                                                        <li>{{bet.lotto_number[4]}}</li>
                                                        <li>{{bet.lotto_number[5]}}</li>
                                                    </ul>
                                                    <ul v-else>
                                                        <li>{{ '' }}</li>
                                                    </ul>
                                                    <div class="ListDetail">
                                                        <small>ชุดที่</small>
                                                        <strong>{{ bet.lotto_set_count }}</strong>
                                                    </div>
                                                    <div class="ListDetail">
                                                        <small>งวดที่</small>
                                                        <strong>{{bet.lotto_count}}</strong>
                                                    </div>
                                                    <div class="priceLottoLabel">
                                                        <span>{{ numberWithCommas(bet.lotto_price) }}</span>บาท
                                                    </div>
                                                </div>
                                                <div class="contentBg"></div>
                                                <div class="contentBgUnder"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div v-if="false">
                                    <div class="LottoNumberContentTitle">
                                    <h5>งวดวันที่ <strong>12/12/65</strong></h5>
                                        <h5>เลขที่รายการ <strong>#123456</strong></h5>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumInfo">
                                        <div class="topic">
                                            <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            <div class="boxStatusBet StatusBetWait"></div>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li>5</li>
                                                <li>6</li>
                                            </ul>
                                            <div class="ListDetail">
                                                <small>ชุดที่</small>
                                                <strong>26</strong>
                                            </div>
                                            <div class="ListDetail">
                                                <small>งวดที่</small>
                                                <strong>13</strong>
                                            </div>
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="LottoNumberContentTitle">
                                    <h5>งวดวันที่ <strong>12/12/65</strong></h5>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumInfo">
                                        <div class="topic">
                                            <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            <div class="boxStatusBet StatusBetWait"></div>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li>5</li>
                                                <li>6</li>
                                            </ul>
                                            <div class="ListDetail">
                                                <small>ชุดที่</small>
                                                <strong>26</strong>
                                            </div>
                                            <div class="ListDetail">
                                                <small>งวดที่</small>
                                                <strong>13</strong>
                                            </div>
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum activeWin">
                                    <div class="ListLottoNumInfo">
                                        <div class="topic">
                                            <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            <div class="boxStatusBet StatusBetWin">ถูกรางวัล</div>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li>5</li>
                                                <li>6</li>
                                            </ul>
                                            <div class="ListDetail">
                                                <small>ชุดที่</small>
                                                <strong>26</strong>
                                            </div>
                                            <div class="ListDetail">
                                                <small>งวดที่</small>
                                                <strong>13</strong>
                                            </div>
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer">
                                        <div class="footerContent">
                                            <p>คุณถูกรางวัลเลขท้าย 3 ตัว</p>
                                            <span>จำนวนเงินรางวัล<strong>4,000</strong>บาท</span>
                                            <div class="btn">รายละเอียด</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumInfo">
                                        <div class="topic">
                                            <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            <div class="boxStatusBet StatusBetLose">ไม่ถุกรางวัล</div>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li>5</li>
                                                <li>6</li>
                                            </ul>
                                            <div class="ListDetail">
                                                <small>ชุดที่</small>
                                                <strong>26</strong>
                                            </div>
                                            <div class="ListDetail">
                                                <small>งวดที่</small>
                                                <strong>13</strong>
                                            </div>
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumInfo">
                                        <div class="topic">
                                            <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            <div class="boxStatusBet StatusBetWait"></div>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li>5</li>
                                                <li>6</li>
                                            </ul>
                                            <div class="ListDetail">
                                                <small>ชุดที่</small>
                                                <strong>26</strong>
                                            </div>
                                            <div class="ListDetail">
                                                <small>งวดที่</small>
                                                <strong>13</strong>
                                            </div>
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumInfo">
                                        <div class="topic">
                                            <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            <div class="boxStatusBet StatusBetWait"></div>
                                        </div>
                                        <div class="content">
                                            <ul>
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>4</li>
                                                <li>5</li>
                                                <li>6</li>
                                            </ul>
                                            <div class="ListDetail">
                                                <small>ชุดที่</small>
                                                <strong>26</strong>
                                            </div>
                                            <div class="ListDetail">
                                                <small>งวดที่</small>
                                                <strong>13</strong>
                                            </div>
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import axiosWrapper from '@/axiosWrapper'
    import store from '@/store'
    export default {
        name: 'LottoNumberBill',
        components: {},
        computed: {
           
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
            
        },
        data (){
            return {
                showModalBill:false,
                showModalDetail:false,
                lotto_type :'gold_number',
                lotto_tab :'all',
                result_list :[],
                bet_status_list:[
                    'รอผล',
                    'ถูกรางวัล',
                    'ไม่ถูกรางวัล'
                ],
                lotto_title_list:{
                    'gold_number':'หวยเบอร์ทอง',
                    'gov_lotto':'ลอตเตอร์รี่ออนไลน์'
                },
                gid : null,
                result_bet_list  :null,
                show_bet : null,
                show_result : null,
                reward_list : ['1','2','3','4','5','n1','f3','p3','p2'],
                reward_str_list : ['รางวัลที่1','รางวัลที่2','รางวัลที่3','รางวัลที่4','รางวัลที่5','รางวัลใกล้เคียงที่1','รางวัลเลขหน้า3ตัว','รางวัลเลขท้าย3ตัว','รางวัลเลขท้าย2ตัว'],
                summary : null,
            }
        },
        methods :{
            showResultTitle(result){
               
                let str='';
                if(result.game_status<3){
                    str = this.bet_status_list[0];
                }else if(result.result_return>0){
                    str = this.bet_status_list[1];
                }else if(result.result_return<0){
                    str = this.bet_status_list[2];

                }
                return str;
            },
            async showLottoByType(_type){
                this.lotto_type =_type;
                await this.getBillList();
            },
            goToIndex() {
                if(this.envDomains.VUE_APP_SITE_TYPE=='lotto'){
                    this.$router.push({'name':'Index'})
                }else{
                    this.$router.push({'name':'LottoNumber'})
                }
            },
            showDate(date){
                
                let moment_datetime=moment(date).format('DD/MM/YYYY');
                let date_array =moment_datetime.split("/");
                return date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)
            },
            showDateTime(date){
                
                let moment_datetime=moment(date).format('DD/MM/YYYY');
                let moment_time  = moment(date).format('HH:mm:ss');
                let date_array =moment_datetime.split("/");
                return date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)+ ' '+moment_time;
            },
            showBill(result,bet){
                this.show_bet = bet;
                this.show_result= result;
                this.showModalBill=true;
                

            },
            async lottoChooseTab(tab){
                this.lotto_tab = tab;
                await this.getBillList();
            },
            async getBillList(){
                
                let limit =1000;
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/get-bill-list?token=`+this.token+'&limit='+limit+'&lotto_type='+this.lotto_type+'&lotto_tab='+this.lotto_tab,
                    data: {
                        
                    }
                })
                // this.isRecommend=true;
                // this.closeModalSearch();
                this.result_list = res.data.data;
                for(let i=0;i<this.result_list.length;i++){
                    let bet_list = JSON.parse(this.result_list[i].bet_list);
                    let number =Object.keys(bet_list)[0];
                    let round_main = bet_list[number].round_main;
                    let round_sub = bet_list[number].round_sub;
                    this.result_list[i].round_main = round_main;
                    this.result_list[i].round_sub = round_sub

                }
                // for(let i=0;i<this.result_list.length;i++){
                //     let bet_list = JSON.parse(this.result_list[i].bet_list);
                //     for(let index =0;index<bet_list.length;index++){
                //         console.log(bet_list[index].lotto_number);
                //     }
                    
                // }
            },
            async getBetList(gid){
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/get-bet-list?token=`+this.token+'&gid='+gid+'&lotto_type='+this.lotto_type,
                    data: {
                        
                    }
                })
                this.result_bet_list = res.data.data;

            },
            async getSummary(){
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/summary?token=${this.token}`,
                    data: {
                        
                    }
                })
                this.summary = res.data.data;

            },
            showRewardTitle(bet_list){
                let str_list =[];
                for(let i=0;i<this.reward_list.length;i++){
                    let key =this.reward_list[i];
                    if(bet_list[`lotto_reward_type_${key}`]){
                        str_list.push(this.reward_str_list[i]);
                    }
                }
                return str_list.join(',');
            },
            numberWithCommas(x) {
                if(x==null){
                    x='0';
                }
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        async mounted() {
            await this.getSummary();
            if(this.$route.query.lotto_type){
                this.lotto_type =this.$route.query.lotto_type 
                await this.getBillList()

            }else{
                await this.getBillList()

            }
            // if(this.$route.query.gid){
            //     this.gid = this.$route.query.gid;
            //     this.lotto_type = this.$route.query.lotto_type;
            //     await this.getBetList(this.gid);
            //     if(this.result_bet_list){
            //         this.showModalBill=true;
            //     }
                
            // }
            // await this.getBillList();
        },
    }
</script>