<script>

import store from '@/store'
import ReconnectingWebSocket from 'reconnecting-websocket'
import {
    TABLE_TYPE_KEY, 
    TABLE_GAME_STATUS, 
    GAME_RESULT, 
    TABLE_TYPE,
    SOUND,
    soundLoad,
    USER_CHOOSE,
    GAME_STATUS_KEY,
} from '@/components/game/Constant'
import debounce from '@/debounce'
import waitForSecond from '@/lib/waitForSecond'
import AdditionalDataMixin from './AdditionalDataMixin'
import axiosWrapper from '@/axiosWrapper'
import {groupBy} from '@/lib/ArrHandle'
import UserMixin from './UserMixin'
import Timer from '@/lib/Timer'
import GoLobbyModal from '@/components/game/GoLobbyModal'
import randomBetween from '@/lib/randomBetween'
import Vue from 'vue'
const VIDEO_PLAYER_REFRESH_LIMIT = 5

// const DEMO_VIDEO_URL = {
//     0: {
//         streamPath: 'd99206bf-ec34-4a1e-84c3-a51207f7a313',
//         hls: 'https://stream.eesbet.com/live/demo3/index.m3u8',
//         flv: 'https://stream.eesbet.com/live/demo3.flv?token=abc',
//     },
//     1: {
//         streamPath: 'd99206bf-ec34-4a1e-84c3-a51207f7a313',
//         hls: 'https://stream.eesbet.com/live/demo2/index.m3u8',
//         flv: 'https://stream.eesbet.com/live/demo2.flv?token=abc',
//     },
//     2: {
//         streamPath: 'd99206bf-ec34-4a1e-84c3-a51207f7a313',
//         hls: 'https://stream.eesbet.com/live/demo2/index.m3u8',
//         flv: 'https://stream.eesbet.com/live/demo2.flv?token=abc',
//     },
//     3: {
//         streamPath: 'd99206bf-ec34-4a1e-84c3-a51207f7a313',
//         hls: 'https://stream.eesbet.com/live/demo1/index.m3u8',
//         flv: 'https://stream.eesbet.com/live/demo1.flv?token=abc',
//     },
// }

export default {
    name: 'GameMixin',
    mixins: [
        AdditionalDataMixin,
        UserMixin,
    ],
    components: {
        GoLobbyModal,
    },
    props: ['noChat', 'noTopSection', 'version'],
    data() {
        return {
            gameSocket: null,
            gameSocketStatus: 'idle',
            gameKeepSocketAlive: null,
            gameReconnectSecond: 10,
            TABLE_TYPE_KEY: null,
            loadGameTableErrorMessage: '',
            allChipArr: [5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000],
            chipArr: {
                1: [5, 10, 50, 100, 500],
                2: [10, 20, 50, 500, 1000],
                3: [20, 100, 500, 1000, 5000],
                4: [100, 500, 1000, 5000, 10000],
                5: [200, 1000, 5000, 10000, 50000],
            },
            ticketList: [],
            currentTable: null,
            profitLoss: 0,
            userBetArr: [],
            videoPlayerWidth: 0,
            videoPlayerHeight: 0,

            is_show_panel_bottom: true,
            panel_to_show: 'play', // play, state
            balanceFromUpdate: 0,
            betMessage: '',
            countUserNotBetOnRound: 0,
            countBeforeVideoPlayerRefresh: 0,
            isSetBetLimit: false,
            bankerPayArr: [],
            playerPayArr: [],
            liveMode: '3d', // video, 3d
            liveModeUserChoose: '',
            customExtraPayData: null,
            isGameSoundOn: false,
            isAlreadyLoadUserBetArr: false,
            isAlreadyLoadWarrantyHistory: false,
            isAlreadyLoadCards: false,
            isOnSendBet: false,
            isHavePendingCancelBet: false,
            orientation: 'portrait',
            playerBus: new Vue(),
        }
    },
    computed: {
        is_member() {
            return store.state.is_member
        },
        pes_profile() {
            return store.state.pes_profile
        },
        balance() {
            if(!this.is_member) {
                return 0
            }
            if(this.balanceFromUpdate) {
                return this.balanceFromUpdate
            } else {
                return this.pes_profile.THB_free
            }
        },
        token() {
            if(!this.is_member) {
                return ''
            }
            return this.pes_profile.token
        },
        routeTableType() {
            return this.$route.meta.table_type || parseInt(this.$route.query.table_type)
        },
        queryTableId() {
            return this.$route.query.table_id
        },
        baccaratTableList: {
            get() {
                return store.state.game.baccaratTableList
            },
            set(value) {
                store.commit('game/updateBaccaratTableList', value)
            }
        },
        baccaratGameList: {
            get() {
                return store.state.game.baccaratGameList
            },
            set(value) {
                store.commit('game/updateBaccaratGameList', value)
            }
        },
        // currentTable: {
        //     get() {
        //         return store.state.game.currentTable
        //     },
        //     set(value) {
        //         store.commit('game/updateCurrentTable', value)
        //     }
        // },
        currentGame: {
            get() {
                return store.state.game.currentGame
            },
            set(value) {
                store.commit('game/updateCurrentGame', value)
            }
        },
        currentBetLimitConfig: {
            get() {
                return store.state.game.currentBetLimitConfig
            },
            set(value) {
                store.commit('game/updateCurrentBetLimitConfig', value)
            }
        },
        bet_limit_config: {
            get() {
                return store.state.game.bet_limit_config
            },
            set(value) {
                store.commit('game/updateBetLimitConfig', value)
            }
        },
        baccarat_bet_limit: {
            get() {
                return store.state.game.baccarat_bet_limit
            },
            set(value) {
                store.commit('game/updateBaccaratBetLimit', value)
            }
        },
        baccaratTableListLength() {
            return this.baccaratTableList.length
        },
        baccaratGameListLength() {
            return this.baccaratGameList.length
        },
        gameStatus() {
            return (this.currentTable) ? this.currentTable.table_game_status : null
        },
        tableType() {
            return (this.currentTable) ? this.currentTable.table_type : -1
        },
        tableId() {
            return (this.currentTable) ? parseInt(this.currentTable.table_id) : null
        },
        tableChannelInfo() {
            return (this.currentTable) ? this.currentTable.channel_info : null
        },
        tableVideoStreamPath() {
            // return (this.currentTable) ? DEMO_VIDEO_URL[this.tableType].streamPath : ''
            return (this.tableChannelInfo) ? this.tableChannelInfo.streamPath : ''
            // return 'd99206bf-ec34-4a1e-84c3-a51207f7a313'
        },
        tableVideoSrcHLS() {
            // return (this.currentTable) ? DEMO_VIDEO_URL[this.tableType].hls : ''
            return (this.tableChannelInfo) ? `${this.tableChannelInfo.url.hls}?token=${this.token}` : ''
            // return `https://live.eesbet.com/live/d99206bf-ec34-4a1e-84c3-a51207f7a313/index.m3u8?token=${this.token}`
        },
        tableVideoSrcFLV() {
            // return (this.currentTable) ? DEMO_VIDEO_URL[this.tableType].flv : ''
            return (this.tableChannelInfo) ? `${this.tableChannelInfo.url['http-flv']}?token=${this.token}` : ''
            // return `https://live.eesbet.com/live/d99206bf-ec34-4a1e-84c3-a51207f7a313.flv?token=${this.token}`
            // return 'http://resource.wangdaodao.com/test.flv'
            // return 'https://flvplayer.js.org/assets/video/weathering-with-you.flv'
        },
        gameResult() {
            return (this.currentGame) ? this.currentGame.game_result : null
        },
        gameInfo() {
            return (this.currentGame) ? this.currentGame.game_info : null
        },
        countDownStart() {
            return (this.currentGame) ? this.currentGame.countdown_datetime : null
        },
        bet_user_ranking() {
            return (this.currentGame) ? this.currentGame.bet_user_ranking : []
        },
        bet_user_bet_list() {
            return (this.currentGame) ? this.currentGame.bet_user_bet_list : []
        },
        statArr() {
            if(!this.currentTable) {
                return []
            }
            return this.baccaratGameList.filter((game) => {
                return parseInt(game.round_main) === parseInt(this.currentTable.table_round_main) &&
                    parseInt(game.table_id) === parseInt(this.currentTable.table_id)
            })
        },
        betLimitArr() {
            if(!this.is_member) {
                return []
            }
            if(!this.currentTable) {
                return []
            }
            if(!this.baccarat_bet_limit[this.currentTable.table_type]) {
                return []
            }
            return this.baccarat_bet_limit[this.currentTable.table_type].map((id) => {
                return this.bet_limit_config.find((config) => {
                    return config.config_id === id
                })
            })
        },
        betLimitArrLength() {
            return this.betLimitArr.length
        },
        chipForSelectArr() {
            if(!this.is_member) {
                return this.chipArr[1]
            }
            if(!this.currentBetLimitConfig) {
                return []
            }
            return this.chipArr[this.currentBetLimitConfig.config_id]
        },
        chipForSelectArrLength() {
            return this.chipForSelectArr.length
        },
        chipForSelectArrFirst() {
            if(this.chipForSelectArrLength > 0) {
                return this.chipForSelectArr[0]
            } else {
                return null
            }
        },
        totalUserBet() {
            return this.userBetArr.reduce((sum, bet) => {
                if(bet.state === 'confirm') {
                    return parseInt(sum) + parseInt(bet.amount)
                } else {
                    return parseInt(sum)
                }
            }, 0)
        },
        isOnBaccaratTable() {
            const baccaratTableTypes = [
                TABLE_TYPE_KEY.baccarat,
                TABLE_TYPE_KEY.baccarat2,
                TABLE_TYPE_KEY.baccarat3,
            ]
            return baccaratTableTypes.includes(parseInt(this.tableType))
        }
    },
    watch: {
        baccaratTableListLength(newVal) {
            if(newVal) {
                this.loadTableGame()
            }
        },
        baccaratGameListLength(newVal) {
            if(newVal) {
                this.loadTableGame()
            }
        },
        chipForSelectArrFirst(newVal) {
            if(newVal) {
                if(!this.currentTable) {
                    return
                }
                if(this.currentTable.table_type === TABLE_TYPE_KEY.bean) {
                    return
                }
                this.amount = newVal
            }
        },
        countBeforeVideoPlayerRefresh(newVal) {
            if(newVal >= VIDEO_PLAYER_REFRESH_LIMIT) {
                this.playerBus.$emit('fastRefresh')
                this.countBeforeVideoPlayerRefresh = 0
            }
        },
        tableId(newVal, oldVal) {
            if(!oldVal && newVal) {
                this.$emit('gotTableId', newVal)
            }
        },
        gameStatus(newVal) {
            if(parseInt(newVal) === GAME_STATUS_KEY.SHUFFLING) {
                this.currentGame = null
            } else if(parseInt(newVal) === GAME_STATUS_KEY.CANCEL) {
                this.clearUserBetArr()
            }
        },
        isOnSendBet(newVal) {
            if(!newVal && this.isHavePendingCancelBet) {
                this.cancelBet()
            }
        },
    },
    filters: {
        displayTableGameStatus(raw, lang = 'EN') {
            if(!TABLE_GAME_STATUS[raw]) {
                return ''
            }
            return TABLE_GAME_STATUS[raw][lang]
        },
        displayGameResult(raw, lang = 'EN') {
            if(!GAME_RESULT[raw]) {
                return ''
            }
            return GAME_RESULT[raw][lang]
        },
    },
    methods: {
        onRoundEnd() {
            this.countUserNotBetOnRound += 1
            this.countBeforeVideoPlayerRefresh += 1
        },
        onUnsetRoom() {
            console.log(this.$moment().format('YYYY-MM-DD HH:mm:ss'), 'onUnsetRoom')
            if(this.noTopSection === 'yes') {
                this.$emit('liffCloseWindow')
                return
            }
            this.$modal.show('go-lobby-modal')
            this.isSetBetLimit = false
        },
        generatePanelArr(){},
        baccaratTableListToArr(map) {
            const arr = []
            for(const key in map) {
                if(map[key].table_status) {
                    arr.push(map[key])
                }
            }
            return arr
        },
        updateTableList(newData) {
            if(!newData.table_status) {
                return
            }
            if(this.baccaratTableList.length > 0) {
                const i = this.baccaratTableList.findIndex((table) => {
                    return parseInt(table.table_id) === parseInt(newData.table_id)
                })
                if(i < 0) {
                    this.baccaratTableList.push(newData)
                } else {
                    store.commit('game/updateBaccaratTable', {
                        i: i,
                        data: newData,
                    })
                }
            }
        },
        updateCurrentTable(newData) {
            if(!this.currentTable) {
                return
            }
            if(parseInt(this.currentTable.table_id) === parseInt(newData.table_id)) {
                this.currentTable = newData
            }
        },
        updateGameList(newGame) {
            try {
                if(!newGame) {
                    return
                }
                if(this.baccaratGameList.length > 0) {
                    const i = this.baccaratGameList.findIndex((game) => {
                        return parseInt(game.gbs_id) === parseInt(newGame.gbs_id) &&
                            parseInt(game.table_id) === parseInt(newGame.table_id)
                    })
                    if(i < 0) {
                        this.baccaratGameList.push(newGame)
                    } else {
                        store.commit('game/updateBaccaratGame', {
                            i: i,
                            data: newGame,
                        })
                    }
                } else {
                    this.baccaratGameList.push(newGame)
                }
            }catch(err) {
                console.log('updateGameList err', err.message)
            }
        },
        async updateCurrentGame(table, game) {

            if(!this.currentTable || !this.currentGame || !table || !game) {
                return
            }
            if(parseInt(this.currentTable.table_id) === parseInt(table.table_id)) {
                // if(parseInt(game.gbs_id) > parseInt(this.currentGame.gbs_id)) { //new game
                //     await waitForSecond(1000 * 2)
                // }
                this.currentGame = game
            }
        },
        baccaratInitHandle(data) {

            this.baccaratTableList = this.baccaratTableListToArr(data.data.baccarat_table_list)

            const baccaratGameList = data.data.baccarat_game_list.reverse()
            const sicboGameList = data.data.sicbo_game_list.reverse()
            const beanGameList = data.data.bean_game_list.reverse()
            const dragonTigerGameList = data.data.dragontiger_game_list.reverse()
            const baccarat2GameList = (data.data.baccarat2_game_list) ? data.data.baccarat2_game_list.reverse() : []
            const baccarat3GameList = (data.data.baccarat3_game_list) ? data.data.baccarat3_game_list.reverse() : []
            this.baccaratGameList = baccaratGameList.concat(sicboGameList, beanGameList, dragonTigerGameList, baccarat2GameList, baccarat3GameList)

            this.bet_limit_config = data.data.bet_limit_config

            this.baccarat_bet_limit = [
                data.data.baccarat_bet_limit,
                data.data.sicbo_bet_limit,
                data.data.bean_bet_limit,
                data.data.dragontiger_bet_limit,
                data.data.baccarat2_bet_limit,
                data.data.baccarat3_bet_limit,
            ]

            const user_baccarat_ticket_list = data.data.user_baccarat_ticket_list
            const user_sicbo_ticket_list = data.data.user_sicbo_ticket_list
            const user_bean_ticket_list = data.data.user_bean_ticket_list
            const user_dragontiger_ticket_list = data.data.user_dragontiger_ticket_list
            const user_baccarat2_ticket_list = data.data.user_baccarat2_ticket_list
            const user_baccarat3_ticket_list = data.data.user_baccarat3_ticket_list

            if(this.is_member) {
                this.ticketList = user_baccarat_ticket_list.concat(
                    user_sicbo_ticket_list,
                    user_bean_ticket_list,
                    user_dragontiger_ticket_list,
                    user_baccarat2_ticket_list,
                    user_baccarat3_ticket_list,
                )
            }
        },
        onBaccaratTableUpdate(data) {
            const newData = data.data
            this.updateTableList(newData)
            this.updateCurrentTable(newData)
        },
        onBaccaratTableGameUpdate(table, game) {
            this.updateTableList(table)
            this.updateCurrentTable(table)
            this.updateCurrentGame(table, game)
            this.updateGameList(game)
        },
        onAddCard() {},
        _onAddCardCountDownHandle() {},
        onDice() {},
        _loadWarrantyHistory() {},
        _loadCards() {},
        onBalanceUpdate(data) {
            this.balanceFromUpdate = data
        },
        onGameStatUpdate(data) {
            try {

                if(!data || !this.currentGame) {
                    return
                }
                if(parseInt(data.gbs_id) === parseInt(this.currentGame.gbs_id) && 
                parseInt(data.game_index) === parseInt(this.currentTable.table_type)) {
                    Object.assign(this.currentGame, data.update)
                }

                if(data.update.bet_user_ranking.length > 0) {
                    const pes_username_list = data.update.bet_user_ranking.map((user) => {
                        return user.username
                    })
                    this.loadUserInfoArrByPesUsername(pes_username_list)
                }

            }catch(err) {
                console.log('onGameStatUpdate err', err.message)
            }
        },
        onTicketUpdate(data) {
            try {

                if(!data) {
                    return
                }
                
                this.calExtraPayArr(data)
                this.updateTicketList(data)
                this.calProfitLoss(data)

            }catch(err) {
                console.log('onTicketUpdate err', err.message)
            }
        },
        calExtraPayArr(data) {

            if(this.liveMode === '3d' && this.tableType === TABLE_TYPE_KEY.baccarat && this.animationState !== 'showResult') {
                this.customExtraPayData = data
                return
            }
            if(!data) {
                return
            }

            let user_choose = data.bet_list.user_choose
            const userBetArrReverse = [...this.userBetArr].reverse()
            let userBet = userBetArrReverse.find((userBet) => {
                return parseInt(userBet.user_choose) === parseInt(data.bet_list.user_choose)
            })

            if(this.tableType === TABLE_TYPE_KEY.bean) {
                user_choose = `${data.bet_list.user_choose_index}:${data.bet_list.user_choose}`
                userBet = userBetArrReverse.find((userBet) => {
                    return parseInt(userBet.user_choose) === parseInt(data.bet_list.user_choose) &&
                    parseInt(userBet.panelIndex) === parseInt(data.bet_list.user_choose_index)
                })
            }

            if(!userBet) {
                return
            }

            if(parseFloat(data.profit_loss) > 0) {
                this.bankerPayArr.push({
                    state: 'bankerPay',
                    chip: userBet.latest_chip,
                    value: data.profit_loss,
                    user_choose: user_choose,
                })
            } else {

                if(this.tableType === TABLE_TYPE_KEY.bean) {
                    for(let i = 0;i < 3;i++) {
                        this.playerPayArr.push({
                            state: 'playerPay',
                            chip: userBet.latest_chip,
                            value: data.cost,
                            user_choose: user_choose,
                        })
                    }
                } else {
                    this.playerPayArr.push({
                        state: 'playerPay',
                        chip: userBet.latest_chip,
                        value: data.profit_loss,
                        user_choose: user_choose,
                    })
                }
            }
        },
        updateTicketList(data) {
            const i = this.ticketList.findIndex((ticket) => {
                return parseInt(ticket.gid) === data.gid
            })
            if(i >= 0) {
                this.ticketList[i] = data
            }
        },
        calProfitLoss(data) {
            if(TABLE_TYPE_KEY[data.game] === this.tableType) {
                this.profitLoss += parseFloat(data.profit_loss)
                this.showBetResult()
            }
        },
        showBetResult: debounce(async function() {
            // if(this.profitLoss > 0) {
            //     this.$modal.show('result-modal')
            // }
            
            if(this.liveMode === '3d' && this.tableType === TABLE_TYPE_KEY.baccarat && this.animationState !== 'showResult') {
                return
            }

            this.$modal.show('result-modal')
            await waitForSecond(1000 * 2)
            this.clearProfitLoss()
        }, 400),
        async clearProfitLoss() {
            this.$modal.hide('result-modal')
            this.profitLoss = 0
        },
        onUserOnlineUpdate(data) {
            try {

                if(!data) {
                    return
                }
                
                const i = this.baccaratTableList.findIndex((table) => {
                    return parseInt(table.table_id) === parseInt(data.table_id)
                })
                if(i >= 0) {
                    Object.assign(this.baccaratTableList[i], data)
                }
            }catch(err) {
                console.log('onUserOnlineUpdate err', err.message)
            }
        },
        showLoadTableGameError() {},
        gameSocketOpenHandle(){
            this.gameSocketStatus = 'open'
            if(this.gameSocket.readyState === 1){
                this.gameSocket.send(JSON.stringify({
                    type: 'subscribe',
                    list: ['baccarat'],
                }))
                if(this.gameKeepSocketAlive){
                    clearInterval(this.gameKeepSocketAlive)
                }
                this.gameKeepSocketAlive = setInterval(() => {
                    this.gameSocket.send(JSON.stringify({task: 'ping'}))
                }, this.gameReconnectSecond*1000)
            }
        },
        gameMessageRecieve(event){

            try {
                const message_recieve = JSON.parse(event.data)
                if(message_recieve.type === 'baccarat') {
                    if(message_recieve.sub_type === 'init') {
                        this.baccaratInitHandle(message_recieve)
                    } else if (message_recieve.sub_type === 'table') {
                        this.onBaccaratTableUpdate(message_recieve)
                    } else if (message_recieve.sub_type === 'table_game') {
                        this.onBaccaratTableGameUpdate(message_recieve.data.table, message_recieve.data.game)
                    } else if (message_recieve.sub_type === 'add_card') {
                        this.onAddCard(message_recieve.data)
                    } else if (message_recieve.sub_type === 'balance') {
                        this.onBalanceUpdate(message_recieve.data)
                    } else if (message_recieve.sub_type === 'game_stat_update') {
                        this.onGameStatUpdate(message_recieve.data)
                    } else if (message_recieve.sub_type === 'dice') {
                        this.onDice(message_recieve.data)
                    } else if (message_recieve.sub_type === 'ticket') {
                        this.onTicketUpdate(message_recieve.data)
                    } else if (message_recieve.sub_type === 'user_online') {
                        this.onUserOnlineUpdate(message_recieve.data)
                    } else if (message_recieve.sub_type === 'unset_room') {
                        this.onUnsetRoom()
                    } else if (message_recieve.sub_type === 'add_card_countdown') {
                        this._onAddCardCountDownHandle(message_recieve.data)
                    }
                }
            } catch(err) {
                console.log('gameMessageRecieve err', err.message)
            }
        },
        gameSocketCloseHandle(event){
            this.gameSocketStatus = 'close'
            clearInterval(this.gameKeepSocketAlive)
            console.log('Game Socket is closed. Reconnect will be attempted in 10 second', event.reason);
            setTimeout(() => {
                this.gameSocketInit()
            }, this.gameReconnectSecond*1000)
        },
        gameSocketInit(){
            this.gameSocketDestoy()
            this.gameSocket = new ReconnectingWebSocket(`${this.envDomains.VUE_APP_PES_WS_BASE_URL}`, this.token, {
                debug: false, 
                reconnectInterval: 3000
            })
            this.gameSocket.addEventListener('open', this.gameSocketOpenHandle)
            this.gameSocket.addEventListener('message', this.gameMessageRecieve)
            this.gameSocket.addEventListener('close', this.gameSocketCloseHandle)
        },
        gameSocketDestoy() {
            if(this.gameSocket){
                this.gameSocket.removeEventListener('open', this.gameSocketOpenHandle)
                this.gameSocket.removeEventListener('message', this.gameMessageRecieve)
                this.gameSocket.removeEventListener('close', this.gameSocketCloseHandle)
                this.gameSocket.close()
                if(this.gameKeepSocketAlive){
                    clearInterval(this.gameKeepSocketAlive)
                }
            }
        },
        findGameByGBSId(table_id, gbs_id) {
            try {
                return this.baccaratGameList.find((game) => {
                    return parseInt(game.gbs_id) === parseInt(gbs_id) && 
                        parseInt(game.table_id) === parseInt(table_id)
                })
            }catch(err) {
                console.log('findGameByGBSId err', err.message)
                return null
            }  
        },
        selectGameTable(table) {

            if(parseInt(table.table_type) === TABLE_TYPE_KEY.baccarat) {
                this.$router.push({name: 'Baccarat', query: {table_id: table.table_id}})
            } else if(parseInt(table.table_type) === TABLE_TYPE_KEY.sicbo) {
                this.$router.push({name: 'MiniSicbo'})
            } else if(parseInt(table.table_type) === TABLE_TYPE_KEY.bean) {
                this.$router.push({name: 'MiniFantan'})
            } else if(parseInt(table.table_type) === TABLE_TYPE_KEY.dragontiger) {
                this.$router.push({name: 'MiniDragonTiger'})
            }
        },
        getTableListByType(type) {
            return this.baccaratTableList.filter((table) => {
                return parseInt(table.table_type) === parseInt(type)
            })
        },
        getTableById(table_id) {
            return this.baccaratTableList.find((table) => {
                return parseInt(table.table_id) === parseInt(table_id)
            })
        },
        getTableByType(table_type) {
            return  this.baccaratTableList.find((table) => {
                return parseInt(table.table_type) === table_type
            })
        },
        loadTableGame: debounce(function() {
            if(this.baccaratTableList.length === 0) {
                return
            }

            const table_type = this.routeTableType || TABLE_TYPE_KEY.baccarat
            const table_id = this.queryTableId

            let table = null

            if(table_id) {
                table = this.getTableById(table_id)
            } else {
                table = this.getTableByType(table_type)
            }

            if(!table) {
                console.log('table not found this.baccaratTableList', this.baccaratTableList)
                this.loadGameTableErrorMessage = 'ไม่พบข้อมูลโต๊ะ กรุณาเลือกโต๊ะอื่น'
                this.showLoadTableGameError()
                return
            }

            this.currentTable = table

            if(!table.ref_gbs_id) {
                return
            }

            const game = this.findGameByGBSId(table.table_id, table.ref_gbs_id)

            if(!game) {
                // this.loadGameTableErrorMessage = 'โต๊ะยังไม่เปิด กรุณาเลือกโต๊ะอื่น'
                // this.showLoadTableGameError()
                this.currentGame = null
                return
            }
            // if(!this.currentGame) {
            //     this.currentGame = game
            // }
            this.currentGame = game

            if(this.is_member && !this.isSetBetLimit) {
                if(this.currentBetLimitConfig) {
                    this.setBetLimit(this.currentBetLimitConfig.config_id)
                } else if(this.tableType === TABLE_TYPE_KEY.bean) {
                    this.setBetLimit(this.baccarat_bet_limit[TABLE_TYPE_KEY.bean][0])
                } else {
                    this.$modal.show('select-bet-limit-modal')
                }
            }

            this._loadCards()
            if(parseInt(this.gameStatus) !== GAME_STATUS_KEY.FINISH) {
                this.loadUserBetArr()
                this._loadWarrantyHistory()
            }
            
            if(this.tableType === TABLE_TYPE_KEY.baccarat2 || this.tableType === TABLE_TYPE_KEY.baccarat3) {
                if(!this.gameInfo) {
                    return
                }
                if(this.gameInfo.countdown3_second || this.gameInfo.countdown2_second || this.gameInfo.countdown1_second) {
                    this._clientEnterOnWarranty({
                        countdown_datetime: this.currentGame.countdown_datetime,
                        countdown1_second: this.gameInfo.countdown1_second,
                        countdown2_second: this.gameInfo.countdown2_second,
                        countdown3_second: this.gameInfo.countdown3_second,
                    })
                }
            }
        }, 100),
        setCurrentBetLimitConfig(config_id) {
            this.currentBetLimitConfig = this.betLimitArr.find((o) => {
                return parseInt(o.config_id) === parseInt(config_id)
            })
        },
        getTicketListByGame(game, table_id) {

            try{ 

                if(!(parseInt(game) >= 0) || !(parseInt(table_id) >= 0)) {
                    return []
                }
                return this.ticketList.filter((ticket) => {
                    if(!ticket) {
                        return false
                    }
                    if(!ticket.game) {
                        return false
                    }
                    if(!ticket.bet_list) {
                        return false
                    }
                    return ticket.game === TABLE_TYPE[game] && ticket.bet_list.table_id === table_id
                })
            } catch (err) {
                console.log('getTicketListByGame err', err.message)
                return []
            }
        },
        addTicketList(ticket) {
            this.ticketList.splice(0, 0, ticket)
        },
        selectChip(value) {
            this.amount = value
        },
        selectBet(bet) {

            if(this.isOnSendBet) {
                return
            }

            if(this.GAME_STATUS.COUNTDOWN !== this.gameStatus) {
                return
            }

            if(this.isOnBaccaratTable && (this.version === 2 || this.version === 3)) {

                if(parseInt(bet) === USER_CHOOSE.BANKER_WIN) {
                    const isAlreadyPlayerChoose = this.userBetArr.find((userBet) => {
                        return parseInt(userBet.user_choose) === USER_CHOOSE.PLAYER_WIN
                    })
                    if(isAlreadyPlayerChoose) {
                        this.betMessage = 'สามารถเลือกเล่นได้ฝั่งเดียว'
                        this.$modal.show('bet-message-modal')
                        return
                    }
                } else if(parseInt(bet) === USER_CHOOSE.PLAYER_WIN) {
                    const isAlreadyBankerChoose = this.userBetArr.find((userBet) => {
                        return parseInt(userBet.user_choose) === USER_CHOOSE.BANKER_WIN
                    })
                    if(isAlreadyBankerChoose) {
                        this.betMessage = 'สามารถเลือกเล่นได้ฝั่งเดียว'
                        this.$modal.show('bet-message-modal')
                        return
                    }
                }
            }            

            this.user_choose = bet

            this.userBetArr.push({
                state: 'wait',
                amount: this.amount, 
                user_choose: bet, 
                latest_chip: this.amount
            })
        },
        clearBet() {
            this.user_choose = 0
        },
        cancelBet() {
            if(this.isOnSendBet) {
                this.isHavePendingCancelBet = true
                return
            }
            this.clearBet()
            this.userBetArr = this.userBetArr.filter((userBet) => {
                return userBet.state === 'confirm'
            })
            this.isHavePendingCancelBet = false
        },
        clearUserBetArr() {
            this.userBetArr = []
            this.bankerPayArr = []
            this.playerPayArr = []
        },
        _confirmBet() {

            if(this.isOnSendBet) {
                return
            }

            this.isOnSendBet = true

            if(this.gameStatus !== this.GAME_STATUS.COUNTDOWN) {
                this.$modal.show('not-bet-modal')
                return
            }

            if(!this.is_member) {
                this.notifyToSignUp('เพื่อเล่นเกม')
                return
            }

            const waitArr = this.userBetArr.filter((userBet) => {
                return userBet.state === 'wait'
            })

            const groupByUserChoose = groupBy(waitArr, 'user_choose')

            if(this.tableType === TABLE_TYPE_KEY.bean) {

                for(const user_choose in groupByUserChoose) {

                    const groupByPanelIndex = groupBy(groupByUserChoose[user_choose], 'panelIndex')

                    for(const panelIndex in groupByPanelIndex) {

                        const amount = groupByPanelIndex[panelIndex].reduce((sum, item) => {
                            return parseInt(sum) + parseInt(item.amount)
                        }, 0)
                        this.sendBet(amount, user_choose, panelIndex)
                    }
                }
            } else {

                for(const user_choose in groupByUserChoose) {
                    const amount = groupByUserChoose[user_choose].reduce((sum, item) => {
                        return parseInt(sum) + parseInt(item.amount)
                    }, 0)
                    this.sendBet(amount, user_choose)
                }
            }
        },
        async sendBet(amount, user_choose, user_choose_index = null) {

            this.isOnSendBet = true

            this.countUserNotBetOnRound = 0

            const game = this.currentTable.table_type
            const gbs_id = this.currentGame.gbs_id
            const token = this.pes_profile.token
            
            const data = {
                game, amount, user_choose, gbs_id, token,
            }

            if(user_choose_index) {
                Object.assign(data, {user_choose_index: parseInt(user_choose_index)})
            }

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/game/baccarat/bet`,
                data: data,
            })

            if(res.data.code !== 1) {

                let betMessage = res.data.msg
                if(res.data.msg === 'Balance not enough....') {
                    betMessage = 'ยอดเงินไม่เพียงพอ'
                }
                this.betMessage = betMessage
                this.$modal.show('bet-message-modal')
                
                this.userBetArr = this.userBetArr.filter((userBet) => {
                    return !(parseInt(userBet.user_choose) === parseInt(user_choose) && userBet.state === 'wait')
                })
            } else {
                this.userBetArr.map((userBet) => {
                    if(parseInt(userBet.user_choose) === parseInt(user_choose)) {
                        userBet.state = 'confirm'
                    }
                })
                this.addTicketList(res.data.data)
                this.saveUserBetArr()
            }
            this.clearBet()

            this.isOnSendBet = false
        },
        setBetLimit: debounce(async function(config_id) {

            const loading = this.$loading()

            this.setCurrentBetLimitConfig(config_id)
            this.generatePanelArr()
            
            const table_id = this.currentTable.table_id
            const token = this.pes_profile.token

            const res = await axiosWrapper({
                method: 'post',
                url: `${this.envDomains.VUE_APP_API}/api/game/bet-limit/set`,
                data: {
                    table_id, config_id, token
                }
            })
            if(res.data.data){
                if(res.data.data.balance){
                    this.balanceFromUpdate = res.data.data.balance;
                }
                
            }

            loading.close()

            if(res.data.code !== 1) {
                this.$bvToast.toast(res.data.msg, {
                    title: 'แจ้งเตือน',
                    toaster: 'b-toaster-top-full'
                })
                return
            } else {
                this.$modal.hide('select-bet-limit-modal')
                this.isSetBetLimit = true
            }
        }, 400),
        handleOrientationChange() {
            this.calOrientation()
            this.calVideoPlayerBoundary()
        },
        calOrientation() {
            if(window.orientation === 0) {
                this.orientation = 'portrait'
            } else {
                this.orientation = 'landscape'
            }
        },
        calVideoPlayerBoundary() {

            const IPAD_HEIGHT_RATIO = 0.35
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

            if(window.orientation === 0) { //portrait

                this.videoPlayerWidth = screen.width

                if(screen.width >= 768 && screen.width <= 1024) {
                    this.videoPlayerHeight = parseInt(vh) * IPAD_HEIGHT_RATIO
                } else if(screen.width > 1024) {
                    this.videoPlayerHeight = screen.height
                } else {
                    this.videoPlayerHeight = 250
                }
            } else { //landscape
                if(screen.width >= 1300) {
                    this.videoPlayerWidth = 375
                    this.videoPlayerHeight = parseInt(vh) * IPAD_HEIGHT_RATIO
                }else if(screen.height > screen.width) {
                    this.videoPlayerWidth = screen.height
                    this.videoPlayerHeight = screen.width
                } else {
                    this.videoPlayerWidth = screen.width
                    this.videoPlayerHeight = screen.height
                }   
            }
        },
        toSelectBetLimit() {
            if(!this.is_member) {
                this.notifyToSignUp('เพื่อเล่นเกม')
                return
            }
            this.$modal.show('select-bet-limit-modal')
        },
        toggleTicketPanel() {
            this.$modal.show('ticket-drawer')
        },
        togglePanelBottom() {
            this.is_show_panel_bottom = !this.is_show_panel_bottom
            this.clearOtherBet()
        },
        showSideMenu() {
            this.$modal.show('drawer-right')
        },
        toggleStatePanel() {
            this.panel_to_show = 'state'
            this.clearOtherBet()
        },
        togglePlayPanel() {
            this.panel_to_show = 'play'
            this.clearOtherBet()
        },
        exit() {
            this.$emit('exit')
        },
        goDepositWithdraw() {
            if(!this.is_member) {
                this.notifyToSignUp('เพื่อเติมเงิน')
                return
            }
            this.$router.push({name: 'DepositWithdraw'})
        },
        autoCloseModal(name) {
            setTimeout(() => {
                this.$modal.hide(name)
            }, 1000 * 2)
        },
        startCountDown: debounce(function() {

            this.secondCountDown = this.secondRemain

            if(this.timer) {
                this.timer.start()
                return
            }
            this.timer = Timer(() => {
                if(this.secondCountDown <= 0) {
                    this.stopCountDown()
                    return
                }
                this.secondCountDown--
                return    
            }, 1000)
        }, 100),
        stopCountDown: debounce(function() {
            if(!this.timer) {
                return
            }
            this.timer.stop()
        }, 100),
        toggleLiveMode() {
            if(this.liveMode === '3d') {
                this.liveMode = 'video'
                this.liveModeUserChoose = 'video'
            } else {
                this.liveMode = '3d'
                this.liveModeUserChoose = '3d'
            }
        },
        videoPlayerReadyHandle() {
            if(this.liveModeUserChoose === '3d') {
                return
            }
            this.liveMode = 'video'
        },
        toggleGameSound() {
            this.isGameSoundOn = !this.isGameSoundOn
        },
        playStartBetSound() {
            if(this.isOnDev && this.isGameSoundOn) {
                SOUND['START_BET'][randomBetween(0, SOUND['START_BET'].length - 1)].play()
            }
        },
        playStopBetSound() {
            if(this.isOnDev && this.isGameSoundOn) {
                SOUND['STOP_BET'].play()
            }
        },
        playGameSound(sound) {
            if(!sound) {
                return
            }
            if(this.isOnDev && this.isGameSoundOn) {
                sound.play()
            }
        },
        saveUserBetArr() {
            const confirmBets = [...this.userBetArr].filter((userBet) => {
                return userBet.state === 'confirm'
            })
            const dataToSave = JSON.stringify({
                gbs_id: this.currentGame.gbs_id,
                userBetArr: confirmBets,
            })
            localStorage.setItem(`userBetArr?table_id=${this.tableId}`, dataToSave)
        },
        loadUserBetArr() {
            try {
                if(this.isAlreadyLoadUserBetArr) {
                    return
                }
                this.isAlreadyLoadUserBetArr = true
                const userBetArr = JSON.parse(localStorage.getItem(`userBetArr?table_id=${this.tableId}`))
                if(!userBetArr) {
                    return
                }
                if(this.userBetArr.length > 0) {
                    return
                }
                if(this.isOnDev) {
                    console.log(`loadUserBetArr ${this.currentGame.gbs_id} === ${userBetArr.gbs_id}`)
                }
                if(parseInt(this.currentGame.gbs_id) !== parseInt(userBetArr.gbs_id)) {
                    return
                }
                this.userBetArr = userBetArr.userBetArr
            } catch(err) {
                console.log('GameMixin loadUserBetArr err', err.message)
            }
        },
    },
    create() {
        this.TABLE_TYPE_KEY = TABLE_TYPE_KEY
    },
    mounted() {
        this.gameSocketInit()
        this.loadTableGame()
        this.calVideoPlayerBoundary()
        this.calOrientation()
        window.addEventListener("orientationchange", this.handleOrientationChange)
        // this.$modal.show('go-lobby-modal')
        soundLoad()
    },
    beforeDestroy() {
        this.gameSocketDestoy()
        store.commit('updateNeedUserReloadWithForceRefreshBalance', true)
        window.removeEventListener("orientationchange", this.handleOrientationChange)
    }
}
</script>