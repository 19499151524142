<template>

    <div class="wrapContentIndex LottoNumber LottoNumberHome wrapContentIndexLotto">
        <div class="bx-mobile">
            <div class="wrapContentIndexTitle ">
                <button style="padding: 0 10px;" class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i>
                </button>
                <h3>หวย</h3>
            </div>
        </div>
        <div class="bx-desktop">
            <div class="wrapContentIndexTitle">
                <h3>หวย</h3>
            </div>
        </div>

        <div class="navTopDesktop">
            <MenuNavTop
                    @showMenuNav="showMenuNav"
                    @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
            ></MenuNavTop>
        </div>
        <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexLeft active">
                    <MenuLeft></MenuLeft>
                </div>
                <div class="contentFlexIndexRight">
                    <div class="container">

                        <div class="boxHitecCasino boxGovernmentLottery">
                            <div class="nav-menu-topic topicGovernmentLottery">
                                <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">
                                <div class="group">
                                    <h4>สลากกินแบ่งรัฐบาล</h4>
                                    <span>งวดวันที่ {{date_result}}</span>
                                </div>
                            </div>
                            <div class="LottoNumberContent">
                                <div class="LottoNumberList LottoNumberListSearch">

                                    <div class="LottoNumberListTopic">
                                        <h4><i class="fas fa-search"></i> ค้นหาเลขเด็ด</h4>
                                    </div>
                                    <div class="LottoNumberListContent"  @click="goToSearch()">
                                        <div class="LottoNumberListSearchLink"></div>
                                        <div class="listBoxSearch">
                                            <ul>
                                                <li><input type="text" class="form-control" placeholder="1"></li>
                                                <li><input type="text" class="form-control" placeholder="2"></li>
                                                <li><input type="text" class="form-control" placeholder="3"></li>
                                                <li><input type="text" class="form-control" placeholder="4"></li>
                                                <li><input type="text" class="form-control" placeholder="5"></li>
                                                <li><input type="text" class="form-control" placeholder="6"></li>
                                            </ul>
                                        </div>
                                        <!--                                        <button class="btn">ค้นหาเลขเด็ด</button>-->
                                    </div>
                                    <div class="boxResultSimple" v-if="false">
                                        <div class="title">
                                            <h3>ผลการออกรางวัลสลากฯ</h3>
                                            <p>งวดวันที่ 12/12/2565</p>
                                        </div>
                                        <div class="content">
                                            <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                <div class="resultLottoCell">
                                                    <span>รางวัลที่1</span>
                                                    <strong>121789</strong>
                                                </div>
                                                <div class="resultLottoCell">
                                                    <span>รางวัลเลขท้าย 2 ตัว</span>
                                                    <strong>64</strong>
                                                </div>
                                            </div>
                                            <div class="resultLottoCellGroup">
                                                <div class="resultLottoCell">
                                                    <span>รางวัลเลขหน้า 3 ตัว</span>
                                                    <strong>121 789</strong>
                                                </div>
                                                <div class="resultLottoCell">
                                                    <span>รางวัลเลขท้าย 3 ตัว</span>
                                                    <strong>121 789</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <!--                                        <div class="footer">-->
                                        <!--                                            <p>ดูผลออกรางวัลทั้งหมด</p>-->
                                        <!--                                        </div>-->
                                    </div>
                                    <div class="box-list-home-list">
                                        <div class="box-list-home box-list-home-bill" @click="goToBillList()">
                                            <div class="box-menu-index">
                                                <img src="/img/icon-lotto-bill.3f1806a1.png">
                                                <span>สลากของฉัน</span>
                                            </div>
                                        </div>
                                        <div class="box-list-home box-list-home-result" @click="goToResultList()">
                                            <div class="box-menu-index">
                                                <img src="/img/icon-lotto-result.a2b42684.png">
                                                <span>ผลออกรางวัล</span></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="containerSubAllIndex">
                            <div class="infoSubAllIndex">
                                <div class="boxHitecCasino">
                                    <div class="nav-menu-topic topicGovernmentLottery">
                                        <img class="lotto-type-icon" src="@/assets/images/iconLottoSide.png">
                                        <div class="group">
                                            <h4>หวยเบอร์</h4>
                                            <!--                                    <span>งวดวันที่ 12/12/2565</span>-->
                                        </div>
                                        <div class="btn" @click="goToBetList()">ทั้งหมด</div>
                                    </div>
                                    <div class="LottoNumberContent">
                                        <div class="noneLottoList" v-if="resut_gold_number_list.length==0">
                                            <span>ยังไม่มีรายการ</span>
                                        </div>
                                        <div class="LottoNumberContentGroup"
                                             v-for="(gameList,game_index) of resut_gold_number_list" :key="game_index">
                                            <div class="LottoNumberList" v-for="(game,g_id) of gameList" :key="g_id"
                                                 @click="goToBet(game.gbs_id)">
                                                <div class="LottoNumberListTopic">
                                                    <div class="LottoNumberListTopicInfo">
                                                        <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                        <h4>{{ title_game_type[game.game_type]}}</h4>
                                                    </div>
                                                    <span>กระดานที่ {{ game.round_main }}</span>
                                                </div>
                                                <div class="LottoNumberListContent">
                                                    <div class="LNListContentLeft">
                                                        <small>งวดวันที่ {{ showDate(game.date)}}</small>
                                                        <ul>
                                                            <li v-if="game.game_type==0">ถูก 2 ตัวล่าง รับ {{
                                                                game.reward_price }}
                                                            </li>
                                                            <!-- <li>ถูก 2 ตัวล่างรับ 350</li>
                                                            <li>ถูก 2 ตัวบนรับ 350</li> -->
                                                        </ul>
                                                        <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                    </div>
                                                    <div class="LNListContentRight">
                                                        <small>เบอร์ละ</small>
                                                        <strong>{{ game.price }}</strong>
                                                        <span>บาท</span>
                                                        <ul>
                                                            <li class="LNDay">{{ (game.days>0? game.days+' วัน ': '' )
                                                                }}
                                                            </li>
                                                            <li>{{ game.hours }}:{{ game.minutes }}:{{ game.seconds }}
                                                            </li>
                                                        </ul>
                                                        <label>ปิดรับ {{ showEndTime(game.end_datetime) }}</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <!-- <div class="LottoNumberContentGroup">
                                            <div class="LottoNumberList">
                                                <div class="LottoNumberListTopic">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                    <h4>หวยเบอร์ทองเวียดนาม</h4>
                                                </div>
                                                <div class="LottoNumberListContent">
                                                    <div class="LNListContentLeft">
                                                        <small>งวดวันที่ 12/12/2565</small>
                                                        <ul>
                                                            <li>ถูก 2 ตัวล่างรับ 350</li>
                                                            <li>ถูก 2 ตัวบนรับ 350</li>
                                                        </ul>
                                                        <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                    </div>
                                                    <div class="LNListContentRight">
                                                        <small>เบอร์ละ</small>
                                                        <strong>10</strong>
                                                        <span>บาท</span>
                                                        <ul>
                                                            <li class="LNDay">5วัน</li>
                                                            <li>12:33:55</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="LottoNumberList">
                                                <div class="LottoNumberListTopic">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                    <h4>หวยเบอร์ทองเวียดนาม</h4>
                                                </div>
                                                <div class="LottoNumberListContent">
                                                    <div class="LNListContentLeft">
                                                        <small>งวดวันที่ 12/12/2565</small>
                                                        <ul>
                                                            <li>ถูก 2 ตัวล่างรับ 350</li>
                                                            <li>ถูก 2 ตัวบนรับ 350</li>
                                                        </ul>
                                                        <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                    </div>
                                                    <div class="LNListContentRight">
                                                        <small>เบอร์ละ</small>
                                                        <strong>10</strong>
                                                        <span>บาท</span>
                                                        <ul>
                                                            <li class="LNDay">5วัน</li>
                                                            <li>12:33:55</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="LottoNumberList">
                                                <div class="LottoNumberListTopic">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                    <h4>หวยเบอร์ทองเวียดนาม</h4>
                                                </div>
                                                <div class="LottoNumberListContent">
                                                    <div class="LNListContentLeft">
                                                        <small>งวดวันที่ 12/12/2565</small>
                                                        <ul>
                                                            <li>ถูก 2 ตัวล่างรับ 350</li>
                                                            <li>ถูก 2 ตัวบนรับ 350</li>
                                                        </ul>
                                                        <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                    </div>
                                                    <div class="LNListContentRight">
                                                        <small>เบอร์ละ</small>
                                                        <strong>10</strong>
                                                        <span>บาท</span>
                                                        <ul>
                                                            <li class="LNDay">5วัน</li>
                                                            <li>12:33:55</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberContentGroup">
                                            <div class="LottoNumberList">
                                                <div class="LottoNumberListTopic">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                    <h4>หวยเบอร์ทองเวียดนาม</h4>
                                                </div>
                                                <div class="LottoNumberListContent">
                                                    <div class="LNListContentLeft">
                                                        <small>งวดวันที่ 12/12/2565</small>
                                                        <ul>
                                                            <li>ถูก 2 ตัวล่างรับ 350</li>
                                                            <li>ถูก 2 ตัวบนรับ 350</li>
                                                        </ul>
                                                        <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                    </div>
                                                    <div class="LNListContentRight">
                                                        <small>เบอร์ละ</small>
                                                        <strong>10</strong>
                                                        <span>บาท</span>
                                                        <ul>
                                                            <li class="LNDay">5วัน</li>
                                                            <li>12:33:55</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="LottoNumberList">
                                                <div class="LottoNumberListTopic">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                    <h4>หวยเบอร์ทองเวียดนาม</h4>
                                                </div>
                                                <div class="LottoNumberListContent">
                                                    <div class="LNListContentLeft">
                                                        <small>งวดวันที่ 12/12/2565</small>
                                                        <ul>
                                                            <li>ถูก 2 ตัวล่างรับ 350</li>
                                                            <li>ถูก 2 ตัวบนรับ 350</li>
                                                        </ul>
                                                        <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                    </div>
                                                    <div class="LNListContentRight">
                                                        <small>เบอร์ละ</small>
                                                        <strong>10</strong>
                                                        <span>บาท</span>
                                                        <ul>
                                                            <li class="LNDay">5วัน</li>
                                                            <li>12:33:55</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="LottoNumberList">
                                                <div class="LottoNumberListTopic">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                    <h4>หวยเบอร์ทองเวียดนาม</h4>
                                                </div>
                                                <div class="LottoNumberListContent">
                                                    <div class="LNListContentLeft">
                                                        <small>งวดวันที่ 12/12/2565</small>
                                                        <ul>
                                                            <li>ถูก 2 ตัวล่างรับ 350</li>
                                                            <li>ถูก 2 ตัวบนรับ 350</li>
                                                        </ul>
                                                        <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                    </div>
                                                    <div class="LNListContentRight">
                                                        <small>เบอร์ละ</small>
                                                        <strong>10</strong>
                                                        <span>บาท</span>
                                                        <ul>
                                                            <li class="LNDay">5วัน</li>
                                                            <li>12:33:55</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                    </div>

                                </div>
                            </div>
                            <div class="AllIndexLottoOnline">
                                <div class="nav-menu-topic topicGovernmentLottery">
                                    <img class="lotto-type-icon" src="@/assets/images/icon/set-icon/icon-lotto3.png">
                                    <div class="group">
                                        <h4>หวยออนไลน์</h4>
                                    </div>
                                    <div class="btn" @click="gotToLotto2()">ทั้งหมด</div>
                                </div>

                                <div class="container-lotto container-lotto-mobile">
                                    <div class="box-list-home-main">

                                    </div>
                                    <div class="wrap-content-home">
                    <!--                    bx-desktop-->
                                        <div class="container-lotto container-lotto-mobile  bx-mobile bx-ipad">
                                            <div class="PriceTable card" v-for="(lottoType, key) of lottoTypeArr" :key="key">
                                                <PriceTableNew :lotto_type="lottoType" />
                                            </div>
                                            
                                        </div>
                                        <div class="container-lotto  bx-desktop bx-ipad">
                                            <carousel
                                                    :perPage="1.2"
                                                    :mouseDrag="true"
                                                    :spacePadding="5"
                                                    :navigationEnabled="true"
                                                    :paginationEnabled="false"
                                                    navigationNextLabel="❯"
                                                    navigationPrevLabel="❮"
                                            >
                                                <slide v-for="(lottoType, key) of lottoTypeArr" :key="key" class="PriceTable card">
                                                    <PriceTable :lotto_type="lottoType" :lotto_type_list="['sam_tua_bon','song_tua_bon']" />
                                                </slide>
                                            </carousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="navButtonMobile">
                <MenuNav
                        @showMenuNav="showMenuNav"
                        @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
                ></MenuNav>
            </div>
        </div>
    </div>
</template>
<script>
    import axiosWrapper from '@/axiosWrapper'
    import store from '@/store'
    import moment from 'moment';
    import MenuBar from "@/components/MenuBar";
    import MenuLeft from "@/components/MenuLeft";
    import MenuNav from "@/components/MenuNav";
    import MenuNavTop from "@/components/MenuNavTop";
    import PriceTable from "@/components/lotto/components/PriceTable";
    import PriceTableNew from "@/components/lotto/components/PriceTableNew";
    import { Carousel, Slide } from 'vue-carousel'
    import {LOTTO_TYPE} from '@/components/lotto/Constant.js'


    export default {
        name: 'LottoNumber',
        components: {
            MenuBar,
            MenuLeft,
            MenuNav,
            MenuNavTop,
            PriceTable,
            PriceTableNew,
            Carousel,
            Slide,
        },
        computed: {

            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
        },
        data() {
            return {
                lottoTypeArr: [],
                showChatWithAdminNumber: 0,
                showDepositWithdrawNumber: 0,
                menuNumber: 0,
                'resut_gold_number_list': [],
                'title_game_type': [
                    'หวยเบอร์ทองรัฐบาล'
                ],
                date_result: '',
            }
        },
        methods: {
            goPage(page) {

                if(page === 'bill') {
                    if(!this.is_member){
                        this.notifyToSignUp('เพื่อดูโพยหวย')
                        return
                    }
                }
                store.commit('lotto/updateCurrentPage', page)
            },
            goAppPage(name) {
                if(!this.is_member) {
                    this.notifyToSignUp()
                    return
                }
                this.$router.push({name})
            },
            showDate(date) {

                let moment_datetime = moment(date).format('DD/MM/YYYY');
                let date_array = moment_datetime.split("/");
                return date_array[0] + '/' + date_array[1] + '/' + (parseInt(date_array[2]) + 543)
            },
            getDateResult() {
                let day = moment().format('D');
                // let hour = moment().format('H');
                if (day == 1) {
                    this.date_result = moment().format('01/MM/YYYY');
                    // if(hour<12){
                    //     this.date_result = moment().format('01/MM/YYYY');
                    // }else{
                    //     this.date_result = moment().format('16/MM/YYYY');
                    // }

                } else if (day == 16) {
                    this.date_result = moment().format('16/MM/YYYY');
                    // if(hour){

                    // }else{

                    // }

                } else if (day < 16) {
                    this.date_result = moment().format('16/MM/YYYY');

                } else {
                    this.date_result = moment().add(1, 'months').format('01/MM/YYYY');
                }
                // console.log('yes', moment().format('DD/MM/YYYY'))
                let date_array = this.date_result.split("/");
                this.date_result = date_array[0] + '/' + date_array[1] + '/' + (parseInt(date_array[2]) + 543)

            },
            showChatWithAdmin() {
                this.showChatWithAdminNumber = 1;
                let self = this;
                setTimeout(() => {
                    self.showChatWithAdminNumber = 0;
                }, 100);
            },
            showEndDay(datetime) {

                let day = moment(datetime).diff(moment(), 'days');
                // a.diff(b, 'days') 
                return (day > 0 ? day + ' วัน' : '');

            },
            showEndTime(datetime) {
                let time = moment(datetime).format('HH:mm');
                return time;
            },
            showDepositWithdrawModal() {
                this.showDepositWithdrawNumber = 1;
                let self = this;
                setTimeout(() => {
                    self.showDepositWithdrawNumber = 0;
                }, 100);

            },

            showMenuNav(menuNumber) {
                this.menuNumber = menuNumber;
                let self = this;
                setTimeout(() => {
                    self.menuNumber = 0;
                }, 1000);
            },
            goToSearch() {
                if (this.date_result) {
                    this.$router.push({'name': 'LottoNumberSearch'})
                } else {
                    alert('ยังไม่มีฉลากงวดใหม่เปิดขาย');
                }

            },
            goHome() {
                this.$router.push({'name': 'Index'})
            },
            gotToLotto2(){
                this.$router.push({'name': 'IndexLotto2'})
            },
            goToBillList() {
                this.$router.push({'name': 'LottoNumberBill'})
            },
            goToBetList() {
                this.$router.push({'name': 'LottoNumberList'})
            },
            goToResultList() {
                // this.$router.push({'name': 'LottoNumberResultGovernment'})
                this.$router.push({'name': 'LottoNumberResultGovernmentAll'})
            },
            goToBet(gbs_id) {
                this.$router.push({'name': 'LottoNumberBetDetail', params: {gbs_id: gbs_id}})
            },
            async getGoldNumberList() {

                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gold_number/get?token=` + this.token + '&game_status=1&game_type=0&date=',
                    data: {}
                })
                this.resut_gold_number_list = []
                let new_list = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i].game_status == 1) {
                        res.data.data[i].days = 0;
                        res.data.data[i].hours = 0;
                        res.data.data[i].minutes = 0;
                        res.data.data[i].seconds = 0;
                        new_list.push(res.data.data[i]);

                        if (!this.date_result) {
                            let first_data = res.data.data[i];
                            // console.log('first',first_data);
                            let date_array = moment(first_data.date).format('YYYY-MM-DD').split("-");

                            this.date_result = date_array[2] + '/' + date_array[1] + '/' + (parseInt(date_array[0]) + 543)

                        }

                    }
                }
                for (let i = 0; i < new_list.length; i++) {
                    if (i == 0) {
                        this.resut_gold_number_list.push([]);
                    }else if(i>2){
                        break;
                    }
                    let index = this.resut_gold_number_list.length - 1;
                    this.resut_gold_number_list[index].push(new_list[i])

                }

                // if(res.data.data.length>0){
                //     let first_data = res.data.data[0];
                //     let date_array = moment(first_data.date).format('YYYY-MM-DD').split("-");

                //     this.date_result = date_array[2]+'/'+date_array[1]+'/'+(parseInt(date_array[0])+543)
                // }else{
                //     //
                // }

            },
            updateRemaining(distance, index1, index2) {
                this.resut_gold_number_list[index1][index2].days = Math.floor(distance / (1000 * 60 * 60 * 24))
                this.resut_gold_number_list[index1][index2].hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                this.resut_gold_number_list[index1][index2].minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                this.resut_gold_number_list[index1][index2].seconds = Math.floor((distance % (1000 * 60)) / 1000)
                // console.log(this.resut_gold_number_list[index1][index2])

            },

            tick(index1, index2) {
                const currentTime = new Date()
                const distance = Math.max(Date.parse(this.resut_gold_number_list[index1][index2].end_datetime) - currentTime, 0)
                this.updateRemaining(distance, index1, index2)

                // if (distance === 0) {
                //     clearInterval(this.timer)
                //     this.isEnded = true
                // }
            },
            setTick() {
                for (let index1 = 0; index1 < this.resut_gold_number_list.length; index1++) {
                    for (let index2 = 0; index2 < this.resut_gold_number_list[index1].length; index2++) {
                        this.tick(index1, index2)
                    }
                }
            },
            async loadPriceTable(){
            const res = axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/price/load_table`,
                data: {
                    no_round: 'yes'
                }
            })
            if(res.status === 200) this.price_table = res.data
        },
        async loadRewardLimit(){
            const res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/reward/get-all`
            })
            if(res.status === 200) this.reward_limit = res.data
        }
        },
        created() {
            for(const key in LOTTO_TYPE) {
                this.lottoTypeArr.push(key);
            }
        },
        async mounted() {
            this.loadPriceTable()
            this.loadRewardLimit()
            await this.getGoldNumberList();
            this.setTick();
            setInterval(this.setTick.bind(this), 1000)

            // this.getDateResult();


            // this.timer = setInterval(this.tick.bind(this), 1000)

        }
    }
</script>
<style>
    .wrapContentIndexLotto .active .itemList li.item.itemLotto{
        background-color: white;
        border: solid 1px rgba(0, 0, 0, 0.84);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    }
    .wrapContentIndexLotto .active .itemList li.item.itemLotto p,
    .wrapContentIndexLotto .active .itemList li.item.itemLotto span{color: black}

    /*Theme*/
    .themeBlackGold .wrapContentIndexLotto .active .itemList li.item.itemLotto {
        background-image: linear-gradient(to bottom, #fec225 0%, #e19d0e 100%) !important;
        background-color: transparent;
        border: solid 1px rgba(254, 194, 37, 0.84);
        box-shadow: 0 5px 10px rgba(254, 194, 37, 0.3);
    }
    .themeDarkGray .wrapContentIndexLotto .active .itemList li.item.itemLotto {
        background-color: #5f646a!important;
        border: solid 1px rgba(95, 100, 106, 0.84);
        box-shadow: 0 5px 10px rgba(59, 59, 59, 0.3);
    }
    .themeDarkGray .wrapContentIndexLotto .active .itemList li.item.itemLotto p,
    .themeDarkGray .wrapContentIndexLotto .active .itemList li.item.itemLotto span {
        color: #fff!important;
    }
    .themeLightGray .wrapContentIndexLotto .active .itemList li.item.itemLotto {
        background-color: #edeef5 !important;
        border: solid 1px rgb(232, 232, 232) !important;
        box-shadow: none;
    }
    .themeLightGray .wrapContentIndexLotto .active .itemList li.item.itemLotto p,
    .themeLightGray .wrapContentIndexLotto .active .itemList li.item.itemLotto span {
        color: #222!important;
    }
</style>