<template>

    <div class="wrapContentIndex wrapContentIndexLotto wrapContentIndexLottoBet">
        <div class="wrapContentIndexTitle">
            <button style="padding: 0 10px;" class="btn TitleBtnBack" @click="goHome()"><i class="fas fa-chevron-left"></i></button>
            <h3>แทงหวย</h3>
            <MenuBar  :callMenu="menuNumber" :goHome="is_go_home"></MenuBar>
        </div>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexLeft active">
                    <MenuLeft></MenuLeft>
                </div>
                <div class="contentFlexIndexRight">
                    <!--                    <div class="contentFlexIndexRightInfo">-->
                    <div class="container">
                        <div class="containerGameListinfo" >
                            <Bet></Bet>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import Bet from '@/components/lotto/Bet'
import MenuBar from '@/components/MenuBar'

// import MenuNavTop from '@/components/MenuNavTop'
import MenuLeft from '@/components/MenuLeft'


export default {
    name: 'ViewBet',
    components: {
        Bet,
        MenuBar,
        MenuLeft,
        // MenuNavTop,
    },
    data(){
        return {
            menuNumber :0,
            is_go_home : '',
        }
    },
    methods: {
        showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
        },
        goHome() {
            this.is_go_home='Index';
            let self = this;
            setTimeout(()=>{
                self.is_go_home='';
            },1000)
            // this.$router.push({name: 'IndexLotto'})
        },
    }
}
</script>
<style>
    .ViewBetLotto .MenuBar.box-all-games{display: none!important}
    .ViewBetLotto .topic-header-page{display: none}
    .ViewBetLotto .box-txt{display: none}
    .ViewBetLotto span.box-txt-back{display: none}
    .ViewBetLotto .box-menu-top{float: right}
    .ViewBetLotto .wrap-menu-top{height: 45px; z-index: 1}
    .ViewBetLotto .box-nav-menu{padding-top: 0}
    .ViewBetLotto .box-advertise-game-bottom {bottom: 80px;}
    .ViewBetLotto .VueCarousel-navigation-button{z-index: 0;}
    @media only screen
    and (max-device-width: 1300px)
    and (orientation: landscape){
        /*.ViewBetLotto .MenuBar.box-all-games{display: block!important}*/
        /*.ViewBetLotto .box-menu-top{*/
        /*    right: 0!important;*/
        /*    left: auto!important;*/
        /*}*/
    }
</style>
<style scoped>
    .ViewBetLotto{
        background-image: linear-gradient(
                135deg
                , #F372D4 10%, #c765f0 100%);
        /*height: 100vh;*/
        /*overflow-y: scroll;*/
    }
    .ViewBetLotto .wrap-menu-top{
        padding: 10px 5px 10px 5px;
        background-image: none;
    }
    @media only screen
    and (max-device-width: 1300px)
    and (orientation: landscape){
        /*.ViewBetLotto{*/
        /*    padding: 0 5px 0 355px;*/
        /*}*/


    }

</style>

<style>
    .wrapContentIndexLotto .active .itemList li.item.itemLotto{
        background-color: white;
        border: solid 1px rgba(0, 0, 0, 0.84);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    }
    .wrapContentIndexLotto .active .itemList li.item.itemLotto p,
    .wrapContentIndexLotto .active .itemList li.item.itemLotto span{color: black}
</style>