<template>

    <div class="wrapContentIndex LottoNumber LottoNumberResult">
        <div class="wrapContentIndexTitle ">
            <div class="IndexTitleInto">
                <button style="padding: 0 10px;" class="btn " @click="goToIndex()"><i class="fas fa-chevron-left"></i></button>
                <h3>ผลการออกรางวัล</h3>
            </div>
        </div>
        <div class="wrapContentIndexInfo wrapContentIndexInfoResultGovernment">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="container">
                        <div class="boxHitecCasino boxGovernmentLottery">
                            <div class="LottoNumberContent">
                                <div class="LottoNumberList topic">
                                    <div class="boxResultSimple" v-for="(result,key) of lotto_result_list" :key="key">
                                        <div class="nav-menu-topic topicGovernmentLottery">
                                            <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">
                                            <div class="group">
                                                <h4>ผลการออกรางวัลสลากฯ</h4>
                                                <span>งวดวันที่ {{ showDateResult(result.date) }}</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                <div class="resultLottoCell OneBigLotto">
                                                    <strong class="text-red">{{ result.lotto_reward_type_1[0] }}</strong>
                                                    <p>รางวัลที่1</p>
                                                </div>
<!--                                                resultLottoCellGroupSmall-->
                                                <div class="resultLottoCell MiniBigLotto">
                                                        <strong>
                                                            <span>{{ result.lotto_reward_type_f3[0] }}</span>
                                                            <span>{{ result.lotto_reward_type_f3[1] }}</span>
                                                        </strong>
                                                        <p>รางวัลเลขหน้า 3 ตัว</p>
                                                    </div>
                                                <div class="resultLottoCell MiniBigLotto">
                                                        <strong>
                                                            <span>{{ result.lotto_reward_type_p3[0] }}</span>
                                                            <span>{{ result.lotto_reward_type_p3[1] }}</span>
                                                        </strong>
                                                        <p>รางวัลเลขท้าย 3 ตัว</p>
                                                    </div>
                                                <div class="resultLottoCell TwoResult">
                                                    <strong>{{ result.lotto_reward_type_p2[0] }}</strong>
                                                    <p>รางวัลเลขท้าย 2 ตัว</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="footer" @click="goLottoGovAll()">
                                            <p>ผลรางวัลย้อนหลัง</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="LottoNumberContentBack ResultNumLotto">
                                    <div class="nav-menu-topic topicGovernmentLottery">
                                        <img class="lotto-type-icon" src="@/assets/images/iconLottoSide.png">
                                        <div class="group">
                                            <h4>ผลออกรางวัลหวยเบอร์</h4>
                                        </div>
                                    </div>
                                    <div class="LottoNumberListGroup LottoNumberResultGroup">
                                        <div class="LottoNumberList" v-for="(result,key) of gold_number_result_list" :key="key">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                    <div class="group">
                                                        <h4>หวยไทย</h4>
                                                        <span>งวดวันที่ {{ showDateResult(result.date) }}</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        
                                                        <div v-if="result.game_type==0" class="resultLottoCell">
                                                            <strong>{{ JSON.parse(result.game_result).rp3 }}</strong>
                                                            <span>3 ตัวบน</span>
                                                        </div>
                                                        <div class="resultLottoCell"></div>
                                                        <div class="resultLottoCell">
                                                            <strong>{{ JSON.parse(result.game_result).rp2 }}</strong>
                                                            <span>2 ตัวล่าง</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="LottoNumberList">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/VN.png">
                                                    <div class="group">
                                                        <h4>หวยฮานอย</h4>
                                                        <span>งวดวันที่ 12/12/2565</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>789</strong>
                                                            <span>เลขท้าย 3 ตัว</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>64</strong>
                                                            <span>เลขท้าย 2 ตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/VN.png">
                                                    <div class="group">
                                                        <h4>หวยฮานอย</h4>
                                                        <span>งวดวันที่ 12/12/2565</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>789</strong>
                                                            <span>เลขท้าย 3 ตัว</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>64</strong>
                                                            <span>เลขท้าย 2 ตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/VN.png">
                                                    <div class="group">
                                                        <h4>หวยฮานอย</h4>
                                                        <span>งวดวันที่ 12/12/2565</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>789</strong>
                                                            <span>เลขท้าย 3 ตัว</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>64</strong>
                                                            <span>เลขท้าย 2 ตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>


                                <div class="LottoNumberContentBack ResultNumLotto">
                                    <div class="nav-menu-topic topicGovernmentLottery">
                                        <img class="lotto-type-icon" src="@/assets/images/icon/set-icon/icon-lotto3.png">
                                        <div class="group">
                                            <h4>ผลออกรางวัลหวยออนไลน์</h4>
                                        </div>
                                    </div>
                                    <div class="LottoNumberListGroup LottoNumberResultGroup" >
                                        <div class="LottoNumberList" v-for="lotto_type of LOTTO_TYPE_LIST" :key="lotto_type">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <LottoTypeIcon :lotto_type="lotto_type"></LottoTypeIcon>
                                                    <!-- <img class="lotto-type-icon" src="@/assets/images/flag/VN.png"> -->
                                                    <div class="group">
                                                        <h4>{{lotto_type_list_str[lotto_type]}}</h4>
                                                        <span v-if="other_lotto_result_list[lotto_type]"> ประจำงวด : {{other_lotto_result_list[lotto_type].round.date}}
                                                            {{(other_lotto_result_list[lotto_type].round.sub_round) ? 'รอบที่ ' + other_lotto_result_list[lotto_type].round.sub_round : ''}}
                                                         </span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>{{get_result_str(lotto_type, 'sam_tua_bon')}}</strong>
                                                            <span>3 ตัวบน</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>{{get_result_str(lotto_type, 'song_tua_bon')}}</strong>
                                                            <span>2 ตัวบน</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="LottoNumberList">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/VN.png">
                                                    <div class="group">
                                                        <h4>หวยฮานอย</h4>
                                                        <span>งวดวันที่ 12/12/2565</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>789</strong>
                                                            <span>เลขท้าย 3 ตัว</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>64</strong>
                                                            <span>เลขท้าย 2 ตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/VN.png">
                                                    <div class="group">
                                                        <h4>หวยฮานอย</h4>
                                                        <span>งวดวันที่ 12/12/2565</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>789</strong>
                                                            <span>เลขท้าย 3 ตัว</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>64</strong>
                                                            <span>เลขท้าย 2 ตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/VN.png">
                                                    <div class="group">
                                                        <h4>หวยฮานอย</h4>
                                                        <span>งวดวันที่ 12/12/2565</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>789</strong>
                                                            <span>เลขท้าย 3 ตัว</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>64</strong>
                                                            <span>เลขท้าย 2 ตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="boxResultSimple">
                                                <div class="nav-menu-topic topicGovernmentLottery">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/VN.png">
                                                    <div class="group">
                                                        <h4>หวยฮานอย</h4>
                                                        <span>งวดวันที่ 12/12/2565</span>
                                                    </div>
                                                </div>
                                                <div class="content">
                                                    <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                        <div class="resultLottoCell">
                                                            <strong>789</strong>
                                                            <span>เลขท้าย 3 ตัว</span>
                                                        </div>
                                                        <div class="resultLottoCell">
                                                            <strong>64</strong>
                                                            <span>เลขท้าย 2 ตัว</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import axiosWrapper from '@/axiosWrapper'
    import store from '@/store'
    import moment from 'moment';
    import LottoTypeIcon from '@/components/lotto/components/LottoTypeIcon'
    import {LOTTO_TYPE} from '@/components/lotto/Constant'
    export default {
        name: 'LottoNumberResultGovernment',
        components: {
            LottoTypeIcon,
        },
        computed: {
           
           pes_profile() {
               return store.state.pes_profile
           },
           token() {
               return this.pes_profile.token
           },
           
       },
       data (){
            return {
                lotto_result_list  : [],
                gold_number_result_list : [],
                other_lotto_result_list:[],
                LOTTO_TYPE_LIST: [
                    // 'yeekee',
                    // 'government',
                    'hanoy',
                    'lao',
                    'malay',
                ],
                lotto_type_list_str:[],
            }
        },
        methods: {
            
            goToIndex() {
                if(this.envDomains.VUE_APP_SITE_TYPE=='lotto'){
                    this.$router.push({'name':'Index'})
                }else{
                    this.$router.push({'name':'LottoNumber'})
                }
            },
            goLottoGovAll(){
                this.$router.push({'name':'LottoNumberResultGovernmentAll'})
            },
            async getLottoResultList(){
                let limit = 1;
                let page = 1;
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gov_lotto/get-result-list?token=${this.token}&limit=${limit}&game_type=&page=${page}`,
                    data: {
                        
                    }
                })
                this.lotto_result_list =res.data.data;
                for(let i=0;i<this.lotto_result_list.length;i++){
                    this.lotto_result_list[i].lotto_reward_type_1 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_1);
                    this.lotto_result_list[i].lotto_reward_type_2 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_2);
                    this.lotto_result_list[i].lotto_reward_type_3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_3);
                    this.lotto_result_list[i].lotto_reward_type_4 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_4);
                    this.lotto_result_list[i].lotto_reward_type_5 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_5);
                    this.lotto_result_list[i].lotto_reward_type_f3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_f3);
                    this.lotto_result_list[i].lotto_reward_type_p3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_p3);
                    this.lotto_result_list[i].lotto_reward_type_n1 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_n1);
                    this.lotto_result_list[i].lotto_reward_type_p2 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_p2);
                }
            },
            async getGoldNumberResultList(lotto_type){
                let limit = 1;
                let page = 1;
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gold_number/get-result-list?token=${this.token}&limit=${limit}&game_type=${lotto_type}&page=${page}`,
                    data: {
                        
                    }
                })
                this.gold_number_result_list =res.data.data;
                // for(let i=0;i<this.gold_number_result_list.length;i++){
                //     this.gold_number_result_list[i].lotto_reward_type_1 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_1);
                //     this.gold_number_result_list[i].lotto_reward_type_2 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_2);
                //     this.gold_number_result_list[i].lotto_reward_type_3 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_3);
                //     this.gold_number_result_list[i].lotto_reward_type_4 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_4);
                //     this.gold_number_result_list[i].lotto_reward_type_5 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_5);
                //     this.gold_number_result_list[i].lotto_reward_type_f3 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_f3);
                //     this.gold_number_result_list[i].lotto_reward_type_p3 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_p3);
                //     this.gold_number_result_list[i].lotto_reward_type_n1 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_n1);
                //     this.gold_number_result_list[i].lotto_reward_type_p2 = JSON.parse(this.gold_number_result_list[i].lotto_reward_type_p2);
                // }
            },
            showDateResult(date){
                
                let moment_datetime=moment(date).format('DD/MM/YYYY');
                let date_array =moment_datetime.split("/");
                return date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)
            },
            async get_all_lotto_type_latest() {
                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_API}/api/result/get_all_lotto_type_latest`,
                });
                if (res.status === 200) {
                    this.other_lotto_result_list = res.data;
                } else {
                    // this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
                }
            },
           
            get_result_str: function (lotto_type, result_type) {

                try {

                    if(!this.other_lotto_result_list) {
                        return '-';
                    }
                    if(!this.other_lotto_result_list[lotto_type]) {
                        return '-';
                    }
                    if(!this.other_lotto_result_list[lotto_type][result_type]) {
                        return '-';
                    }

                    return this.other_lotto_result_list[lotto_type][result_type];

                } catch (err) {

                    console.log('get_result_str err', err.message);
                    return '-';
                }
            },
        },
        created() {
            this.LOTTO_TYPE_LIST = [];
            for(const key in LOTTO_TYPE) {
                
                // if(key === 'yeekee' || key==='government') {
                //     continue;
                // }
                this.LOTTO_TYPE_LIST.push(key);
            }
        },
        async mounted() {
            this.lotto_type_list_str = LOTTO_TYPE;
            await this.getLottoResultList();
            await this.getGoldNumberResultList(0);
            await this.get_all_lotto_type_latest();
            
        }
    }
</script>