<template>
  <div
    class="wrapContentIndex wrapContentIndexCasino wrapContentIndexCasino2"
    :class="{ wrapContentAllInto: currentProvider }"
  >
    <div class="contentOverlay" :class="{ popupProvider: isHideProvider }">
      <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
      <ProviderList @openGameList="ClosePopupProvider"></ProviderList>
    </div>
    <FloatButtomRightPanel :callChatWithAdmin="showChatWithAdminNumber" />
    <div class="wrapContentIndexTitle">
      <div class="hamburger-lines" @click="OpenListProvider()">
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
      <h3>คาสิโน</h3>
    </div>
    <div class="navTopDesktop">
      <MenuNavTop
        @showMenuNav="showMenuNav"
        @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
      ></MenuNavTop>
    </div>
    <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"> </MenuBar>
    <div class="wrapContentIndexInfo">
      <div class="contentFlexIndex">
        <div class="contentFlexIndexLeft active">
          <MenuLeft></MenuLeft>
        </div>
        <div class="contentFlexIndexRight">
          <!--                    <div class="contentFlexIndexRightInfo">-->
          <div class="container">
            <CasinoList v-show="isHideListGame"></CasinoList>
            <div
              class="containerGameList"
              :class="{ GameListContainer: isHideListGameContent }"
            >
              <div class="containerGameListinfo">
                <div class="bx-mobile">
                  <div class="catList">
                    <carousel
                      :perPage="2.5"
                      :mouseDrag="true"
                      :spacePadding="5"
                      :navigationEnabled="true"
                      :paginationEnabled="false"
                      navigationNextLabel="❯"
                      navigationPrevLabel="❮"
                    >
                      <slide>
                        <div
                          @click="showDivByGameType('all')"
                          class="sm-tab"
                          v-bind:class="{ active: isBtnAll }"
                        >
                          ทั้งหมด
                        </div>
                      </slide>
                      <slide>
                        <div
                          @click="showDivByGameType('baccarat')"
                          class="sm-tab"
                          v-bind:class="{ active: isBtnBaccarat }"
                        >
                          บาคาร่า
                        </div>
                      </slide>
                      <slide>
                        <div
                          @click="showDivByGameType('sicbo')"
                          class="sm-tab"
                          v-bind:class="{ active: isBtnSicbo }"
                        >
                          ไฮโล
                        </div>
                      </slide>
                      <slide>
                        <div
                          @click="showDivByGameType('dragontiger')"
                          class="sm-tab"
                          v-bind:class="{ active: isBtnDragontiger }"
                        >
                          เสือมังกร
                        </div>
                      </slide>
                      <slide>
                        <div
                          @click="showDivByGameType('roulette')"
                          class="sm-tab"
                          v-bind:class="{ active: isBtnRoulette }"
                        >
                          รูเล็ท
                        </div>
                      </slide>
                      <slide>
                        <div
                          @click="showDivByGameType('blackjack')"
                          class="sm-tab"
                          v-bind:class="{ active: isBtnBlackjack }"
                        >
                          แบล็คเจ็ค
                        </div>
                      </slide>
                      <slide>
                        <div
                          @click="showDivByGameType('other')"
                          class="sm-tab"
                          v-bind:class="{ active: isBtnOther }"
                        >
                          อื่นๆ
                        </div>
                      </slide>
                    </carousel>
                  </div>
                </div>
                <div class="bx-desktop">
                  <div class="catList">
                    <div
                      @click="showDivByGameType('all')"
                      class="sm-tab"
                      v-bind:class="{ active: isBtnAll }"
                    >
                      ทั้งหมด
                    </div>
                    <div
                      @click="showDivByGameType('baccarat')"
                      class="sm-tab"
                      v-bind:class="{ active: isBtnBaccarat }"
                    >
                      บาคาร่า
                    </div>
                    <div
                      @click="showDivByGameType('sicbo')"
                      class="sm-tab"
                      v-bind:class="{ active: isBtnSicbo }"
                    >
                      ไฮโล
                    </div>
                    <div
                      @click="showDivByGameType('dragontiger')"
                      class="sm-tab"
                      v-bind:class="{ active: isBtnDragontiger }"
                    >
                      เสือมังกร
                    </div>
                    <div
                      @click="showDivByGameType('roulette')"
                      class="sm-tab"
                      v-bind:class="{ active: isBtnRoulette }"
                    >
                      รูเล็ท
                    </div>
                    <div
                      @click="showDivByGameType('blackjack')"
                      class="sm-tab"
                      v-bind:class="{ active: isBtnBlackjack }"
                    >
                      แบล็คเจ็ค
                    </div>
                    <div
                      @click="showDivByGameType('other')"
                      class="sm-tab"
                      v-bind:class="{ active: isBtnOther }"
                    >
                      อื่นๆ
                    </div>
                  </div>
                </div>

                <!-- <nav class="nav-menu GameList-box GameList-box-wide" v-if="casinoList.length>0">
                                    <div class="nav-menu-topic ">
                                        <img  src="/images/slot/provider/PragmaticPlay.png">
                                        <h4>Pragmatic Play</h4>
                                        <div class="btn-list-games" @click="OpenListAllGame('pragmatic',casinoList[0])"><span>All</span></div>
                                    </div>
                                    <ul class="nav nav-pills ">
                                        <li class="nav-item" v-for="(game,key) of casinoList[0].slice(0,5)" :key="key" :class="game.cat_type">
                                            <div class="nav-item-info" @click="openGame2(game,'pragmatic')">
                                                <div class="overlay">
                                                     
                                                </div>
                                                <a href="#">
                                                    <img :src="game.img" />
                                                </a>
                                            </div>
                                            <div class="-text-nav-menu">{{game.name}} </div>
                                        </li>
                                        
                                        <li class="nav-item nav-itemAll">
                                            <div class="btn-list-games" @click="OpenListAllGame('pragmatic',casinoList[0])"><span>All</span></div>
                                        </li>
                                    </ul>
                                </nav> -->
                <!-- <nav class="nav-menu GameList-box GameList-box-wide" v-if="casinoList.length>0">
                                    <div class="nav-menu-topic ">
                                        <img  src="@/assets/images/slot/SAGaming-icon.png">
                                        <h4>SA Gaming</h4>
                                        <div class="btn-list-games"  @click="OpenListAllGame('sa',casinoList[1])"><span>All</span></div>
                                    </div>
                                    <ul class="nav nav-pills ">
                                        <li class="nav-item" v-for="(game,key) of casinoList[1].slice(0,5)" :key="key" :class="game.cat_type">
                                            <div class="nav-item-info" @click="openGame2(game,'sa')">
                                                <div class="overlay">
                                                     
                                                </div>
                                                <a href="#">
                                                    <img :src="game.img" />
                                                </a>
                                            </div>
                                            <div class="-text-nav-menu">{{game.name}} </div>
                                        </li>
                                        <li class="nav-item nav-itemAll">
                                            <div class="btn-list-games" @click="OpenListAllGame('sa',casinoList[1])"><span>All</span></div>
                                        </li>
                                    </ul>
                                </nav> -->
                <HorizontalShortList2
                  v-for="(provider, key) of providerList"
                  :key="`${key}`"
                  :provider="provider"
                  :cat="provider.slug == 'PRAGMATIC' ? 'CASINO' : cat"
                  :showMore="true"
                  @seeAll="OpenListAllGame"
                >
                </HorizontalShortList2>
              </div>
            </div>
            <div
              v-if="providerInfo"
              class="containerGameListinfoAll AllContainerGameList"
              :class="[
                providerInfo.wrapperClass,
                isHideListGame ? 'allListGameProvider' : '',
              ]"
            >
              <div class="nav-menu-topic">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="CloseListAllGame()"
                >
                  <i class="fas fa-angle-left"></i> <span>กลับ</span>
                </button>
                <img :src="providerInfo.img" />
                <h4>{{ providerInfo.name }}</h4>
                <div class="box-sublist-area-topTap">
                  <span class="boxTopTapInput">
                    <i class="fas fa-search"></i>
                    <input
                      v-model="keyword"
                      type="text"
                      placeholder="ค้นหา"
                      :disabled="
                        currentProvider == 'pragmatic' ||
                        currentProvider == 'sa'
                      "
                    />
                  </span>
                </div>
              </div>

              <ListAllGame
                :list="showList"
                :provider="currentProvider"
                :keyword="keyword"
                :cat="providerInfo.cat? providerInfo.cat : (currentProvider == 'pragmatic' ? 'CASINO' : cat)"
              ></ListAllGame>
            </div>
          </div>
        </div>
      </div>
      <div class="navButtonMobile">
        <MenuNav
          @showMenuNav="showMenuNav"
          @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
        ></MenuNav>
      </div>
    </div>
  </div>
</template>
<script>
import FloatButtomRightPanel from "@/components/FloatButtomRightPanel";
import ListAllGame from "@/components/ListAllGame2";
import MenuBar from "@/components/MenuBar";
import MenuLeft from "@/components/MenuLeft";
import MenuNav from "@/components/MenuNav";
import MenuNavTop from "@/components/MenuNavTop";
import HorizontalShortList2 from "@/components/gamelist/HorizontalShortList2";
import CasinoList from "@/components/gamelist/CasinoList";
import { Carousel, Slide } from "vue-carousel";
import axiosWrapper from "@/axiosWrapper";
import randomBetween from "@/lib/randomBetween";
import store from "@/store";
import $ from "jquery";
import {
  ALL_PROVIDER_LIST,
  PES_PROVIDER_LIST,
} from "@/components/gamelist/Constant";
import ProviderList from "@/components/ProviderList";
export default {
  name: "HomeCasino",
  components: {
    FloatButtomRightPanel,
    MenuBar,
    MenuLeft,
    MenuNav,
    MenuNavTop,
    HorizontalShortList2,
    Carousel,
    Slide,
    ListAllGame,
    CasinoList,
    ProviderList,
    // AllProvider,
  },
  data() {
    return {
      showChatWithAdminNumber: 0,
      showDepositWithdrawNumber:0,
      menuNumber: 0,
      showList: [],
      isHideProvider: false,
      isHideListGame: true,
      isHideListGameContent: false,
      games: [],
      icons: [
        "/images/icon/set-icon/icon-casino.png",
        "/images/icon/set-icon/icon-slot.png",
        "/images/icon/set-icon/icon-casino.png",
        "/images/icon/set-icon/icon-football.png",
        "/images/icon/set-icon/icon-fishing.png",
        "/images/icon/set-icon/icon-lotto.png",
        "/images/icon/set-icon/icon-roulette.png",
        "/images/icon/set-icon/icon-case.png",
      ],
      selectedBrand: "queenmaker",
      base_pes_url: "",
      isBtnAll: false,
      isBtnBaccarat: false,
      isBtnRoulette: false,
      isBtnDragontiger: false,
      isBtnBlackjack: false,
      isBtnSicbo: false,
      isBtnOther: false,
      list2: null,
      service_list: `${process.env.VUE_APP_PES_SERVICE_LIST}`.split(","),
      quickTables: [],
      cat: null,
      catPrev: null,
      tag: "",
      tagPrev: "",
      keyword: "",
      keywordPrev: "",
      currentProvider: null,
      providerList: [
        {
          slug: "PRAGMATIC",
          label: "Pragmatic Play",
          image: "/images/slot/provider/PragmaticPlay.png",
          wrapperClass: "GameList-box-wide",
          perPage: 2.8,
        },
        {
          slug: "SAGAME",
          label: "SA Gaming",
          image: "/images/slot/provider/SAGaming-icon.png",
          wrapperClass: "GameList-box-wide",
        },
        {
          slug: "SEXY",
          label: "Sexy Baccarat",
          image: "/images/slot/provider/SexyBaccarat-icon.png",
          wrapperClass: "GameList-box-wide",
          perPage: 2.8,
        },
        {
          slug: "PRETTY",
          label: "Pretty",
          image: "/images/slot/provider/PRETTY.png",
          wrapperClass: "GameList-box-wide",
        },
        {
          slug: "EVO",
          label: "Evolution Gaming",
          image: "/images/slot/provider/EvolutionGaming.png",
          wrapperClass: "GameList-box-wide",
        },
      ],
      isShowGameListModal: false,
      casinoList: [],
      isOnLoad: false,
      isCanLoadMore: false,
      page: 1,
      itemsPerPage: 15,
      providerInfo: null,
    };
  },
  computed: {
    is_member() {
      return store.state.is_member;
    },
    pes_profile() {
      return store.state.pes_profile;
    },
    token() {
      return this.pes_profile.token;
    },
    getAllGameList() {
      return store.state.allGameList;
    },
    appImageProfile() {
      return store.state.appInfo.app_image_profile;
    },
  },
  methods: {
    getCheckService(service) {
      let service_obj = this.pes_profile.service;
      if (service_obj[service]) {
        let is_login = service_obj[service].login;
        if (is_login == false) {
          console.log("yes");
        }
      } else {
        console.log(service);
      }
    },
    showChatWithAdmin() {
      this.showChatWithAdminNumber = 1;
      let self = this;
      setTimeout(() => {
        self.showChatWithAdminNumber = 0;
      }, 100);
    },
    showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
    },

    showMenuNav(menuNumber) {
      this.menuNumber = menuNumber;
      let self = this;
      setTimeout(() => {
        self.menuNumber = 0;
      }, 1000);
    },
    OpenListProvider() {
      this.isHideProvider = true;
    },
    ClosePopupProvider(provider,cat) {
      if (provider) {
        if (this.currentProvider) {
          this.currentProvider = null;
          this.providerInfo = null;
          let self = this;
          setTimeout(() => {
            self.providerInfo = provider;
            self.currentProvider = provider.slug;
            self.catPrev = this.cat;
            self.cat = null;
            self.providerInfo.cat = cat?cat:null
            self.tagPrev = this.tag;
            self.tag = null;
            self.keywordPrev = this.keyword;
            self.keyword = "";
            self.OpenListAllGame(provider.slug, null,cat);
            self.isHideProvider = false;
          }, 50);
        } else {
          this.providerInfo = provider;
          this.currentProvider = provider.slug;
          this.catPrev = this.cat;
          this.cat = null
          this.providerInfo.cat = cat?cat:null
          this.tagPrev = this.tag;
          this.tag = null;
          this.keywordPrev = this.keyword;
          this.keyword = "";
          this.OpenListAllGame(provider.slug, null,cat);
          this.isHideProvider = false;
        }
      } else {
        this.isHideProvider = false;
      }
    },
    OpenListAllGame(service, list,cat) {
      this.showList = list;
      this.currentProvider = service;
      this.providerInfo = this.getProviderInfo(service);
      if(cat){
        this.providerInfo.cat = cat;
      }
      this.isHideListGame = false;
      this.isHideListGameContent = true;
    },
    CloseListAllGame() {
      this.isHideListGame = true;
      this.isHideListGameContent = false;
      this.providerInfo = null;
      this.currentProvider = null;
      this.tag = this.tagPrev;
      this.cat = this.catPrev;
      this.keyword = this.keywordPrev;
    },
    openGame2(game, service) {
      if (!this.is_member) {
        this.notifyToSignUp("เพื่อเล่น");
        return;
      }
      var params = [];

      if (service == "pragmatic") {
        params.push("code=" + game.gameId);
      } else if (service == "sa") {
        params.push("code=" + game.code);
      }
      if (this.appImageProfile) {
        params.push("logo_img=" + this.appImageProfile);
      }
      const url = `${
        this.envDomains.VUE_APP_PES_BASE_URL
      }/game/casinoonline/sa_sexy_redirect.php?mode=mobile&service=${service}&${params.join(
        "&"
      )}&token=${this.token}`;
      if (service == "sa") {
        window.location.href = url;
      } else {
        this.$router.push({
          name: "GamePlayFrame",
          query: { url: encodeURIComponent(url) },
        });
      }
    },
    async getGameList(brand = "queenmaker") {
      const res = await axiosWrapper({
        method: "post",
        url: `${this.envDomains.VUE_APP_API}/api/game/list`,
        data: {
          brand,
        },
      });

      if (!res.data.success) {
        this.$alert(res.data.message, "แจ้งเตือน", {
          confirmButtonText: "ตกลง",
          customClass: "custom-message-box",
        });
        return;
      }

      return res.data.data;
    },
    calIcon(key) {
      return this.icons[key % this.icons.length];
    },
    getPlayingCount() {
      return randomBetween(111, 1111);
    },
    openGame(game) {
      if (!this.is_member) {
        this.notifyToSignUp("เพื่อเล่น");
        return;
      }

      const baseUrl = `/game/casinoonline/sa_sexy_redirect.php?service=${this.selectedBrand}`;

      let query = "";
      if (this.selectedBrand === "queenmaker") {
        query = `&gpcode=${game.gpcode}&gcode=${game.gcode}`;
      } else if (this.selectedBrand === "kp") {
        query = `&gameId=${game.gameId}`;
      } else if (this.selectedBrand === "cqgame") {
        query = `&gamehall=${game.gamehall}&gamecode=${game.gamecode}`;
      }
      if (this.appImageProfile) {
        query = query + "&logo_img=" + this.appImageProfile;
      }

      const url = `${this.envDomains.VUE_APP_PES_BASE_URL}${baseUrl}${query}&token=${this.token}`;
      window.open(url, "_blank");
    },
    getPesBaseUrl() {
      return `${this.envDomains.VUE_APP_PES_BASE_URL}`;
    },
    getPesToken() {
      return this.token;
    },
    showDivByGameType(type) {
      if (type != "all") {
        $(
          ".baccarat,.dragontiger,.sicbo,.roulette,.blackjack,.other,.table,.slot,.casino"
        ).hide();
        this.isBtnAll = false;
        this.isBtnBaccarat = false;
        this.isBtnRoulette = false;
        this.isBtnBlackjack = false;
        this.isBtnSicbo = false;
        this.isBtnDragontiger = false;
        this.isBtnOther = false;

        if (type == "other") {
          $(".other,.table,.slot,.casino").show();
        } else {
          $("." + type).show();
        }
        this["isBtn" + type.charAt(0).toUpperCase() + type.slice(1)] = true;
        let ulsize = $("ul.nav-pills").length;
        for (let i = 0; i < ulsize; i++) {
          let lisize = $("ul.nav-pills:eq(" + i + ")").children("li").length;
          let showCount = 0;
          for (let j = 0; j < lisize - 1; j++) {
            let display = $("ul.nav-pills:eq(" + i + ")")
              .children("li:eq(" + j + ")")
              .css("display");
            if (display != "none") {
              showCount++;
            }
          }
          if (showCount == 0) {
            $("ul.nav-pills:eq(" + i + ")")
              .parent()
              .hide();
          } else {
            $("ul.nav-pills:eq(" + i + ")")
              .parent()
              .show();
          }
        }
        // $('ul.nav-pills').each(()=>{

        // });
      } else {
        $("ul.nav-pills").parent().show();
        $(
          ".baccarat,.dragontiger,.sicbo,.roulette,.blackjack,.other,.table,.slot,.casino"
        ).show();
        this.isBtnAll = true;
        this.isBtnBaccarat = false;
        this.isBtnRoulette = false;
        this.isBtnBlackjack = false;
        this.isBtnSicbo = false;
        this.isBtnDragontiger = false;
        this.isBtnOther = false;
      }
    },
    play(item) {
      this.$router.push({ name: item.routeName });
    },
    openGameListModal(provider) {
      this.currentProvider = provider;
      this.isShowGameListModal = true;
    },
    closeGameListModal() {
      // this.currentProvider = null
      this.isShowGameListModal = false;
    },
    async getGameServiceList() {
      const res = await axiosWrapper({
        method: "get",
        url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/slot/get.php`,
      });
      return res.data;
    },
    async loadMore() {
      if (this.isOnLoad || !this.isCanLoadMore) {
        return;
      }
      this.page++;
      console.log(this.page);
      // const gameList = await this.getGameList()
      // if(gameList) {
      //     this.gameList = this.gameList.concat(gameList)
      // }
    },
    getProviderInfo(provider) {
      const PROVIDER = provider.toUpperCase();
      var info = null;
      for (let i = 0; i < PES_PROVIDER_LIST.length; i++) {
        let p = PES_PROVIDER_LIST[i];
        if (p.slug == PROVIDER) {
          info = {
            img: p.image,
            name: p.label,
            wrapperClass: p.wrapperClass,
          };
          break;
        }
      }
      if (info) return info;
      for (let i = 0; i < ALL_PROVIDER_LIST.length; i++) {
        let p = ALL_PROVIDER_LIST[i];
        if (p.slug == PROVIDER) {
          info = {
            img: p.image,
            name: p.label,
            wrapperClass: p.wrapperClass,
          };
          break;
        }
      }
      if (info) {
        return info;
      } else {
        return {
          name: "",
          img: "",
          wrapperClass: "",
        };
      }
    },
  },
  async mounted() {
    // this.list2 = await this.getGameServiceList();
    // if (this.list2.casino) {
    //   for (let i = 0; i < this.list2.casino.length; i++) {
    //     this.casinoList[i] = [];
    //     for (let j = 0; j < this.list2.casino[i].length; j++) {
    //       this.casinoList[i].push(this.list2.casino[i][j]);
    //     }
    //   }
    // }
    this.showDivByGameType("all");
  },
};
</script>
<style>
.wrapContentIndexCasino .active .itemList li.item.itemCasino {
  background-color: white;
  border: solid 1px rgba(0, 0, 0, 0.84);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.wrapContentIndexCasino .active .itemList li.item.itemCasino p,
.wrapContentIndexCasino .active .itemList li.item.itemCasino span {
  color: black;
}

/*Theme*/
.themeBlackGold .wrapContentIndexCasino .active .itemList li.item.itemCasino {
  background-image: linear-gradient(to bottom, #fec225 0%, #e19d0e 100%) !important;
  background-color: transparent;
  border: solid 1px rgba(254, 194, 37, 0.84);
  box-shadow: 0 5px 10px rgba(254, 194, 37, 0.3);
}
.themeDarkGray .wrapContentIndexCasino .active .itemList li.item.itemCasino {
  background-color: #5f646a!important;
  border: solid 1px rgba(95, 100, 106, 0.84);
  box-shadow: 0 5px 10px rgba(59, 59, 59, 0.3);
}
.themeDarkGray .wrapContentIndexCasino .active .itemList li.item.itemCasino p,
.themeDarkGray .wrapContentIndexCasino .active .itemList li.item.itemCasino span {
  color: #fff!important;
}
.themeLightGray .wrapContentIndexCasino .active .itemList li.item.itemCasino {
  background-color: #edeef5 !important;
  border: solid 1px rgb(232, 232, 232) !important;
  box-shadow: none;
}
.themeLightGray .wrapContentIndexCasino .active .itemList li.item.itemCasino p,
.themeLightGray .wrapContentIndexCasino .active .itemList li.item.itemCasino span {
  color: #222!important;
}
</style>
