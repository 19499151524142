<template>

    <div class="wrapContentIndex wrapContentIndexSport" :class="{'wrapContentIndexSportBet':is_index_sport_bet}">
        <div class="contentOverlay contentOverlaySidebar" :class="{'popupProvider': isHideProvider}">
            <div class="btn-close-popupAllgame" @click="ClosePopupProvider()"></div>
            <div class="contentListProviderLeft menuStyle">
                <div class="contentListProviderLeftInfo">
                    <h3>เมนู</h3>
                    <MenuLeft :is_slidebar="true"></MenuLeft>
                </div>
            </div>
        </div>
        <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/>
        <div class="wrapContentIndexTitle">
            <div class="bx-mobile">
                <div class="hamburger-lines" @click="OpenListProvider()">
                    <span class="line line1"></span>
                    <span class="line line2"></span>
                    <span class="line line3"></span>
                </div>
            </div>
            <h3>กีฬา</h3>
        </div>
        <div class="navTopDesktop">
            <MenuNavTop  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop>
        </div>
        <MenuBar  :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber">

        </MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexLeft active">
                    <MenuLeft></MenuLeft>
                </div>
                <div class="contentFlexIndexRight">
                    <FootballApp @panel_state="panel_state" ></FootballApp>
                </div>
            </div>
            <div class="navButtonMobile">
                <MenuNav  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
            </div>
        </div>
    </div>
</template>
<script>
    import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
    import FootballApp from '@/components/football/App'
    import MenuBar from '@/components/MenuBar'
    import MenuLeft from '@/components/MenuLeft'
    import MenuNav from '@/components/MenuNav'
    import MenuNavTop from '@/components/MenuNavTop'
    
    export default {
        name: 'IndexSport',
        components: {
            MenuBar,
            MenuLeft,
            MenuNav,
            MenuNavTop,
            FootballApp,
            FloatButtomRightPanel
        },
        data() {
            return {
                showChatWithAdminNumber:0,
                showDepositWithdrawNumber:0,
                menuNumber :0,
                isHideProvider: false,
                isHideListGame: true,
                isHideListGameContent: false,
                is_index_sport_bet :false,
            }
        },
        methods: {
            showChatWithAdmin(){
                this.showChatWithAdminNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showChatWithAdminNumber=0;
                },100);
                
            },
            panel_state(val){
                if(val=='expand'){
                    this.is_index_sport_bet=true;
                }else{
                    this.is_index_sport_bet=false;
                }
            },
            showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

            showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
            },

            OpenListProvider() {
                this.isHideProvider = true;
            },
            ClosePopupProvider() {
                this.isHideProvider = false;
            },
            OpenListAllGame(){
                this.isHideListGame = false;
                this.isHideListGameContent = true;
            },
            CloseListAllGame() {
                this.isHideListGame = true;
                this.isHideListGameContent = false;
            },

            toIndexPopularPage() {
                this.$router.push({name: 'IndexPopular'})
            },
            toIndexCasinoPage() {
                this.$router.push({name: 'IndexCasino'})
            },
            toIndexSlotPage() {
                this.$router.push({name: 'IndexSlot'})
            },
            toIndexFishingPage() {
                this.$router.push({name: 'IndexFishing'})
            },
            toIndexSportPage() {
                this.$router.push({name: 'IndexSport'})
            },
            toIndexLottoPage() {
                this.$router.push({name: 'IndexLotto'})
            },
            toIndexUnboxPage() {
                this.$router.push({name: 'IndexUnbox'})
            },
        }
    }
</script>
<style>
    .wrapContentIndexSport .active .itemList li.item.itemSport{
        background-color: white;
        border: solid 1px rgba(0, 0, 0, 0.84);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    }
    .wrapContentIndexSport .active .itemList li.item.itemSport p,
    .wrapContentIndexSport .active .itemList li.item.itemSport span{color: black}

    /*Theme*/
    .themeBlackGold .wrapContentIndexSport .active .itemList li.item.itemSport {
        background-image: linear-gradient(to bottom, #fec225 0%, #e19d0e 100%) !important;
        background-color: transparent;
        border: solid 1px rgba(254, 194, 37, 0.84);
        box-shadow: 0 5px 10px rgba(254, 194, 37, 0.3);
    }
    .themeDarkGray .wrapContentIndexSport .active .itemList li.item.itemSport {
        background-color: #5f646a!important;
        border: solid 1px rgba(95, 100, 106, 0.84);
        box-shadow: 0 5px 10px rgba(59, 59, 59, 0.3);
    }
    .themeDarkGray .wrapContentIndexSport .active .itemList li.item.itemSport p,
    .themeDarkGray .wrapContentIndexSport .active .itemList li.item.itemSport span {
        color: #fff!important;
    }
    .themeLightGray .wrapContentIndexSport .active .itemList li.item.itemSport {
        background-color: #edeef5 !important;
        border: solid 1px rgb(232, 232, 232) !important;
        box-shadow: none;
    }
    .themeLightGray .wrapContentIndexSport .active .itemList li.item.itemSport p,
    .themeLightGray .wrapContentIndexSport .active .itemList li.item.itemSport span {
        color: #222!important;
    }
</style>