<template>

    <div class="wrapContentIndex LottoNumber LottoNumberListAll">
        <div class="bx-mobile">
            <div class="wrapContentIndexTitle ">
                <button style="padding: 0 10px;" class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i>
                </button>
                <h3>หวยเบอร์</h3>
            </div>
        </div>
        <div class="bx-desktop">
            <div class="wrapContentIndexTitle">
                <h3>หวยเบอร์</h3>
            </div>
        </div>

        <div class="navTopDesktop">
            <MenuNavTop
                    @showMenuNav="showMenuNav"
                    @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
            ></MenuNavTop>
        </div>
        <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexLeft active">
                    <MenuLeft></MenuLeft>
                </div>
                <div class="contentFlexIndexRight">
                    <div class="container">
                        <div class="box-list-home-list">
                            <div class="box-list-home box-list-home-bill">
                                <div class="box-menu-index"  @click="goToBillList()">
                                    <img src="/img/icon-lotto-bill.3f1806a1.png">
                                    <span>โพยหวย</span>
                                </div>
                            </div>
                            <div class="box-list-home box-list-home-result">
                                <div class="box-menu-index" @click=" goToResultList()">
                                    <img src="/img/icon-lotto-result.a2b42684.png">
                                    <span>ผลออกรางวัล</span></div>
                                </div>
                        </div>
                        <div class="boxHitecCasino" style="margin-top: 15px;">
                            <div class="LottoNumberContent" v-for="(gameList,game_index) of resut_gold_number_list" :key="game_index">
                                <div class="LottoNumberList" v-for="(game,g_id) of gameList" :key="g_id"
                                                 @click="goToBet(game.gbs_id)">
                                    <div class="LottoNumberListTopic">
                                        <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                        <h4>{{ title_game_type[game.game_type]}}</h4>
                                    </div>
                                    <div class="LottoNumberListContent">
                                        <div class="LNListContentLeft">
                                            <small>งวดวันที่ {{ showDate(game.date)}}</small>
                                            <ul>
                                                <li v-if="game.game_type==0">ถูก 2 ตัวล่าง รับ {{
                                                                game.reward_price }}
                                                </li>
                                            </ul>
                                            <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                        </div>
                                        <div class="LNListContentRight">
                                            <small>เบอร์ละ</small>
                                            <strong>{{ game.price }}</strong>
                                            <span>บาท</span>
                                            <ul>
                                                <li class="LNDay">{{ (game.days>0? game.days+' วัน ': '' )
                                                                }}
                                                            </li>
                                                            <li>{{ game.hours }}:{{ game.minutes }}:{{ game.seconds }}
                                                            </li>
                                            </ul>
                                            <label>ปิดรับ {{ showEndTime(game.end_datetime) }}</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="LottoNumberList">
                                    <div class="LottoNumberListTopic">
                                        <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                        <h4>หวยเบอร์ทองเวียดนาม</h4>
                                    </div>
                                    <div class="LottoNumberListContent">
                                        <div class="LNListContentLeft">
                                            <small>งวดวันที่ 12/12/2565</small>
                                            <ul>
                                                <li>ถูก 2 ตัวล่างรับ 350</li>
                                                <li>ถูก 2 ตัวบนรับ 350</li>
                                            </ul>
                                            <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                        </div>
                                        <div class="LNListContentRight">
                                            <small>เบอร์ละ</small>
                                            <strong>10</strong>
                                            <span>บาท</span>
                                            <ul>
                                                <li class="LNDay">5วัน</li>
                                                <li>12:33:55</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="LottoNumberList">
                                    <div class="LottoNumberListTopic">
                                        <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                        <h4>หวยเบอร์ทองเวียดนาม</h4>
                                    </div>
                                    <div class="LottoNumberListContent">
                                        <div class="LNListContentLeft">
                                            <small>งวดวันที่ 12/12/2565</small>
                                            <ul>
                                                <li>ถูก 2 ตัวล่างรับ 350</li>
                                                <li>ถูก 2 ตัวบนรับ 350</li>
                                            </ul>
                                            <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                        </div>
                                        <div class="LNListContentRight">
                                            <small>เบอร์ละ</small>
                                            <strong>10</strong>
                                            <span>บาท</span>
                                            <ul>
                                                <li class="LNDay">5วัน</li>
                                                <li>12:33:55</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navButtonMobile">
                <MenuNav
                        @showMenuNav="showMenuNav"
                        @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
                ></MenuNav>
            </div>
        </div>
    </div>
</template>
<script>
    import axiosWrapper from '@/axiosWrapper'
    import store from '@/store'
    import moment from 'moment';
    import MenuBar from "@/components/MenuBar";
    import MenuLeft from "@/components/MenuLeft";
    import MenuNav from "@/components/MenuNav";
    import MenuNavTop from "@/components/MenuNavTop";



    export default {
        name: 'LottoNumberList',
        components: {
            MenuBar,
            MenuLeft,
            MenuNav,
            MenuNavTop,
        },
        data() {
            return {
                'title_game_type': [
                    'หวยเบอร์ทองรัฐบาล'
                ],
                'resut_gold_number_list': [],
                showChatWithAdminNumber: 0,
                showDepositWithdrawNumber: 0,
                menuNumber: 0,
              
            }
        },
        computed: {

            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
        },
        methods : {
            goToBet(gbs_id) {
                this.$router.push({'name': 'LottoNumberBetDetail', params: {gbs_id: gbs_id}})
            },
            showDate(date) {

                let moment_datetime = moment(date).format('DD/MM/YYYY');
                let date_array = moment_datetime.split("/");
                return date_array[0] + '/' + date_array[1] + '/' + (parseInt(date_array[2]) + 543)
            },
            showEndTime(datetime) {
                let time = moment(datetime).format('HH:mm');
                return time;
            },
            async getGoldNumberList() {

                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gold_number/get?token=` + this.token + '&game_status=1&game_type=0&date=',
                    data: {}
                })
                this.resut_gold_number_list = []
                let new_list = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i].game_status == 1) {
                        res.data.data[i].days = 0;
                        res.data.data[i].hours = 0;
                        res.data.data[i].minutes = 0;
                        res.data.data[i].seconds = 0;
                        new_list.push(res.data.data[i]);

                        if (!this.date_result) {
                            let first_data = res.data.data[i];
                            // console.log('first',first_data);
                            let date_array = moment(first_data.date).format('YYYY-MM-DD').split("-");

                            this.date_result = date_array[2] + '/' + date_array[1] + '/' + (parseInt(date_array[0]) + 543)

                        }

                    }
                }
                for (let i = 0; i < new_list.length; i++) {
                    if (i == 0) {
                        this.resut_gold_number_list.push([]);
                    }else if(i>2){
                        // break;
                    }
                    let index = this.resut_gold_number_list.length - 1;
                    this.resut_gold_number_list[index].push(new_list[i])

                }

            },
            showDepositWithdrawModal() {
                this.showDepositWithdrawNumber = 1;
                let self = this;
                setTimeout(() => {
                    self.showDepositWithdrawNumber = 0;
                }, 100);

            },
            showChatWithAdmin() {
                this.showChatWithAdminNumber = 1;
                let self = this;
                setTimeout(() => {
                    self.showChatWithAdminNumber = 0;
                }, 100);
            },
            showMenuNav(menuNumber) {
                this.menuNumber = menuNumber;
                let self = this;
                setTimeout(() => {
                    self.menuNumber = 0;
                }, 1000);
            },
            goToBillList() {
                this.$router.push({'name': 'LottoNumberBill'})
            },
            goToResultList() {
                this.$router.push({'name': 'LottoNumberResultGovernment'})
            },
            updateRemaining(distance, index1, index2) {
                this.resut_gold_number_list[index1][index2].days = Math.floor(distance / (1000 * 60 * 60 * 24))
                this.resut_gold_number_list[index1][index2].hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                this.resut_gold_number_list[index1][index2].minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                this.resut_gold_number_list[index1][index2].seconds = Math.floor((distance % (1000 * 60)) / 1000)
                // console.log(this.resut_gold_number_list[index1][index2])

            },

            tick(index1, index2) {
                const currentTime = new Date()
                const distance = Math.max(Date.parse(this.resut_gold_number_list[index1][index2].end_datetime) - currentTime, 0)
                this.updateRemaining(distance, index1, index2)

                // if (distance === 0) {
                //     clearInterval(this.timer)
                //     this.isEnded = true
                // }
            },
            setTick() {
                for (let index1 = 0; index1 < this.resut_gold_number_list.length; index1++) {
                    for (let index2 = 0; index2 < this.resut_gold_number_list[index1].length; index2++) {
                        this.tick(index1, index2)
                    }
                }
            },
        },
        async mounted() {
            await this.getGoldNumberList();
            this.setTick();
            setInterval(this.setTick.bind(this), 1000)
        }
    }
</script>