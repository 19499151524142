<template>

    <div class="wrapContentIndex LottoNumberSearch LottoNumberCheck">
        <!--  ยืนยันการลบ    //////////////////////////////////////////-->
        <div class="containerNumSelect RemoveSelectConfirm"   v-if="showModaleConfirmRemove" >
<!--        <div class="containerNumSelect containerNumSelectBetDetail"    >-->
            <div class="wrapNumSelect">
                <div class="boxNumSelect">
                    <div class="title">
                        <h4>ยืนยันการลบ</h4>
                    </div>
                    <div class="content">
                        <div class="LottoNumberContent">
                            <div class="ContentResultSearchNum">
                                <div class="ListLottoNum ListLottoNumSet" v-if="true">
                                    <div class="ListLottoNumInfo">
                                        <div class="topic">
                                            <div class="tagTopicSet">
                                                <p>{{ showModaleConfirmRemove.lotto_set_count }} ใบ</p>
                                                <span>{{ showModaleConfirmRemove.lotto_set_count * 6 }} ล.</span>
                                            </div>
                                            <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                        </div>
                                        <div class="content">
                                            <ul>

                                                <li>{{ showModaleConfirmRemove.lotto_number.charAt(0) }}</li>
                                                <li>{{ showModaleConfirmRemove.lotto_number.charAt(1) }}</li>
                                                <li>{{ showModaleConfirmRemove.lotto_number.charAt(2) }}</li>
                                                <li>{{ showModaleConfirmRemove.lotto_number.charAt(3) }}</li>
                                                <li>{{ showModaleConfirmRemove.lotto_number.charAt(4) }}</li>
                                                <li>{{ showModaleConfirmRemove.lotto_number.charAt(5) }}</li>
                                            </ul>
                                            <div class="ListDetail">
                                                <small>ชุดที่</small>
                                                <strong>{{ showModaleConfirmRemove.lotto_set_count }}</strong>
                                            </div>
                                            <div class="ListDetail">
                                                <small>งวดที่</small>
                                                <strong>{{ showModaleConfirmRemove.lotto_count }}</strong>
                                            </div>
                                            <div class="priceLottoLabel">
                                                <span>{{ showModaleConfirmRemove.lotto_price}}</span>บาท
                                            </div>
                                        </div>
                                        <div class="contentBg"></div>
                                        <div class="contentBgUnder"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="footer">
                        <div class="footerGroup">
                            <button type="button" class="btn btnPlay" @click="confirmRemove2(showModaleConfirmRemove)">ลบ</button>
                            <button type="button" class="btn btnBill"  @click="cancelRemove(showModaleConfirmRemove)">ยกเลิก</button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>

        <!-- Confirm  ยืนยันการซื่อ    //////////////////////////////////////////-->
<!--        <div class="containerNumSelect"  v-if="false" >-->
        <div class="containerNumSelect" v-if="purchase_status==1" >
            <div class="wrapNumSelect">
                <div class="boxNumSelect">
                    <div class="title">
                        <h4>ยืนยันการซื้อ</h4>
                    </div>
                    <div class="content contentReCheckLotto">
                        <div class="ListLottoNum" v-for="(lotto,key) of cart_list" :key="key" :class="{'ListLottoNumSet' : lotto.lotto_set_count>1}">
                            <div class="ListLottoNumInfo">
                                <div class="topic">
                                    <div class="tagTopicSet">
                                        <p>{{ lotto.lotto_set_count }} ใบ</p>
                                        <span>{{ lotto.lotto_set_count * 6 }} ล.</span>
                                    </div>
                                    <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                </div>
                                <div class="content">
                                    <ul>
                                        <li>{{ lotto.lotto_number.charAt(0) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(1) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(2) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(3) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(4) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(5) }}</li>
                                    </ul>
                                    <div class="ListDetail">
                                        <small>ชุดที่</small>
                                        <strong>{{lotto.lotto_set_count}}</strong>
                                    </div>
                                    <div class="ListDetail">
                                        <small>งวดที่</small>
                                        <strong>{{lotto.lotto_count}}</strong>
                                    </div>
                                    <div class="priceLottoLabel">
                                        <span>{{lotto.lotto_price}}</span>บาท
                                    </div>
                                </div>
                                <div class="contentBg"></div>
                                <div class="contentBgUnder"></div>
                            </div>
                        </div>
                       
                        <table class="tableTotalBill">
                            <tr>
                                <td>จำนวนจำนวนสลากฯ</td>
                                <td><span>{{ select_count }}</span> ใบ</td>
                            </tr>
                            <tr>
                                <td>งวดวันที่</td>
                                <td><span>{{ showDate(date_result) }}</span></td>
                            </tr>
                            <tr>
                                <td>วัน/เวลาที่ซื้อ</td>
                                <td><span>{{ getCurrentDatetime()    }}</span></td>
                            </tr>
                            
                            <tr>
                                <td>โพยเลขที่</td>
                                <td><span>#</span></td>
                            </tr>
                        </table>

                        <div class="tableTotalBillWrap">
                            <table class="tableTotalBill">
                                <tr>
                                    <td>ทั้งหมด</td>
                                    <td><span>{{sum}}</span> บาท</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                    <div class="footer">
                        <div class="footerGroup">
                            <button type="button" class="btn btnPlay" @click="confirmPurchase(3)">ยืนยัน</button>
                            <button type="button" class="btn btnBill"  @click="purchase_status=0">ยกเลิก</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>

        <!--  กดซื้อแล้ว    //////////////////////////////////////////-->
<!--        <div class="containerNumSelect containerNumSelectBetDetail"  v-if="true" >-->
        <div class="containerNumSelect containerNumSelectBetDetail"  v-if="purchase_status==3" >
            <div class="wrapNumSelect">
                <div class="boxNumSelect">
                    <div class="title">
                        <h4><i class="fas fa-check-circle"></i> ทำการซื้อสำเร็จแล้ว</h4>
                    </div>
                    <div class="content">
                        <div class="ListLottoNum" v-for="(lotto,key) of data_result.bet_list" :key="key" :class="{'ListLottoNumSet' : lotto.lotto_set_count>1}">
                            <div class="ListLottoNumInfo">
                                <div class="topic">
                                    <div class="tagTopicSet">
                                        <p>{{ lotto.lotto_set_count }} ใบ</p>
                                        <span>{{ lotto.lotto_set_count * 6 }} ล.</span>
                                    </div>
                                    <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                </div>
                                <div class="content BetDetailContent">
                                    <ul>
                                        <li>{{ lotto.lotto_number.charAt(0) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(1) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(2) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(3) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(4) }}</li>
                                        <li>{{ lotto.lotto_number.charAt(5) }}</li>
                                    </ul>
                                    
                                    <div class="ListDetail">
                                        <small>งวดที่</small>
                                        <strong>{{lotto.lotto_count}}</strong>
                                    </div>
                                    <div class="priceLottoLabel">
                                        <span>{{lotto.lotto_price}}</span>บาท
                                    </div>
                                </div>
                                <div class="contentBg"></div>
                                <div class="contentBgUnder"></div>
                            </div>
                        </div>
                        <table class="tableTotalBill">
                            <tr>
                                <td>จำนวนจำนวนสลากฯ</td>
                                <td><span>{{ select_count }} </span> ใบ</td>
                            </tr>
                            <tr>
                                <td>งวดวันที่</td>
                                <td><span>{{ showDate(date_result) }}</span></td>
                            </tr>
                            <tr>
                                <td>วัน/เวลาที่ซื้อ</td>
                                <td><span>{{  getCurrentDatetime() }}</span></td>
                            </tr>
                            <!-- <tr>
                                <td>ชุดที่</td>
                                <td><span>16</span></td>
                            </tr> -->
                            <tr>
                                <td>โพยเลขที่</td>
                                <td><span>#{{ last_purchase_id }}</span></td>
                            </tr>
                        </table>

                        <div class="tableTotalBillWrap">
                            <table class="tableTotalBill">
                                <tr>
                                    <td>ทั้งหมด</td>
                                    <td><span>{{sum}}</span> บาท</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                    <div class="footer">
                        <div class="footerGroup">
                            <button type="button" class="btn btnBill" @click="goToBill(1)">ดูสลากของฉัน</button>
                            <button type="button" class="btn btnPlay" @click="goToSearch">ซื้อสลาก</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>

        <div class="wrapContentIndexTitle ">
            <div class="IndexTitleInto">
                <button style="padding: 0 10px;" class="btn " @click="goBack()"><i class="fas fa-chevron-left"></i></button>
                <div class="IndexTitleIntoGroup">
                    <h3>ตรวจสอบรายการ</h3>
                    <span>งวดวันที่ {{ showDate(date_result) }}</span>
                </div>

            </div>
            <MenuBar ></MenuBar>

        </div>

        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="container">
                        <div class="nav-menu-topic topicGovernmentLottery">
                            <i class="fas fa-check-circle"></i>
                            <div class="group">
                                <h4>ตรวจสอบรายการ</h4>
                                <span>งวดวันที่ {{ showDate(date_result) }}</span>
                            </div>
                        </div>
                        <div class="boxGovernmentLottery">
                            <div class="LottoNumberContent">
                                <div class="ContentResultSearchNum">

                                    <!--                                    Demo-->
                                    <div class="ListLottoNum ListLottoNumSet active" v-if="false">
                                        <div class="ListLottoNumGroup">
                                            <div class="ListLottoNumLeft">
                                                <div class="topic">
                                                    <div class="tagTopic">
                                                        <p>1 ใบ</p>
                                                        <span>6 ล.</span>
                                                    </div>
                                                    <h4><img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                                </div>
                                                <div class="content">
                                                    <ul>
                                                        <li>1</li>
                                                        <li>2</li>
                                                        <li>3</li>
                                                        <li>4</li>
                                                        <li class="active">5</li>
                                                        <li class="active">6</li>
                                                    </ul>
                                                    <div class="ListDetail">
                                                        <small>ชุดที่</small>
                                                        <strong>22</strong>
                                                    </div>
                                                    <div class="ListDetail">
                                                        <small>งวดที่</small>
                                                        <strong>22</strong>
                                                    </div>
                                                </div>
                                                <div class="contentBg"></div>
                                                <div class="contentBgUnder"></div>
                                            </div>
                                            <div class="ListLottoNumRight">
                                                <div class="priceLottoLabel">
                                                    <span>80</span>บาท
                                                </div>
                                                <button class="btn"></button>

                                            </div>
                                        </div>
                                    </div>
                                    <!--                                    //////-->

<!--                                    ListLottoNumSet-->
                                    <div class="ListLottoNum active"  v-for="(lotto,key) of cart_list" :key="key" :class="{'ListLottoNumSet' : lotto.lotto_set_count>1}">
                                        <div class="ListLottoNumGroup">
                                            <div class="ListLottoNumLeft">
                                                <div class="topic">
                                                    <div class="tagTopicSet">
                                                        <p>{{ lotto.lotto_set_count }} ใบ</p>
                                                        <span>{{ lotto.lotto_set_count * 6 }} ล.</span>
                                                    </div>
                                                    <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                                </div>
                                                <div class="content">
                                                    <ul>
                                                        <li>{{ lotto.lotto_number.charAt(0) }}</li>
                                                        <li>{{ lotto.lotto_number.charAt(1) }}</li>
                                                        <li>{{ lotto.lotto_number.charAt(2) }}</li>
                                                        <li>{{ lotto.lotto_number.charAt(3) }}</li>
                                                        <li class="active">{{ lotto.lotto_number.charAt(4) }}</li>
                                                        <li class="active">{{ lotto.lotto_number.charAt(5) }}</li>
                                                    </ul>
                                                    <div class="ListDetail">
                                                        <small>ชุดที่</small>
                                                        <strong>{{lotto.lotto_set_count}}</strong>
                                                    </div>
                                                    <div class="ListDetail">
                                                        <small>งวดที่</small>
                                                        <strong>{{lotto.lotto_count}}</strong>
                                                    </div>
                                                </div>
                                                <div class="contentBg"></div>
                                                <div class="contentBgUnder"></div>
                                            </div>
                                            <div class="ListLottoNumRight">
                                                <div class="priceLottoLabel">
                                                    <span>{{ lotto.lotto_price }}</span>บาท
                                                </div>
                                                <button class="btn" @click="confirmRemove(lotto,key)"></button>

                                            </div>
                                        </div>

                                    </div>
                                    <!-- <div class="ListLottoNum active">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            </div>
                                            <div class="content">
                                                <ul>
                                                    <li>1</li>
                                                    <li>2</li>
                                                    <li>3</li>
                                                    <li>4</li>
                                                    <li>5</li>
                                                    <li>6</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>26</strong>
                                                </div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>13</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                            <button class="btn"></button>

                                        </div>
                                    </div>
                                    <div class="ListLottoNum active">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <h4> <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            </div>
                                            <div class="content">
                                                <ul>
                                                    <li>1</li>
                                                    <li>2</li>
                                                    <li>3</li>
                                                    <li>4</li>
                                                    <li>5</li>
                                                    <li>6</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>26</strong>
                                                </div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>13</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel">
                                                <span>80</span>บาท
                                            </div>
                                            <button class="btn"></button>

                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="LottoNumberFooter">
                                <div class="boxAddLotto">
                                    <button class="btn" @click="goToSearch"><i class="fas fa-plus"></i> เลือกสลากเพิ่ม</button>
                                </div>
                                <div class="boxLottoNumberFooter">
                                    <!-- <p class="timePay">ชำระเงินภายใน <span>15:00</span> นาที</p> -->
                                    <ul>
                                        <li><button class="btn" @click="confirmPurchase(1)">ชำระเงิน</button></li>
                                        <li>
                                            <p>จำนวนที่เลือก</p>
                                            <strong><span>{{ select_count }}</span> ใบ</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import store from '@/store'
    import MenuBar from '@/components/MenuBar'
    import axiosWrapper from '@/axiosWrapper'
    import moment from 'moment';
    const querystring = require('query-string')

    export default {
        name: 'LottoNumberCheck',
        components: {
            MenuBar,
        },
        computed: {
            getCurrentCart: function() {
                return store.state.newlotto.cart_list;
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
        
            
        },
        data () {
            return {
                cart_list :[

                ],
                sum : 0,
                select_count :0,
                purchase_status : 0,
                last_purchase_id:0,
                lotto_type :'gov_lotto',
                date_result : null,
                data_result : null,
                showModaleConfirmRemove:false,
                
            }
        },
        methods :{
            goToSearch() {
                this.$router.push({'name':'LottoNumberSearch'})
            },
            goBack() {
                this.$router.push({'name':'LottoNumberSearch'})
            },
           
            goToBill() {
                
                this.$router.push({'path':`/LottoNumberBill?gid=${this.last_purchase_id}&lotto_type=${this.lotto_type}`})
            },
            setCartList(cart_list){
                store.commit('newlotto/updateCartList', cart_list);
            },
            chooseLotto (l,index){  
                if(this.cart_list[index].selected){
                    // console.log('in 1');
                    // let index_remove = 0;
                    // for(let i=0;i<this.cart_list.length;i++){
                    //     if(this.cart_list[i].lotto_id==l.lotto_id){
                    //         index_remove=i;
                    //         break;
                    //     }
                        
                    // }
                    // this.cart_list.splice(index_remove,1);
                    this.cart_list[index].selected=false;

                }else{
                    this.cart_list[index].selected=true;
                    // console.log('log 2');
                    // this.cart_list.push(l);
                }
                this.setCartList(this.cart_list);
                this.calAllSum();
            },
            confirmRemove(l,index){
                index;
                this.showModaleConfirmRemove=l;
                // console.log(l,index);
            },
            cancelRemove(l){
                l;
                this.showModaleConfirmRemove=null;
            },
            confirmRemove2(l){
                let remove_index =null;
                for(let i=0;i<this.cart_list.length;i++){
                    if(this.cart_list[i].lotto_number ==  l.lotto_number){
                        remove_index=i;
                        break;
                    }
                }
                this.cart_list.splice(remove_index,1)
                this.setCartList(this.cart_list);
                this.showModaleConfirmRemove=null;
                if(this.cart_list.length==0){
                    this.$router.push({'name':'LottoNumberSearch'});
                }
                this.calAllSum();

            },
            calAllSum(){
                this.sum=0;
                this.select_count=0;
                for(let i=0;i<this.cart_list.length;i++){
                    if(this.cart_list[i].selected){
                        this.sum+=this.cart_list[i].lotto_price;
                        this.select_count++;
                    }
                }

            },
            confirmPurchase(status){
                console.log('in in',status);
                if(status==3){

                    this.purchase()
                }else{
                    this.purchase_status=status;
                }
                
            },
            async purchase(){
                let bet_list =[];
                // let date =moment().format('YYYY-MM-DD');

                for(let i=0;i<this.cart_list.length;i++){
                    let c = this.cart_list[i];
                    let b ={
                        lotto_number : c.lotto_number,
                        lotto_set : c.lotto_set,
                        lotto_set_count : c.lotto_set_count,
                    }
                    bet_list.push(b);
                }
                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gov_lotto/purchase`,
                    data: querystring.stringify({
                        token: this.token,
                        date: this.date_result,
                        bet_list : JSON.stringify(bet_list),
                    })
                })
                if(res.status !== 200) {
                    console.log(res.data.message);
                    alert(res.data.message);
                }else{
                    let data  = res.data.data;
                    
                    this.data_result = data;
                    this.data_result.bet_list = JSON.parse(data.bet_list);
                    this.purchase_status=3;
                    this.cart_list = [];
                    this.setCartList(this.cart_list);
                    this.last_purchase_id = data.gid;
                    // this.date_result = data.date_result;
                    
                }
            


            },
            getCurrentDatetime(){
                return moment().format('HH:mm:ss DD/MM/YYYY')
            },
            // getDateResult(){
            //     let day = moment().format('D');
            //     // let hour = moment().format('H');
            //     if(day==1){
            //         this.date_result = moment().format('01/MM/YYYY');
            //         // if(hour<12){
            //         //     this.date_result = moment().format('01/MM/YYYY');
            //         // }else{
            //         //     this.date_result = moment().format('16/MM/YYYY');
            //         // }

            //     }else if(day==16){
            //         this.date_result = moment().format('16/MM/YYYY');
            //         // if(hour){

            //         // }else{

            //         // }

            //     }else if(day<16){
            //         this.date_result = moment().format('16/MM/YYYY');
                    
            //     }else{
            //         this.date_result = moment().add(1,'months').format('01/MM/YYYY');
            //     }
            //     // console.log('yes', moment().format('DD/MM/YYYY'))
            //     let date_array=this.date_result.split("/");
            //     this.date_result = date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)

            // },
            showDate(date){
                
                let moment_datetime=moment(date).format('DD/MM/YYYY');
                let date_array =moment_datetime.split("/");
                return date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)
            },

        },
        mounted() {
            this.cart_list = this.getCurrentCart
            if(this.cart_list.length>0){
                let first_data = this.cart_list[0];
                this.date_result = first_data.lotto_date;
            }

            this.calAllSum()
            // this.getDateResult();
            // console.log(this.date_result);
        }
    }
</script>