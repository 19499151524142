<template>

    <div class="wrapContentIndex LottoNumber LottoNumberHome lottoSite">
        <div class="boxMenuListLottoSite" :class="{'sideShowListMenu':isMenuListLottoSite_show}">
            <ul>
                <li class="topic">
                    <!--                    <h3>เมนู</h3>-->
                    <div class="close-box-bonus" @click="CloseListMenu()"></div>
                </li>
                <li class="active">หน้าแรก</li>
                <li @click="goToBillList()">สลากของฉัน</li>
                <li @click="goToResultList()">ผลออกรางวัล</li>
                <li>ออกจากระบบ</li>
            </ul>
            <!--            <div class="boxMenuListLottoSiteBG" @click="CloseListMenu()"></div>-->
        </div>
        <div class="bx-mobile">
            <div class="wrapContentIndexTitle ">
                <!--                <div class="hamburger-lines" @click="OpenListProvider()">-->
                <div class="hamburger-lines" @click="OpenListMenu()">
                    <span class="line line1"></span>
                    <span class="line line2"></span>
                    <span class="line line3"></span>
                </div>
                <img src="@/assets/images/devilSite/logo devil lotto Black 128x128 px (Favicon).png">
            </div>
        </div>
        <div class="bx-desktop">
            <div class="wrapContentIndexTitle">
                <img src="@/assets/images/devilSite/devilLogo.png">
            </div>

        </div>
        <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>


        <!--        popup ถูกรางวัล -->
        <div class="popupResultLottoCheck WinLottoCheck" v-if="false">
            <div class="content-bonus-day">
                <div class="content-all">
                    <div class="close-box-bonus" ></div>
                    <div class="content">
                        <ul>
                            <li>
                                <p>สลากหมายเลข</p>
                                <strong>471781</strong>
                                <span>ถูกรางวัลข้างเคียงรางวัลที่ 1 ค่ะ</span>
                                <span>มูลค่า 100,000 บาท</span>
                                <i class="far fa-smile-beam"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="overlay-box"></div>
        </div>

        <!--        popup ไม่ถูกรางวัล -->
        <div class="popupResultLottoCheck LoseLottoCheck"  v-if="false">
            <div class="content-bonus-day">
                <div class="content-all">
                    <div class="close-box-bonus" ></div>
                    <div class="content">
                        <ul>
                            <li>
                                <p>สลากหมายเลข</p>
                                <strong>471781</strong>
                                <span>ไม่ถูกรางวัล</span>
                                <i class="far fa-tired"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="overlay-box"></div>
        </div>


        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="container">
                    <div class="boxHitecCasino boxGovernmentLottery">
                        <div class="LottoNumberContent">
                            <div class="LottoNumberList LottoNumberListSearch">

                                <div class="LottoNumberListTopic">
                                    <h4><i class="fas fa-search"></i> ค้นหาเลขเด็ด</h4>
                                    <p>งวดประจำวันที่ {{date_result}}</p>
                                </div>

                                <div class="LottoNumberListContent" @click="goToSearch()">
                                    <!--                                    <div class="LottoNumberListSearchLink"></div>-->
                                    <div class="listBoxSearch">
                                        <ul>
                                            <li><input type="text" class="form-control" placeholder="1"></li>
                                            <li><input type="text" class="form-control" placeholder="2"></li>
                                            <li><input type="text" class="form-control" placeholder="3"></li>
                                            <li><input type="text" class="form-control" placeholder="4"></li>
                                            <li><input type="text" class="form-control" placeholder="5"></li>
                                            <li><input type="text" class="form-control" placeholder="6"></li>
                                        </ul>
                                    </div>
                                    <!--                                        <button class="btn">ค้นหาเลขเด็ด</button>-->
                                </div>

                                <div class="boxResultSimple" v-if="false">
                                    <div class="title">
                                        <h3>ผลการออกรางวัลสลากฯ</h3>
                                        <p>งวดวันที่ 12/12/2565</p>
                                    </div>
                                    <div class="content">
                                        <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                            <div class="resultLottoCell">
                                                <span>รางวัลที่1</span>
                                                <strong>121789</strong>
                                            </div>
                                            <div class="resultLottoCell">
                                                <span>รางวัลเลขท้าย 2 ตัว</span>
                                                <strong>64</strong>
                                            </div>
                                        </div>
                                        <div class="resultLottoCellGroup">
                                            <div class="resultLottoCell">
                                                <span>รางวัลเลขหน้า 3 ตัว</span>
                                                <strong>121 789</strong>
                                            </div>
                                            <div class="resultLottoCell">
                                                <span>รางวัลเลขท้าย 3 ตัว</span>
                                                <strong>121 789</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <!--                                        <div class="footer">-->
                                    <!--                                            <p>ดูผลออกรางวัลทั้งหมด</p>-->
                                    <!--                                        </div>-->
                                </div>

                            </div>

                        </div>

                    </div>
                    <div class="containerSubAllIndex" v-if="false">
                        <div class="infoSubAllIndex">
                            <div class="boxHitecCasino">
                                <div class="nav-menu-topic topicGovernmentLottery">
                                    <img class="lotto-type-icon" src="@/assets/images/iconLottoSide.png">
                                    <div class="group">
                                        <h4>หวยเบอร์</h4>
                                        <!--                                    <span>งวดวันที่ 12/12/2565</span>-->
                                    </div>
                                    <div class="btn">ทั้งหมด</div>
                                </div>
                                <div class="LottoNumberContent">
                                    <div class="noneLottoList" v-if="resut_gold_number_list.length==0">
                                        <span>ยังไม่มีรายการ</span>
                                    </div>
                                    <div class="LottoNumberContentGroup"
                                         v-for="(gameList,game_index) of resut_gold_number_list" :key="game_index">
                                        <div class="LottoNumberList" v-for="(game,g_id) of gameList" :key="g_id"
                                             @click="goToBet(game.gbs_id)">
                                            <div class="LottoNumberListTopic">
                                                <div class="LottoNumberListTopicInfo">
                                                    <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                    <h4>{{ title_game_type[game.game_type]}}</h4>
                                                </div>
                                                <span>กระดานที่ {{ game.round_main }}</span>
                                            </div>
                                            <div class="LottoNumberListContent">
                                                <div class="LNListContentLeft">
                                                    <small>งวดวันที่ {{ showDate(game.date)}}</small>
                                                    <ul>
                                                        <li v-if="game.game_type==0">ถูก 2 ตัวล่าง รับ {{
                                                            game.reward_price }}
                                                        </li>
                                                        <!-- <li>ถูก 2 ตัวล่างรับ 350</li>
                                                        <li>ถูก 2 ตัวบนรับ 350</li> -->
                                                    </ul>
                                                    <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                </div>
                                                <div class="LNListContentRight">
                                                    <small>เบอร์ละ</small>
                                                    <strong>{{ game.price }}</strong>
                                                    <span>บาท</span>
                                                    <ul>
                                                        <li class="LNDay">{{ (game.days>0? game.days+' วัน ': '' )
                                                            }}
                                                        </li>
                                                        <li>{{ game.hours }}:{{ game.minutes }}:{{ game.seconds }}
                                                        </li>
                                                    </ul>
                                                    <label>ปิดรับ {{ showEndTime(game.end_datetime) }}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- <div class="LottoNumberContentGroup">
                                        <div class="LottoNumberList">
                                            <div class="LottoNumberListTopic">
                                                <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                <h4>หวยเบอร์ทองเวียดนาม</h4>
                                            </div>
                                            <div class="LottoNumberListContent">
                                                <div class="LNListContentLeft">
                                                    <small>งวดวันที่ 12/12/2565</small>
                                                    <ul>
                                                        <li>ถูก 2 ตัวล่างรับ 350</li>
                                                        <li>ถูก 2 ตัวบนรับ 350</li>
                                                    </ul>
                                                    <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                </div>
                                                <div class="LNListContentRight">
                                                    <small>เบอร์ละ</small>
                                                    <strong>10</strong>
                                                    <span>บาท</span>
                                                    <ul>
                                                        <li class="LNDay">5วัน</li>
                                                        <li>12:33:55</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="LottoNumberListTopic">
                                                <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                <h4>หวยเบอร์ทองเวียดนาม</h4>
                                            </div>
                                            <div class="LottoNumberListContent">
                                                <div class="LNListContentLeft">
                                                    <small>งวดวันที่ 12/12/2565</small>
                                                    <ul>
                                                        <li>ถูก 2 ตัวล่างรับ 350</li>
                                                        <li>ถูก 2 ตัวบนรับ 350</li>
                                                    </ul>
                                                    <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                </div>
                                                <div class="LNListContentRight">
                                                    <small>เบอร์ละ</small>
                                                    <strong>10</strong>
                                                    <span>บาท</span>
                                                    <ul>
                                                        <li class="LNDay">5วัน</li>
                                                        <li>12:33:55</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="LottoNumberListTopic">
                                                <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                <h4>หวยเบอร์ทองเวียดนาม</h4>
                                            </div>
                                            <div class="LottoNumberListContent">
                                                <div class="LNListContentLeft">
                                                    <small>งวดวันที่ 12/12/2565</small>
                                                    <ul>
                                                        <li>ถูก 2 ตัวล่างรับ 350</li>
                                                        <li>ถูก 2 ตัวบนรับ 350</li>
                                                    </ul>
                                                    <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                </div>
                                                <div class="LNListContentRight">
                                                    <small>เบอร์ละ</small>
                                                    <strong>10</strong>
                                                    <span>บาท</span>
                                                    <ul>
                                                        <li class="LNDay">5วัน</li>
                                                        <li>12:33:55</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="LottoNumberContentGroup">
                                        <div class="LottoNumberList">
                                            <div class="LottoNumberListTopic">
                                                <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                <h4>หวยเบอร์ทองเวียดนาม</h4>
                                            </div>
                                            <div class="LottoNumberListContent">
                                                <div class="LNListContentLeft">
                                                    <small>งวดวันที่ 12/12/2565</small>
                                                    <ul>
                                                        <li>ถูก 2 ตัวล่างรับ 350</li>
                                                        <li>ถูก 2 ตัวบนรับ 350</li>
                                                    </ul>
                                                    <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                </div>
                                                <div class="LNListContentRight">
                                                    <small>เบอร์ละ</small>
                                                    <strong>10</strong>
                                                    <span>บาท</span>
                                                    <ul>
                                                        <li class="LNDay">5วัน</li>
                                                        <li>12:33:55</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="LottoNumberListTopic">
                                                <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                <h4>หวยเบอร์ทองเวียดนาม</h4>
                                            </div>
                                            <div class="LottoNumberListContent">
                                                <div class="LNListContentLeft">
                                                    <small>งวดวันที่ 12/12/2565</small>
                                                    <ul>
                                                        <li>ถูก 2 ตัวล่างรับ 350</li>
                                                        <li>ถูก 2 ตัวบนรับ 350</li>
                                                    </ul>
                                                    <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                </div>
                                                <div class="LNListContentRight">
                                                    <small>เบอร์ละ</small>
                                                    <strong>10</strong>
                                                    <span>บาท</span>
                                                    <ul>
                                                        <li class="LNDay">5วัน</li>
                                                        <li>12:33:55</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LottoNumberList">
                                            <div class="LottoNumberListTopic">
                                                <img class="lotto-type-icon" src="@/assets/images/flag/TH.png">
                                                <h4>หวยเบอร์ทองเวียดนาม</h4>
                                            </div>
                                            <div class="LottoNumberListContent">
                                                <div class="LNListContentLeft">
                                                    <small>งวดวันที่ 12/12/2565</small>
                                                    <ul>
                                                        <li>ถูก 2 ตัวล่างรับ 350</li>
                                                        <li>ถูก 2 ตัวบนรับ 350</li>
                                                    </ul>
                                                    <span>***ไม่ถึง 80 เบอร์ จ่าย 50%ของที่ขายได้</span>
                                                </div>
                                                <div class="LNListContentRight">
                                                    <small>เบอร์ละ</small>
                                                    <strong>10</strong>
                                                    <span>บาท</span>
                                                    <ul>
                                                        <li class="LNDay">5วัน</li>
                                                        <li>12:33:55</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                </div>

                            </div>
                        </div>
                        <div class="AllIndexLottoOnline">
                            <div class="nav-menu-topic topicGovernmentLottery">
                                <img class="lotto-type-icon" src="@/assets/images/icon/set-icon/icon-lotto3.png">
                                <div class="group">
                                    <h4>หวยออนไลน์</h4>
                                    <p>Online lottery</p>
                                </div>
                                <div class="btn" @click="gotToLotto2()">ทั้งหมด</div>
                            </div>

                            <div class="container-lotto container-lotto-mobile">
                                <div class="box-list-home-main">

                                </div>
                                <div class="wrap-content-home">
                                    <!--                    bx-desktop-->
                                    <div class="container-lotto container-lotto-mobile  bx-mobile bx-ipad">
                                        <div class="PriceTable card" v-for="(lottoType, key) of lottoTypeArr"
                                             :key="key">
                                            <PriceTableNew :lotto_type="lottoType"/>
                                        </div>

                                    </div>
                                    <div class="container-lotto  bx-desktop bx-ipad">
                                        <carousel
                                                :perPage="1.2"
                                                :mouseDrag="true"
                                                :spacePadding="5"
                                                :navigationEnabled="true"
                                                :paginationEnabled="false"
                                                navigationNextLabel="❯"
                                                navigationPrevLabel="❮"
                                        >
                                            <slide v-for="(lottoType, key) of lottoTypeArr" :key="key"
                                                   class="PriceTable card">
                                                <PriceTable :lotto_type="lottoType"/>
                                            </slide>
                                        </carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="LottoNumberList topic">

                    <div class="boxCheckLotto bx-mobile">
                        <div class="contentCheckLotto">
                            <h5>ตรวจผลสลากกินแบ่งรัฐบาล</h5>
                            <div class="CheckLottoDate">
                                <select class="form-control">
                                    <option>2 พฤษภาคม 2566</option>
                                    <option>2 พฤษภาคม 2566</option>
                                    <option>2 พฤษภาคม 2566</option>
                                    <option>2 พฤษภาคม 2566</option>
                                    <option>2 พฤษภาคม 2566</option>
                                </select>
                            </div>
                            <div class="InputLottoCheck">
                                <ul>
                                    <li>
                                        <input type="text" class="form-control" placeholder="กรอกตัวเลข เพื่อตรวจสลาก">
                                     </li>
                                </ul>
                            </div>
                            <div class="btnCheckLotto">ตรวจสลาก</div>
                        </div>
                    </div>

                    <div class="LottoNumberListColumn">
                        <div class="boxResultSimple" v-for="(result,key) of lotto_result_list" :key="key">
                            <div class="nav-menu-topic topicGovernmentLottery">
                                <div class="group"><h4>ผลการออกรางวัลสลากฯ</h4><span>งวดวันที่ {{ showDateResult(result.date) }}</span>
                                </div>
                            </div>
                            <div class="content">
                                <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                    <div class="resultLottoCell OneBigLotto">
                                        <p>รางวัลที่1</p>
                                        <strong class="text-red">
                                            <span>{{ result.lotto_reward_type_1[0] }}</span>
                                        </strong>
                                    </div>
                                    <div class="resultLottoCell MiniBigLotto">
                                        <p>รางวัลเลขหน้า 3 ตัว</p>
                                        <strong>
                                            <span>{{ result.lotto_reward_type_f3[0] }}</span>
                                            <span>{{ result.lotto_reward_type_f3[1] }}</span>
                                        </strong>

                                    </div>
                                    <div class="resultLottoCell MiniBigLotto">
                                        <p>รางวัลเลขท้าย 3 ตัว</p>
                                        <strong>
                                            <span>{{ result.lotto_reward_type_p3[0] }}</span>
                                            <span>{{ result.lotto_reward_type_p3[1] }}</span>
                                        </strong>

                                    </div>
                                    <div class="resultLottoCell TwoResult">
                                        <p>รางวัลเลขท้าย 2 ตัว</p>
                                        <strong>{{ result.lotto_reward_type_p2[0] }}</strong>

                                    </div>
                                </div>
                            </div>
                            <div class="footer" @click="goLottoGovAll()"><p>ผลรางวัลย้อนหลัง</p></div>
                        </div>

                        <div class="boxCheckLotto bx-desktop">
                            <div class="contentCheckLotto">
                                <h5>ตรวจผลสลากกินแบ่งรัฐบาล</h5>
                                <div class="CheckLottoDate">
                                    <select class="form-control">
                                        <option>2 พฤษภาคม 2566</option>
                                        <option>2 พฤษภาคม 2566</option>
                                        <option>2 พฤษภาคม 2566</option>
                                        <option>2 พฤษภาคม 2566</option>
                                        <option>2 พฤษภาคม 2566</option>
                                    </select>
                                </div>
                                <div class="InputLottoCheck">
                                    <ul>
                                        <li>
                                            <input type="text" class="form-control" placeholder="กรอกตัวเลขใบที่ 1">
                                            <input type="text" class="form-control" placeholder="กรอกตัวเลขใบที่ 2">
                                        </li>
                                        <li>
                                            <input type="text" class="form-control" placeholder="กรอกตัวเลขใบที่ 3">
                                            <input type="text" class="form-control" placeholder="กรอกตัวเลขใบที่ 4">
                                        </li>
                                    </ul>
                                </div>
                                <div class="btnCheckLotto">ตรวจสลาก</div>
                            </div>
                        </div>

                    </div>

<!--                    ผลการค้นหา-->
<!--                    v-if="false"-->
                    <div class="boxCheckLottoResult bx-desktop" v-if="false">
                        <div class="contentCheckLottoResult">
                            <div class="close-box-bonus"></div>
                            <h4>ผลการตรวจสลากหมายเลข</h4>
                            <ul>
                                <li class="lottoWin">
                                    <strong>471781</strong>
                                    <span>ถูกรางวัลข้างเคียงรางวัลที่ 1 ค่ะ</span>
                                    <span>มูลค่า 100,000 บาท</span>
                                </li>
                                <li class="lottoLose">
                                    <strong>534543</strong>
                                    <span>ไม่ถูกรางวัล</span>
                                </li>
                                <li class="lottoLose">
                                    <strong>675665</strong>
                                    <span>ไม่ถูกรางวัล</span>
                                </li>
                                <li class="lottoLose">
                                    <strong>675665</strong>
                                    <span>ไม่ถูกรางวัล</span>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>


            </div>
            <div class="containerRecommendLotto">
                <div class="container">
                    <div class="boxGovernmentLottery">
                        <div class="nav-menu-topic">
                            <!----><h4>แนะนำเลขเด็ด</h4>
                        </div>
                        <div class="LottoNumberContent">
                            <div class="ContentResultSearchNum" v-if="search_number_result.lengh>0">
                                <div class="ListLottoNum" v-for="(lotto,key) of search_number_result" :key="key" :class="{'active':lotto.selected ,'ListLottoNumSet' : lotto.lotto_set_count>1}">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet">
                                                    <p>{{ lotto.lotto_set_count }} ใบ</p>
                                                    <span>{{ lotto.lotto_set_count * 6 }} ล.</span>
                                                </div>
                                                <h4><img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                            </div>
                                            <div class="content">
                                                <ul>
                                                    <li>{{ lotto.lotto_number.charAt(0) }}</li>
                                                    <li>{{ lotto.lotto_number.charAt(1) }}</li>
                                                    <li>{{ lotto.lotto_number.charAt(2) }}</li>
                                                    <li>{{ lotto.lotto_number.charAt(3) }}</li>
                                                    <li class="active">{{ lotto.lotto_number.charAt(4) }}</li>
                                                    <li class="active">{{ lotto.lotto_number.charAt(5) }}</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>{{lotto.lotto_set_count}}</strong>
                                                </div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>{{lotto.lotto_count}}</strong>
                                                </div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel">
                                                <span>{{ lotto.lotto_price }}</span>บาท
                                            </div>
                                            <button class="btn" @click="chooseLotto(lotto,key)" ></button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div  v-else-if="search_number_result.lengh==0">
                                <br>
                                <h2>สลากดิจิทัลในงวดนี้ขายหมดแล้ว</h2><br>
                                <h2>โปรดติดตามสลากฯรอบใหม่ในงวดถัดไป</h2>
                            </div>
                            <div class="ContentResultSearchNum" v-else>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>1 ใบ</p><span>6 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>6</li>
                                                    <li>0</li>
                                                    <li>6</li>
                                                    <li>7</li>
                                                    <li class="active">1</li>
                                                    <li class="active">1</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>1</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>80</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>1 ใบ</p><span>6 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>6</li>
                                                    <li>9</li>
                                                    <li>9</li>
                                                    <li>3</li>
                                                    <li class="active">7</li>
                                                    <li class="active">8</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>1</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>80</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>1 ใบ</p><span>6 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>3</li>
                                                    <li>1</li>
                                                    <li>1</li>
                                                    <li>0</li>
                                                    <li class="active">1</li>
                                                    <li class="active">9</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>1</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>80</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum ListLottoNumSet">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>2 ใบ</p><span>12 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>1</li>
                                                    <li>2</li>
                                                    <li>6</li>
                                                    <li>6</li>
                                                    <li class="active">4</li>
                                                    <li class="active">9</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>2</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>200</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum ListLottoNumSet">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>10 ใบ</p><span>60 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>5</li>
                                                    <li>9</li>
                                                    <li>7</li>
                                                    <li>5</li>
                                                    <li class="active">0</li>
                                                    <li class="active">2</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>10</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>1300</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>1 ใบ</p><span>6 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>1</li>
                                                    <li>8</li>
                                                    <li>4</li>
                                                    <li>7</li>
                                                    <li class="active">1</li>
                                                    <li class="active">2</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>1</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>80</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>1 ใบ</p><span>6 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>4</li>
                                                    <li>9</li>
                                                    <li>7</li>
                                                    <li>3</li>
                                                    <li class="active">6</li>
                                                    <li class="active">4</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>1</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>80</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>1 ใบ</p><span>6 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>9</li>
                                                    <li>9</li>
                                                    <li>8</li>
                                                    <li>3</li>
                                                    <li class="active">7</li>
                                                    <li class="active">4</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>1</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>80</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum ListLottoNumSet">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>8 ใบ</p><span>48 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>4</li>
                                                    <li>4</li>
                                                    <li>8</li>
                                                    <li>2</li>
                                                    <li class="active">1</li>
                                                    <li class="active">7</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>8</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>1000</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="ListLottoNum">
                                    <div class="ListLottoNumGroup">
                                        <div class="ListLottoNumLeft">
                                            <div class="topic">
                                                <div class="tagTopicSet"><p>1 ใบ</p><span>6 ล.</span></div>
                                                <h4><img src="/img/GovernmentLottery-logo.2eff707b.png"
                                                         class="lotto-type-icon">สลากกินแบ่งรัฐบาล</h4></div>
                                            <div class="content">
                                                <ul>
                                                    <li>5</li>
                                                    <li>7</li>
                                                    <li>3</li>
                                                    <li>8</li>
                                                    <li class="active">3</li>
                                                    <li class="active">3</li>
                                                </ul>
                                                <div class="ListDetail">
                                                    <small>ชุดที่</small>
                                                    <strong>1</strong></div>
                                                <div class="ListDetail">
                                                    <small>งวดที่</small>
                                                    <strong>1</strong></div>
                                            </div>
                                            <div class="contentBg"></div>
                                            <div class="contentBgUnder"></div>
                                        </div>
                                        <div class="ListLottoNumRight">
                                            <div class="priceLottoLabel"><span>80</span>บาท</div>
                                            <button class="btn"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!---->
                    </div>
                    <div class="LottoNumberFooter" v-if="this.cart_list.length>0">
                        <div class="boxLottoNumberFooter">
                            <ul>
                                <li><button class="btn" @click="confirmPurchase()">ตรวจสอบรายการ</button></li>
                                <li>
                                    <p>จำนวนที่เลือก</p>
                                    <strong><span>{{ this.cart_list.length }}</span> ใบ</strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import axiosWrapper from '@/axiosWrapper'
    import store from '@/store'
    import moment from 'moment';
    import MenuBar from "@/components/MenuBar";
    import PriceTable from "@/components/lotto/components/PriceTable";
    import PriceTableNew from "@/components/lotto/components/PriceTableNew";
    import {Carousel, Slide} from 'vue-carousel'
    import {LOTTO_TYPE} from '@/components/lotto/Constant.js'
    import UserMixin from '@/mixins/UserMixin'

    export default {
        name: 'LottoNumber',
        mixins: [UserMixin],
        components: {
            MenuBar,
            PriceTable,
            PriceTableNew,
            Carousel,
            Slide,

        },
        computed: {
            getCurrentCart(){
                return store.state.newlotto.cart_list;
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return  this.pes_profile ? this.pes_profile.token :'';
            },

            is_member() {
                return this.token;
            }
        },
        data() {
            return {
                lottoTypeArr: [],
                showChatWithAdminNumber: 0,
                showDepositWithdrawNumber: 0,
                menuNumber: 0,
                'resut_gold_number_list': [],
                'title_game_type': [
                    'หวยเบอร์ทองรัฐบาล'
                ],
                date_result: '',
                date_result_str:null,
                lotto_result_list: [],
                isMenuListLottoSite_show: false,
                isRecommend : true,
                search_number_result:[],
                cart_list: [],
            }

        },
        methods: {
            confirmPurchase(){
                this.$router.push({'name':'LottoNumberCheck'})
            },
            setCartList(cart_list){
                store.commit('newlotto/updateCartList', cart_list);
            },
            OpenListMenu() {
                this.isMenuListLottoSite_show = true;
            },
            CloseListMenu() {
                this.isMenuListLottoSite_show = false;
            },
            goPage(page) {

                if (page === 'bill') {
                    if (!this.is_member) {
                        this.notifyToSignUp('เพื่อดูโพยหวย')
                        return
                    }
                }
                store.commit('lotto/updateCurrentPage', page)
            },
            goAppPage(name) {
                if (!this.is_member) {
                    this.notifyToSignUp()
                    return
                }
                this.$router.push({name})
            },
            showDate(date) {

                let moment_datetime = moment(date).format('DD/MM/YYYY');
                let date_array = moment_datetime.split("/");
                return date_array[0] + '/' + date_array[1] + '/' + (parseInt(date_array[2]) + 543)
            },
            getDateResult() {
                let day = moment().format('D');
                // let hour = moment().format('H');
                if (day == 1) {
                    this.date_result = moment().format('01/MM/YYYY');
                    // if(hour<12){
                    //     this.date_result = moment().format('01/MM/YYYY');
                    // }else{
                    //     this.date_result = moment().format('16/MM/YYYY');
                    // }

                } else if (day == 16) {
                    this.date_result = moment().format('16/MM/YYYY');
                    // if(hour){

                    // }else{

                    // }

                } else if (day < 16) {
                    this.date_result = moment().format('16/MM/YYYY');

                } else {
                    this.date_result = moment().add(1, 'months').format('01/MM/YYYY');
                }
                // console.log('yes', moment().format('DD/MM/YYYY'))
                let date_array = this.date_result.split("/");
                this.date_result = date_array[0] + '/' + date_array[1] + '/' + (parseInt(date_array[2]) + 543)

            },
            showChatWithAdmin() {
                this.showChatWithAdminNumber = 1;
                let self = this;
                setTimeout(() => {
                    self.showChatWithAdminNumber = 0;
                }, 100);
            },
            showEndDay(datetime) {

                let day = moment(datetime).diff(moment(), 'days');
                // a.diff(b, 'days')
                return (day > 0 ? day + ' วัน' : '');

            },
            showEndTime(datetime) {
                let time = moment(datetime).format('HH:mm');
                return time;
            },
            showDepositWithdrawModal() {
                this.showDepositWithdrawNumber = 1;
                let self = this;
                setTimeout(() => {
                    self.showDepositWithdrawNumber = 0;
                }, 100);

            },

            showMenuNav(menuNumber) {
                this.menuNumber = menuNumber;
                let self = this;
                setTimeout(() => {
                    self.menuNumber = 0;
                }, 1000);
            },
            goToSearch() {
                
                if (!this.is_member) {
                    this.notifyToSignUp()
                    return
                }
                if (this.date_result) {
                    this.$router.push({'name': 'LottoNumberSearch'})
                } else {
                    alert('ยังไม่มีฉลากงวดใหม่เปิดขาย');
                }

            },
            goLottoGovAll() {
                this.$router.push({'name': 'LottoNumberResultGovernmentAll'})
            },
            goHome() {
                this.$router.push({'name': 'Index'})
            },
            gotToLotto2() {
                this.$router.push({'name': 'IndexLotto2'})
            },
            goToBillList() {
                this.$router.push({'name': 'LottoNumberBill'})
            },
            goToResultList() {
                this.$router.push({'name': 'LottoNumberResultGovernment'})
            },
            goToBet(gbs_id) {
                this.$router.push({'name': 'LottoNumberBetDetail', params: {gbs_id: gbs_id}})
            },
            async getGoldNumberList() {

                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gold_number/get?token=` + this.token + '&game_status=1&game_type=0&date=',
                    data: {}
                })
                this.resut_gold_number_list = []
                let new_list = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i].game_status == 1) {
                        res.data.data[i].days = 0;
                        res.data.data[i].hours = 0;
                        res.data.data[i].minutes = 0;
                        res.data.data[i].seconds = 0;
                        new_list.push(res.data.data[i]);

                        if (!this.date_result) {
                            let first_data = res.data.data[i];
                            // console.log('first',first_data);
                            let date_array = moment(first_data.date).format('YYYY-MM-DD').split("-");

                            this.date_result = date_array[2] + '/' + date_array[1] + '/' + (parseInt(date_array[0]) + 543)

                        }

                    }
                }
                for (let i = 0; i < new_list.length; i++) {
                    if (i == 0) {
                        this.resut_gold_number_list.push([]);
                    } else if (i > 2) {
                        break;
                    }
                    let index = this.resut_gold_number_list.length - 1;
                    this.resut_gold_number_list[index].push(new_list[i])

                }
                let first_data = res.data.data[0];
                if(first_data){
                    // console.log('first',first_data);
                let date_array = moment(first_data.date).format('YYYY-MM-DD').split("-");
                this.date_result_str = moment(first_data.date).format('YYYY-MM-DD')
                // console.log('getGoldNumberLIst', this.date_result_str)
                
                this.date_result = date_array[2]+'/'+date_array[1]+'/'+(parseInt(date_array[0])+543) 
                }
                
                    

                // if(res.data.data.length>0){
                //     let first_data = res.data.data[0];
                //     let date_array = moment(first_data.date).format('YYYY-MM-DD').split("-");

                //     this.date_result = date_array[2]+'/'+date_array[1]+'/'+(parseInt(date_array[0])+543)
                // }else{
                //     //
                // }

            },
            async getLottoResultList() {
                let limit = 1;
                let page = 1;
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gov_lotto/get-result-list?token=${this.token}&limit=${limit}&game_type=&page=${page}`,
                    data: {}
                })
                this.lotto_result_list = res.data.data;
                for (let i = 0; i < this.lotto_result_list.length; i++) {
                    this.lotto_result_list[i].lotto_reward_type_1 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_1);
                    this.lotto_result_list[i].lotto_reward_type_2 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_2);
                    this.lotto_result_list[i].lotto_reward_type_3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_3);
                    this.lotto_result_list[i].lotto_reward_type_4 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_4);
                    this.lotto_result_list[i].lotto_reward_type_5 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_5);
                    this.lotto_result_list[i].lotto_reward_type_f3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_f3);
                    this.lotto_result_list[i].lotto_reward_type_p3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_p3);
                    this.lotto_result_list[i].lotto_reward_type_n1 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_n1);
                    this.lotto_result_list[i].lotto_reward_type_p2 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_p2);
                }
            },
            updateRemaining(distance, index1, index2) {
                this.resut_gold_number_list[index1][index2].days = Math.floor(distance / (1000 * 60 * 60 * 24))
                this.resut_gold_number_list[index1][index2].hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                this.resut_gold_number_list[index1][index2].minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                this.resut_gold_number_list[index1][index2].seconds = Math.floor((distance % (1000 * 60)) / 1000)
                // console.log(this.resut_gold_number_list[index1][index2])

            },

            tick(index1, index2) {
                const currentTime = new Date()
                const distance = Math.max(Date.parse(this.resut_gold_number_list[index1][index2].end_datetime) - currentTime, 0)
                this.updateRemaining(distance, index1, index2)

                // if (distance === 0) {
                //     clearInterval(this.timer)
                //     this.isEnded = true
                // }
            },
            setTick() {
                for (let index1 = 0; index1 < this.resut_gold_number_list.length; index1++) {
                    for (let index2 = 0; index2 < this.resut_gold_number_list[index1].length; index2++) {
                        this.tick(index1, index2)
                    }
                }
            },
            async loadPriceTable() {
                const res = axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/price/load_table`,
                    data: {
                        no_round: 'yes'
                    }
                })
                if (res.status === 200) this.price_table = res.data
            },
            async loadRewardLimit() {
                const res = await axiosWrapper({
                    method: 'POST',
                    url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/reward/get-all`
                })
                if (res.status === 200) this.reward_limit = res.data
            },
            showDateResult(date) {

                let moment_datetime = moment(date).format('DD/MM/YYYY');
                let date_array = moment_datetime.split("/");
                return date_array[0] + '/' + date_array[1] + '/' + (parseInt(date_array[2]) + 543)
            },
            async getRecommend(closeModal){
                if(this.date_result_str){
                    let limit =12;
                    let type='all';
                    if(this.is_lotto_type_multi){
                        type='multi';
                    }else if(this.is_lotto_type_single){
                        type='single';
                    }
                    // console.log('getRecommend', this.date_result_str)
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gov_lotto/get-recommend?token=`+this.token+'&limit='+limit+'&type='+type+'&date='+this.date_result_str,
                        data: {
                            
                        }
                    })
                    this.isRecommend=true;
                    // this.closeModalSearch();
                    this.search_number_result = res.data.data;
                    for(let i=0;i<this.search_number_result.length;i++){
                        this.search_number_result[i].lotto_date = this.date_result_str;
                    }
                    console.log(this.search_number_result)
                    if(closeModal){
                        this.closeModalSearch()
                    }
                }else{
                    alert('ยังไม่มีฉลากงวดใหม่เปิดขาย');
                }
                
                
            },
            chooseLotto (l,index){  
                if(!this.is_member){
                    this.notifyToSignUp()
                    return;
                }
                if(this.search_number_result[index].selected){
                    let index_remove = 0;
                    for(let i=0;i<this.cart_list.length;i++){
                        if(this.cart_list[i].lotto_id==l.lotto_id){
                            index_remove=i;
                            break;
                        }
                        
                    }
                    this.cart_list.splice(index_remove,1);
                    this.search_number_result[index].selected=false;

                }else{
                    this.search_number_result[index].selected=true;
                    this.cart_list.push(l);
                }
                this.setCartList(this.cart_list);
            },
        },
        created() {
            for (const key in LOTTO_TYPE) {
                this.lottoTypeArr.push(key);
            }
        },
        async mounted() {
            if(this.is_member){
                this.cart_list = this.getCurrentCart
            }
            
            this.loadPriceTable()
            this.loadRewardLimit()
            await this.getLottoResultList();
            await this.getGoldNumberList();
            this.setTick();
            setInterval(this.setTick.bind(this), 1000)
            if(this.date_result_str){
                await this.getRecommend();
            }
            // this.getDateResult();


            // this.timer = setInterval(this.tick.bind(this), 1000)

        }
    }
</script>
<style>
    .wrapContentIndexLotto .active .itemList li.item.itemLotto {
        background-color: white;
        border: solid 1px rgba(0, 0, 0, 0.84);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    }

    .wrapContentIndexLotto .active .itemList li.item.itemLotto p,
    .wrapContentIndexLotto .active .itemList li.item.itemLotto span {
        color: black
    }

    /*Theme*/
    .themeBlackGold .wrapContentIndexLotto .active .itemList li.item.itemLotto {
        background-image: linear-gradient(to bottom, #fec225 0%, #e19d0e 100%) !important;
        background-color: transparent;
        border: solid 1px rgba(254, 194, 37, 0.84);
        box-shadow: 0 5px 10px rgba(254, 194, 37, 0.3);
    }

    .themeDarkGray .wrapContentIndexLotto .active .itemList li.item.itemLotto {
        background-color: #5f646a !important;
        border: solid 1px rgba(95, 100, 106, 0.84);
        box-shadow: 0 5px 10px rgba(59, 59, 59, 0.3);
    }

    .themeDarkGray .wrapContentIndexLotto .active .itemList li.item.itemLotto p,
    .themeDarkGray .wrapContentIndexLotto .active .itemList li.item.itemLotto span {
        color: #fff !important;
    }

    .themeLightGray .wrapContentIndexLotto .active .itemList li.item.itemLotto {
        background-color: #edeef5 !important;
        border: solid 1px rgb(232, 232, 232) !important;
        box-shadow: none;
    }

    .themeLightGray .wrapContentIndexLotto .active .itemList li.item.itemLotto p,
    .themeLightGray .wrapContentIndexLotto .active .itemList li.item.itemLotto span {
        color: #222 !important;
    }
</style>