<template>
    <div class="BetPanel BetPanel-side" :class="{'sidebar-box': !$isMobileTablet}" id="box-play">

        <div class="tab-header">
            <div class="row p-1">
                <div class="tab-title-bill fa-pull-left">
                    <strong>
                        <i class="fas fa-receipt"></i> รายการแทง <span class="badge">{{bet_list.length}}</span>
                    </strong>
                </div>
            </div>
        </div>
        <div class="wrap-box-bill">
            <div class="wrap-box-bill-info">
                <div v-for="(key, i) of bill_key" :key="i">
                    <div v-if="bet_list_filter(key).length">
                        <strong class="wrap-box-bill-title">{{category_list[key].name}}</strong>
                        <div v-for="(bet, i) in bet_list_filter(key)" :key="i"
                             class="colContent" :class="{
                                'v-is-duplicate': isDuplicate(bet.number_bet_type),
                                'v-is-not-allow': !bet.is_allow,
                            }">
                            <div class="bill-input-group input-group-sm">
                                <div class="input-group-prepend input-group-prepend-cs">
                                    <div class="num-bet" >
                                        {{bet.number}}
                                    </div>
                                </div>
                                <div class="flex-bill-input-group">
                                    <div :class="{'amount-out-of-range': calIsAmountOutOfRange(category_list[key].slug, bet.amount)}">
                                        <input type="number" class="form-control" 
                                           @keyup="bet_amount_changed(bet)" v-model="bet.amount"
                                           @focus="selectAllInputText($event)"
                                           aria-label=""
                                           aria-describedby="basic-addon1">
                                    </div>

                                    <div class="input-group-append">
                                        <div class="btn-text-num">
                                            <Price :price="bet.reward"
                                                   :price_change="cal_price_change(bet.number, bet.bet_type)"/>
                                            , ชนะ : {{cal_reward(bet.amount, bet.reward) |
                                            floatFormat}}
                                        </div>
                                        <div class="text-input-group-append">ขั้นต่ำ: {{getMinBetPerNumber(category_list[key].slug)}}, สูงสุด: {{getMaxBetPerNumber(category_list[key].slug)}}</div>
                                        <button @click="cancel_by_id(bet.bet_id)"
                                                class="btn btnTrash" type="button" title="ลบ">
                                            <i class="far fa-trash-alt" title="ลบ"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrap-box-price-all">
            <div class="wrap-box-price-all-option">
                <div class="col-12 box-btnNum1">
                <div @click="showDuplicate" class="btn">ดูเลขซ้ำ</div>
                <div @click="confirmRemoveDuplicate" class="btn">ตัดเลขซ้ำ</div>
                </div>
                <div class="col-12 box-btnNum2">
                    <div v-for="quick_amount_all of quick_amount_all_arr"
                         :key="quick_amount_all"
                         @click="amount_all = quick_amount_all"
                         class="btnPrice1"
                        :class="{'active': quick_amount_all === amount_all}">
                        {{quick_amount_all}}฿
                    </div>
                </div>
                <div class="col-12" style="margin-top: 6px;">
                    เดิมพันราคาเท่ากัน
                </div>
            </div>
            <div class="colBasic-addon">
                <div class="input-group">
                    <!-- <div class="input-group-prepend">-->
                        <!--<button class="btn" type="button" placeholder="เดิมพันราคาเท่ากัน"></button>-->
                    <!--</div> -->
                    <input v-model="amount_all" @focus="selectAllInputText($event)" @keyup.enter="closeSoftKeyboard" ref="amount_all_input" type="number" class="form-control"/>
                </div>
                <div>
                    <b-form-checkbox
                            id="checkbox-1"
                            v-model="accept_any_odd"
                            name="checkbox-1"
                            :value="true"
                            :unchecked-value="false">
                        ยอมรับทุกราคา
                    </b-form-checkbox>
                </div>
            </div>
            <div class="box-total">รวมยอดแทง<span><strong>{{cal_total_amount | floatFormat}}</strong></span></div>

            <div class="box-button-confirm">
                <div class="col-4" style="padding-right: 0">
                    <button @click="$bvModal.show('cancel_all_confirm')"
                            :disabled="bet_list.length === 0"
                            class="btn btn-danger btn-block btn-lg">
                        <span>ยกเลิก</span>
                        </button>
                </div>
                <div class="col-8">
                    <button @click="before_send_bet" class="btn btn-success btn-block btn-lg">
                        ส่งโพย
                    </button>
                </div>
            </div>
        </div>

        <b-modal @ok="bet_cancel_all" id="cancel_all_confirm" hide-header centered
        ok-title="ตกลง"
        cancel-title="ยกเลิก">
            <p class="my-4">ต้องการลบเลขทั้งหมด?</p>
        </b-modal>

        <b-modal id="ConfirmBetModal" 
        no-close-on-backdrop 
        no-close-on-esc 
        title="ดูโพยอีกครั้งก่อนส่งโพย" 
        hide-footer
        size="lg">
            <ConfirmBet @bet_confirm="send_bet" @bet_cancel="closeConfirmBetModal" :round="round_obj"/>
        </b-modal>

        <b-modal modal-class="editModal-bet"  id="please_wait_modal" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
           <div class="content-editModal-bet editModal-bet-spin">
               <i class="fas fa-spinner"></i>
               <p class="my-4" style="text-align: center;">กำลังส่งโพย กรุณารอสักครู่</p>
               <div class="content-pleaseWait-note">
                   <h6>Tips</h6>
                   <span>
                       หลังจากส่งโพยเสร็จเรียบร้อย ลูกค้าสามารถเข้าไปตรวจสอบโพยได้ที่ปุ่ม"ดูโพย" และสามารถแทงบิลต่อไปได้ที่ปุ่ม"แทงต่อ"
                   </span>
               </div>
           </div>

        </b-modal>

        <b-modal modal-class="editModal-bet" @close="bet_message = ''" id="bet_message_modal" hide-header centered>
            <div class="content-editModal-bet editModal-bet-alert">
                <i class="fas fa-exclamation-triangle"></i>
                <h5>{{betMessageDisplay(bet_message)}}</h5>
                <div class="editModal-bet-alert-content">
                    <p v-for="(status, key) of arr_status" :key="key">{{status.msg}}</p>
                </div>
<!--                <h5>ยอดซื้อเต็ม!! กำลังปรับเรทอัตราจ่ายใหม่</h5>-->

<!--                <span  style="font-size: 16px;">เลข 03 ยอดซื้อเต็ม</span>-->
                <div class="content-pleaseWait-note" style="margin-top: 30px;">
                    <h6>Tips</h6>
                    <span>
                        ยอดซื้อเต็ม หมายถึง มีการซื้อตัวเลขเดียวเป็นจำนวนมากในระยะเวลา
                        อันสั้นจึงทำให้ไม่สามารถซื้อตัวเลขนี้ได้ในขณะนั้น ลูกค้าสามารถซื้อตัวเลขอื่นหรือ
                        รอการปรับเรทอัตราจ่ายใหม่และค่อยซื้ออีกครั้ง
                    </span>
                </div>
            </div>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-button variant="secondary" class="btn bg-gradient-btn" @click="$bvModal.hide('bet_message_modal')">ปิด
                    </b-button>
                    <b-button v-if="validate_case === 'odd_change' && !accept_any_odd" @click="submit_accept_any_odd"
                              variant="primary" class="float-right" style="margin: 0 4px;">ยอมรับทุกราคา
                    </b-button>
                </div>
            </template>
        </b-modal>

        <b-modal @hide="clearBetList" id="BetDoneModal" no-close-on-backdrop no-close-on-esc 
        title="ระบบได้ทำการส่งโพยของท่านเรียบร้อยแล้ว" 
        hide-footer
        @shown="onBetDoneModalShow"
        @hidden="onBetDoneModalHide">
            <ConfirmBet
                    :round="round_obj"
                    :is_done="true"
                    :bill_id="bill_id"
                    @bet_done_close="closeBetDoneModal"
                    @go_bill_detail="goBillDetail"/>
        </b-modal>
        <b-modal @ok="removeDuplicate" id="remove_duplication_confirm_modal" hide-header centered
        ok-title="ตกลง"
        cancel-title="ยกเลิก">
            <p class="my-4">ต้องการลบเลขซ้ำทั้งหมด?</p>
        </b-modal>
    </div>
</template>

<script>
    import store from '@/store';
    import {BILL_KEY, BILL_KEY_PRIORITY} from "../Constant";
    import debounce from "@/debounce";
    import Price from "./Price";
    import axios_wrapper from "@/axiosWrapper";
    import ConfirmBet from "./ConfirmBet";
    import BetMixin from '../mixins/BetMixin';
    import ArrHandle from '../lib/ArrHandle';
    import UserMixin from '@/mixins/UserMixin'
    import sort_wrapper from '../lib/sort_wrapper';

    export default {
        name: "BetPanel",
        components: {ConfirmBet, Price},
        mixins: [
            BetMixin,
            UserMixin,
        ],
        props: [
            'config',
            'setting_xx',
            'setting_xxx',
        ],
        computed: {
            channel: function () {
                return store.state.channel;
            },
            bet_list: function(){
                return store.state.lotto.bet_list;
            },
            accept_any_odd: {
                get: function () {
                    return store.state.lotto.accept_any_odd;
                },
                set: function (new_val) {
                    store.commit('lotto/update_accept_any_odd', new_val);
                }
            },
            cal_total_amount: function () {
                return this.bet_list.reduce((sum, number) => {
                    return parseFloat(sum) + parseFloat(number.amount);
                }, 0)
            },
            category_list: function(){
                return store.state.lotto.category_list;
            },
            price_dynamic: function(){
                return store.state.lotto.price_dynamic;
            },
            price_dynamic_3: function(){
                return store.state.lotto.price_dynamic_3;
            },
            user_profile: function () {
                return store.state.user_profile;
            },
            pes_profile: function () {
                return store.state.pes_profile;
            },
            pes_currency : function () {
                return store.state.pes_profile.mode==0?'THB':'DIAMOND';
            },
            pes_balance: function () {
                if (this.pes_profile) {
                    if (this.pes_profile.balances[0]['THB_free']) {
                        return parseFloat(this.pes_profile.balances[0]['THB_free']);
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            },
            amount_all: {
                get(){
                    return store.state.lotto.amount_all;
                },
                set(value){
                    store.commit('lotto/update_amount_all', value);
                }
            },
            round_obj(){
                return store.state.lotto.round_obj;
            },
            round_lotto_type: function(){
                return (this.round_obj) ? this.round_obj.lotto_type : '';
            },
            is_member() {
                return store.state.is_member
            },
            isOnBetDoneModalShow: {
                get() {
                    return store.state.lotto.isOnBetDoneModalShow
                },
                set(value) {
                    store.commit('lotto/updateIsOnBetDoneModalShow', value)
                }
            },
        },
        data(){
            return {
                bill_key: [],
                bet_message: '',
                arr_status: [],
                validate_case: 'other',
                bill_id: null,
                duplicate_number_map: null,
                quick_amount_all_arr: [5, 10, 20, 50, 100]
            }
        },
        watch: {
            amount_all: function (new_val) {
                if (new_val) {
                    for (let i = 0; i < this.bet_list.length; i++) {
                        this.bet_list[i].amount = new_val;
                    }
                }
            },
            bet_list: function(){
                if(this.duplicate_number_map){
                    this.calDuplicate();
                }
            }
        },
        methods: {
            bet_list_filter: function (search) {
                return this.bet_list.filter((bet) => {
                    return bet.bet_type === search
                });
            },
            bet_amount_changed: debounce(function (bet) {
                try {
                    let amount = parseFloat(bet.amount);
                    if (!amount || isNaN(amount) || amount < 1) {
                        bet.amount = 1;
                    }
                } catch (err) {
                    bet.amount = 1;
                }
            }, 1000),
            cal_price_change: function (number, bet_type) {
                try {
                    return this.bet_get_price(bet_type, number);
                } catch (err) {
                    return 0;
                }
            },
            cal_reward: function (amount, mul) {
                return isNaN(parseFloat(amount) * parseFloat(mul)) ? 0 : parseFloat(amount) * parseFloat(mul);
            },
            cancel_by_id: function (bet_id) {
                let i = this.bet_list.findIndex((o) => {
                    return o.bet_id === bet_id
                });
                store.commit('lotto/remove_bet_list', i);
            },
            before_send_bet: function(){
                if(!this.is_member){
                    this.notifyToSignUp('เพื่อเล่น')
                    return
                }
                if (this.pes_balance < this.cal_total_amount) {
                    this.bet_message = 'เครดิตของท่านไม่เพียงพอ';
                    this.$bvModal.show('bet_message_modal');
                    return
                }
                if (this.bet_list.length === 0) {
                    return
                }
                if(this.calIsAmountOutOfRanges()) {
                    this.$bvToast.toast(`ยอดแทงเกินกำหนด`, {
                        title: 'แจ้งเตือน',
                        toaster: 'b-toaster-top-full',
                    })
                    return
                }
                this.$bvModal.show('ConfirmBetModal');
            },
            submit_accept_any_odd: async function () {
                store.commit('lotto/update_accept_any_odd', true);
                this.update_bet_list();
                this.$bvModal.hide('bet_message_modal');
            },
            send_bet: debounce(async function () {

                if (this.user_profile && this.pes_profile) {

                    this.$bvModal.hide('ConfirmBetModal');

                    this.$bvModal.show('please_wait_modal');

                    let payload = await this.generate_payload();
                    let bet_res = await axios_wrapper({
                        method: 'POST',
                        url: `${this.envDomains.VUE_APP_LOTTO_API}/api/bill/create`,
                        data: payload
                    });
                    if (bet_res.status === 200) {

                        this.$bvModal.hide('please_wait_modal');
                        // const _this = this;
                        // setTimeout(function(){
                        //     _this.$jquery('#BetDoneModal').modal('show');
                        //
                        // }, 400);
                        this.$bvModal.show('BetDoneModal');

                        this.requestUserReload()

                        this.bill_id = bet_res.data[0].bill_id;
                    } else {
                        this.bet_message = bet_res.data.message;
                        this.arr_status = bet_res.data.arr_status;
                        this.validate_case = bet_res.data.validate_case;
                        this.$bvModal.hide('please_wait_modal');
                        this.$bvModal.show('bet_message_modal');
                    }

                } else {
                    this.notifyToSignUp('เพื่อเล่น')
                }

            }, 400),
            requestUserReload() {
                store.commit('updateNeedUserReloadWithForceRefreshBalance', true)
            },
            closeConfirmBetModal: function(){
                this.$bvModal.hide('ConfirmBetModal');
            },
            generate_payload: async function () {
                let currency = this.pes_currency
                console.log('currency',currency);

                const bet_list = this.bet_list.map((b) => {
                    return {
                        "lotto_type": this.round_lotto_type,
                        "round_id": this.round_obj.id,
                        "round_date": this.round_obj.date,
                        "sub_round": (this.round_obj.sub_round) ? this.round_obj.sub_round : null,
                        "bet_type": b.bet_type,
                        "bet_number": b.number,
                        "amount": b.amount,
                        "reward": b.reward,
                        "priority": BILL_KEY_PRIORITY[b.bet_type],
                        "currency" : currency,
                    }
                });

                sort_wrapper(bet_list, 'priority')

                const cost = this.bet_list.reduce((sum, number) => {
                    return parseFloat(sum) + parseFloat(number.amount)
                }, 0);

                return {
                    channel: this.channel,
                    lotto_type: this.round_lotto_type,
                    round_id: this.round_obj.id,
                    token: this.pes_profile.token,
                    bet_list: bet_list,
                    cost: cost,
                    result_cost: cost,
                };
            },
            closeBetDoneModal: function(){
                this.$bvModal.hide('BetDoneModal');
            },
            goBillDetail: function(){
                this.$bvModal.hide('BetDoneModal');
                store.commit('lotto/update_is_modal_open', false);
                this.$emit('goBillDetail', this.bill_id)
            },
            clearBetList(){
                store.dispatch('lotto/clearBetList');
            },
            showDuplicate(){
                if(!this.duplicate_number_map){
                    this.calDuplicate();
                }else{
                    this.duplicate_number_map = null;
                }
            },
            calDuplicate(){
                this.duplicate_number_map = ArrHandle.generateDuplicateMap(this.bet_list, 'number_bet_type');
            },
            confirmRemoveDuplicate(){
                if(!this.duplicate_number_map){
                    this.calDuplicate();
                }
                const duplicated_arr = ArrHandle.findDuplicate(this.bet_list, 'number_bet_type', this.duplicate_number_map);
                if(this.duplicate_number_map && duplicated_arr.length > 0){
                    this.$bvModal.show('remove_duplication_confirm_modal');
                }
            },
            removeDuplicate(){
                if(this.duplicate_number_map){
                    const duplicated_arr = ArrHandle.findDuplicate(this.bet_list, 'number_bet_type', this.duplicate_number_map);
                    const duplicated_grouped_map = ArrHandle.groupBy(duplicated_arr, 'number_bet_type');
                    for(let key in duplicated_grouped_map){
                        const dup_arr = duplicated_grouped_map[key];
                        for(let i = 1;i < dup_arr.length;i++){
                            this.cancel_by_id(dup_arr[i].bet_id);
                        }
                    }
                    this.duplicate_number_map = null;
                }
            },
            isDuplicate(number_bet_type){
                return (this.duplicate_number_map) ? this.duplicate_number_map[number_bet_type] : 0;
            },
            closeSoftKeyboard(){
                this.$refs.amount_all_input.blur()
            },
            selectAllInputText(e){
                e.target.select()
            },
            onBetDoneModalShow() {
                this.isOnBetDoneModalShow = true
            },
            onBetDoneModalHide() {
                this.isOnBetDoneModalShow = false
            },
            betMessageDisplay(message) {
                if(message === 'Balance not enough') {
                    return 'เครดิตของท่านไม่เพียงพอ'
                } else {
                    return message
                }
            },
            getMinBetPerNumber(category) {
                if(category.includes('song') || category.includes('wing')) {
                    return this.setting_xx.bet_min
                } else if(category.includes('sam')) {
                    return this.setting_xxx.bet_min
                }
            },
            getMaxBetPerNumber(category) {
                return this.config[category].bet_max
            },
            calIsAmountOutOfRange(category, amount) {
                const min = parseFloat(this.getMinBetPerNumber(category))
                const max = parseFloat(this.getMaxBetPerNumber(category))
                return parseFloat(amount) < min || parseFloat(amount) > max
            },
            calIsAmountOutOfRanges() {
                let isAmountOutOfRange = false
                for(const key of this.bill_key) {
                    for(const bet of  this.bet_list_filter(key)) {
                        const result = this.calIsAmountOutOfRange(this.category_list[key].slug, bet.amount)
                        if(result) {
                            isAmountOutOfRange = true
                            break
                        }
                    }
                    if(isAmountOutOfRange) {
                        break
                    }
                }
                return isAmountOutOfRange
            },
        },
        created() {
            this.bill_key = BILL_KEY;
        },
        mounted() {

            // this.$bvModal.show('bet_message_modal');

            if(this.$isMobileTablet){
                // this.$refs.amount_all_input.focus()
                const wrapper = document.querySelector('#bet_panel_wrapper')
                if(wrapper){
                    wrapper.scrollTo(0, wrapper.scrollHeight)
                }
            }
        },
        beforeDestroy() {
            this.isOnBetDoneModalShow = false
        }
    }
</script>
<style>
    .editModal-bet .modal-content{
        height: 100vh;
    }
    .editModal-bet .modal-body {
       display: flex;
        align-items: center;
        justify-content: center;
    }
    .editModal-bet .modal-dialog{margin: 0}

    .editModal-bet p{
        font-size: 18px;
    }
    .editModal-bet{text-align: center;}
    .editModal-bet i{
        display: block;
        font-size: 70px;
        color: #aaa;
    }
    .editModal-bet-spin i{
        animation: spin 2s linear infinite;
    }
    @keyframes spin {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
    }
    .editModal-bet-alert h5{font-size: 23px; margin-bottom: 30px; position: relative;}
    .editModal-bet-alert h5:after{
        content: '';
        position: absolute;
        width: 15%;
        height: 5px;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -7px;
        background-color: #d20a84;
    }
    .editModal-bet-alert i{color: rgba(210, 10, 132, 0.1); margin-bottom: 15px;}
    .editModal-bet-alert p{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-bottom: 0;
        font-size: 18px;
    }
    .editModal-bet-alert p:before{
        content: '\f105';
        font: 800 18px "Font Awesome\ 5 Free";
        margin-right: 5px;
        color: #d20a84;
    }
    .editModal-bet .btn{
        min-width: 100px;
        font-size: 20px;
        border-radius: 20px;
        border: 0;
    }
    .content-pleaseWait-note{
        /*text-align: left;*/
        /*border-color: ;*/
    }
    .content-pleaseWait-note h6 {
        width: 100%;
        font: 600 18px Athiti;
        padding: 0;
        color: #D00B84;
    }
    .content-pleaseWait-note h6:before {
        content: '\f05a';
        display: inline-block;
        margin-right: 5px;
        font: 800 14px 'Font Awesome\ 5 Free';
    }
    .content-pleaseWait-note span{
        background-color: rgba(208, 11, 132, 0.15);
        display: inline-block;
        color: #660d34;
        font: 400 16px/30px Helvetica;
        border-radius: 10px;
        padding: 30px 15px;
        height: auto;
    }
    .editModal-bet-alert .content-pleaseWait-note span{
        background-color: transparent;
        border: solid 1px rgba(208, 11, 132, 0.15);
    }
    .editModal-bet-alert .editModal-bet-alert-content{
        border-radius: 10px;
        padding: 30px 15px;
        background-color: rgba(208, 11, 132, 0.15);
        color: #660d34;
    }
    .darkTheme .editModal-bet .content-pleaseWait-note span{
        background-color: rgba(255, 255, 255, 0.07);
    }
    .darkTheme .editModal-bet .content-pleaseWait-note span,
    .darkTheme .content-pleaseWait-note h6{
        color: white;
    }
    .darkTheme .editModal-bet-alert i{color: rgba(255, 255, 255, 0.1);}
    .darkTheme .editModal-bet .modal-footer{
        border-color: rgba(255, 255, 255, 0.1);
    }
    .darkTheme .editModal-bet-alert .editModal-bet-alert-content{
        color: #ff0aa2;
    }
    .darkTheme .editModal-bet-alert .content-pleaseWait-note span{
        background-color: transparent;
        border: solid 1px rgba(255, 255, 255, 0.15);
    }
    @media only screen
    and (min-device-width: 1300px)
    and (orientation: landscape){
        .editModal-bet .modal-dialog{
            width: 375px;
            position: fixed;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
</style>
<style scoped>

    .v-is-duplicate {
        background-color: yellow;
    }
    .v-is-not-allow {
        background-color: orange;
    }
    .amount-out-of-range input{
        border: solid 1px red;
    }
    .amount-out-of-range input:focus{
        border: solid 1px red;
        box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.57);
    }
</style>