<template>

    <div class="wrapContentIndex LottoNumber LottoNumberResult LottoNumberResultAll">
        <div class="wrapContentIndexTitle ">
            <div class="IndexTitleInto">
                <button style="padding: 0 10px;" class="btn " @click="goBack()"><i class="fas fa-chevron-left"></i></button>
                <h3>ผลการออกรางวัล</h3>
            </div>
        </div>
        <div class="wrapContentIndexInfo wrapContentIndexInfoResultGovernment">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="container">
                        <div class="boxHitecCasino boxGovernmentLottery">
                            <div class="LottoNumberContent">
                                <div class="LottoNumberList topic" v-if="lotto_result">
                                    <div class="boxResultSimple">
                                        <div class="nav-menu-topic topicGovernmentLottery">
                                            <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">
                                            <div class="group">
                                                <h4>ผลการออกรางวัลสลากฯ</h4>
                                                <span>งวดวันที่ {{ showDateResult(lotto_result.date) }}</span>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                <div class="resultLottoCell OneBigLotto">
                                                    <strong class="text-red">{{ lotto_result.lotto_reward_type_1[0] }}</strong>
                                                    <p>รางวัลที่1</p>
                                                </div>

                                                <div class="resultLottoCell MiniBigLotto">
                                                    <strong>
                                                        <span>{{ lotto_result.lotto_reward_type_f3[0] }}</span>
                                                        <span>{{ lotto_result.lotto_reward_type_f3[1] }}</span>
                                                    </strong>
                                                    <p>รางวัลเลขหน้า 3 ตัว</p>
                                                </div>
                                                <div class="resultLottoCell MiniBigLotto">
                                                    <strong>
                                                        <span>{{ lotto_result.lotto_reward_type_p3[0] }}</span>
                                                       <span>{{ lotto_result.lotto_reward_type_p3[1] }}</span>
                                                    </strong>
                                                    <p>รางวัลเลขท้าย 3 ตัว</p>
                                                </div>
                                                <div class="resultLottoCell TwoResult">
                                                    <strong>{{ lotto_result.lotto_reward_type_p2[0] }}</strong>
                                                    <p>รางวัลเลขท้าย 2 ตัว</p>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="footer"  @click="goToInfo(lotto_result.date)">
                                            <p>ดูทั้งหมด</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="LottoNumberContentBack ResultNumLotto">
                                    <h4>ตรวจสลากกินแบ่งรัฐบาล ย้อนหลัง</h4>
                                    <div class="LottoNumberList" v-for="(result,key) of lotto_result_list" :key="key">
                                        <div class="boxResultSimple" @click="goToInfo(result.date)">
                                            <div class="title">
                                                <i class="fas fa-calendar-week"></i>
                                                <p>งวดวันที่</p>
                                                <h3>{{ showDateResult(result.date) }}</h3>
                                                <button style="padding: 0 10px;" class="btn "><i class="fas fa-chevron-right"></i></button>
                                            </div>
                                            <div class="content">
                                                <div class="resultLottoCellGroup">
                                                    <div class="resultLottoCell OneBigLotto">
                                                        <strong>{{ result.lotto_reward_type_1[0] }}</strong>
                                                        <p>รางวัลที่1</p>
                                                    </div>
                                                    <div class="resultLottoCell MiniBigLotto">
                                                        <strong>
                                                            <span>{{ result.lotto_reward_type_f3[0] }}</span>
                                                            <span>{{ result.lotto_reward_type_f3[1] }}</span>
                                                        </strong>
                                                        <p>รางวัลเลขหน้า 3 ตัว</p>
                                                    </div>
                                                    <div class="resultLottoCell MiniBigLotto">
                                                        <strong>
                                                            <span>{{ result.lotto_reward_type_p3[0] }}</span>
                                                            <span>{{ result.lotto_reward_type_p3[1] }}</span>
                                                        </strong>
                                                        <p>รางวัลเลขท้าย 3 ตัว</p>
                                                    </div>
                                                    <div class="resultLottoCell TwoResult">
                                                        <strong>{{ result.lotto_reward_type_p2[0] }}</strong>
                                                        <p>รางวัลเลขท้าย 2 ตัว</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="LottoNumberList">
                                        <div class="boxResultSimple">
                                            <div class="title">
                                                <p>งวดวันที่</p>
                                                <h3>12/12/2565</h3>
                                                <button style="padding: 0 10px;" class="btn "><i class="fas fa-chevron-right"></i></button>
                                            </div>
                                            <div class="content">
                                                <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                    <div class="resultLottoCell">
                                                        <span>รางวัลที่1</span>
                                                        <strong>121789</strong>
                                                    </div>
                                                    <div class="resultLottoCell">
                                                        <span>รางวัลเลขท้าย 2 ตัว</span>
                                                        <strong>64</strong>
                                                    </div>
                                                </div>
                                                <div class="resultLottoCellGroup">
                                                    <div class="resultLottoCell">
                                                        <span>รางวัลเลขหน้า 3 ตัว</span>
                                                        <strong>121 789</strong>
                                                    </div>
                                                    <div class="resultLottoCell">
                                                        <span>รางวัลเลขท้าย 3 ตัว</span>
                                                        <strong>121 789</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="LottoNumberList">
                                        <div class="boxResultSimple">
                                            <div class="title">
                                                <p>งวดวันที่</p>
                                                <h3>12/12/2565</h3>
                                                <button style="padding: 0 10px;" class="btn "><i class="fas fa-chevron-right"></i></button>
                                            </div>
                                            <div class="content">
                                                <div class="resultLottoCellGroup resultLottoCellGroupBig">
                                                    <div class="resultLottoCell">
                                                        <span>รางวัลที่1</span>
                                                        <strong>121789</strong>
                                                    </div>
                                                    <div class="resultLottoCell">
                                                        <span>รางวัลเลขท้าย 2 ตัว</span>
                                                        <strong>64</strong>
                                                    </div>
                                                </div>
                                                <div class="resultLottoCellGroup">
                                                    <div class="resultLottoCell">
                                                        <span>รางวัลเลขหน้า 3 ตัว</span>
                                                        <strong>121 789</strong>
                                                    </div>
                                                    <div class="resultLottoCell">
                                                        <span>รางวัลเลขท้าย 3 ตัว</span>
                                                        <strong>121 789</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import axiosWrapper from '@/axiosWrapper'
    import store from '@/store'
    import moment from 'moment';
    export default {
        name: 'LottoNumberResultGovernmentAll',
        components: {
        },
        computed: {
           
           pes_profile() {
               return store.state.pes_profile
           },
           token() {
            return  this.pes_profile ? this.pes_profile.token :'';
           },
       },
        data (){
            return {
                lotto_result_list  : [],
                lotto_result : null,
                gold_number_result_list : [],

            }
        },
        methods : {
            goBack() {
                // this.$router.push({'name':'LottoNumberResultGovernment'})
                if(this.envDomains.VUE_APP_SITE_TYPE=='lotto'){
                    this.$router.push({'name':'Index'})
                }else{
                    this.$router.push({'name':'LottoNumber'})
                }
            },
            goToInfo(id) {
                let date = moment(id).format('YYYY-MM-DD');
                this.$router.push({'name':'LottoNumberResultGovernmentInfo','params':{'id':date}})
            },
            showDateResult(date){
                
                let moment_datetime=moment(date).format('DD/MM/YYYY');
                let date_array =moment_datetime.split("/");
                return date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)
            },
            async getLottoResultList(){
                let limit = 10;
                let page = 1;
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gov_lotto/get-result-list?token=${this.token}&limit=${limit}&game_type=&page=${page}`,
                    data: {
                        
                    }
                })
                this.lotto_result_list =res.data.data;
                for(let i=0;i<this.lotto_result_list.length;i++){
                    this.lotto_result_list[i].lotto_reward_type_1 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_1);
                    this.lotto_result_list[i].lotto_reward_type_2 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_2);
                    this.lotto_result_list[i].lotto_reward_type_3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_3);
                    this.lotto_result_list[i].lotto_reward_type_4 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_4);
                    this.lotto_result_list[i].lotto_reward_type_5 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_5);
                    this.lotto_result_list[i].lotto_reward_type_f3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_f3);
                    this.lotto_result_list[i].lotto_reward_type_p3 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_p3);
                    this.lotto_result_list[i].lotto_reward_type_n1 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_n1);
                    this.lotto_result_list[i].lotto_reward_type_p2 = JSON.parse(this.lotto_result_list[i].lotto_reward_type_p2);
                }
                if(this.lotto_result_list.length>0){
                    this.lotto_result = this.lotto_result_list[0];
                    this.lotto_result_list.shift();
                }
                
            },
        },
        async mounted() {
            await this.getLottoResultList();
            
        }
    }
</script>