<template>

    <div class="wrapContentIndex wrapContentIndexPopular wrapDiamondMode">
        <FloatButtomRightPanel :callChatWithAdmin="showChatWithAdminNumber" />
        <b-modal modal-class="" ref="changeModeModal"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 :hide-footer="true"
                 hide-header
                 :hide-header-close="true"
                 title="">
            <div class="style-modal modal-notify-text-center">
                <i class="fas fa-hourglass-half"></i>
                <p>{{changeModeMessage}} </p>
                <h2>กรุณารอสักครู่...</h2>
            </div>
        </b-modal>
        <!--     ///////////Modal////////////////////////////////-->
        <!--            แลกเพชร-->
        <div class="OverAllContainer" :class="{ 'active':show_convert_diamond ,'OverAllContainerShow':show_convert_diamond}">
            <div class="ContentShopExchange">
                <div class="title">
                    <div class="title-logo">
                        <img src="@/assets/images/bg-topUp/icon-Exchange.png" class="title-logo-img">
                    </div>
                    <div class="close-box-bonus" @click="closeConvertDiamond"></div>
                </div>
                <div class="ContentShopExchangeInfo">
                    
                    <ul   :class="{ 'activeExchange' : pes_profile.DIAMOND_free > reward.amount}" v-for="(reward,key)  of reward_diamond"  :key=key>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>{{reward.amount*convert_rate}}
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>{{reward.amount}}
                        </li>
                        <li>
                            <div class="buttonBonus" @click="convertDiamond( pes_profile.DIAMOND_free > reward.amount? reward.amount :0)"><span>แลก</span></div>
                        </li>
                    </ul>
                    <!-- <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>9,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>90
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>7,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>70
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>5,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>50
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>3,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>30
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="@/assets/images/diamond-icon.png"/>1,000
                        </li>
                        <li><i class="fas fa-chevron-circle-right"></i></li>
                        <li>
                            <img src="@/assets/images/coin-small-1.png"/>10
                        </li>
                        <li>
                            <div class="buttonBonus"><span>แลก</span></div>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>

        <!--            ยืนยันแลกเงิน     -->
        <div class="box-award box-level-up" :class="{'hide':hide_convert_cash}">
            <div class="box-alert-content">
                <div class="content-all">
                    <div class="contentTopOver">
                        <div class="footer">
                            <button type="button" class="btn" @click="convertConvertCash">ยืนยัน</button>
                        </div>
                    </div>
                    <div class="content">
                        <div class="close-box-bonus" @click="closeConvertCash"></div>
                        <div class="box-level-up">
                            <div class="box-level-up-detail">
                                <h5>การแลกสำเร็จ</h5>
                                <strong>คุณได้รับของรางวัล</strong>
                            </div>
                            <div class="row-box-award">
                                <div class="item-award">
                                    <div class="set-coin">
                                        <img src="@/assets/images/coin-small-1.png"/>
                                        <img src="@/assets/images/spint-gold.png" class="bg-spin">
                                    </div>
                                    <strong><img src="@/assets/images/coin.png"/> {{THB_income}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-bg"></div>
                </div>
            </div>
        </div>

        <!--            สปิน วงล้อ -->
        <div class="OverAllContainer" :class="{'active': spin_fortune_active,'OverAllContainerShow': spin_fortune_show}">  <!-- class active  OverAllContainerShow -->
            <div class="ContentSpinFortune" :class="{'active':spin_fortune_content_active,'activeSpinWheel': spin_fortune_content_wheel}">  <!-- class active  activeSpinWheel -->
                <div class="title">
                    <div class="title-logo">
                        <img src="@/assets/images/bg-topUp/icon-spinFortune.png" class="title-logo-img">
                    </div>
                    <img src="@/assets/images/spint-gold.png" class="bg-spin ">
                    <div class="close-box-bonus" @click="closeSpinFortune()"></div>
                </div>
                <div class="wheel-canvas-layout" >
                    <div class="btnStart" @click="spinFortune">
                        <span>
                            <div class="Loading">
                            <div class="loading-fantasy">
                                <div class="spinner-grow"></div>
                                <div class="spinner-grow"></div>
                                <div class="spinner-grow"></div>
                            </div>
                        </div>
                        </span>

                    </div>
                    <div class="boxWinkWheels">
                        <div class="boxWink" style="top: 49px; left: 52px;"></div>
                        <div class="boxWink" style="top: -12px; left: 201px;"></div>
                        <div class="boxWink" style="top: 52px; left: 354px;"></div>
                        <div class="boxWink" style="top: 204px; left: 411px;"></div>
                        <div class="boxWink" style="top: 354px; left: 350px;"></div>
                        <div class="boxWink" style="top: 413px; left: 198px;"></div>
                        <div class="boxWink" style="top: 349px; left: 52px;"></div>
                        <div class="boxWink" style="top: 198px; left: -13px;"></div>
                    </div>
                    <div class="wheel-canvas-triangle-down"></div>
                    <div class="wheelBgBorder"></div>
                    <div class="wheel-canvas-cells" :style="wheel_canvas_cell_style" v-if="wheel_canvas_cell_list.length>=8">
                        <div class="cell cell-1" :style="{'border-color':wheel_canvas_cell_list[0]['border_color']}"  >
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge" :style="{color:wheel_canvas_cell_list[0]['badge_color']}">{{wheel_canvas_cell_list[0]['amount']}}K</div>
                            </div>
                        </div>
                        <div class="cell cell-2" :style="{'border-color':wheel_canvas_cell_list[1]['border_color']}">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge" :style="{color:wheel_canvas_cell_list[1]['badge_color']}">{{wheel_canvas_cell_list[1]['amount']}}K</div>
                            </div>
                        </div>
                        <div class="cell cell-3" :style="{'border-color':wheel_canvas_cell_list[2]['border_color']}">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge" :style="{color:wheel_canvas_cell_list[2]['badge_color']}">{{wheel_canvas_cell_list[2]['amount']}}K</div>
                            </div>
                        </div>
                        <div class="cell cell-4" :style="{'border-color':wheel_canvas_cell_list[3]['border_color']}">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge" :style="{color:wheel_canvas_cell_list[3]['badge_color']}">{{wheel_canvas_cell_list[3]['amount']}}K</div>
                            </div>
                        </div>
                        <!-- cell-5 is target  -->
                        <div class="cell cell-5" :style="{'border-color':wheel_canvas_cell_list[4]['border_color']}">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge" :style="{color:wheel_canvas_cell_list[4]['badge_color']}">{{wheel_canvas_cell_list[4]['amount']}}K</div>
                            </div>
                        </div>
                        <div class="cell cell-6" :style="{'border-color':wheel_canvas_cell_list[5]['border_color']}">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge" :style="{color:wheel_canvas_cell_list[5]['badge_color']}">{{wheel_canvas_cell_list[5]['amount']}}K</div>
                            </div>
                        </div>
                        <div class="cell cell-7" :style="{'border-color':wheel_canvas_cell_list[6]['border_color']}">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge" :style="{color:wheel_canvas_cell_list[6]['badge_color']}">{{wheel_canvas_cell_list[6]['amount']}}K</div>
                            </div>
                        </div>
                        <div class="cell cell-8" :style="{'border-color':wheel_canvas_cell_list[7]['border_color']}">
                            <div class="cellInfo">
                                <img src="@/assets/images/spin/diamond1.png">
                                <div class="badge"  :style="{color:wheel_canvas_cell_list[7]['badge_color']}">{{wheel_canvas_cell_list[7]['amount']}}K</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <!--            ได้เพขร     -->
        <div class="box-award box-level-up getDiamond" :class="{'hide':hide_claim_diamond}">
            <div class="box-alert-content">
                <div class="content-all">
                    <div class="contentTopOver">
                        <div class="footer">
                            <button type="button" class="btn" @click="showDiamondConvertShop">ยืนยัน</button>
                        </div>
                    </div>
                    <div class="content">
                        <div class="close-box-bonus" @click="closeClaimDiamond"></div>
                        <div class="box-level-up">
                            <div class="box-level-up-detail">
                                <h5>คุณได้รับเพชร</h5>
                                <!--                                <strong>คุณได้รับของรางวัล</strong>-->
                            </div>
                            <div class="row-box-award">
                                <div class="item-award">
                                    <div class="set-coin">
                                        <img src="@/assets/images/spin/diamond1.png"/>
                                        <img src="@/assets/images/spint-blue.png" class="bg-spin">
                                    </div>
                                    <strong><img src="@/assets/images/diamond-icon.png"/> {{spin_DIAMOND_income}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-bg"></div>
                </div>
            </div>
        </div>

        <div class="wrapContentIndexTitle">
            <div class="boxSwitchDiamondMode">
                <ul>
                    <li class="active"><img src="@/assets/images/diamond-icon.png"/><span>โหมดเพชร</span></li>
                    <li @click="changeModeToCash(null)"><img src="@/assets/images/coin-small-1.png"/><span>เครดิต</span></li>
                </ul>
            </div>
        </div>
        <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"> </MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="popSlide bx-mobile bx-ipad">
                <carousel
                        :autoplayTimeout="5000"
                        :perPage="1"
                        :autoplay="true"
                        :mouseDrag="true"
                        :loop="true"
                        :centerMode="true"
                        :navigationEnabled="true"
                        :paginationEnabled="false"
                        navigationNextLabel="❯"
                        navigationPrevLabel="❮"
                >
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner1.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner2.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner3.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner4.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner5.jpg"/>
                        </div>
                    </slide>
                    <slide>
                        <div>
                            <img src="@/assets/images/banner/banner6.jpg"/>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <span class="topText">
                        <marquee>
                          <h2>คาสิโนออนไลน์ บาคาร่า ไฮโล เสือมังกร เว็บตรง</h2>
                        </marquee>
                    </span>
                    <div class="popSlide bx-desktop bx-ipad">
                        <carousel
                                :perPage="3"
                                :perPageCustom="[ [768, 2],[1400, 3],]"
                                :autoplay="true"
                                :mouseDrag="true"
                                :loop="true"
                                :centerMode="true"
                                :spacePadding="20"
                                :navigationEnabled="true"
                                :paginationEnabled="false"
                                navigationNextLabel="❯"
                                navigationPrevLabel="❮">
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner1.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner2.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner3.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner4.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner5.jpg"/>
                                </div>
                            </slide>
                            <slide>
                                <div>
                                    <img src="@/assets/images/banner/banner6.jpg"/>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                    <div class="container">
                        <div class="wrapDiamondModeContainer">
                            <div class="boxHitecCasino boxHitecCasino1">
                                <div class="nav-menu-topic ">
                                    <img src="/img/casino-hitech.13762e26.png">
                                    <h4>ไฮเทค คาสิโน</h4>
                                </div>
                                <ul>
                                    <li @click="goToTable(0)"><img src="@/assets/images/diamondMode/diamond_banner_bacarat.png"/></li>
                                    <li @click="goToTable(1)"><img src="@/assets/images/diamondMode/diamond_banner_hilo.png"/></li>
                                    <li @click="goToTable(2)"><img src="@/assets/images/diamondMode/diamond_banner_TG.png"/></li>
                                    <li @click="goToTable(3)"><img src="@/assets/images/diamondMode/diamond_banner_fantan.png"/></li>
                                </ul>
                            </div>
                            <div class="boxHitecCasino boxHitecCasino2">
                                <div class="nav-menu-topic ">
                                    <img src="@/assets/images/gameOther.png">
                                    <h4>เกมอื่นๆ</h4>
                                </div>
                                <ul>
                                    <li>
                                        <div @click="goBet('yeekee')" class="lottoDiamondMode" v-if="lotto_price_list['yeekee']">
                                            <span class="roundLotto">รอบที่ {{lotto_price_list['yeekee']['round']['sub_round']}}</span>
                                            <div class="tableLottoPrice">
                                                <ul>
                                                    <li>
                                                        <small>บาทละ</small>
                                                        <strong>{{lotto_price_list['yeekee']['sam_tua_bon']}}</strong>
                                                        <p>3 ตัวบน</p>
                                                    </li>
                                                    <li>
                                                        <small>บาทละ</small>
                                                        <strong>{{lotto_price_list['yeekee']['song_tua_bon']}}</strong>
                                                        <p>2 ตัวบน</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="timeLotto">
                                                สิ้นสุดในเวลา : {{timeFormat(lotto_price_list['yeekee']['round']['timeout'])}}
                                            </div>
                                        </div>
<!--                                        <img src="@/assets/images/diamondMode/diamond_banner_lotto2.png"/>-->
                                    </li>
                                    <li>
                                        <div @click="goBet('government')" class="lottoDiamondModeThai" v-if="lotto_price_list['government']">
                                            <span class="roundLotto" v-if="false">รอบที่ {{lotto_price_list['government']['round']['sub_round']}}</span>
                                            <div class="tableLottoPrice">
                                                <ul>
                                                    <li>
                                                        <small>บาทละ</small>
                                                        <strong>{{lotto_price_list['government']['sam_tua_bon']}}</strong>
                                                        <p>3 ตัวบน</p>
                                                    </li>
                                                    <li>
                                                        <small>บาทละ</small>
                                                        <strong>{{lotto_price_list['government']['song_tua_bon']}}</strong>
                                                        <p>2 ตัวบน</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="timeLotto">
                                                สิ้นสุดในเวลา : {{timeFormat(lotto_price_list['government']['round']['timeout'])}}
                                            </div>
                                        </div>
<!--                                        <img src="@/assets/images/diamondMode/diamond_banner_lotto2.png"/>-->
                                    </li>

                                </ul>
                                <div class="bx-desktop">
                                    <div class="boxSpinWheelSectionContent">
                                        <div class="boxSpinWheelSection" @click="showSpinFortune">
                                            <img src="@/assets/images/spin/logo-spin1.png" class="logoSpinWheel">
                                            <img src="@/assets/images/spin/logo-spin1BG.png" class="bg-spin bg-spinWheel ">
                                            <img src="@/assets/images/spint-gold.png" class="bg-spin ">
<!--                                            <img src="@/assets/images/diamondMode/diamond_banner_spin.png"/>-->
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="boxHitecCasino boxHitecCasino2">
                                <div class="nav-menu-topic" style="margin-top: 20px;">
                                    <img src="@/assets/images/icon/set-icon/icon-spin2.png">
                                    <h4>กล่องสุ่ม</h4>
                                </div>
                                <div v-for="(bonus, key) of bonus_list" :key="key" class="wrap-games-list wrap-games-list-unbox full-width" :class="['bt-jackpot-'+bonus['bonus_color']]">
                                    <div class="container-fluid-jackpot">
                                        <div class="container-fluid-jackpot-info">
                                            <div class="container-jackpot-grand">
                                                <div class="detail">
                                                    <h2>GRAND</h2>
                                                    <ul>
                                                        <li>
                                                            <span :class="'bonus-'+ key+'-0'">{{bonus.bonus_current[0]}}K</span>
                                                        </li>
                                                        <li><p>{{bonus.bonus_range[0]}}K</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="progress">
                                                    <div role="progressbar" v-bind:aria-valuenow="bonus.bonus_percent[0]"  aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: bonus.bonus_percent[0]+'%' }">
                                                        <div class="wink"></div>
                                                    </div>
                                                </div>
                                                <div class="jackpot-bg"></div>
                                            </div>
                                            <div class="container-jackpot-minor container-jackpot-major">
                                                <div class="detail"><h2>MAJOR</h2>
                                                    <ul>
                                                        <li><span :class="'bonus-'+ key+'-1'">{{bonus.bonus_current[1]}}K</span></li>
                                                        <li><p>{{bonus.bonus_range[1]}}K</p></li>
                                                    </ul>
                                                </div>
                                                <div class="progress">
                                                    <div role="progressbar" aria-valuenow="36"
                                                         aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                                                         style="width: 36%;">
                                                        <div class="wink"></div>
                                                    </div>
                                                </div>
                                                <div class="jackpot-bg"></div>
                                            </div>
                                            <div class="container-jackpot-mini">
                                                <div class="detail"><h2>MINI</h2>
                                                    <ul>
                                                        <li><span :class="'bonus-'+ key+'-3'">{{bonus.bonus_current[3]}}K</span></li>
                                                        <li><p>{{bonus.bonus_range[3]}}K</p></li>
                                                    </ul>
                                                </div>
                                                <div class="progress">
                                                    <div role="progressbar" aria-valuenow="52"
                                                         aria-valuemin="0" aria-valuemax="100" class="progress-bar"
                                                         style="width: 52%;">
                                                        <div class="wink"></div>
                                                    </div>
                                                </div>
                                                <div class="jackpot-bg"></div>
                                            </div>
                                            <div class="container-jackpot-bonus">
                                                <div class="detail"><h2>FIXED</h2>
                                                    <ul>
                                                        <li><p>{{bonus.bonus_range[4]}}K</p></li>
                                                    </ul>
                                                </div>
                                                <div class="jackpot-bg"></div>
                                            </div>
                                        </div>
                                        <div class="container-fluid-jackpot-bg"></div>
                                    </div>
                                    <div class="row">
                                        <div class="content-jackpot-box">
                                            <div class="content-jackpot-box-info">
                                                <div v-for="(case_obj, case_key) of bonus.case_list"
                                                 :key="case_key" class="" >
                                                    <div class="box-jackpot-item ">
                                                        <router-link :to="{name: 'UnboxPlay',params: { id: case_obj.id }}">
                                                            <div v-if="case_obj.bonus_count>0" class="shop-ribbon free-ribbon-top" style="right: -8px; left: auto; top: 0px;">
                                                                <strong data-v-77a61008="">ฟรีสปิน</strong>
                                                                <h4>x{{case_obj.bonus_count}}</h4>
                                                                <div class="shop-ribbon-info"></div>
                                                            </div>
                                                            <div class="info-jackpot-item"><!---->
                                                                <div class="item-jackpot-case-image">
                                                                    <div :style="'background-image: url('+case_obj.choose_case+')'"></div>
                                                                </div>
                                                                <div class="item-detail">
                                                                    <h2><div class="icon-coin-bar-Diamond"></div> {{case_obj.price_case}}K </h2>
                                                                    <h4>{{case_obj.name_case}}</h4>
                                                                </div>
                                                                <div class="background-bg-case"></div>
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="navTopDesktop">
            <MenuNavTopDiamond
                @changeModeToCash="changeModeToCash"
                @showMenuNav="showMenuNav"
                @showSpinFortune="showSpinFortune"
                @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"
                @showShopDiamondModal="showShopDiamondModal"
            >

            </MenuNavTopDiamond>
<!--            <MenuNavTop-->
<!--        @showMenuNav="showMenuNav"-->
<!--        @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"-->
<!--      ></MenuNavTop>-->
        </div>
        <div class="navButtonMobile">
<!--            <MenuNav @showSpinFortune="showSpinFortune"></MenuNav>-->
            <MenuNavDiamond  @showSpinFortune="showSpinFortune"></MenuNavDiamond>
        </div>
    </div>
</template>
<script>
    import MenuBar from "@/components/MenuBar";
    import MenuNavDiamond from "@/components/MenuNavDiamond";
    import MenuNavTopDiamond from "@/components/MenuNavTopDiamond";
    import {Carousel, Slide} from "vue-carousel";
    import store from '@/store'
    import axiosWrapper from '@/axiosWrapper'
    import ReconnectingWebSocket from 'reconnecting-websocket'
    import $ from 'jquery'
    import moment from 'moment';
    import FloatButtomRightPanel from "@/components/FloatButtomRightPanel";
    // import axios from 'axios'
    let ws;
    let _vue;
    window.jQuery = $;
    window.$ = $;
    const BONUS_LEVEL_MODAL=['ModalGrand','ModalMajor','ModalMinor','ModalMini','ModalBonus'];        
    export default {
        name: "DiamondMode",
        components: {
            MenuBar,
            MenuNavDiamond,
            MenuNavTopDiamond,
            Carousel,
            Slide,
            FloatButtomRightPanel,
        },
        data() {

        
            return {
                showShopDiamondNumber:0,
                menuNumber: 0,
                wheel_canvas_cell_mapping :{
                        0:
                        [
                                4,//0
                                5,//1
                                6,//2
                                7,//3
                                0,//4
                                1,//5
                                2,//6
                                3,//7
                        ],
                        1:
                        [
                                5,//0
                                6,//1
                                7,//2
                                0,//3
                                1,//4
                                2,//5
                                3,//6
                                4,//7
                        ],
                        2:
                        [
                                6,//0
                                7,//1
                                0,//2
                                1,//3
                                2,//4
                                3,//5
                                4,//6
                                5,//7
                        ],   
                        3:
                        [
                                7,//0
                                0,//1
                                1,//2
                                2,//3
                                3,//4
                                4,//5
                                5,//6
                                6,//7
                        ], 
                        4:
                        [
                                0,//0
                                1,//1
                                2,//2
                                3,//3
                                4,//4
                                5,//5
                                6,//6
                                7,//7
                        ],  
                        5:
                        [
                                1,//0
                                2,//1
                                3,//2
                                4,//3
                                5,//4
                                6,//5
                                7,//6
                                0,//7
                        ], 
                        6:
                        [
                                2,//0
                                3,//1
                                4,//2
                                5,//3
                                6,//4
                                7,//5
                                0,//6
                                1,//7
                        ],
                        7:
                        [
                                3,//0
                                4,//1
                                5,//2
                                6,//3
                                7,//4
                                0,//5
                                1,//6
                                2,//7
                        ],
                },
                wheel_canvas_cell_list :[],
                wheel_canvas_cell_style:{},
                convert_rate : 1000,
                reward_diamond : [],
                spin_message :'success',
                spin_DIAMOND_income :0,
                THB_income:0,
                DIAMOND_free : 0,
                spin_fortune_active:false,
                spin_fortune_show:false,
                spin_fortune_content_active:false,
                spin_fortune_content_wheel:false,
                hide_convert_cash :true,
                show_convert_diamond:false,
                hide_claim_diamond: true,

                changeModeMessage:'',
                table_link_list : [
                    null,null,null,null,null,null
                ],
                lotto_price_list :{
                    'yeekee':null,
                    'government':null,

                },
                showChatWithAdminNumber:0,
                showDepositWithdrawNumber:0,
                item: null,
                gameGroups: [
                    {
                        icon : '/images/casino/logo-sexy-baccarat.png',
                        title : 'Sexy Baccarat',
                    },
                    {
                        icon : '/images/casino/sa-casino.png',
                        title : 'SA Baccarat',
                    },
                    {
                        icon : '/images/casino/logo-111.png',
                        title : 'Pragmatic Play',
                    },
                    {
                        icon : '/images/casino/play-bacarat.jpg',
                        title : `King's Poker`,
                    },
                ],
                all_case:null,
                bonus_list :[],

                bonus:[
                    {bonus_current:null},
                    {bonus_current:null},
                    {bonus_current:null},
                    {bonus_current:null},
                    {bonus_end:null},
                ],
                color_cell :['#902b56',' #faa729'],

            }
        },
        destroyed: function() {
        //  console.log('my-component destroyed');
        ws.close();
},
        computed: {
           
            is_member() {
                return store.state.is_member
            },
            pes_profile() {
                return store.state.pes_profile?store.state.pes_profile: null
                
            },
            token() {
                return  this.pes_profile?this.pes_profile.token:null;
            },
            
        },
        methods: {
            showMenuNav(menuNumber) {
                this.menuNumber = menuNumber;
                let self = this;
                setTimeout(() => {
                    self.menuNumber = 0;
                }, 1000);
            },
            showChatWithAdmin() {
                this.showChatWithAdminNumber = 1;
                let self = this;
                setTimeout(() => {
                    self.showChatWithAdminNumber = 0;
                }, 100);
            },
            showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
            },
            showShopDiamondModal(){
                this.showDiamondConvertShop();
                
                
            },
            closeClaimDiamond(){
                this.hide_claim_diamond=true;
            },
            closeConvertCash(){
                this.hide_convert_cash=true;
                this.showDiamondConvertShop()

            },
            closeConvertDiamond(){
                this.show_convert_diamond=false;
            },

            hideConvertCash(){
                this.hide_convert_cash=true;
            },
            showSpinFortune(){
                this.spin_fortune_active=true;
                this.spin_fortune_show=true;
               
            },
            async spinFortuneTest(){
                if(this.spin_fortune_content_active==false){
                   
                        this.spin_fortune_content_active=true;
                        this.spin_fortune_content_wheel=true;
                        this.wheel_canvas_cell_style={
                            animation: 'spin_fortune 5s ease-out '
                        }
                        this.spin_DIAMOND_income=5;
                        this.setWheelCanvasChell(this.spin_DIAMOND_income)
                        setTimeout(()=>{
                            this.spin_fortune_content_active=false;
                            this.spin_fortune_content_wheel=false;
                            this.wheel_canvas_cell_style={
                            // animation: 'spin_fortune 5s ease-out '
                            }
                            // this.hide_claim_diamond=false;
                            // this.closeSpinFortune();
                            // this.pes_profile.DIAMOND_free = this.DIAMOND_free;
                        },1000*6)
                    
                }
            },
            setWheelCanvasChell(amount){
                let reward_index=null;
                for(let i=0;i<this.reward_diamond.length;i++){
                    if(this.reward_diamond[i].amount==amount/1000){
                        reward_index = i;
                        break;
                    }

                }
                let new_reward_list =this.wheel_canvas_cell_mapping[reward_index];
                for(let i=0;i<new_reward_list.length;i++){
                    let new_index = new_reward_list[i];

                    let border_color = new_index< 7 ? this.color_cell[new_index%2]  : '#000'
                    let badge_color = new_index< 7 ? 'white' : '#faa729'
                    let cell ={
                        amount : this.reward_diamond[new_index].amount,
                        border_color :`transparent transparent ${border_color} transparent` ,
                        badge_color: badge_color,
                    }
                    this.wheel_canvas_cell_list[i]=cell;

                }


            },
            async spinFortune(){
                if(this.spin_fortune_content_active==false){
                    let data_response =await this.spinForeturnServer()
                    this.spin_message = data_response.msg;
                    
                    if(data_response.code==1){
                        this.wheel_canvas_cell_style={animation: 'spin_fortune 5s ease-out'}
                        this.setWheelCanvasChell(this.spin_DIAMOND_income)
                        this.spin_fortune_content_active=true;
                        this.spin_fortune_content_wheel=true;
                        setTimeout(()=>{
                            this.wheel_canvas_cell_style={}
                            this.hide_claim_diamond=false;
                            this.closeSpinFortune();
                            this.pes_profile.DIAMOND_free = this.DIAMOND_free;
                        },1000*6)
                    }else{
                        // console.log('in in');
                        this.closeSpinFortune2(this.spin_message);
                        // this.closeSpinFortune(this.spin_message);
                    }
                    
                }
                
            },

            showDiamondConvertShop(){
                this.closeSpinFortune()
                this.closeClaimDiamond();
                this.show_convert_diamond=true;
            },
            async convertDiamond(amount){
                if(amount>0){
                    let res_data=await this.convertDiamondServer(amount);
                
                    if(res_data.code==1){
                        this.THB_income = amount;
                        this.pes_profile.THB_free = res_data.data.THB_free;
                        this.show_convert_diamond=false;
                        this.hide_convert_cash=false;

                    }
                }
                
                
                
            },
            convertConvertCash(){
                this.closeConvertCash();
            },
            closeSpinFortune(message){
                this.spin_fortune_active=false;
                this.spin_fortune_show=false;
                this.spin_fortune_content_active=false;
                this.spin_fortune_content_wheel=false;
                
                if(message){
                    setTimeout(() => {
                        alert(message)
                        this.show_convert_diamond=true;
                    }, 500);
                }
            },
            closeSpinFortune2(message){
                this.spin_fortune_active=false;
                this.spin_fortune_show=false;
                this.spin_fortune_content_active=false;
                this.spin_fortune_content_wheel=false;
                alert(message)
                
            },
            update_pes_data(profile){
                store.commit('updatePesProfile',profile);
            },
            goBet(lotto_type){
                store.commit('lotto/updatePageBetQueryLottoType',lotto_type)
                store.commit('lotto/updateCurrentPage', 'bet')
                this.$router.push({name: 'LottoBet'})
            },
            timeFormat(datetime){
                return moment(datetime).format('HH:mm:ss');
            },
            goToTable(index){
                if(this.table_link_list[index]){
                    this.$router.push({'path':this.table_link_list[index]})
                }
            },
            update_pes_profile(val){
                store.commit('updatePesProfile', val)
            },
            async setDiamondMode() {

                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/game/mode/change`,
                    data:{
                        token : this.token,
                        mode : 1,
                    }
                });
                return res.data;
            },
            async spinForeturnServer() {
                // axios.post(`${this.envDomains.VUE_APP_API}/api/game/mode/spinFortune`, {
                //     token :this.token,
                // })
                // .then(function (response) {
                //     console.log(response);
                //     this.spin_message = response.data.msg;
                //     if(response.data.code==1){
                //         let response_data = response.data.data;
                //         let DIAMOND_income = response_data.DIAMOND_income
                //         console.log(DIAMOND_income)

                //     }else{
                //         //
                //     }
                // })
                // .catch(function (error) {
                //     console.log(error);
                // });
                const res = await axiosWrapper({
                    method: 'post',
                    url:`${this.envDomains.VUE_APP_API}/api/game/mode/spinFortune`,
                    data: {
                        token :this.token,
                    }
                });
                if(res.data.code==1){
                    this.spin_DIAMOND_income=res.data.data.DIAMOND_income*this.convert_rate
                    
                    this.DIAMOND_free = res.data.data.DIAMOND_free;
                    
                    // this.update_pes_data(this.pes_profile);
                }else{
                    this.spin_DIAMOND_income=0;
                }
                return res.data;
            },
            async convertDiamondServer(amount) {
                
                const res = await axiosWrapper({
                    method: 'post',
                    url:`${this.envDomains.VUE_APP_API}/api/game/mode/convert`,
                    data: {
                        token :this.token,
                        amount :amount,
                    }
                });
                if(res.data.code==1){
                    this.DIAMOND_free = res.data.data.DIAMOND_free;
                    this.pes_profile.DIAMOND_free = this.DIAMOND_free;
                    this.pes_profile.THB_free = res.data.data.THB_free;
                }else{
                    this.spin_DIAMOND_income=0;
                }
                return res.data;
            },
            async changeModeToCash(redirect){
                this.changeModeMessage='กำลังเปลี่ยนโหมดเป็นเงินสด'
                this.$refs['changeModeModal'].show();
                  
                    
                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/game/mode/change`,
                    data:{
                        token : this.token,
                        mode : 0,
                    }
                });
                if(res.data.code==1){
                    // this.$router.push({'name':'Index',params:{'mode':0}})
                    if(redirect){
                        window.location.href=redirect
                    }else{
                        window.location.href='/'
                    }
                    
                }else{
                    this.$refs['changeModeModal'].hide();
                    alert('คุณอยู่ในโหมดนี้อยู่แล้ว')
                    this.$router.push({'name':'Index'})
                }
            },
            async getTableListByCurrency(currency){
                const res = await axiosWrapper({
                    method: 'post',
                    url: `${this.envDomains.VUE_APP_API}/api/game/table/get_by_currency?currency=${currency}`,
                    
                })
                return res.data.data;
            },
            async loadLottoPrice(){

                try{

                    const res = await axiosWrapper({
                        method: 'POST',
                        url: `${this.envDomains.VUE_APP_LOTTO_ADMIN_API}/api/price/load_table`
                    })
                    return res.data;
                    
                }catch(err){
                    this.$alert('ไม่สามารถโหลดข้อมูล กรุณาลองอีกครั้ง', 'แจ้งเตือน', {
                        type: 'error',
                        confirmButtonText: 'ตกลง',
                        customClass: 'custom-message-box'
                    })
                    
                    return null;
                }
            },
            manageLottoPrice(list){
                this.lotto_price_list['government']  =list['government'];
                this.lotto_price_list['yeekee'] = list['yeekee'];
                // console.log(this.lotto_price_list['yeekee']['round'])
            },
            manageTableList(list){
                for(let i=0;i<list.length;i++){
                    let table_id = list[i].table_id;
                    if(list[i].table_type==0 && list[i].table_status==1){ //baccarat
                        this.table_link_list[0] = `/mini-baccarat?table_id=${table_id}`
                    }else if(list[i].table_type==1 && list[i].table_status==1){ //sicbo
                        this.table_link_list[1] = `/mini-sicbo?table_id=${table_id}`
                    }else if(list[i].table_type==2 && list[i].table_status==1){ //bean
                        this.table_link_list[2] = `/mini-fantan?table_id=${table_id}`
                    }else if(list[i].table_type==3 && list[i].table_status==1){ //dragontiger
                        this.table_link_list[3] = `/mini-dragontiger?table_id=${table_id}`
                    }
                }

            },
            manageSpinFortune(){
                for(let i=0;i<this.reward_diamond.length;i++){
                    let new_index=i;
                    let border_color = new_index< 7 ? this.color_cell[new_index%2]  : '#000'
                    let badge_color = new_index< 7 ? 'white' : '#faa729'
                    let cell ={
                        amount : this.reward_diamond[new_index].amount,
                        border_color :`transparent transparent ${border_color} transparent` ,
                        badge_color: badge_color,
                    }
                    this.wheel_canvas_cell_list.push(cell);
                }
                

            },

            async getAllCase() {

                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/getAllCase.php?currency=DIAMOND`,
                });
                return res.data;
            },
            initWs() {
                var hostname =`${this.envDomains.VUE_APP_PES_WS_BASE_URL}`;
                var token = this.token;
                ws = new ReconnectingWebSocket(hostname, token, {debug: false, reconnectInterval: 3000});
                ws.onmessage = function (msg) {
                    var response = JSON.parse(msg.data);
                    manageWSContent(response, 'all');

                }
                ws.onopen = function () {
                    var data = {'type': 'subscribe', list: ['chat', 'spiner']};
                    wsSendData(data);
                };

            },
            formatToPrice(value) {
                return `${value.toFixed(3)}`;
            }
            
        },
        async mounted() {
            this.changeModeMessage='กำลังเปลี่ยนเป็นโหมดเพชร'
            

            let pes_profile = this.pes_profile;
            if(pes_profile){
                this.$refs['changeModeModal'].show();
                await this.setDiamondMode()
                this.reward_diamond = this.pes_profile.reward_diamond
                this.manageSpinFortune()
                pes_profile.mode = 1;
                this.update_pes_data(pes_profile)
            }
            
            // console.log('######   diamond mode  #######');
            let tableList=await this.getTableListByCurrency(1)
            this.manageTableList(tableList)
            
            let lotto_price=await this.loadLottoPrice()
            this.manageLottoPrice(lotto_price);
            let allCase=(await this.getAllCase());
            let spiner = pes_profile? this.pes_profile.sp_bonus_item.spiner :{};
            // console.log(spiner);
            for(let aIndex=0;aIndex<allCase.length;aIndex++){
                for(let bIndex=0;bIndex<allCase[aIndex].length;bIndex++){
                    if(allCase[aIndex][bIndex].id in spiner){
                        allCase[aIndex][bIndex].bonus_count = spiner[allCase[aIndex][bIndex].id];
                        

                    }else{
                        allCase[aIndex][bIndex].bonus_count=0;
                    }
                    // console.log(aIndex,allCase[aIndex][bIndex].bonus_count);
                }
                
            }
            this.all_case =allCase;


            let case_list = allCase[0];
            let blv = allCase[1];
            let bl = allCase[3];
            let bonus_list={};
            for(let index =0;index<bl.length;index++){
                let gbc_id = bl[index].gbc_id;
                bonus_list[gbc_id]=bl[index];
            }
           
            let bonus_list_key={};
            let new_bonus_list={};
            
            for(let i=0;i<case_list.length;i++){
                let c = case_list[i];
                if(c.bonus_config_id){
                    let bonus_config_id = c.bonus_config_id;
                    if (!(bonus_config_id in bonus_list_key)){

                        bonus_list_key[bonus_config_id]=0;
                        let b=bonus_list[bonus_config_id];
                        
                        let obj={
                            bonus_color:b.bonus_color,
                            bonus_range :[
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_0_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_0_end),
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_1_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_1_end),
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_2_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_2_end),
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_3_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_3_end),
                                new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(b.bonus_lv_4_end)
                            ],
                            bonus_percent :[
                                0,
                                0,
                                0,
                                0,
                                0
                            ],
                             
                            bonus_current :[
                                0,0,0,0,0
                            ],
                            case_list :[],
                        }
                        new_bonus_list[bonus_config_id] =obj;
                    }
                    // console.log(obj.bonus_percent)
                    bonus_list_key[bonus_config_id]++;
                    new_bonus_list[bonus_config_id].case_list.push(c);
                    
                }
            }

             for(let j=0;j<blv.length;j++){
                let gbc_ref_id = blv[j].gbc_ref_id;
                let bonus_level =blv[j].bonus_level;
                let bonus_status  =blv[j].bonus_status;
                let bonus_current = blv[j].bonus_current;
                let bonus_end = blv[j].bonus_end;
                if(bonus_status==0){
                    if ((gbc_ref_id in new_bonus_list)){
                        let percent = parseInt((bonus_current/bonus_end)*100);
                        new_bonus_list[gbc_ref_id]['bonus_percent'][bonus_level]=percent;
                        new_bonus_list[gbc_ref_id]['bonus_current'][bonus_level]=formatNumber(parseFloat(bonus_current).toFixed(2));
                        // new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(bonus_current);
                    }
                }
            }
            this.bonus_list = new_bonus_list;
            this.initWs();
            _vue = this;
            this.$refs['changeModeModal'].hide();
        },
        
    };
    function  manageWSContent(response,page){
                    var type = response.type;
                    var data = response.data;
                    if(page=='all') {
                        if (type == 'chat') {
                            var init1 = response.init;
                            init1;
                        }else if(type=='balance'){
                            setTimeout(function(){
                                _vue.pes_profile.THB_free = data; 
                            },1000*10.5)
                            
                        }else if(type=='unbox-packet'){

                            if(!(data.ref_id+'' in _vue.pes_profile.sp_bonus_item.spiner)){
                                _vue.pes_profile.sp_bonus_item.spiner[data.ref_id]=0;
                            }
                            _vue.pes_profile.sp_bonus_item.spiner[data.ref_id]=data.count;
                            _vue.pes_profile.THB_free = data.balance;
                            for(var key in _vue.case_list){
                                if(_vue.case_list[key].id==data.ref_id){
                                    _vue.case_list[key].bonus_count = data.count;
                                    _vue.$set(_vue.case_list[key],'bonus_count',  data.count);
                                }
                            }
                            
                        } else if (type == 'bonus') {
                            for (var i = 0; i < data.length; i++) {
                                var b = data[i];
                                if (b != null) {
                                    var bonus_config_id = b.gbc_ref_id;
                                    var bonus_current = b.bonus_current;
                                    var bonus_status = b.bonus_status;
                                    var bonus_level = b.bonus_level;
                                    // var bonus_start = b.bonus_start;
                                    var bonus_end = b.bonus_end;
                                    if(_vue.bonus_list[bonus_config_id]){
                                        _vue.bonus_list[bonus_config_id].bonus_percent[bonus_level] = parseInt((bonus_current/bonus_end)*100);
                                        if (bonus_status == 0 && bonus_level != 4) {
                                            updateBonusNumber('.bonus-'+bonus_config_id+'-'+bonus_level,bonus_current,2);
                                        } else if (bonus_level != 4) {
                                            updateBonusNumber('.bonus-'+bonus_config_id+'-'+bonus_level,bonus_current,2);
                                        }
                                        if(bonus_level==0 || bonus_level==1){
                                            bonus_level;
                                            // manageShowUnboxBonus(b);

                                        }
                                    }
                                    
                                }

                            }
                        } else if (type == 'bonus_reward') {
                            showBonusReward(response);
                            type;
                            // for (var i = 0; i < data.length; i++) {
                            //     updateContentGame(data[i],9000);
                            // }
                        } else if (type == 'item_recent') {
                            type;
                            // // $('#item_recent_left').html('');
                            // for (var i = 0; i < data.length; i++) {
                            //     updateContentGame(response.data[i]);
                            // }
                        }else if(type=='noti_msg') {
                            type;
                            // var noti_count = data.noti_count;
                            // $('div.noti-box').html('<span class="label">' + noti_count + '</span>');
                            // $('span.noti-box-right').html(noti_count);
                        }else if(type=='lotto_noti1') {
                            // console.log("have>>>>");
                            type;
                            // manageLottoNoti1(data);


                        }else if(type=='lotto_noti2'){
                            type;
                            // updateContentGame(response.data);
                        } else if (response.code == 1) {
                            type;
                            // manageGameData(response);
                        }
                    }else if(page=='spiner'){
                        page;

                    }else if(page=='mini_game'){
                        if (type == 'chat') {
                            var init2 = response.init;
                            init2;
                            // manageChat(data, init);
                        }else if(type=='last_result'){
                            type;
                            // var is_mobile = path.includes("/m/");
                            // if(response.hasOwnProperty('data')){
                            //     var list = response.data;

                            //     for(var i=0;i<list.length;i++){
                            //         var html = is_mobile?lastResultTrMobile(list[i]):lastResultTr(list[i]);
                            //         if(is_mobile){
                            //             $('#collapseTopJackpot').children('div').append(html);
                            //         }else{
                            //             $('#ul-game-list').append(html);
                            //         }

                            //     }
                            // }else{
                            //     var html = is_mobile?lastResultTrMobile(response):lastResultTr(response);
                            //     var second = 7000;
                            //     if(response.game_name=='Hilo'){
                            //         second=3000;
                            //     }else if(response.hasOwnProperty('delay')){
                            //         second=delay_second[response.delay];
                            //     }
                            //     setTimeout(function(){
                            //         if(is_mobile){
                            //             $('#collapseTopJackpot').children('div').prepend(html);
                            //         }else{
                            //             $('#ul-game-list').append(html);
                            //         }
                            //     },second);

                            // }
                        }else if(type=='noti_msg'){
                            type;
                            // var noti_count=data.noti_count;
                            // $('div.noti-box').html('<span class="label">'+noti_count+'</span>');
                            // $('span.noti-box-right').html(noti_count);
                        }
                    }else{
                        type;
                    }

                } 
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function  wsSendData(_data) {
     ws.send(JSON.stringify(_data));
}
function updateBonusNumber(id,value){

    $(id).each(function() {
    var self = $(this),
    countTo = value;

    $({
        countNum: self.text().replace(",",'')
    }).animate({
        countNum: countTo
        },

        {
        duration: 500,
        easing: 'linear',
        step: function() {
        
            self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
            
        },
        complete: function() {
            self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
            // alert('finished');
        }
        }
    );

    });
}
function commaSeparateNumber(val) {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return val;
}
function showBonusReward(b){

    if(b.game=='spiner'){
        var bonus = b.data[0];
        var bonus_modal = BONUS_LEVEL_MODAL[bonus.bonus_level];
        $('#'+bonus_modal).show();
        _vue.bonus[bonus.bonus_level].bonus_current = parseFloat(bonus.bonus_current).toFixed(2);
        _vue.bonus[bonus.bonus_level].bonus_end = bonus.bonus_end;
    }
}
</script>
<style>
@-webkit-keyframes spin_fortune {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(1800deg); }
}
@keyframes spin_fortune {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(1800deg); }
}
</style>
<style src="@/assets/css/spin-circle.css"></style>
