<template>
    <div v-if="is_slidebar">
        <b-modal modal-class="" ref="logoutService"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 :hide-footer="true"
                 hide-header
                 :hide-header-close="true"
                 title="">
            <div class="style-modal modal-notify-text-center">
                <i class="fas fa-hourglass-half"></i>
                <p>กำลังถอนเงินจาก msport </p>
                <h2>กรุณารอสักครู่...</h2>
            </div>
    </b-modal>
        <ul v-if="menu_left_setting_list!=null">
            <li v-for="(item,key) of menu_left_setting_list" :key="key"  @click="beforeToPage(item.id)">
                <div class="boxProviderLeft">
                    <img v-if="item.id=='popular'" src="@/assets/images/hot.gif"/>
                    <img v-else-if="item.id=='casino'" src="@/assets/images/iconCasinoSide.png"/>
                    <img v-else-if="item.id=='slot'" src="@/assets/images/iconSlotSide.png"/>
                    <img v-else-if="item.id=='lotto'" src="@/assets/images/iconLottoSide.png"/>
                    <img v-else-if="item.id=='sport'" src="@/assets/images/iconSportSide.png"/>
                    <img v-else-if="item.id=='fishing'" src="@/assets/images/iconFishSide.png"/>
                    <img v-else-if="item.id=='unbox'" src="@/assets/images/icon/set-icon/icon-spin4.png"/>
                    <div  class="boxTextMenuLeft">
                        <span>{{item.name}}</span>
                    <p>{{item.name_en}}</p>
                    </div>
                </div>
            </li>
            
        </ul>
        <ul v-else>
            <li @click="beforeToPage('popular')">
                <div class="boxProviderLeft">
                    <img src="@/assets/images/hot.gif"/>
                    <div  class="boxTextMenuLeft">
                        <span>ยอดนิยม</span>
                        <p>Popular</p>
                    </div>
                </div>
            </li>
            <li @click="beforeToPage('casino')">
                <div class="boxProviderLeft">
                    <img src="@/assets/images/iconCasinoSide.png"/>
                    <div  class="boxTextMenuLeft">
                        <span>คาสิโน</span>
                        <p>Casino</p>
                    </div>
                </div>
            </li>
            <li  @click="beforeToPage('slot')">
                <div class="boxProviderLeft">
                    <img src="@/assets/images/iconSlotSide.png"/>
                    <div  class="boxTextMenuLeft">
                        <span>สล็อต</span>
                        <p>Slot</p>
                    </div>
                </div>
            </li>
            <li @click="beforeToPage('lotto')">
                <div class="boxProviderLeft">
                    <img src="@/assets/images/iconLottoSide.png"/>
                    <div  class="boxTextMenuLeft">
                        <span>หวย</span>
                        <p>Popular</p>
                    </div>
                </div>
            </li>
            
            <li  @click="beforeToPage('sport')">
                <div class="boxProviderLeft">
                    <img src="@/assets/images/iconSportSide.png"/>
                    <div  class="boxTextMenuLeft">
                        <span>กีฬา</span>
                        <p>Sport</p>
                    </div>
                </div>
            </li>
            <li @click="beforeToPage('fishing')">
                <div class="boxProviderLeft">
                    <img src="@/assets/images/iconFishSide.png"/>
                    <div  class="boxTextMenuLeft">
                        <span>ยิงปลา</span>
                        <p>Fishing</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="boxProviderLeft" @click="beforeToPage('unbox')">
                    <img src="@/assets/images/icon/set-icon/icon-spin4.png"/>
                    <div  class="boxTextMenuLeft">
                        <span>กล่องสุ่ม</span>
                        <p>Popular</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div v-else>
        <b-modal modal-class="" ref="logoutService"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 :hide-footer="true"
                 hide-header
                 :hide-header-close="true"
                 title="">
            <div class="style-modal modal-notify-text-center">
                <i class="fas fa-hourglass-half"></i>
                <p>กำลังถอนเงินจาก msport </p>
                <h2>กรุณารอสักครู่...</h2>
            </div>
    </b-modal>
        <ul v-if="menu_left_setting_list!=null" class="itemList">
            <li v-for="(item,key) of menu_left_setting_list" class="item " :class="['item'+item.name_en]" :key="key">
                <div class="linKToDiv" @click="beforeToPage(item.id)"></div>
                <div class="navLeftIcon iconMain" :class="['icon'+item.name_en+'Left']"></div>
                <div class="boxTextMenuLeft">
                    <span>{{item.name}}</span>
                    <p>{{item.name_en}}</p>
                </div>
            </li>
        </ul>
        <ul v-else class="itemList">
            
            <li class="item itemPopular" >
                <div class="linKToDiv" @click="beforeToPage('popular')"></div>
                <div class="navLeftIcon iconMain iconPopularLeft"></div>
                <div class="boxTextMenuLeft">
                    <span>ยอดนิยม</span>
                    <p>Popular</p>
                </div>
            </li>
            <li class="item itemCasino"  @click="beforeToPage('casino')">
                <div class="navLeftIcon iconMain iconCasinoLeft"></div>
                <div class="boxTextMenuLeft">
                    <span>คาสิโน</span>
                    <p>Casino</p>
                </div>
            </li>
            <li class="item itemSlot"  @click="beforeToPage('slot')">
                <div class="navLeftIcon iconMain iconSlotLeft"></div>
                <div class="boxTextMenuLeft">
                    <span>สล็อต</span>
                    <p>Slot</p>
                </div>
            </li>
            <li class="item itemLotto"  @click="beforeToPage('lotto')">
                <div class="navLeftIcon iconMain iconLottoLeft"></div>
                <div class="boxTextMenuLeft">
                    <span>หวย</span>
                    <p>Lotto</p>
                </div>
            </li>
            <li class="item itemSport"  @click="beforeToPage('sport')">
                <div class="navLeftIcon iconMain iconSportLeft"></div>
                <div class="boxTextMenuLeft">
                    <span>กีฬา</span>
                    <p>Sport</p>
                </div>
            </li>
            <li class="item itemFishing"  @click="beforeToPage('fishing')">
                <div class="navLeftIcon iconMain iconFishingLeft"></div>
                <div class="boxTextMenuLeft">
                    <span>ยิงปลา</span>
                    <p>Fishing</p>
                </div>
            </li>
            <li class="item itemUnbox"  @click="beforeToPage('unbox')">
                <div class="navLeftIcon iconMain iconUnboxLeft"></div>
                <div class="boxTextMenuLeft">
                    <span>กล่องสุ่ม</span>
                    <p>Unbox</p>
                </div>
            </li>
        </ul>
    </div>

</template>
<script>

import UserMixin from '@/mixins/UserMixin'
import store from '@/store'
export default {
    name: 'MenuLeft',
    mixins: [UserMixin],
    components: {
    },
    props : ['is_slidebar'],
    computed :{
        menu_left_setting(){
            return localStorage.getItem('menu_left_setting')? JSON.parse(localStorage.getItem('menu_left_setting')) :null;
        },
        currentRouteName() {
            return this.$route.name;
        },
        pes_profile() {
            return store.state.pes_profile
        },
        token() {
            return this.pes_profile.token
        },
    },
    data() {
        return {
            menu_left_setting_list : null,
        }
    },
    methods: {
        toIndexPopularPage() {
            this.$router.push({name: 'IndexPopular'})
        },
        toIndexCasinoPage() {
            this.$router.push({name: 'IndexCasino'})
        },
        toIndexSlotPage() {
            this.$router.push({name: 'IndexSlot'})
        },
        toIndexFishingPage() {
            this.$router.push({name: 'IndexFishing'})
        },
        toIndexSportPage() {
            this.$router.push({name: 'IndexSport'})
        },
        toIndexLottoPage() {
            this.$router.push({name: 'IndexLotto'})
        },
        toIndexUnboxPage() {
            this.$router.push({name: 'IndexUnbox'})
        },
        async beforeToPage(id){
            
            
            if(this.currentRouteName=='IndexSport'){
                this.$refs['logoutService'].show();
                await this.loadUserData(this.token,'yes')
                this.$refs['logoutService'].hide();
                this.toPage(id)
            }else{
                this.toPage(id)
            }
        },
        toPage(id){
            if(id=='popular'){
                this.toIndexPopularPage() 
            }else if(id=='casino'){
                this.toIndexCasinoPage()
            }else if(id=='slot'){
                this.toIndexSlotPage()
            }else if(id=='fishing'){
                this.toIndexFishingPage()
            }else if(id=='sport'){
                this.toIndexSportPage()
            }else if(id=='lotto'){
                this.toIndexLottoPage()
            }else if(id=='unbox'){
                this.toIndexUnboxPage()
            }
        }
        
    },
    mounted (){
        this.menu_left_setting_list = {};
        for(let menu in this.menu_left_setting){
            if(this.menu_left_setting[menu]['is_show']){
                this.menu_left_setting_list[menu] = this.menu_left_setting[menu]
            }
        }
        
    },
}
</script>
<style scoped>
</style>