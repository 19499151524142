import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from '@/Cookie'
import Football from './Football'
import Lotto from './Lotto'
import Game from './Game'
import NewLotto  from './NewLotto'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user_profile: null,
		pes_profile: null,
		user_info_arr: [],
		is_member: false,
		routeQueryChannel: '',
		routeQueryMessageApiId: '',
		channel: '',
		message_api_id: '',
		referral_code: Cookie.get('referral_code') || '',
		reward_id: '',
		appInfo: null,
		is_user_ban: false,
		super_admin: JSON.parse(Cookie.get('super_admin')) || null,
		admin: JSON.parse(Cookie.get('admin')) || null,
		queryToken: '',
		embedMode: false,
		showMenuLeft: false,
		current_game: '',
		showDrawer: false,
		needUserReload: false,
		needUserReloadWithForceRefreshBalance: false,
		allGameList:null,
		needManageDailyBonusMockup: false,
		redirectPage: '',
		theme: localStorage.getItem('theme') || 'light', //light, dark
		messageSectionWidth: 0,
		envDomains: null,
		needChatWithAdmin: false,
		gameProviderList: [],
		menuBarNavigation: {
			page: '',
			tab: '',
			provider: null,
		},
		isOnLoadUserProfile: false,
		isInLineApp: false,
	},
	mutations: {
		updateUserProfile(state, ctx){
			state.user_profile = ctx
		},
		updatePesProfile(state, ctx){
			state.pes_profile = ctx
		},
		updateService(state, ctx){
			state.service = ctx
			// localStorage.setItem('service', ctx)
		},
		addUserInfoArr(state, ctx){
			state.user_info_arr.push(ctx)
		},
		updateIsMember(state, ctx){
			state.is_member = ctx
		},
		updateRouteQueryChannel(state, ctx) {
			state.routeQueryChannel = ctx
		},
		updateRouteQueryMessageApiId(state, ctx) {
			state.routeQueryMessageApiId = ctx
		},
		updateChannel(state, ctx) {
			state.channel = ctx
		},
		updateMessageApiId(state, ctx){
			state.message_api_id = ctx
		},
		updateReferralCode(state, ctx) {
			state.referral_code = ctx
			Cookie.set('referral_code', ctx, 1)
		},
		updateRewardId(state, ctx) {
			state.reward_id = ctx
		},
		updateAppInfo(state, ctx) {
			state.appInfo = ctx
		},
		updateIsUserBan(state, ctx){
			state.is_user_ban = ctx
		},
		updateSuperAdmin(state, ctx){
			state.super_admin = ctx
			Cookie.set('super_admin', JSON.stringify(ctx), 1)
		},
		updateAdmin(state, ctx){
			state.admin = ctx
			Cookie.set('admin', JSON.stringify(ctx), 1)
		},
		updateQueryToken(state, ctx) {
			state.queryToken = ctx
		},
		updateEmbedMode(state, ctx) {
			state.embedMode = ctx
		},
		updateShowMenuLeft(state, ctx) {
			state.showMenuLeft = ctx
		},
		updateCurrentGame(state, ctx) {
			state.current_game = ctx
		},
		updateShowDrawer(state, ctx) {
			state.showDrawer = ctx
		},
		updateNeedUserReload(state, ctx) {
			state.needUserReload = ctx
		},
		updateNeedUserReloadWithForceRefreshBalance(state, ctx) {
			state.needUserReloadWithForceRefreshBalance = ctx
		},
		updateAllGameList(state,ctx){
			state.allGameList = ctx;
		},
		updateNeedManageDailyBonusMockup(state, ctx) {
			state.needManageDailyBonusMockup = ctx
		},
		updateRedirectPage(state, ctx) {
			state.redirectPage = ctx
		},
		updateTheme(state, ctx) {
			state.theme = ctx
			localStorage.setItem('theme', ctx)
		},
		updateMenuSetting(state, ctx) {
			// state.menu_setting =  JSON.stringify(ctx)
			localStorage.setItem('menu_setting',  JSON.stringify(ctx))
		},
		updateMenuLeftSetting(state, ctx) {
			// state.menu_setting =  JSON.stringify(ctx)
			localStorage.setItem('menu_left_setting',  JSON.stringify(ctx))
		},
		updateAgentId(state, ctx) {
			state.agent_id = ctx
			localStorage.setItem('agent_id', ctx)
			console.log('set agent_id ',state.agent_id)
		},
		updateMessageSectionWidth(state, ctx) {
			state.messageSectionWidth = ctx
		},
		updateEnvDomains(state, ctx) {
			state.envDomains = ctx
		},
		updateNeedChatWithAdmin(state, ctx) {
			state.needChatWithAdmin = ctx
		},
		updateGameProviderList(state, ctx) {
			state.gameProviderList = ctx
		},
		updateMenuBarNavigation(state, ctx) {
			state.menuBarNavigation = ctx
		},
		updateIsOnLoadUserProfile(state, ctx) {
			state.isOnLoadUserProfile = ctx
		},
		updateIsInLineApp(state, value) {
			state.isInLineApp = value
		},
	},
	actions: {
		async superAdminLogout({commit}){
            commit('updateSuperAdmin', null)
		},
		async adminLogout({commit}){
            commit('updateAdmin', null)
        }
	},
	modules: {
		football: Football,
		lotto: Lotto,
		game: Game,
		newlotto:NewLotto,
	}
})
