<template>

    <div class="wrapContentIndex LottoNumberSearch LottoNumberSearchMain">

        <!--     ค้นหาเลขเด็ด    //////////////////////////////////////////-->
        <div class="containerNumSelect popupSearchNum" v-if="isModalSearch">
            <div class="wrapNumSelect">
                <div class="boxNumSelect">
                    <div class="content">
                        <div class="boxGovernmentLottery">
                            <div class="nav-menu-topic topicGovernmentLottery">
                                <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">
                                <div class="group">
                                    <h4>สลากกินแบ่งรัฐบาล</h4>
                                    <span>งวดวันที่ {{date_result}}</span>
                                </div>
                                <button type="button" class="btn" @click="closeModalSearch"><i class="fas fa-times"></i></button>
                            </div>
                            <div class="LottoNumberContent">
                                <div class="LottoNumberList">
                                    <div class="LottoNumberListContent">
                                        <div class="listBoxSearch">
                                            <ul>
                                                
                                                <li v-for="(number,key) of search_number" :key="key">
                                                    <input  type="text" :_index="key" @focus="$event.target.select()" :_name_index="1" @keyup.delete ="onPressDelete(1,key)" v-on:keypress="keyPress0" inputmode="numeric" maxlength="1" class="form-control" :placeholder="key+1" :ref="'search_number1'+key" v-model="search_number[key]" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                                                </li>
                                            </ul>
                                        </div>
                                        <p>กรอกตัวเลขที่คุณต้องการค้นหา</p>
                                        <div class="listBoxSearchGroup">
                                            <button class="btn" :class="{'active':is_lotto_type_all}" @click="setLottoType('all')">ทั้งหมด</button>
                                            <button class="btn" :class="{'active':is_lotto_type_single}" @click="setLottoType('single')">หวยเดี่ยว</button>
                                            <button class="btn" :class="{'active':is_lotto_type_multi}" @click="setLottoType('multi')">หวยชุด</button>
                                        </div>
                                        <div class="boxRandom">
                                            <button type="submit" @click="randomNumber()" class="btn"><i class="fas fa-random" ></i> สุ่มเลข</button>
                                        </div>
                                        <button class="btn btnSearchNum" @click="searchNumber"><i class="fas fa-search"></i> ค้นหาเลขเด็ด</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="blackDropBillDetail"></div>
        </div>

        <div class="wrapContentIndexTitle ">
            <div class="IndexTitleInto">
                <button style="padding: 0 10px;" class="btn " @click="goToIndex()"><i class="fas fa-chevron-left"></i></button>
                <h3>ค้นหาเลขเด็ด</h3>
            </div>
        </div>

        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexRight">
                    <div class="container">
                        <div class="boxGovernmentLottery">
                            <div class="nav-menu-topic topicGovernmentLottery">
                                <img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">
                                <div class="group">
                                    <h4>สลากกินแบ่งรัฐบาล</h4>
                                    <span>งวดวันที่ {{ date_result }}</span>
                                </div>
                            </div>
                            <div class="LottoNumberContent">
                                <div class="LottoNumberList">
                                    <div class="LottoNumberListContent">
                                        <div class="boxGovernmentLottery" >
                                            <div class="listBoxSearch">
                                                <ul>
                                                    <li v-for="(number,key) of search_number" :key="key">
                                                        <input type="text"  :_index="key" @focus="$event.target.select()" :_name_index="2" @keyup.delete ="onPressDelete(2,key)" v-on:keypress="keyPress0"  inputmode="numeric" maxlength="1" class="form-control" :placeholder="key+1" :ref="'search_number2'+key" v-model="search_number[key]" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>กรอกตัวเลขที่คุณต้องการค้นหา</p>
                                            <div class="listBoxSearchGroup">
                                                <button class="btn" :class="{'active':is_lotto_type_all}" @click="setLottoType('all')">ทั้งหมด</button>
                                                <button class="btn" :class="{'active':is_lotto_type_single}" @click="setLottoType('single')">หวยเดี่ยว</button>
                                                <button class="btn" :class="{'active':is_lotto_type_multi}" @click="setLottoType('multi')">หวยชุด</button>
                                            </div>
                                        </div>
                                        <div class="boxRandom"><button type="submit" class="btn" @click="randomNumber()"><i class="fas fa-random"></i> สุ่มเลข</button></div>
                                        <button class="btn btnSearchNum" @click="searchNumber"><i class="fas fa-search"></i>  ค้นหาเลขเด็ด</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="boxGovernmentLottery">
                            <div class="nav-menu-topic">
                                <h4 v-if="isRecommend==false">ผลการค้นหา<span>{{ search_number_result.length }}</span></h4>
                                <h4  v-if="isRecommend">แนะนำเลขเด็ด</h4>
                            </div>
                            <div class="LottoNumberContent">
                                <div class="noneSearchNum" v-if="search_number_result.length==0">
                                    <span>ไม่พบการค้นหา</span>
                                </div>
                                <div class="ContentResultSearchNum" v-else>
<!--                                    ListLottoNumSet-->
                                    <div class="ListLottoNum" v-for="(lotto,key) of search_number_result" :key="key" :class="{'active':lotto.selected ,'ListLottoNumSet' : lotto.lotto_set_count>1}">
                                        <div class="ListLottoNumGroup">
                                            <div class="ListLottoNumLeft">
                                                <div class="topic">
                                                    <div class="tagTopicSet">
                                                        <p>{{ lotto.lotto_set_count }} ใบ</p>
                                                        <span>{{ lotto.lotto_set_count * 6 }} ล.</span>
                                                    </div>
                                                    <h4><img class="lotto-type-icon" src="@/assets/images/GovernmentLottery-logo.png">สลากกินแบ่งรัฐบาล</h4>
                                                </div>
                                                <div class="content">
                                                    <ul>
                                                        <li>{{ lotto.lotto_number.charAt(0) }}</li>
                                                        <li>{{ lotto.lotto_number.charAt(1) }}</li>
                                                        <li>{{ lotto.lotto_number.charAt(2) }}</li>
                                                        <li>{{ lotto.lotto_number.charAt(3) }}</li>
                                                        <li class="active">{{ lotto.lotto_number.charAt(4) }}</li>
                                                        <li class="active">{{ lotto.lotto_number.charAt(5) }}</li>
                                                    </ul>
                                                    <div class="ListDetail">
                                                        <small>ชุดที่</small>
                                                        <strong>{{lotto.lotto_set_count}}</strong>
                                                    </div>
                                                    <div class="ListDetail">
                                                        <small>งวดที่</small>
                                                        <strong>{{lotto.lotto_count}}</strong>
                                                    </div>
                                                </div>
                                                <div class="contentBg"></div>
                                                <div class="contentBgUnder"></div>
                                            </div>
                                            <div class="ListLottoNumRight">
                                                <div class="priceLottoLabel">
                                                    <span>{{ lotto.lotto_price }}</span>บาท
                                                </div>
                                                <button class="btn" @click="chooseLotto(lotto,key)" ></button>

                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="LottoNumberFooter"  v-if="this.cart_list.length>0">
                                <div class="boxLottoNumberFooter">
                                    <ul>
                                        <li><button class="btn" @click="confirmPurchase()">ตรวจสอบรายการ</button></li>
                                        <li>
                                            <p>จำนวนที่เลือก</p>
                                            <strong><span>{{ this.cart_list.length }}</span> ใบ</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import store from '@/store'
    import axiosWrapper from '@/axiosWrapper'
    import moment from 'moment';
    export default {
        name: 'LottoNumberSearch',
        components: {},
        computed: {
            getCurrentCart(){
                return store.state.newlotto.cart_list;
            },
            pes_profile() {
                return store.state.pes_profile
            },
            token() {
                return this.pes_profile.token
            },
        },
        watch: {
            // "search_number": function (newVal, oldVal) {
            //     oldVal
            //     // console.log(this.search_number_old)
            //     // console.log(newVal);
                
            //     for(let i=0;i<newVal.length;i++){
                   
            //         if(newVal[i]!=this.search_number_old[i] && newVal[i]!='' && i!=5){
            //             // let nextFocus = i+1;
            //             // console.log(nextFocus)
            //             // console.log(i);
            //             let name=this.isModalSearch?  ('search_number'+(i+1)) :  ('search_number2'+(i+1))
            //             // console.log(this.showModalSearch,name);
            //             // console.log(this.$refs[name])
            //             // this.$refs['search_number'+i].focus();
            //             this.$refs[name][0].focus()
            //             break;
            //         }else if(newVal[i]=='' && i!=0){
                        
            //             let name=this.isModalSearch?  ('search_number'+(i-1)) :  ('search_number2'+(i-1))
            //             console.log(this.showModalSearch,name);
            //             // console.log(this.$refs[name])
            //             // this.$refs['search_number'+i].focus();
            //             // this.$refs[name][0].focus()

            //         }
            //     }
            //     for(let i=0;i<newVal.length;i++){
            //         this.search_number_old[i]=newVal[i];
            //     }
            // },
        },
        data () {
            return {
                isModalSearch:true,
                isRecommend : true,
                search_number:[
                    '','','','','',''
                ],
                search_number_old : [
                    '','','','','',''
                ],
                search_number_result :[

                ],
                cart_list :[

                ],
                date_result :'',
                is_lotto_type_all : true,
                is_lotto_type_single: false,
                is_lotto_type_multi : false,
                date_result_str:null,

            }
        },
        methods: {

            keyPress0(e){
                this.blockSpecialChar(e )
            },
           
            blockSpecialChar(e) {
                let index =parseInt(e.srcElement.getAttribute('_index'))
                // console.log(e);
                // console.log(index);
                if(e.keyCode>=48  && e.keyCode< 48 +10){
                    if(index<5){
                        let next_index = index+1;
                        let name_index = e.srcElement.getAttribute('_name_index');

                        let search_name = 'search_number'+name_index+''+next_index;
                        // console.log(search_name)
                        setTimeout(()=>{
                            this.$refs[search_name][0].focus();
                            // this.$refs[search_name][0].input.select()
                            // this.$refs['input_number_focus'+this.input_number_focus].focus();
                        },100)
                    }
                    
                }
      
            },
            onPressDelete(name_index,index){
                // console.log('pressDelete',index);
                if(index>0){
                    let next_index = parseInt(index)-1;
                    // console.log(this.$refs['search_number1'+next_index])
                    let name ='search_number'+name_index+''+next_index
                    // console.log(name);  
                    this.$refs[name][0].focus();
                    // this.$refs[name][0].input.select()
                    // this.$refs['search_number2'+next_index][0].focus();
                    // this.$refs['search_number'+i].focus();
                    // console.log('index',this.input_number_focus)
                }
                
            },
            goToIndex() {
                if(this.envDomains.VUE_APP_SITE_TYPE=='lotto'){
                    this.$router.push({'name':'Index'})
                }else{
                    this.$router.push({'name':'LottoNumber'})
                }
            },
            setCartList(cart_list){
                store.commit('newlotto/updateCartList', cart_list);
            },
            closeModalSearch(){
                this.isModalSearch=false;
            },
            showModalSearch(){
                this.isModalSearch=true;
            },
            async searchNumber(){
                if(this.date_result_str){
                    let lotto_number='';
                    let limit =12;
                    // this.search_number_result =[];
                    for(let i=0;i<6;i++){
                        if(this.search_number[i]==''){
                            lotto_number = lotto_number+'x';
                        }else{
                            lotto_number = lotto_number+this.search_number[i];
                        }
                    }
                    let type='all';
                    if(this.is_lotto_type_multi){
                        type='multi';
                    }else if(this.is_lotto_type_single){
                        type='single';
                    }
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gov_lotto/get-search?token=`+this.token+'&lotto_number='+lotto_number+'&limit='+limit+'&type='+type,
                        data: {
                            
                        }
                    })
                    this.search_number_result = res.data.data;
                    for(let i=0;i<this.search_number_result.length;i++){
                        this.search_number_result[i].lotto_date = this.date_result_str;
                    }
                    this.closeModalSearch();
                    this.isRecommend=false;
                }else{
                    alert('ยังไม่มีฉลากงวดใหม่เปิดขาย');
                }

                

            },
            async getRecommend(closeModal){
                if(this.date_result_str){
                    let limit =12;
                    let type='all';
                    if(this.is_lotto_type_multi){
                        type='multi';
                    }else if(this.is_lotto_type_single){
                        type='single';
                    }
                    // console.log('getRecommend', this.date_result_str)
                    const res = await axiosWrapper({
                        method: 'get',
                        url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gov_lotto/get-recommend?token=`+this.token+'&limit='+limit+'&type='+type+'&date='+this.date_result_str,
                        data: {
                            
                        }
                    })
                    this.isRecommend=true;
                    // this.closeModalSearch();
                    this.search_number_result = res.data.data;
                    for(let i=0;i<this.search_number_result.length;i++){
                        this.search_number_result[i].lotto_date = this.date_result_str;
                    }
                    if(closeModal){
                        this.closeModalSearch()
                    }
                }else{
                    alert('ยังไม่มีฉลากงวดใหม่เปิดขาย');
                }
                
                
            },
           
            chooseLotto (l,index){  
                if(this.search_number_result[index].selected){
                    let index_remove = 0;
                    for(let i=0;i<this.cart_list.length;i++){
                        if(this.cart_list[i].lotto_id==l.lotto_id){
                            index_remove=i;
                            break;
                        }
                        
                    }
                    this.cart_list.splice(index_remove,1);
                    this.search_number_result[index].selected=false;

                }else{
                    this.search_number_result[index].selected=true;
                    this.cart_list.push(l);
                }
                this.setCartList(this.cart_list);
            },
            setLottoType(type){
                if(type=='all'){
                    this.is_lotto_type_all=true;
                    this.is_lotto_type_multi=false;
                    this.is_lotto_type_single=false;

                }else if(type=='single'){
                    this.is_lotto_type_all=false;
                    this.is_lotto_type_multi=false;
                    this.is_lotto_type_single=true;

                }else if(type=='multi'){
                    this.is_lotto_type_all=false;
                    this.is_lotto_type_multi=true;
                    this.is_lotto_type_single=false;

                }

            },
            confirmPurchase(){
                this.$router.push({'name':'LottoNumberCheck'})
            },
            randomNumber(){
                for(let i=0;i<6;i++){
                    this.search_number[i]=Math.floor(Math.random() * 10);
                    // console.log(this.search_number);
                    this.$set(this.search_number, i,  this.search_number[i])
                }
                
                
            },
            // getDateResult(){
            //     let day = moment().format('D');
            //     // let hour = moment().format('H');
            //     if(day==1){
            //         this.date_result = moment().format('01/MM/YYYY');
            //         // if(hour<12){
            //         //     this.date_result = moment().format('01/MM/YYYY');
            //         // }else{
            //         //     this.date_result = moment().format('16/MM/YYYY');
            //         // }

            //     }else if(day==16){
            //         this.date_result = moment().format('16/MM/YYYY');
            //         // if(hour){

            //         // }else{

            //         // }

            //     }else if(day<16){
            //         this.date_result = moment().format('16/MM/YYYY');
                    
            //     }else{
            //         this.date_result = moment().add(1,'months').format('01/MM/YYYY');
            //     }
            //     // console.log('yes', moment().format('DD/MM/YYYY'))
            //     let date_array=this.date_result.split("/");
            //     this.date_result = date_array[0]+'/'+date_array[1]+'/'+(parseInt(date_array[2])+543)

            // },
            async getGoldNumberList(){
                
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_API}/api/pes/service/lotto/gold_number/get?token=`+this.token+'&game_status=1&game_type=0&date=',
                    data: {
                        
                    }
                })
               
                if(res.data.data.length>0){
                    let first_data = res.data.data[0];
                            // console.log('first',first_data);
                    let date_array = moment(first_data.date).format('YYYY-MM-DD').split("-");
                    this.date_result_str = moment(first_data.date).format('YYYY-MM-DD')
                    // console.log('getGoldNumberLIst', this.date_result_str)
                    
                    this.date_result = date_array[2]+'/'+date_array[1]+'/'+(parseInt(date_array[0])+543) 
                }
                
                
            },
            
        },
        async mounted() {
            this.cart_list = this.getCurrentCart
            await this.getGoldNumberList();
            if(this.date_result_str){
                await this.getRecommend();
            }
            
            // this.getDateResult();
            // console.log('date_result',this.date_result);
        }
    }
</script>